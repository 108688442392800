var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Form, Field, FormElement } from "@progress/kendo-react-form";
import { Transform } from "helper/Transform";
import { TranslationConstant } from "common/constant";
import { FormTextArea } from "components/shared/FormComponents";
import FormButtons from "components/shared/FormButton";
import { requiredValidator } from "components/shared/FormValidators";
var SignandSubmitDialogForm = function (props) {
    var handleSignAndSubmit = function (e) {
        if (e.isValid) {
            props === null || props === void 0 ? void 0 : props.handleSubmit(e);
            // Todo Need to call create time sheet api
        }
    };
    return (_jsx(_Fragment, { children: _jsx(Form, { onSubmitClick: handleSignAndSubmit, render: function (formRenderProps) { return (_jsxs(FormElement, __assign({ className: "general-form border-0 mx-0 pb-0 mb-0" }, { children: [props.alertNote && (_jsxs("div", __assign({ className: "alert alert-danger" }, { children: [_jsx(Transform, { mkey: TranslationConstant.SIGN_AND_SUBMIT_PRE_COMMENT_TXT }), " ", props.alertNote, _jsx(Transform, { mkey: TranslationConstant.SIGN_AND_SUBMIT_POST_COMMENT_TXT })] }))), _jsx(Field, { id: "Comment", label: _jsx(Transform, { mkey: props.customLabel
                                ? props.customLabel
                                : TranslationConstant.LABEL_COMMENT }), name: "Comment", component: FormTextArea, maxlength: "100", showCharacterLimit: true, rows: 2, isRequired: props.isRequired, validator: props.isRequired && requiredValidator }), props.stateAgreementDataText, _jsx("div", __assign({ className: "k-form-buttons mt-4 pb-0 pe-0" }, { children: _jsx(FormButtons, { wrapperClass: "pe-0 pb-1", hideResetButton: true, customSubmitText: props.customSubmitBtnText
                                ? props.customSubmitBtnText
                                : TranslationConstant.SIGN_AND_SUBMIT, closeText: _jsx(Transform, { mkey: TranslationConstant.CLOSE_BUTTON }), showCancelButton: true, cancelHandler: props.handleClose }) }))] }))); } }) }));
};
export default SignandSubmitDialogForm;
