var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { Form, Field, FormElement } from "@progress/kendo-react-form";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import { useCurrentViewContext, Transform, ComponentConstant, TranslationConstant, withValueField, FormInput, FormHeading, FormButtons, useSecurityContext, decodedURL, } from "./index";
var DropDownListWithValueField = withValueField(DropDownList);
export var SearchWebUserForm = function (props) {
    var queryParameters = JSON.parse(new URLSearchParams(window.location.search).get("userstatus"));
    var location = useLocation();
    var userData = (location.state || {}).userData;
    var UserRoleId = decodedURL().UserRoleId;
    var _a = useCurrentViewContext(), userStatusDropDownList = _a.userStatusDropDownList, fetchUserStatusList = _a.fetchUserStatusList;
    var _b = useSecurityContext(), roleListForSearchWebUser = _b.roleListForSearchWebUser, fetchAllRolesList = _b.fetchAllRolesList;
    var _c = useState(null), userrole = _c[0], setuserrole = _c[1];
    var _d = useState(1), userStatusValue = _d[0], setuserStatusValue = _d[1];
    var _e = useState({}), initialUserData = _e[0], updateInitialUserData = _e[1];
    useEffect(function () {
        useCurrentViewContext.getState().resetShowProgress(true);
        setuserStatusValue(1);
        fetchUserStatusList();
        fetchAllRolesList();
        if (queryParameters !== null || (userData !== undefined && Object.keys(userData).length)) {
            setuserStatusValue(queryParameters ||
                (userData && userData.UserStatus && parseInt(userData.UserStatus)) ||
                1);
            setuserrole((userData && userData.UserRole && parseInt(userData.UserRole)) || null);
            updateInitialUserData(userData);
            var dataObj = {
                firstName: (userData === null || userData === void 0 ? void 0 : userData.firstName) || null,
                lastName: (userData === null || userData === void 0 ? void 0 : userData.lastName) || null,
                UserStatus: queryParameters || (userData === null || userData === void 0 ? void 0 : userData.UserStatus) || null,
                UserRole: (userData === null || userData === void 0 ? void 0 : userData.UserRole) || null,
            };
            props.UserSearchdata(dataObj);
        }
    }, [queryParameters, userData]);
    useEffect(function () {
        if (UserRoleId) {
            setuserrole(parseInt(UserRoleId));
        }
    }, [UserRoleId]);
    var userRoleDefaultItem = {
        Name: _jsx(Transform, { mkey: TranslationConstant.LABEL_DEFAULT_OPTION_AGENT }),
        roleID: null,
    };
    var handleOnUserStatusOpen = function () {
        if (userStatusDropDownList.length === 0) {
            fetchUserStatusList();
        }
    };
    var handleUserStatusChange = function (event) {
        setuserStatusValue(event.target.value);
    };
    var handleOnUserRoleOpen = function () {
        if (roleListForSearchWebUser.length === 0) {
            fetchAllRolesList();
        }
    };
    var handleUserRoleChange = function (event) {
        setuserrole(event.target.value);
    };
    var handleSubmit = function (dataItem) {
        var _a, _b, _c, _d;
        var dataObj = {
            firstName: ((_b = (_a = dataItem === null || dataItem === void 0 ? void 0 : dataItem.values) === null || _a === void 0 ? void 0 : _a.firstName) === null || _b === void 0 ? void 0 : _b.trim())
                ? dataItem.values.firstName.trim()
                : null,
            lastName: ((_d = (_c = dataItem === null || dataItem === void 0 ? void 0 : dataItem.values) === null || _c === void 0 ? void 0 : _c.lastName) === null || _d === void 0 ? void 0 : _d.trim()) ? dataItem.values.lastName.trim() : null,
            UserStatus: userStatusValue || null,
            UserRole: userrole || null,
        };
        props.UserSearchdata(dataObj);
    };
    var SearchWebUser = {
        title: _jsx(Transform, { mkey: TranslationConstant.SEARCH_NEWHIRE_DOCUMENT_TITLE }),
    };
    var handleresetform = function () {
        setuserStatusValue(1);
        setuserrole(null);
    };
    return (_jsxs(_Fragment, { children: [_jsx(FormHeading, { data: SearchWebUser }), _jsx(Form, { onSubmitClick: handleSubmit, initialValues: initialUserData, render: function (formRenderProps) { return (_jsxs(FormElement, __assign({ className: "general-form add-new-job-form" }, { children: [_jsxs("fieldset", __assign({ className: "k-form-fieldset row" }, { children: [_jsx("div", __assign({ className: "col-xs-12 col-md-6 mb-3" }, { children: _jsx(Field, { id: "first_name", name: "firstName", component: FormInput, maxLength: 50, label: _jsx(Transform, { mkey: TranslationConstant.LABEL_FIRST_NAME }), placeholder: ComponentConstant.PLACEHOLDER_LABEL_FIRST_NAME }) })), _jsx("div", __assign({ className: "col-xs-12 col-md-6 mb-3" }, { children: _jsx(Field, { id: "last_name", name: "lastName", maxLength: 50, label: _jsx(Transform, { mkey: TranslationConstant.LABEL_LAST_NAME }), component: FormInput, placeholder: ComponentConstant.PLACEHOLDER_LABEL_LAST_NAME }) })), _jsx("div", __assign({ className: "col-xs-12 col-md-6 mb-4" }, { children: _jsx(DropDownListWithValueField, { id: "userStatus", name: "UserStatus", label: _jsx(Transform, { mkey: TranslationConstant.LABEL_USER_STATUS }), data: userStatusDropDownList, onOpen: handleOnUserStatusOpen, textField: "webUserStatus", valueField: "webUserStatusID", value: userStatusValue, onChange: handleUserStatusChange }) })), _jsx("div", __assign({ className: "col-xs-12 col-md-6 mb-4" }, { children: _jsx(DropDownListWithValueField, { id: "userrole", name: "UserRole", label: _jsx(Transform, { mkey: TranslationConstant.LABEL_USER_ROLE }), data: roleListForSearchWebUser, defaultItem: userRoleDefaultItem, textField: "Name", valueField: "WebUserRoleId", value: userrole, onOpen: handleOnUserRoleOpen, onChange: handleUserRoleChange }) }))] })), _jsx(FormButtons, { searchType: true, resetHandler: function () {
                                formRenderProps.onFormReset();
                                handleresetform();
                            } })] }))); } }, JSON.stringify(initialUserData))] }));
};
