import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import InvalidUserPage from "components/shared/InvalidUserPage";
import AdminAsEmployeeLogin from "./AdminAsEmployeeLogin";
export var InvalidUserRoutes = function () {
    var element = document.querySelector(".application-loader");
    if (element)
        element.style.display = "none";
    return (_jsx(BrowserRouter, { children: _jsxs(Routes, { children: [_jsx(Route, { path: "/invaliduser", element: _jsx(InvalidUserPage, {}) }), _jsx(Route, { path: "/loginasemployee", element: _jsx(AdminAsEmployeeLogin, {}) })] }) }));
};
