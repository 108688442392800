var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { create } from "zustand";
import { getClientUserList, getClientSupervisorManagerList, getClientWorkersList, postSelectedSnM, exportWorkerData, getClientUserData, getClientUserWebSettingData, saveClientUserData, saveClientUserWebSettingsData, getRevalidationWorkerList, getSearchClients, editClient, getRevalidationWorkersListExport, getDownloadWorkerData } from "services/clientusers/clientusers";
export var useClientUserViewContext = create(function (set, get) { return ({
    clientUserList: [],
    supervisorAndManagerList: [],
    clienWorkersList: [],
    clientUserDataList: [],
    clientUserWebSettingsDataList: [],
    searchClientsList: [],
    fetchClientUserList: function (groupData) { return __awaiter(void 0, void 0, void 0, function () {
        var response;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, getClientUserList(groupData)];
                case 1:
                    response = _a.sent();
                    set(function (state) { return (__assign(__assign({}, state), { clientUserList: response.data })); });
                    return [2 /*return*/, response];
            }
        });
    }); },
    resetClientUserList: function () {
        set(function (state) { return (__assign(__assign({}, state), { clientUserList: [] })); });
    },
    fetchClientSupervisorAndManagerList: function (clientId) { return __awaiter(void 0, void 0, void 0, function () {
        var response;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, getClientSupervisorManagerList(clientId)];
                case 1:
                    response = _a.sent();
                    set(function (state) { return (__assign(__assign({}, state), { supervisorAndManagerList: response.data })); });
                    return [2 /*return*/, response];
            }
        });
    }); },
    getClientWorkersList: function (param) { return __awaiter(void 0, void 0, void 0, function () {
        var response;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, getClientWorkersList(param)];
                case 1:
                    response = _a.sent();
                    set(function (state) { return (__assign(__assign({}, state), { clienWorkersList: response.data.items })); });
                    return [2 /*return*/, response];
            }
        });
    }); },
    postSelectedSupAndMan: function (postData, isEdit) { return __awaiter(void 0, void 0, void 0, function () { return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, postSelectedSnM(postData)];
            case 1: return [2 /*return*/, _a.sent()];
        }
    }); }); },
    exportWorkerData: function (status, clientID) { return __awaiter(void 0, void 0, void 0, function () {
        var response;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, exportWorkerData(status, clientID)];
                case 1:
                    response = _a.sent();
                    return [2 /*return*/, response];
            }
        });
    }); },
    fetchClientUserData: function (clientUserId) { return __awaiter(void 0, void 0, void 0, function () {
        var response;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, getClientUserData(clientUserId)];
                case 1:
                    response = _a.sent();
                    set(function (state) { return (__assign(__assign({}, state), { clientUserDataList: response.data })); });
                    return [2 /*return*/, response];
            }
        });
    }); },
    fetchClientUserWebSettingData: function (clientUserId) { return __awaiter(void 0, void 0, void 0, function () {
        var response;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, getClientUserWebSettingData(clientUserId)];
                case 1:
                    response = _a.sent();
                    set(function (state) { return (__assign(__assign({}, state), { clientUserWebSettingsDataList: response.data })); });
                    return [2 /*return*/, response];
            }
        });
    }); },
    saveClientUserData: function (grouptdata) { return __awaiter(void 0, void 0, void 0, function () { return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, saveClientUserData(grouptdata)];
            case 1: return [2 /*return*/, _a.sent()];
        }
    }); }); },
    saveClientUserWebSettingsData: function (grouptdata) { return __awaiter(void 0, void 0, void 0, function () { return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, saveClientUserWebSettingsData(grouptdata)];
            case 1: return [2 /*return*/, _a.sent()];
        }
    }); }); },
    getRevalidationWorkerList: function (param) { return __awaiter(void 0, void 0, void 0, function () { return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, getRevalidationWorkerList(param)];
            case 1: return [2 /*return*/, _a.sent()];
        }
    }); }); },
    fetchSearchClients: function (queryString) { return __awaiter(void 0, void 0, void 0, function () {
        var response;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, getSearchClients(queryString)];
                case 1:
                    response = _a.sent();
                    set(function (state) { return (__assign(__assign({}, state), { searchClientsList: response.data })); });
                    return [2 /*return*/, response];
            }
        });
    }); },
    resetSearchClients: function () {
        set(function (state) { return (__assign(__assign({}, state), { searchClientsList: [] })); });
    },
    editClient: function (data) { return __awaiter(void 0, void 0, void 0, function () {
        var response;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, editClient(data)];
                case 1:
                    response = _a.sent();
                    return [2 /*return*/, response];
            }
        });
    }); },
    getRevalidationWorkersListExport: function (clientID, status, reValidatedYear) { return __awaiter(void 0, void 0, void 0, function () {
        var response;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, getRevalidationWorkersListExport(clientID, status, reValidatedYear)];
                case 1:
                    response = _a.sent();
                    return [2 /*return*/, response];
            }
        });
    }); },
    downloadWorkerData: function (ClientId) { return __awaiter(void 0, void 0, void 0, function () {
        var response;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, getDownloadWorkerData(ClientId)];
                case 1:
                    response = _a.sent();
                    return [2 /*return*/, response];
            }
        });
    }); },
}); });
