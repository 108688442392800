var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { UserManager, WebStorageStateStore } from "oidc-client";
import { useNavigate } from "react-router-dom";
import { AppConstant } from "common/constant";
import { getCookie, redirectAdminUser } from "components/shared/utils";
import { cleanLocalUserStorage } from "components/shared/utils";
import { useCurrentViewContext } from "store/common/currentViewContext";
import { startTimeInterval } from "components/shared/utils";
var config = {
    authority: process.env.AUTHORITY_URL,
    client_id: process.env.CLIENT_ID,
    redirect_uri: process.env.REDIRECT_URI,
    client_uri: process.env.CLIENT_URI,
    post_logout_redirect_uri: process.env.POST_LOGOUT_REDIRECT_URI,
    response_type: process.env.RESPONSE_TYPE,
    automaticSilentRenew: true,
    includeIdTokenInSilentRenew: true,
    scope: "openid profile",
    accessTokenExpiringNotificationTime: 120,
    loadUserInfo: true,
    userStore: new WebStorageStateStore({ store: window.localStorage }),
};
var userManager = new UserManager(config);
var getUser = function () { return __awaiter(void 0, void 0, void 0, function () {
    return __generator(this, function (_a) {
        return [2 /*return*/, userManager.getUser()];
    });
}); };
export var signinRedirect = function () { return __awaiter(void 0, void 0, void 0, function () {
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, getUser()];
            case 1:
                _a.sent();
                return [2 /*return*/, userManager.signinRedirect()];
        }
    });
}); };
export var signinRedirectCallback = function () {
    return userManager.signinRedirectCallback();
};
export var signoutRedirect = function () { return __awaiter(void 0, void 0, void 0, function () {
    var user;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, getUser()];
            case 1:
                user = _a.sent();
                userManager.clearStaleState();
                userManager.removeUser();
                return [4 /*yield*/, userManager.signoutRedirect({
                        id_token_hint: user && user.id_token,
                    })];
            case 2: return [2 /*return*/, _a.sent()];
        }
    });
}); };
export var signoutRedirectCallback = function () {
    userManager.clearStaleState();
    userManager.removeUser();
    var localStorageKey = "".concat(AppConstant.LOCAL_STORAGE_USER_PROFILE, ":").concat(process.env.AUTHORITY_URL, ":").concat(process.env.CLIENT_ID);
    var localStorageEmployeeKey = "".concat(AppConstant.LOCAL_STORAGE_EMPLOYEE_DATA, ":").concat(process.env.AUTHORITY_URL, ":").concat(process.env.CLIENT_ID);
    localStorage.removeItem(localStorageKey);
    localStorage.removeItem(localStorageEmployeeKey);
    localStorage.removeItem("sessionextend");
    localStorage.removeItem("flashIdArray");
    document.cookie = "IsLogoutTriggered=false";
    return userManager.signoutRedirectCallback();
};
export var exendUserSession = function () { return __awaiter(void 0, void 0, void 0, function () {
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, userManager.signinSilent()];
            case 1: return [2 /*return*/, _a.sent()];
        }
    });
}); };
export var signinSilentSessionCallback = function () {
    if (localStorage.getItem("adminClientLoggedIn")) {
        var selectedClient = JSON.parse(localStorage.getItem("selectedClient"));
        redirectAdminUser(selectedClient === null || selectedClient === void 0 ? void 0 : selectedClient.clientID);
    }
    return userManager.signinRedirectCallback();
};
export var renewSilentSession = function () { return __awaiter(void 0, void 0, void 0, function () {
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                useCurrentViewContext.getState().setIsApiRequest(false);
                startTimeInterval();
                return [4 /*yield*/, userManager.signinSilent()];
            case 1: return [2 /*return*/, _a.sent()];
        }
    });
}); };
export var IsSessionExpired = function () {
    var isLogoutTriggered = getCookie("IsLogoutTriggered");
    var oidcStorage = JSON.parse(localStorage.getItem("oidc.user:".concat(process.env.AUTHORITY_URL, ":").concat(process.env.CLIENT_ID)));
    if (isLogoutTriggered === "true") {
        var Navigate = useNavigate();
        Navigate("/signout-oidc");
        return true;
    }
    else if (oidcStorage === null ||
        (oidcStorage && oidcStorage.expires_at === undefined) ||
        oidcStorage.expires_at < new Date().getTime() / 1000) {
        cleanLocalUserStorage();
        return true;
    }
    return false;
};
export default userManager;
