var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useEffect, useState } from "react";
import { AppConstant } from "common/constant";
import { DialogBox } from "components/shared/DialogBox";
import { renewSession } from "services/common/httpPromise";
var SessionExtendDialog = function () {
    var oidcStorage = JSON.parse(localStorage.getItem("oidc.user:".concat(process.env.AUTHORITY_URL, ":").concat(process.env.CLIENT_ID))) || null;
    var _a = useState(false), userSessionExtendModal = _a[0], updateUserSessionModal = _a[1];
    var _b = useState(localStorage.getItem("sessionextend")
        ? JSON.parse(localStorage.getItem("sessionextend"))
        : true), sessionExtendRequired = _b[0], updateSessionExtendRequired = _b[1];
    var _c = useState({
        headingText: "Please Confirm",
        bodyText: "",
        showSpinner: false,
        isErrorDialog: false,
    }), modalContent = _c[0], updateModalContent = _c[1];
    useEffect(function () {
        if (oidcStorage !== null) {
            var interval_1 = setInterval(function () {
                var oidcStorage = JSON.parse(localStorage.getItem("oidc.user:".concat(process.env.AUTHORITY_URL, ":").concat(process.env.CLIENT_ID)));
                var oidcStorageExpiryVal = (oidcStorage && oidcStorage.expires_at) || 0;
                var getCurrentTime = new Date().getTime() / 1000;
                var timeDifferenceInMin = Math.floor((oidcStorageExpiryVal - getCurrentTime) / 60);
                if (timeDifferenceInMin <= AppConstant.USER_SESSION_EXTENDED_POPUP_TIME &&
                    timeDifferenceInMin > 0 &&
                    sessionExtendRequired) {
                    updateUserSessionModal(true);
                    updateModalContent(__assign(__assign({}, modalContent), { bodyText: (_jsxs("div", __assign({ className: "block subheading" }, { children: [_jsx("i", { className: "fa fa-info-circle fa-lg" }), _jsxs("span", { children: ["Your session is going to expire in ", timeDifferenceInMin, " ", timeDifferenceInMin <= 1 ? "minute" : "minutes", "."] }), _jsx("span", __assign({ className: "block" }, { children: "Do you want to extend it?" }))] }))) }));
                }
                else if (timeDifferenceInMin <= 0 || oidcStorageExpiryVal === 0) {
                    updateUserSessionModal(false);
                    window.location.href = "/signout-oidc";
                }
                else {
                    updateUserSessionModal(false);
                    updateModalContent(__assign(__assign({}, modalContent), { showSpinner: false }));
                }
            }, 1000);
            return function () { return clearInterval(interval_1); };
        }
    }, [oidcStorage, sessionExtendRequired]);
    var onAcceptHandler = function () { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            renewSession();
            updateModalContent(__assign(__assign({}, modalContent), { showSpinner: true }));
            updateUserSessionModal(false);
            return [2 /*return*/];
        });
    }); };
    var hideModal = function () {
        updateUserSessionModal(false);
        updateSessionExtendRequired(false);
        localStorage.setItem("sessionextend", "false");
    };
    return (_jsx(_Fragment, { children: userSessionExtendModal && (_jsx(DialogBox, { headingText: modalContent.headingText, bodyText: modalContent.bodyText, acceptText: _jsxs("div", __assign({ className: "accept-wrapper" }, { children: [modalContent.showSpinner && (_jsx("i", { className: "fa fa-spinner fa-spin", "aria-hidden": "true" })), _jsx("span", { children: "Yes" })] })), cancelText: "No", isDialogOpen: userSessionExtendModal, acceptHandler: onAcceptHandler, onModalChangeState: hideModal })) }));
};
export default SessionExtendDialog;
