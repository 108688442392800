var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { Transform } from "helper/Transform";
import { TranslationConstant } from "common/constant";
import { useState } from "react";
var useUpdateDialogContent = function () {
    var _a = useState(), dialogContent = _a[0], setDialogContentData = _a[1];
    var _b = useState(false), showDialog = _b[0], setShowDialog = _b[1];
    var setDialogContent = function (data) {
        var headingText = data.headingText, displayMsg = data.displayMsg, staticMsg = data.staticMsg, iconClass = data.iconClass, _a = data.acceptTextKey, acceptTextKey = _a === void 0 ? null : _a, _b = data.cancelTextKey, cancelTextKey = _b === void 0 ? null : _b, acceptHandler = data.acceptHandler, isErrorDialog = data.isErrorDialog, _c = data.hideCancel, hideCancel = _c === void 0 ? false : _c, _d = data.showBodyIcon, showBodyIcon = _d === void 0 ? true : _d;
        var objData = __assign(__assign({}, data), { headingText: _jsx(Transform, { mkey: headingText }), bodyText: (_jsxs("div", __assign({ className: "block subheading" }, { children: [showBodyIcon && (_jsxs(_Fragment, { children: [iconClass && _jsx("i", { className: iconClass }), !iconClass && !isErrorDialog && _jsx("i", { className: "fa fa-check fa-lg" }), !iconClass && !!isErrorDialog && (_jsx("i", { className: "fa fa-times-circle fa-lg" }))] })), _jsx("span", { children: staticMsg ? staticMsg : _jsx(Transform, { mkey: displayMsg }) })] }))), cancelText: cancelTextKey ? (cancelTextKey) : (_jsx(Transform, { mkey: TranslationConstant.CLOSE_BUTTON })), isErrorDialog: !!isErrorDialog });
        if (acceptTextKey && acceptHandler) {
            objData = __assign(__assign({}, objData), { acceptHandler: acceptHandler, acceptText: _jsx(Transform, { mkey: acceptTextKey }) });
        }
        if (hideCancel) {
            objData = __assign(__assign({}, objData), { cancelText: null });
        }
        setDialogContentData(objData);
    };
    return {
        dialogContent: dialogContent,
        setDialogContent: setDialogContent,
        showDialog: showDialog,
        setShowDialog: setShowDialog,
    };
};
export default useUpdateDialogContent;
