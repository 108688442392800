var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import { Grid, GridColumn } from "@progress/kendo-react-grid";
import { cloneElement } from "react";
export default function ChildGird(_a) {
    var columns = _a.columns, gridData = _a.gridData, _b = _a.isRowRender, isRowRender = _b === void 0 ? false : _b;
    var rowRender = function (trElement, props) {
        var _a;
        var budgetException = isRowRender && ((_a = props === null || props === void 0 ? void 0 : props.dataItem) === null || _a === void 0 ? void 0 : _a.budgetException);
        var red = {
            backgroundColor: "rgb(243, 23, 0, 0.32)",
        };
        var trProps = {
            style: budgetException ? red : {},
        };
        return cloneElement(trElement, __assign({}, trProps), trElement.props.children);
    };
    return (_jsx(Grid, __assign({ sortable: false, filterable: false, data: gridData, resizable: true, rowRender: rowRender }, { children: columns.map(function (col) { return (_jsx(GridColumn, __assign({}, col))); }) })));
}
