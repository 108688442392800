var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import { Grid, ActionCell, getUserDetailsFromLocalStorage, createEncodedURL, } from "components/shared";
import { useNavigate, useParams } from "react-router-dom";
import { getSelectedState, } from "@progress/kendo-react-grid";
import { getter } from "@progress/kendo-data-query";
import { useState, useEffect, cloneElement, useCallback } from "react";
import { useBatchesImportContext } from "store/batchimport/batchimport";
import { DateFilterCell } from "components/shared/GridCommonCells";
export default function ARVPDetailsGrid(_a) {
    var _b = _a.colums, colums = _b === void 0 ? [] : _b, _c = _a.gridData, gridData = _c === void 0 ? [] : _c, handleRefresh = _a.handleRefresh, _d = _a.multiSelect, multiSelect = _d === void 0 ? false : _d, _e = _a.tabCode, tabCode = _e === void 0 ? "" : _e, _f = _a.dataItemKey, dataItemKey = _f === void 0 ? "" : _f, _g = _a.backRoute, backRoute = _g === void 0 ? "" : _g, _h = _a.showReallocateAccountMsg, showReallocateAccountMsg = _h === void 0 ? false : _h, _j = _a.handleBudgetException, handleBudgetException = _j === void 0 ? false : _j;
    var DATA_ITEM_KEY = dataItemKey;
    var SELECTED_FIELD = "selected";
    var idGetter = getter(DATA_ITEM_KEY);
    var oidcUserProfile = getUserDetailsFromLocalStorage("pro");
    var navigate = useNavigate();
    var params = useParams();
    var exportsId = parseInt(params.exportsId);
    var _k = useState(gridData), GridData = _k[0], setGridData = _k[1];
    var _l = useBatchesImportContext(), fetchExportExpandDetails = _l.fetchExportExpandDetails, selectedExportsData = _l.selectedExportsData, setSelectedExportsData = _l.setSelectedExportsData;
    var _m = useState(selectedExportsData["".concat(tabCode)] || {}), selectedRecords = _m[0], setSelectedRecords = _m[1];
    useEffect(function () {
        setGridData(gridData);
    }, [gridData]);
    useEffect(function () {
        if (selectedExportsData && selectedExportsData.length >= 0) {
            var formattedList = selectedExportsData.map(function (list) {
                return __assign(__assign({}, list), { invoiceDate: (list === null || list === void 0 ? void 0 : list.invoiceDate) ? new Date(list === null || list === void 0 ? void 0 : list.invoiceDate) : null });
            });
            setGridData(formattedList);
        }
    }, [selectedExportsData]);
    var updateSelectedRows = function (values) {
        var selectedRows = __assign({}, selectedExportsData);
        selectedRows["".concat(tabCode)] = values;
        setSelectedExportsData(selectedRows);
    };
    var getColumns = function () {
        return multiSelect
            ? [
                {
                    title: "",
                    field: SELECTED_FIELD,
                    width: "40px",
                    filterable: false,
                    headerSelectionValue: GridData.findIndex(function (item) { return !selectedRecords[idGetter(item)]; }) === -1,
                },
            ]
            : [];
    };
    var onViewBtnClick = function (cell) {
        var dataItem = cell.dataItem;
        var url = createEncodedURL("/viewvendorpayment", {
            page: "view",
            vendorPaymentID: dataItem.vendorPaymentID,
            FirstName: dataItem.firstName,
            LastName: dataItem.lastName,
            UserRoleId: oidcUserProfile === null || oidcUserProfile === void 0 ? void 0 : oidcUserProfile.WebUserRoleId,
            ClientId: oidcUserProfile === null || oidcUserProfile === void 0 ? void 0 : oidcUserProfile.ClientId,
            weekEndingDate: dataItem.weekEndingDate,
            redirectFrom: "batches",
            redirectFromUrl: {
                id: "batchesVendorPayments",
                text: "Export Vendor Payments",
                route: backRoute || "/batcharexports/".concat(exportsId, "?activetab=4"),
            },
            showReallocateAccountMsg: showReallocateAccountMsg,
        });
        navigate(url);
    };
    var Columns = __spreadArray(__spreadArray(__spreadArray(__spreadArray([], getColumns(), true), [
        {
            cell: function (cell) { return (_jsx(ActionCell, { cellprops: cell, title: "View", onClick: function () { return onViewBtnClick(cell); } })); },
            field: "",
            title: "View",
            width: "80px",
            filterable: false,
        }
    ], false), colums, true), [
        {
            field: "batchName",
            title: "Batch Name",
            width: "150px",
        },
        {
            field: "invoiceDate",
            title: "Invoiced Date",
            width: "150px",
            filter: "date",
            format: "{0:MM/dd/yy}",
            filterCell: DateFilterCell,
        },
        {
            field: "companyName",
            title: "Company Name",
            width: "150px",
        },
        {
            field: "lastName",
            title: "Last Name",
            width: "150px",
        },
        {
            field: "firstName",
            title: "First Name",
            width: "150px",
        },
        {
            field: "accountName",
            title: "Account",
            width: "150px",
        },
        {
            field: "subAccountName",
            title: "Sub Account",
            width: "150px",
        },
        {
            field: "invoicedAmount",
            title: "Invoiced Amount",
            width: "150px",
            format: "{0:c2}",
        },
    ], false);
    var onSelectionChange = useCallback(function (event) {
        var _a;
        var newSelectedState = handleBudgetException && ((_a = event.dataItems["".concat(event.endRowIndex)]) === null || _a === void 0 ? void 0 : _a.budgetException)
            ? selectedRecords
            : getSelectedState({
                event: event,
                selectedState: selectedRecords,
                dataItemKey: DATA_ITEM_KEY,
            });
        setSelectedRecords(newSelectedState);
        updateSelectedRows(newSelectedState);
    }, [selectedRecords]);
    var onHeaderSelectionChange = useCallback(function (event) {
        var checkboxElement = event.syntheticEvent.target;
        var checked = checkboxElement.checked;
        var newSelectedState = {};
        event.dataItems.forEach(function (item) {
            newSelectedState[idGetter(item)] =
                handleBudgetException && (item === null || item === void 0 ? void 0 : item.budgetException) ? false : checked;
        });
        setSelectedRecords(newSelectedState);
        updateSelectedRows(newSelectedState);
    }, []);
    var rowRender = function (trElement, props) {
        var _a;
        var budgetException = ((_a = props === null || props === void 0 ? void 0 : props.dataItem) === null || _a === void 0 ? void 0 : _a.budgetException) && handleBudgetException;
        var red = {
            backgroundColor: "rgb(243, 23, 0, 0.32)",
        };
        var trProps = {
            style: budgetException ? red : {},
        };
        return cloneElement(trElement, __assign({}, trProps), trElement.props.children);
    };
    var customFilterTitles = {
        subAccountName: "Sub Account",
        invoiceDate: "Invoiced Date",
    };
    return (_jsx(_Fragment, { children: multiSelect ? (_jsx(Grid, { columns: Columns, customFilterTitles: customFilterTitles, gridData: GridData.map(function (item) {
                var _a;
                return (__assign(__assign({}, item), (_a = {}, _a[SELECTED_FIELD] = selectedRecords[idGetter(item)], _a)));
            }), dataItemKey: DATA_ITEM_KEY, selectedField: SELECTED_FIELD, selectable: {
                enabled: true,
                drag: false,
                cell: false,
                mode: "multiple",
            }, onSelectionChange: onSelectionChange, onHeaderSelectionChange: onHeaderSelectionChange, handleRefresh: handleRefresh, rowRender: rowRender })) : (_jsx(Grid, { handleRefresh: handleRefresh, columns: Columns, customFilterTitles: customFilterTitles, gridData: GridData })) }));
}
