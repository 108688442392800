var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { forwardRef, useEffect, useState, useImperativeHandle } from "react";
import { GridColumn as Column } from "@progress/kendo-react-grid";
import { AppConstant, getGridItemCount, ComponentConstant, KendoGrid, GridOData, useSearchEmpViewContext, DialogBox, Transform, TranslationConstant, } from "./index";
import { Link } from "react-router-dom";
import { formatDate } from "@progress/kendo-intl";
var Grid_Item_Count = getGridItemCount();
var initialDataState = {
    skip: 0,
    take: Grid_Item_Count,
    sort: [{ field: "lastName", dir: "asc" }],
};
var TimeClockGrid = forwardRef(function (props, ref) {
    var searchData = props.searchData, updateDayTimeDetails = props.updateDayTimeDetails;
    var _a = useSearchEmpViewContext(), fetchManagerEmployeeList = _a.fetchManagerEmployeeList, resetAdminEmployeeList = _a.resetAdminEmployeeList, removeManagerEmployeeDetails = _a.removeManagerEmployeeDetails;
    var _b = useState({
        data: [],
        total: 0,
    }), gridData = _b[0], setGridData = _b[1];
    var _c = useState(false), forceReload = _c[0], setForceReload = _c[1];
    var _d = useState(initialDataState), dataState = _d[0], setDataState = _d[1];
    var _e = useState(false), modalState = _e[0], updateModalState = _e[1];
    var _f = useState({
        headingText: "",
        bodyText: "",
        isErrorDialog: false,
    }), modalContent = _f[0], updateModalContent = _f[1];
    useEffect(function () {
        return function () {
            resetAdminEmployeeList();
        };
    }, []);
    var dataReceived = function (results) {
        setGridData(results);
    };
    var dataStateHandle = function (e) {
        setDataState(e);
    };
    var refreshGridDataHandler = function () {
        setForceReload(!forceReload);
    };
    useImperativeHandle(ref, function () { return ({
        refreshGridDataHandler: refreshGridDataHandler
    }); });
    var hideModal = function () {
        refreshGridDataHandler();
        updateModalState(false);
    };
    var handleEditClick = function (dataItem) {
        var details = {
            weekEndingDate: dataItem.weekEndingDate,
            timeSheetID: dataItem.timeSheetID,
            employeeID: dataItem.employeeID,
            dateWorked: dataItem.date,
            timeSheetDayDetailID: dataItem.timesheetDayDetailID,
            isDuplicateTimesheet: false
        };
        updateDayTimeDetails(details);
    };
    var deleteTimeSheetHandler = function (props) {
        updateModalContent({
            headingText: _jsx(Transform, { mkey: TranslationConstant.DELETE_TIMESTAMP_HEADING }),
            bodyText: (_jsxs("div", __assign({ className: "block subheading" }, { children: [_jsx("i", { className: "fa fa-info-circle fa-lg" }), _jsx("span", { children: _jsx(Transform, { mkey: TranslationConstant.TIMECLOCK_DELETE_TIMESTAMP }) })] }))),
            cancelText: _jsx(Transform, { mkey: TranslationConstant.CLOSE_BUTTON }),
            acceptText: _jsx(Transform, { mkey: TranslationConstant.DELETE }),
            acceptHandler: function () { return onDeleteAccept(props); },
            hidedialogBtns: false,
            isFixedWidth: false,
        });
        updateModalState(true);
    };
    var DeleteLinkFormCell = function (props) {
        return (_jsxs("td", __assign({ colSpan: props.colSpan, role: "gridcell", "aria-colindex": props.ariaColumnIndex, "aria-selected": props.isSelected }, { children: [_jsx("i", { className: "fa fa-delete fa-lg" }), _jsx(Link, __assign({ to: "#", onClick: function (e) {
                        e.preventDefault();
                        deleteTimeSheetHandler(props.dataItem);
                    } }, { children: ComponentConstant.DELETE }))] })));
    };
    var onDeleteAccept = function (cellprops) { return __awaiter(void 0, void 0, void 0, function () {
        var response, err_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 2, , 3]);
                    return [4 /*yield*/, removeManagerEmployeeDetails(cellprops.timesheetDayDetailID)];
                case 1:
                    response = _a.sent();
                    if (AppConstant.HTTP_SUCCESS_CODES.includes(response.status) &&
                        response.data === true) {
                        onSuccess(TranslationConstant.SUCCESSFULLY_DELETE_TEXT, TranslationConstant.TIMESHEET_DELETED_SUCEESS);
                        refreshGridDataHandler();
                    }
                    else {
                        onFailure(TranslationConstant.DELETE_FAILURE_TEXT, TranslationConstant.TIMESHEET_DELETED_FAILURE);
                    }
                    return [3 /*break*/, 3];
                case 2:
                    err_1 = _a.sent();
                    console.log(err_1);
                    updateModalState(false);
                    return [3 /*break*/, 3];
                case 3: return [2 /*return*/];
            }
        });
    }); };
    var onSuccess = function (headingText, msg) {
        updateModalContent({
            headingText: _jsx(Transform, { mkey: headingText }),
            bodyText: (_jsxs("div", __assign({ className: "block subheading" }, { children: [_jsx("i", { className: "fa fa-check fa-lg" }), _jsx("span", { children: _jsx(Transform, { mkey: msg }) })] }))),
            acceptText: "",
            cancelText: _jsx(Transform, { mkey: TranslationConstant.CLOSE_BUTTON }),
            isErrorDialog: false,
        });
        updateModalState(true);
    };
    var onFailure = function (headingText, msg) {
        updateModalContent({
            headingText: _jsx(Transform, { mkey: headingText }),
            bodyText: (_jsxs("div", __assign({ className: "block subheading" }, { children: [_jsx("i", { className: "fa fa-times-circle fa-lg" }), _jsx("span", { children: _jsx(Transform, { mkey: msg }) })] }))),
            acceptText: "",
            cancelText: _jsx(Transform, { mkey: TranslationConstant.CLOSE_BUTTON }),
            isErrorDialog: true,
        });
        updateModalState(true);
    };
    var DeleteLinkCustomCell = function (props) {
        return _jsx(DeleteLinkFormCell, __assign({}, props));
    };
    var formatDateCell = function (cellData) {
        var _a;
        return (_jsx("td", __assign({ colSpan: cellData.colSpan, role: "gridcell", "aria-colindex": cellData.ariaColumnIndex, "aria-selected": cellData.isSelected }, { children: formatDate(new Date((_a = cellData === null || cellData === void 0 ? void 0 : cellData.dataItem) === null || _a === void 0 ? void 0 : _a.date), "MM/dd/yyyy") || "" })));
    };
    return (_jsxs(_Fragment, { children: [_jsxs(KendoGrid, __assign({ data: gridData, removeProcessData: true, dataStateHandle: dataStateHandle, refreshMethod: function () { return refreshGridDataHandler(); } }, { children: [_jsx(Column, { field: "", title: "Edit", width: "80px", filterable: false, cell: function (_a) {
                            var dataItem = _a.dataItem;
                            return (_jsxs("td", __assign({ colSpan: props.colSpan, role: "gridcell", "aria-colindex": props.ariaColumnIndex, "aria-selected": props.isSelected }, { children: [_jsx("i", { className: "fa fa-edit fa-lg" }), _jsx(Link, __assign({ to: "#", onClick: function () { return handleEditClick(dataItem); } }, { children: ComponentConstant.EDIT }))] })));
                        } }), _jsx(Column, { field: "", title: "Delete", width: "80px", filterable: false, cell: DeleteLinkCustomCell }), _jsx(Column, { field: "date", title: "Date", width: "120px", cell: formatDateCell }), _jsx(Column, { field: "timeIn", title: "Time IN ", width: "120px" }), _jsx(Column, { field: "timeOut", title: "Time OUT", width: "120px" }), _jsx(Column, { field: "lastName", title: "Last Name", width: "120px" }), _jsx(Column, { field: "firstName", title: "First Name", width: "120px" })] })), searchData && (_jsx("div", { children: _jsx(GridOData, { dataState: dataState, onDataReceived: dataReceived, contextMethod: function (queryParam) { return fetchManagerEmployeeList(queryParam); }, paramObj: searchData || {} }) }, "".concat(forceReload))), modalState && (_jsx(DialogBox, __assign({}, modalContent, { isDialogOpen: modalState, onModalChangeState: hideModal })))] }));
});
export default TimeClockGrid;
