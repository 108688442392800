var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Form, FormHeading, Transform, TranslationConstant, FormElement, Field, } from "components/shared";
import { FormDropDownListWithValue } from "components/shared/FormComponents";
import { requiredValidator } from "components/shared/FormValidators";
import { useBatchesImportContext } from "store/batchimport/batchimport";
var formheading = {
    title: _jsx(Transform, { mkey: TranslationConstant.FILL_FORM_FIELDS }),
};
export default function ManageExportsForm(props) {
    var manageExportsList = useBatchesImportContext().manageExportsList;
    var handleSubmit = function (e) {
        if (e.isValid) {
            props.handleAddSelectedExport(e.values);
        }
    };
    return (_jsxs(_Fragment, { children: [_jsx(FormHeading, { data: formheading }), _jsx(Form, { onSubmitClick: handleSubmit, render: function (formRenderProps) { return (_jsx(FormElement, __assign({ className: "general-form" }, { children: _jsxs("fieldset", __assign({ className: "k-form-fieldset row" }, { children: [_jsx("div", __assign({ className: "col-xs-12 col-md-4 mb-2" }, { children: _jsx(Field, { id: "ExportId", name: "ExportId", label: props.dropdownLabel ? (_jsx(Transform, { mkey: props.dropdownLabel })) : ("Export"), component: FormDropDownListWithValue, data: manageExportsList, isRequired: true, validator: requiredValidator, defaultItem: {
                                        text: (_jsx(Transform, { mkey: TranslationConstant.LABEL_DEFAULT_OPTION_SELECT })),
                                        value: null,
                                    }, textField: "text", valueField: "value" }) })), _jsx("div", __assign({ className: "col-xs-12 col-md-4 mb-0 pt-4" }, { children: _jsx("button", __assign({ className: "k-button btn-success", disabled: !props.recordsCount || props.recordsCount === 0 }, { children: props.dropdownLabel ? (_jsx(Transform, { mkey: TranslationConstant.BATCH_SELECTED_RECORDS })) : (_jsx(Transform, { mkey: TranslationConstant.ADD_SELECTED_RECORDS })) })) }))] })) }))); } })] }));
}
