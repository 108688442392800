var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Form, Field, FormElement } from "@progress/kendo-react-form";
import { useState } from "react";
import { Transform, TranslationConstant, requiredValidator, FormRadioGroup, FormButtons, FormDropDownListWithValue, } from "./index";
import { useCustomFormContext } from "store/customforms/customforms";
var directDepositDataList = [
    {
        label: _jsx(Transform, { mkey: TranslationConstant.FEDERAL_DIALOG }),
        value: 0,
    },
    {
        label: _jsx(Transform, { mkey: TranslationConstant.STATE_DIALOG }),
        value: 1,
    },
];
var UpdateStateW4DialogContent = function (props) {
    var _a = useState(props.redirectFromDialog ? directDepositDataList[1].value : directDepositDataList[0].value), selectedOption = _a[0], setSelectedOption = _a[1];
    var _b = useCustomFormContext(), stateList = _b.stateList, fetchstateList = _b.fetchstateList;
    var _c = useState(null), selectedState = _c[0], setSelectedState = _c[1];
    var handleStateW4Submit = function (e) {
        if (e.isValid) {
            props.handleClose();
            props.handleSubmit(e, selectedOption, "false");
        }
    };
    var loadHandleStateList = function () {
        if (stateList.length == 0) {
            fetchstateList();
        }
    };
    return (_jsx(_Fragment, { children: _jsx(Form, { onSubmitClick: handleStateW4Submit, initialValues: {
                directdepositorpaycard: props.redirectFromDialog
                    ? directDepositDataList[1].value
                    : directDepositDataList[0].value,
            }, render: function (formRenderProps) { return (_jsxs(FormElement, __assign({ className: "general-form border-0 mx-0 pb-0 mb-0" }, { children: [_jsxs("fieldset", __assign({ className: "k-form-fieldset row mt-3 pt-0 px-0" }, { children: [_jsx("div", __assign({ className: "col-xs-12 col-md-12 mb-6 px-0" }, { children: _jsx(Field, { id: "directdepositorpaycard", label: _jsx(Transform, { mkey: TranslationConstant.FEDERAL_OR_STATE_DIALOG_BODY }), isRequired: true, validator: requiredValidator, name: "directdepositorpaycard", component: FormRadioGroup, data: directDepositDataList, layout: "horizontal", value: selectedOption, onChange: function (e) {
                                        setSelectedOption(e.value);
                                    } }) })), selectedOption === 1 && (_jsx("div", __assign({ className: "col-xs-12 col-md-12 mb-6 px-0" }, { children: _jsx(Field, { id: "stateID", name: "stateID", label: _jsx(Transform, { mkey: TranslationConstant.LABEL_STATE }), data: stateList, valueField: "stateID", textField: "longName", isRequired: true, validator: requiredValidator, defaultItem: {
                                        longName: (_jsx(Transform, { mkey: TranslationConstant.LABEL_DEFAULT_OPTION_SELECT })),
                                        stateID: null,
                                    }, onChange: function (e) { return setSelectedState(e.value); }, onOpen: loadHandleStateList, component: FormDropDownListWithValue }) })))] })), _jsx("div", __assign({ className: "k-form-buttons mt-2 pb-0 pe-0" }, { children: _jsx(FormButtons, { wrapperClass: "pe-0 pb-1", hideResetButton: true, customSubmitText: TranslationConstant.LABEL_SUBMIT, closeText: _jsx(Transform, { mkey: TranslationConstant.CLOSE_BUTTON }), showCancelButton: true, cancelHandler: props.handleClose }) }))] }))); } }) }));
};
export default UpdateStateW4DialogContent;
