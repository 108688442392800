var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
var LegacyAppLinks = function () {
    return (_jsx(_Fragment, { children: _jsxs("ul", { children: [_jsx("li", { children: _jsx("a", __assign({ href: "/CORP?IsRevampRedirect=false", title: "" }, { children: "CORP Legacy" })) }), _jsx("li", { children: _jsx("a", __assign({ href: "/Onboarding?IsRevampRedirect=false", title: "" }, { children: "OnBoarding Legacy" })) })] }) }));
};
export default LegacyAppLinks;
