var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useState } from "react";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import { PageTitle, ManageFeaturesGrid, withValueField, Transform, TranslationConstant, BreadcrumbComponent, useSecurityContext, } from "./index";
import { applicationDefaultItem, applicationTypeData, roleDefaultItem } from "../StaticData";
var DropDownListWithValueField = withValueField(DropDownList);
export var SecurityManageFeatures = function () {
    var _a = useSecurityContext(), getAllRolesList = _a.getAllRolesList, fetchAllRolesList = _a.fetchAllRolesList;
    var _b = useState(), selectedRole = _b[0], setSelectedRole = _b[1];
    var _c = useState(), bussinessChannelName = _c[0], changeBusinessChannel = _c[1];
    var handleBusinessChannel = function (e) {
        changeBusinessChannel(e.value);
    };
    var handleRoleChange = function (e) {
        setSelectedRole(e.value);
    };
    var handleRolesOpen = function () {
        if (getAllRolesList.length === 0) {
            fetchAllRolesList();
        }
    };
    var breadcrumbItems = [
        {
            id: "home",
            text: "Home",
            iconClass: "k-i-home",
            route: "/",
        },
        {
            id: "manage-features",
            text: "Manage Features",
        },
    ];
    var data = {
        title: _jsx(Transform, { mkey: TranslationConstant.MANAGE_FEATURES }),
    };
    return (_jsxs(_Fragment, { children: [_jsx(BreadcrumbComponent, { items: breadcrumbItems }), _jsx(PageTitle, { data: data }), _jsxs("div", __assign({ className: "manage-feature-top-container row pad-x-20" }, { children: [_jsx("div", __assign({ className: "col-12 col-md-4 pb-3" }, { children: _jsx(DropDownListWithValueField, { id: "bussinessChannel", name: "bussinessChannel", label: "Application Type", onChange: handleBusinessChannel, data: applicationTypeData, value: bussinessChannelName, defaultItem: applicationDefaultItem, textField: "channelName", valueField: "channelID" }) })), _jsx("div", __assign({ className: "col-12 col-md-4 pb-3" }, { children: _jsx(DropDownListWithValueField, { id: "roles", name: "roles", label: "Roles", onChange: handleRoleChange, data: getAllRolesList, value: selectedRole, defaultItem: roleDefaultItem, onOpen: handleRolesOpen, textField: "Name", valueField: "WebUserRoleId" }) }))] })), _jsx(ManageFeaturesGrid, { bussinessChannelName: bussinessChannelName, selectedRole: selectedRole })] }));
};
