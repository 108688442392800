var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import { Grid, ActionCell, getUserDetailsFromLocalStorage, createEncodedURL, getQueryParams, } from "components/shared";
import { useNavigate, useParams } from "react-router-dom";
import { getSelectedState, } from "@progress/kendo-react-grid";
import { getter } from "@progress/kendo-data-query";
import { useState, useEffect, cloneElement, useCallback } from "react";
import { useBatchesImportContext } from "store/batchimport/batchimport";
import { DateFilterCell } from "components/shared/GridCommonCells";
export default function ARTSDetailsGrid(_a) {
    var _b = _a.colums, colums = _b === void 0 ? [] : _b, _c = _a.gridData, gridData = _c === void 0 ? [] : _c, handleRefresh = _a.handleRefresh, _d = _a.multiSelect, multiSelect = _d === void 0 ? false : _d, _e = _a.tabCode, tabCode = _e === void 0 ? "" : _e, _f = _a.dataItemKey, dataItemKey = _f === void 0 ? "" : _f, _g = _a.backRoute, backRoute = _g === void 0 ? "" : _g, _h = _a.hideTimeCardBackup, hideTimeCardBackup = _h === void 0 ? false : _h, _j = _a.showReallocateAccountMsg, showReallocateAccountMsg = _j === void 0 ? false : _j, _k = _a.handleBudgetException, handleBudgetException = _k === void 0 ? false : _k;
    var DATA_ITEM_KEY = dataItemKey;
    var SELECTED_FIELD = "selected";
    var idGetter = getter(DATA_ITEM_KEY);
    var oidcUserProfile = getUserDetailsFromLocalStorage("pro");
    var params = useParams();
    var exportsId = parseInt(params.exportsId);
    var ClientDetails = JSON.parse(localStorage.getItem("selectedClient")).clientDto;
    var navigate = useNavigate();
    var _l = useState(gridData), GridData = _l[0], setGridData = _l[1];
    var _m = useBatchesImportContext(), selectedExportsData = _m.selectedExportsData, setSelectedExportsData = _m.setSelectedExportsData;
    var _o = useState(selectedExportsData["".concat(tabCode)] || {}), selectedRecords = _o[0], setSelectedRecords = _o[1];
    useEffect(function () {
        setGridData(gridData);
    }, [gridData]);
    useEffect(function () {
        if (selectedExportsData && selectedExportsData.length >= 0) {
            var formattedList = selectedExportsData.map(function (list) {
                return __assign(__assign({}, list), { weekEndingDate: (list === null || list === void 0 ? void 0 : list.weekEndingDate) ? new Date(list === null || list === void 0 ? void 0 : list.weekEndingDate) : "" });
            });
            setGridData(formattedList);
        }
    }, [selectedExportsData]);
    var updateSelectedRows = function (values) {
        var selectedRows = __assign({}, selectedExportsData);
        selectedRows["".concat(tabCode)] = values;
        setSelectedExportsData(selectedRows);
    };
    var getColumns = function () {
        return multiSelect
            ? [
                {
                    title: "",
                    field: SELECTED_FIELD,
                    width: "40px",
                    filterable: false,
                    headerSelectionValue: GridData.findIndex(function (item) { return !selectedRecords[idGetter(item)]; }) === -1,
                },
            ]
            : [];
    };
    var onViewBtnClick = function (cell) {
        var queryParams = getQueryParams(window.location.search);
        var activeTab = parseInt(queryParams.get("activetab")) || 0;
        var dataItem = cell.dataItem;
        var vzSetup = ClientDetails === null || ClientDetails === void 0 ? void 0 : ClientDetails.vzSetup;
        var url = "";
        if (activeTab === 0 || activeTab === 1) {
            url = createEncodedURL("/viewtimesheet", {
                page: "view",
                TimeSheetId: dataItem.timeSheetID,
                FirstName: dataItem.firstName,
                LastName: dataItem.lastName,
                UserRoleId: oidcUserProfile === null || oidcUserProfile === void 0 ? void 0 : oidcUserProfile.WebUserRoleId,
                ClientId: oidcUserProfile === null || oidcUserProfile === void 0 ? void 0 : oidcUserProfile.ClientId,
                weekEndingDate: dataItem.weekEndingDate,
                redirectFrom: "batches",
                redirectFromUrl: {
                    id: "batchesTSdetails",
                    text: activeTab === 0 ? "Export TS Details" : "Export TS Details - Allocated",
                    route: backRoute || "/batcharexports/".concat(exportsId, "?activetab=").concat(activeTab),
                },
                showReallocateAccountMsg: showReallocateAccountMsg,
                isVZClient: vzSetup,
                hideTimeCardBackup: hideTimeCardBackup,
            });
        }
        else if (activeTab === 2 || activeTab === 3) {
            url = createEncodedURL("/viewexpensereport", {
                page: "view",
                FirstName: dataItem.firstName,
                LastName: dataItem.lastName,
                expenseReportID: dataItem.expenseReportID,
                UserRoleId: oidcUserProfile === null || oidcUserProfile === void 0 ? void 0 : oidcUserProfile.WebUserRoleId,
                clientId: oidcUserProfile === null || oidcUserProfile === void 0 ? void 0 : oidcUserProfile.ClientId,
                weekEndingDate: dataItem.weekEndingDate,
                redirectFrom: "batches",
                redirectFromUrl: {
                    id: "exportErDetails",
                    text: activeTab === 2 ? "Export ER Details" : "Export ER Details - Allocated",
                    route: backRoute || "/batcharexports/".concat(exportsId, "?activetab=").concat(activeTab),
                },
                showReallocateAccountMsg: showReallocateAccountMsg,
                isVZClient: vzSetup,
                hideReturnToEmployee: true,
            });
        }
        navigate(url);
    };
    var Columns = __spreadArray(__spreadArray(__spreadArray(__spreadArray([], getColumns(), true), [
        {
            cell: function (cell) { return (_jsx(ActionCell, { cellprops: cell, title: "View", onClick: function () { return onViewBtnClick(cell); } })); },
            field: "",
            title: "View",
            width: "80px",
            filterable: false,
        }
    ], false), colums, true), [
        {
            field: "batchName",
            title: "Batch Name",
            width: "150px",
        },
        {
            field: "weekEndingDate",
            title: "Ending Date",
            width: "120px",
            filter: "date",
            format: "{0:MM/dd/yy}",
            filterCell: DateFilterCell,
        },
        {
            field: "lastName",
            title: "Last Name",
            width: "120px",
        },
        {
            field: "firstName",
            title: "First Name",
            width: "120px",
        },
        {
            field: "accountName",
            title: "Account",
            width: "120px",
        },
        {
            field: "subAccountName",
            title: "Sub Account",
            width: "120px",
        },
        {
            field: "invoicedAmount",
            title: "Inv Amount",
            width: "120px",
            format: "{0:c2}",
        },
    ], false);
    var onSelectionChange = useCallback(function (event) {
        var _a;
        var newSelectedState = handleBudgetException && ((_a = event.dataItems["".concat(event.endRowIndex)]) === null || _a === void 0 ? void 0 : _a.budgetException)
            ? selectedRecords
            : getSelectedState({
                event: event,
                selectedState: selectedRecords,
                dataItemKey: DATA_ITEM_KEY,
            });
        setSelectedRecords(newSelectedState);
        updateSelectedRows(newSelectedState);
    }, [selectedRecords]);
    var onHeaderSelectionChange = useCallback(function (event) {
        var checkboxElement = event.syntheticEvent.target;
        var checked = checkboxElement.checked;
        var newSelectedState = {};
        event.dataItems.forEach(function (item) {
            newSelectedState[idGetter(item)] =
                handleBudgetException && (item === null || item === void 0 ? void 0 : item.budgetException) ? false : checked;
        });
        setSelectedRecords(newSelectedState);
        updateSelectedRows(newSelectedState);
    }, []);
    var rowRender = function (trElement, props) {
        var _a;
        var budgetException = ((_a = props === null || props === void 0 ? void 0 : props.dataItem) === null || _a === void 0 ? void 0 : _a.budgetException) && handleBudgetException;
        var red = {
            backgroundColor: "rgb(243, 23, 0, 0.32)",
        };
        var trProps = {
            style: budgetException ? red : {},
        };
        return cloneElement(trElement, __assign({}, trProps), trElement.props.children);
    };
    var customFilterTitles = {
        subAccountName: "Sub Account",
        weekEndingDate: "Ending Date",
        invoicedAmount: "Inv Amount",
    };
    return (_jsx(_Fragment, { children: multiSelect ? (_jsx(Grid, { columns: Columns, customFilterTitles: customFilterTitles, gridData: GridData.map(function (item) {
                var _a;
                return (__assign(__assign({}, item), (_a = {}, _a[SELECTED_FIELD] = selectedRecords[idGetter(item)], _a)));
            }), 
            // dataItemKey={DATA_ITEM_KEY}
            selectedField: SELECTED_FIELD, selectable: {
                enabled: true,
                drag: false,
                cell: false,
                mode: "multiple",
            }, onSelectionChange: onSelectionChange, onHeaderSelectionChange: onHeaderSelectionChange, handleRefresh: handleRefresh, rowRender: rowRender })) : (_jsx(Grid, { handleRefresh: handleRefresh, columns: Columns, customFilterTitles: customFilterTitles, gridData: GridData })) }));
}
