var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import { FooterCellWithCustomValue } from "components/shared/GridCommonCells";
import { useEffect, useState, useCallback } from "react";
import { Grid, ActionCell, createEncodedURL } from "components/shared";
import { useNavigate, useParams } from "react-router-dom";
import { useBatchesImportContext } from "store/batchimport/batchimport";
import { getter } from "@progress/kendo-data-query";
import { getSelectedState, } from "@progress/kendo-react-grid";
var DATA_ITEM_KEY = "manualPaymentID";
var SELECTED_FIELD = "selected";
var idGetter = getter(DATA_ITEM_KEY);
export default function APManualPaymentsGrid(_a) {
    var _b = _a.colums, colums = _b === void 0 ? [] : _b, _c = _a.gridData, gridData = _c === void 0 ? [] : _c, _d = _a.multiSelect, multiSelect = _d === void 0 ? false : _d, handleRefresh = _a.handleRefresh, _e = _a.backRoute, backRoute = _e === void 0 ? "" : _e, _f = _a.tabCode, tabCode = _f === void 0 ? "" : _f, title = _a.title, _g = _a.hidePayrollButton, hidePayrollButton = _g === void 0 ? false : _g;
    var params = useParams();
    var exportsId = parseInt(params.exportsId);
    var navigate = useNavigate();
    var _h = useBatchesImportContext(), selectedExportsData = _h.selectedExportsData, setSelectedExportsData = _h.setSelectedExportsData;
    var _j = useState((gridData === null || gridData === void 0 ? void 0 : gridData.exportDetails) || []), GridData = _j[0], setGridData = _j[1];
    var _k = useState(selectedExportsData["".concat(tabCode)] || {}), selectedRecords = _k[0], setSelectedRecords = _k[1];
    var updateSelectedRows = function (values) {
        var selectedRows = __assign({}, selectedExportsData);
        selectedRows["".concat(tabCode)] = values;
        setSelectedExportsData(selectedRows);
    };
    var getColumns = function () {
        return multiSelect
            ? [
                {
                    title: "",
                    field: SELECTED_FIELD,
                    width: "50px",
                    filterable: false,
                    headerSelectionValue: GridData.findIndex(function (item) { return !selectedRecords[idGetter(item)]; }) === -1,
                },
            ]
            : [];
    };
    var onViewBtnClick = function (cell) {
        var dataItem = cell.dataItem;
        var url = createEncodedURL("/viewmanualpayments", {
            page: "view",
            manualPaymentID: dataItem.manualPaymentID,
            redirectFrom: "batches",
            hidePayrollButton: true,
            redirectFromUrl: {
                id: "batchesManualPayments",
                text: title || "Export Manual Payments",
                route: backRoute || "/batchapexports/".concat(exportsId, "?activetab=2"),
            },
        });
        navigate(url);
    };
    var Columns = __spreadArray(__spreadArray(__spreadArray(__spreadArray([], getColumns(), true), [
        {
            cell: function (cell) { return (_jsx(ActionCell, { cellprops: cell, title: "View", onClick: function () { return onViewBtnClick(cell); } })); },
            field: "",
            title: "View",
            width: "80px",
            filterable: false,
        }
    ], false), colums, true), [
        {
            field: "manualPaymentType",
            title: "Manual Payment Type",
            width: "160px",
        },
        {
            field: "lastName",
            title: "Last Name",
            width: "120px",
        },
        multiSelect
            ? {
                field: "firstName",
                title: "First Name",
                width: "120px",
            }
            : {
                field: "firstName",
                title: "First Name",
                width: "120px",
                footerCell: function (cell) { return (_jsx(FooterCellWithCustomValue, __assign({}, cell, { cellTitle: "Equipment Total", colSpan: 3, cellTotal: "".concat((gridData === null || gridData === void 0 ? void 0 : gridData.equipTotal) || 0) }))); },
            },
        multiSelect
            ? {
                field: "grossPay",
                title: "Gross Pay",
                width: "120px",
                format: "{0:c2}",
            }
            : {
                field: "grossPay",
                title: "Gross Pay",
                width: "120px",
                format: "{0:c2}",
                footerCell: function (cell) { return (_jsx(FooterCellWithCustomValue, __assign({}, cell, { cellTitle: "", colSpan: 2, cellTotal: "".concat((gridData === null || gridData === void 0 ? void 0 : gridData.expenseTotal) || 0) }))); },
            },
        multiSelect
            ? {
                field: "invoicedAmount",
                title: "Invoiced Amount",
                width: "120px",
                format: "{0:c2}",
            }
            : {
                field: "invoicedAmount",
                title: "Invoiced Amount",
                width: "120px",
                format: "{0:c2}",
                footerCell: function (cell) { return (_jsx(FooterCellWithCustomValue, __assign({}, cell, { cellTitle: "", colSpan: 2, cellTotal: "".concat((gridData === null || gridData === void 0 ? void 0 : gridData.vendorTotal) || 0) }))); },
            },
    ], false);
    var onSelectionChange = useCallback(function (event) {
        var newSelectedState = getSelectedState({
            event: event,
            selectedState: selectedRecords,
            dataItemKey: DATA_ITEM_KEY,
        });
        setSelectedRecords(newSelectedState);
        updateSelectedRows(newSelectedState);
    }, [selectedRecords]);
    var onHeaderSelectionChange = useCallback(function (event) {
        var checkboxElement = event.syntheticEvent.target;
        var checked = checkboxElement.checked;
        var newSelectedState = {};
        event.dataItems.forEach(function (item) {
            newSelectedState[idGetter(item)] = checked;
        });
        setSelectedRecords(newSelectedState);
        updateSelectedRows(newSelectedState);
    }, []);
    useEffect(function () {
        if (gridData === null || gridData === void 0 ? void 0 : gridData.exportDetails)
            setGridData(gridData.exportDetails);
    }, [gridData]);
    return (_jsx(_Fragment, { children: multiSelect ? (_jsx(Grid, { columns: Columns, gridData: GridData.map(function (item) {
                var _a;
                return (__assign(__assign({}, item), (_a = {}, _a[SELECTED_FIELD] = selectedRecords[idGetter(item)], _a)));
            }), dataItemKey: DATA_ITEM_KEY, selectedField: SELECTED_FIELD, selectable: {
                enabled: true,
                drag: false,
                cell: false,
                mode: "multiple",
            }, onSelectionChange: onSelectionChange, onHeaderSelectionChange: onHeaderSelectionChange, handleRefresh: handleRefresh })) : (_jsx("div", __assign({ className: "hide-grid-footer-border" }, { children: _jsx(Grid, { columns: Columns, gridData: GridData, handleRefresh: handleRefresh }) }))) }));
}
