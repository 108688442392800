var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
// NOTE: This component cabnnot be used to render a button that requires the
// `disabledForReadOnlyRoles=true` prop.  If you need that use the Button component directly.
import { TranslationConstant } from "common/constant";
import { Transform } from "helper/Transform";
import Button from "./Button";
function TopButtons(props) {
    var buttonData = props.buttonData, onClick = props.onClick, _a = props.styleClass, styleClass = _a === void 0 ? null : _a, _b = props.disabled, disabled = _b === void 0 ? false : _b, _c = props.buttonClass, buttonClass = _c === void 0 ? "btn-success" : _c;
    return (_jsx(_Fragment, { children: _jsx("div", __assign({ className: "button-wrapper pad-x-20 d-flex gap-2 ".concat(styleClass) }, { children: buttonData === null || buttonData === void 0 ? void 0 : buttonData.map(function (listItem) {
                return listItem.active === true && (_jsx(Button, { autoFocus: (listItem === null || listItem === void 0 ? void 0 : listItem.autoFocus) || false, buttonClass: (listItem === null || listItem === void 0 ? void 0 : listItem.buttonClass) || buttonClass, buttonClickHandler: function () { return onClick(listItem); }, buttonIconClass: listItem === null || listItem === void 0 ? void 0 : listItem.icon, buttonText: listItem.noTransformText ? (listItem.title) : (_jsx(Transform, { mkey: TranslationConstant["".concat(listItem.title)] })), disabled: disabled || (listItem === null || listItem === void 0 ? void 0 : listItem.disabled), id: listItem.id, type: "button" }, listItem.id));
            }) }), JSON.stringify(buttonData)) }));
}
export default TopButtons;
