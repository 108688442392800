var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { create } from "zustand";
import * as Service from "services/BatchImport";
var initialState = {
    batches: [],
    payrollExports: [],
    arExports: [],
    batchDetailCreditCard: [],
    batchDetailVendorPayment: [],
    batchDetailManualPayment: [],
    creditCardDetails: [],
    allocatedVPDetails: [],
    nonAllocatedVPDetails: [],
    nonAllocatedERDetails: [],
    allocatedERDetails: [],
    timesheet: [],
    manualPayment: [],
    batchDetailTimeSheet: [],
    batchDetailClientTimesheet: [],
    recordCounts: {},
    batchDetailExpenseReport: [],
    nonAllocatedTSDetails: [],
    allocatedTimesheetDetailsOracle: [],
    allocatedtimesheetdetailsvz: [],
    manualPayments: [],
    clientTimesheets: [],
    apExports: [],
    expenseReports: {},
    vendorPayments: [],
    apExportSettingsandCount: {},
    manageExportsList: [],
    manageExportsTabsCount: {},
    managetimesheets: [],
    managemanualpayments: [],
    batchRecordtimesheets: [],
    batchRecordexpensereports: [],
    batchRecordvendorpayments: [],
    batchRecordclienttimesheets: [],
    batchRecordmanualpayments: [],
    selectedExportsData: {},
    manageexpensereports: [],
    managevendorpayments: [],
    nonallocatedtimesheetdetails: [],
    allocatedtimesheetdetails: [],
    nonallocatederdetails: [],
    allocatederdetails: [],
    nonallocatedvpdetails: [],
    allocatedvpdetails: [],
    clienttimesheetdetails: [],
    manualpayments: [],
    CreditCardDetails: [],
};
export var useBatchesImportContext = create(function (set, get) { return (__assign(__assign({}, initialState), { getBatches: function (ClientID) { return __awaiter(void 0, void 0, void 0, function () {
        var response;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, Service.getAllBatches(ClientID)];
                case 1:
                    response = _a.sent();
                    set(function (state) { return (__assign(__assign({}, state), { batches: response.data })); });
                    return [2 /*return*/];
            }
        });
    }); }, fetchAllPayrollExports: function (ClientID) { return __awaiter(void 0, void 0, void 0, function () {
        var response;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, Service.getAllPayrollExports(ClientID)];
                case 1:
                    response = _a.sent();
                    set(function (state) { return (__assign(__assign({}, state), { payrollExports: response.data })); });
                    return [2 /*return*/];
            }
        });
    }); }, saveAddPayrollExports: function (ClientID, postData) { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, Service.postAddPayrollExports(ClientID, postData)];
                case 1: return [2 /*return*/, _a.sent()];
            }
        });
    }); }, fetchAllARExports: function (ClientID) { return __awaiter(void 0, void 0, void 0, function () {
        var response;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, Service.getAllARExports(ClientID)];
                case 1:
                    response = _a.sent();
                    set(function (state) { return (__assign(__assign({}, state), { arExports: response.data })); });
                    return [2 /*return*/];
            }
        });
    }); }, fetchBatchDetailRecords: function (batchId, type) { return __awaiter(void 0, void 0, void 0, function () {
        var response;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, Service.getBatchViewDetails(batchId, type)];
                case 1:
                    response = _a.sent();
                    set(function (state) {
                        var _a;
                        return (__assign(__assign({}, state), (_a = {}, _a[type] = response.data, _a)));
                    });
                    return [2 /*return*/];
            }
        });
    }); }, removeBatchRecord: function (id, type) { return __awaiter(void 0, void 0, void 0, function () {
        var response;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, Service.removeBatchRecord(id, type)];
                case 1:
                    response = _a.sent();
                    return [2 /*return*/, response];
            }
        });
    }); }, closeExport: function (exportId, exportType) { return __awaiter(void 0, void 0, void 0, function () { return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, Service.putCloseExports(exportId, exportType)];
            case 1: return [2 /*return*/, _a.sent()];
        }
    }); }); }, reOpenExport: function (exportId, exportType) { return __awaiter(void 0, void 0, void 0, function () { return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, Service.putReOpenExports(exportId, exportType)];
            case 1: return [2 /*return*/, _a.sent()];
        }
    }); }); }, fetchAllARExportDetails: function (exportId, exportType) { return __awaiter(void 0, void 0, void 0, function () {
        var response;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, Service.getARExportDetails(exportId, exportType)];
                case 1:
                    response = _a.sent();
                    set(function (state) {
                        var _a;
                        return (__assign(__assign({}, state), (_a = {}, _a[exportType] = response.data, _a)));
                    });
                    return [2 /*return*/];
            }
        });
    }); }, fetchAllPayrollExportDetails: function (exportId, exportType) { return __awaiter(void 0, void 0, void 0, function () {
        var response;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, Service.getPayrollExportDetails(exportId, exportType)];
                case 1:
                    response = _a.sent();
                    set(function (state) {
                        var _a;
                        return (__assign(__assign({}, state), (_a = {}, _a[exportType] = response.data, _a)));
                    });
                    return [2 /*return*/];
            }
        });
    }); }, removePayrollExportDetails: function (id, exportType) { return __awaiter(void 0, void 0, void 0, function () { return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, Service.removePayrollExportDetails(id, exportType)];
            case 1: return [2 /*return*/, _a.sent()];
        }
    }); }); }, fetchTabCountBatchDetails: function (batchId, activeTab) { return __awaiter(void 0, void 0, void 0, function () {
        var response;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, Service.getBatchViewDetailsTabCount(batchId, activeTab)];
                case 1:
                    response = _a.sent();
                    set(function (state) { return (__assign(__assign({}, state), { recordCounts: response.data })); });
                    return [2 /*return*/];
            }
        });
    }); }, fetchTabCountPayrollExport: function (masPayrollExportId, activeTab) { return __awaiter(void 0, void 0, void 0, function () {
        var response;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, Service.getPayrollExportTabCount(masPayrollExportId, activeTab)];
                case 1:
                    response = _a.sent();
                    set(function (state) { return (__assign(__assign({}, state), { recordCounts: response.data })); });
                    return [2 /*return*/];
            }
        });
    }); }, createBatch: function (clientId) { return __awaiter(void 0, void 0, void 0, function () { return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, Service.createBatch(clientId)];
            case 1: return [2 /*return*/, _a.sent()];
        }
    }); }); }, downloadPayrollThinkware: function (masPayrollExportId) { return __awaiter(void 0, void 0, void 0, function () { return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, Service.downloadPayrollThinkware(masPayrollExportId)];
            case 1: return [2 /*return*/, _a.sent()];
        }
    }); }); }, removeARExportDetails: function (id, exportType) { return __awaiter(void 0, void 0, void 0, function () { return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, Service.removeARExportDetails(id, exportType)];
            case 1: return [2 /*return*/, _a.sent()];
        }
    }); }); }, fetchTabCountARExport: function (masPayrollExportId, activeTab) { return __awaiter(void 0, void 0, void 0, function () {
        var response;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, Service.getARExportTabCount(masPayrollExportId, activeTab)];
                case 1:
                    response = _a.sent();
                    set(function (state) { return (__assign(__assign({}, state), { recordCounts: response.data })); });
                    return [2 /*return*/];
            }
        });
    }); }, fetchAllAPExports: function (ClientID) { return __awaiter(void 0, void 0, void 0, function () {
        var response;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, Service.getAllAPExports(ClientID)];
                case 1:
                    response = _a.sent();
                    set(function (state) { return (__assign(__assign({}, state), { apExports: response.data })); });
                    return [2 /*return*/];
            }
        });
    }); }, saveAddAPExports: function (_a) {
        var clientId = _a.clientId, exportName = _a.exportName;
        return __awaiter(void 0, void 0, void 0, function () {
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0: return [4 /*yield*/, Service.postAddAPExports({ clientId: clientId, exportName: exportName })];
                    case 1: return [2 /*return*/, _b.sent()];
                }
            });
        });
    }, fetchAllAPExportDetails: function (exportId, exportType) { return __awaiter(void 0, void 0, void 0, function () {
        var response;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, Service.getAPExportDetails(exportId, exportType)];
                case 1:
                    response = _a.sent();
                    set(function (state) {
                        var _a;
                        return (__assign(__assign({}, state), (_a = {}, _a[exportType] = response.data, _a)));
                    });
                    return [2 /*return*/];
            }
        });
    }); }, removeAPExportDetails: function (id, exportType) { return __awaiter(void 0, void 0, void 0, function () { return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, Service.removeAPExportDetails(id, exportType)];
            case 1: return [2 /*return*/, _a.sent()];
        }
    }); }); }, saveAddARExports: function (data) { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, Service.postAddARExports(data)];
                case 1: return [2 /*return*/, _a.sent()];
            }
        });
    }); }, downloadExport: function (exportId, type) { return __awaiter(void 0, void 0, void 0, function () { return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, Service.downloadExport(exportId, type)];
            case 1: return [2 /*return*/, _a.sent()];
        }
    }); }); }, fetchTabCountAPExport: function (masAPExportId, activeTab) { return __awaiter(void 0, void 0, void 0, function () {
        var response;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, Service.getAPExportTabCount(masAPExportId, activeTab)];
                case 1:
                    response = _a.sent();
                    set(function (state) { return (__assign(__assign({}, state), { apExportSettingsandCount: response.data })); });
                    return [2 /*return*/];
            }
        });
    }); }, fetchExportExpandDetails: function (exportId, type) { return __awaiter(void 0, void 0, void 0, function () { return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, Service.getExportExpandDetails(exportId, type)];
            case 1: return [2 /*return*/, _a.sent()];
        }
    }); }); }, fetchManageExports: function (clientId, exportType) { return __awaiter(void 0, void 0, void 0, function () {
        var response;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, Service.getManageExports(clientId, exportType)];
                case 1:
                    response = _a.sent();
                    set(function (state) { return (__assign(__assign({}, state), { manageExportsList: response.data })); });
                    return [2 /*return*/];
            }
        });
    }); }, fetchManageExportsTabsCount: function (clientId, activeTabCode, exportType) { return __awaiter(void 0, void 0, void 0, function () {
        var response;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, Service.getManageExportsTabCount(clientId, activeTabCode, exportType)];
                case 1:
                    response = _a.sent();
                    set(function (state) { return (__assign(__assign({}, state), { manageExportsTabsCount: response.data })); });
                    return [2 /*return*/];
            }
        });
    }); }, fetchManageExportsGridData: function (clientId, type, exportType) { return __awaiter(void 0, void 0, void 0, function () {
        var response;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, Service.getManageExportsGridData(clientId, type, exportType)];
                case 1:
                    response = _a.sent();
                    if (exportType === "mgARExport") {
                        set(function (state) {
                            var _a;
                            return (__assign(__assign({}, state), (_a = {}, _a["".concat(type)] = response.data, _a)));
                        });
                    }
                    else {
                        set(function (state) {
                            var _a;
                            return (__assign(__assign({}, state), (_a = {}, _a["manage".concat(type)] = response.data, _a)));
                        });
                    }
                    return [2 /*return*/];
            }
        });
    }); }, fetchBatchRecordsGridData: function (clientId, type, exportType) { return __awaiter(void 0, void 0, void 0, function () {
        var response;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, Service.getManageExportsGridData(clientId, type, exportType)];
                case 1:
                    response = _a.sent();
                    set(function (state) {
                        var _a;
                        return (__assign(__assign({}, state), (_a = {}, _a["batchRecord".concat(type)] = response.data, _a)));
                    });
                    return [2 /*return*/];
            }
        });
    }); }, setSelectedExportsData: function (items) {
        set(function (state) { return (__assign(__assign({}, state), { selectedExportsData: items })); });
    }, resetSelectedExportsData: function (resetFields) {
        set(function (state) { return (__assign(__assign(__assign({}, state), { selectedExportsData: {}, manageExportsTabsCount: {} }), resetFields)); });
    }, saveSelectedRecords: function (clientId, exportType, postData) { return __awaiter(void 0, void 0, void 0, function () { return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, Service.postSelectedRecords(clientId, exportType, postData)];
            case 1: return [2 /*return*/, _a.sent()];
        }
    }); }); }, resetRecordCounts: function () {
        set(function (state) { return (__assign(__assign({}, state), { recordCounts: {} })); });
    }, resetBatchStore: function () {
        set(function () { return (__assign({}, initialState)); });
    } })); });
