import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { BreadcrumbComponent, Transform, TranslationConstant, PageTitle, CaliforniaMinorTrustAccountForm } from './index';
import { useSearchParams } from 'react-router-dom';
export var CaliforniaMinorTrustAccount = function (props) {
    var searchParams = useSearchParams()[0];
    var newHireDocumentID = searchParams.get('newHireDocumentID');
    var items = [
        {
            id: "dashboard",
            text: "Dashboard",
            iconClass: "k-i-home",
            route: '/employee'
        },
        {
            id: "californiaminortrustaccount",
            text: "Minor Trust Account",
        },
    ];
    var data = {
        title: _jsx(Transform, { mkey: TranslationConstant.MINOR_TRUST_ACCOUNT })
    };
    return (_jsxs(_Fragment, { children: [_jsx(BreadcrumbComponent, { items: items }), _jsx(PageTitle, { data: data }), _jsx(CaliforniaMinorTrustAccountForm, { newHireDocumentID: newHireDocumentID })] }));
};
