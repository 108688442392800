var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx } from "react/jsx-runtime";
import { TabsComponent, getQueryParams } from "components/shared";
import ExpenseReportsGrid from "../../Common/ExpenseReportsGrid";
import APVendorPaymentsGrid from "../../Common/APVendorPaymentsGrid";
import APManualPaymentsGrid from "../../Common/APManualPaymentsGrid";
import { useBatchesImportContext } from "store/batchimport/batchimport";
import { useEffect, useState } from "react";
import { EntityTypesEnum } from "../../data";
var exportType = ["expensereports", "vendorpayments", "manualpayments"];
export default function APRecordsTabView() {
    var _this = this;
    var queryParams = getQueryParams(window.location.search);
    var activeTab = queryParams.get("activetab")
        ? parseInt(queryParams.get("activetab"))
        : 0;
    var _a = useState(activeTab), activeTabValue = _a[0], setActiveTabValue = _a[1];
    var _b = useBatchesImportContext(), manageExportsTabsCount = _b.manageExportsTabsCount, fetchManageExportsGridData = _b.fetchManageExportsGridData, manageexpensereports = _b.manageexpensereports, managemanualpayments = _b.managemanualpayments, managevendorpayments = _b.managevendorpayments;
    var clientID = JSON.parse(localStorage.getItem("selectedClient")).clientDto.clientID;
    useEffect(function () {
        initData();
    }, [activeTabValue]);
    var initData = function () { return __awaiter(_this, void 0, void 0, function () {
        var queryParams, activeTab;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    queryParams = getQueryParams(window.location.search);
                    activeTab = queryParams.get("activetab") || 0;
                    return [4 /*yield*/, fetchManageExportsGridData(clientID, exportType[activeTab], "mgAPExport")];
                case 1:
                    _a.sent();
                    return [2 /*return*/];
            }
        });
    }); };
    var tabMenu = [
        {
            title: "Expense Reports - ".concat((manageexpensereports === null || manageexpensereports === void 0 ? void 0 : manageexpensereports.length) || manageExportsTabsCount.totalExpenseReports || 0),
            children: (_jsx(ExpenseReportsGrid, { gridData: manageexpensereports, tabCode: EntityTypesEnum.ExpenseReport, multiSelect: true, handleRefresh: initData, title: "Manage AP Records", backRoute: "/manageaprecords?activetab=0" })),
        },
        {
            title: "Vendor Payments - ".concat((managevendorpayments === null || managevendorpayments === void 0 ? void 0 : managevendorpayments.length) || manageExportsTabsCount.totalVendorPayments || 0),
            children: (_jsx(APVendorPaymentsGrid, { colums: [], handleRefresh: initData, gridData: managevendorpayments, tabCode: EntityTypesEnum.VendorPayment, multiSelect: true, title: "Manage AP Records", backRoute: "/manageaprecords?activetab=1" })),
        },
        {
            title: "Manual Payments - ".concat((managemanualpayments === null || managemanualpayments === void 0 ? void 0 : managemanualpayments.length) || manageExportsTabsCount.totalManualPayments || 0),
            children: (_jsx(APManualPaymentsGrid, { tabCode: EntityTypesEnum.ManualPayment, multiSelect: true, gridData: { exportDetails: managemanualpayments }, handleRefresh: initData, title: "Manage AP Records", backRoute: "/manageaprecords?activetab=2" })),
        },
    ];
    var onTabChangeHandler = function (tab) {
        setActiveTabValue(tab);
        window.history.replaceState("", "", "".concat(window.location.pathname, "?activetab=").concat(tab));
    };
    return (_jsx("div", __assign({ className: "admin-panel-wrapper admin-agent-task employee-task-grid mt-1" }, { children: _jsx(TabsComponent, { tabsMenu: tabMenu, selectedIndex: activeTabValue, onSelectHandler: onTabChangeHandler }) })));
}
