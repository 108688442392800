var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Link } from "react-router-dom";
import { Card, CardTitle, CardBody, CardActions, } from "@progress/kendo-react-layout";
import 'assets/css/cards.scss';
export var CardsComponent = function (props) {
    var cardTitle = props.cardTitle, cardBody = props.cardBody, cardLink = props.cardLink, cardLinkText = props.cardLinkText, statCount = props.statCount;
    var CardBodyAndActions = function (props) {
        return (_jsxs(_Fragment, { children: [_jsxs(CardBody, { children: [_jsx("h2", { children: statCount }), cardTitle &&
                            _jsx(CardTitle, { children: cardTitle }), _jsx("section", __assign({ className: 'k-body-wrapper' }, { children: cardBody }))] }), cardLinkText && _jsx(CardActions, { children: _jsx(Link, __assign({ to: cardLink, target: "_self" }, { children: cardLinkText })) })] }));
    };
    return (_jsx(Card, { children: cardLinkText ?
            _jsx(CardBodyAndActions, {}) :
            _jsx(Link, __assign({ to: cardLink, target: "_self" }, { children: _jsx(CardBodyAndActions, {}) })) }));
};
