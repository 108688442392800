export var LOCALE;
(function (LOCALE) {
    LOCALE["ENGLISH_LANGUAGE"] = "en-US";
})(LOCALE || (LOCALE = {}));
export var WEBUSERROLEID;
(function (WEBUSERROLEID) {
    WEBUSERROLEID[WEBUSERROLEID["PAYREEL_ADMIN"] = 1] = "PAYREEL_ADMIN";
    WEBUSERROLEID[WEBUSERROLEID["CLIENT"] = 2] = "CLIENT";
    WEBUSERROLEID[WEBUSERROLEID["VENDOR"] = 4] = "VENDOR";
    WEBUSERROLEID[WEBUSERROLEID["NEW_HIRE_EMPLOYEE"] = 5] = "NEW_HIRE_EMPLOYEE";
    WEBUSERROLEID[WEBUSERROLEID["Employee"] = 3] = "Employee";
    WEBUSERROLEID[WEBUSERROLEID["EMPLOYEE_AUTHORISED_I9_VERIFIER"] = 11] = "EMPLOYEE_AUTHORISED_I9_VERIFIER";
    WEBUSERROLEID[WEBUSERROLEID["NEW_HIRE_WORKER"] = 12] = "NEW_HIRE_WORKER";
    WEBUSERROLEID[WEBUSERROLEID["WORKER_1099"] = 13] = "WORKER_1099";
    WEBUSERROLEID[WEBUSERROLEID["CLIENT_CONTRACTOR"] = 8] = "CLIENT_CONTRACTOR";
    WEBUSERROLEID[WEBUSERROLEID["MASTER_CLIENT"] = 9] = "MASTER_CLIENT";
    WEBUSERROLEID[WEBUSERROLEID["MASTER_CLIENT_READ_ONLY"] = 10] = "MASTER_CLIENT_READ_ONLY";
    WEBUSERROLEID[WEBUSERROLEID["ENDEAVOR_CLIENT_ID"] = 16] = "ENDEAVOR_CLIENT_ID";
})(WEBUSERROLEID || (WEBUSERROLEID = {}));
export var ONBOARDING_REPORT_JOB_STATUS;
(function (ONBOARDING_REPORT_JOB_STATUS) {
    ONBOARDING_REPORT_JOB_STATUS[ONBOARDING_REPORT_JOB_STATUS["CREATED"] = 1] = "CREATED";
    ONBOARDING_REPORT_JOB_STATUS[ONBOARDING_REPORT_JOB_STATUS["REQUIRED_FORMS_INCOMPLETE"] = 75] = "REQUIRED_FORMS_INCOMPLETE";
    ONBOARDING_REPORT_JOB_STATUS[ONBOARDING_REPORT_JOB_STATUS["IN_PROGRESS"] = 100] = "IN_PROGRESS";
    ONBOARDING_REPORT_JOB_STATUS[ONBOARDING_REPORT_JOB_STATUS["SCREENING_PENDING"] = 101] = "SCREENING_PENDING";
})(ONBOARDING_REPORT_JOB_STATUS || (ONBOARDING_REPORT_JOB_STATUS = {}));
export var DeleteStatus;
(function (DeleteStatus) {
    DeleteStatus[DeleteStatus["SUCCESS"] = 1] = "SUCCESS";
    DeleteStatus[DeleteStatus["ERROR"] = 2] = "ERROR";
    DeleteStatus[DeleteStatus["MAPPINGEXISTS"] = 3] = "MAPPINGEXISTS";
})(DeleteStatus || (DeleteStatus = {}));
export var SUPPORTING_NEWHIRE_DOCUMENT_TYPEID;
(function (SUPPORTING_NEWHIRE_DOCUMENT_TYPEID) {
    SUPPORTING_NEWHIRE_DOCUMENT_TYPEID[SUPPORTING_NEWHIRE_DOCUMENT_TYPEID["DDFORM2058"] = 13] = "DDFORM2058";
    SUPPORTING_NEWHIRE_DOCUMENT_TYPEID[SUPPORTING_NEWHIRE_DOCUMENT_TYPEID["MILITARY_SPOUSE_IDCARD"] = 14] = "MILITARY_SPOUSE_IDCARD";
})(SUPPORTING_NEWHIRE_DOCUMENT_TYPEID || (SUPPORTING_NEWHIRE_DOCUMENT_TYPEID = {}));
export var DOCUMENT_CATEGORY;
(function (DOCUMENT_CATEGORY) {
    DOCUMENT_CATEGORY[DOCUMENT_CATEGORY["PAYREEL"] = 1] = "PAYREEL";
    DOCUMENT_CATEGORY[DOCUMENT_CATEGORY["FEDERAL"] = 2] = "FEDERAL";
    DOCUMENT_CATEGORY[DOCUMENT_CATEGORY["STATE"] = 3] = "STATE";
    DOCUMENT_CATEGORY[DOCUMENT_CATEGORY["LOCAL"] = 4] = "LOCAL";
    DOCUMENT_CATEGORY[DOCUMENT_CATEGORY["CLIENT"] = 5] = "CLIENT";
    DOCUMENT_CATEGORY[DOCUMENT_CATEGORY["PAYREELUPLOAD"] = 6] = "PAYREELUPLOAD";
    DOCUMENT_CATEGORY[DOCUMENT_CATEGORY["WORK_STATE"] = 7] = "WORK_STATE";
    DOCUMENT_CATEGORY[DOCUMENT_CATEGORY["CUSTOMDOCUMENTUPLOAD"] = 8] = "CUSTOMDOCUMENTUPLOAD";
    DOCUMENT_CATEGORY[DOCUMENT_CATEGORY["CUSTOMDOCUEMNT"] = 9] = "CUSTOMDOCUEMNT";
    DOCUMENT_CATEGORY[DOCUMENT_CATEGORY["PAYMENT"] = 10] = "PAYMENT";
    DOCUMENT_CATEGORY[DOCUMENT_CATEGORY["SHTC"] = 11] = "SHTC";
    DOCUMENT_CATEGORY[DOCUMENT_CATEGORY["PERFORMANCECOMP"] = 12] = "PERFORMANCECOMP";
    DOCUMENT_CATEGORY[DOCUMENT_CATEGORY["HR_DOCUMENTS"] = 13] = "HR_DOCUMENTS";
})(DOCUMENT_CATEGORY || (DOCUMENT_CATEGORY = {}));
export var BACKBUTTONPATH;
(function (BACKBUTTONPATH) {
    BACKBUTTONPATH["SEARCHFORM"] = "Search Custom Forms";
    BACKBUTTONPATH["UPLOADFORM"] = "Upload Custom Form";
})(BACKBUTTONPATH || (BACKBUTTONPATH = {}));
export var CLIENT_DASHBOARD;
(function (CLIENT_DASHBOARD) {
    CLIENT_DASHBOARD[CLIENT_DASHBOARD["NEWHIREJOBSTATUSID75"] = 75] = "NEWHIREJOBSTATUSID75";
    CLIENT_DASHBOARD[CLIENT_DASHBOARD["NEWHIREJOBSTATUSID1"] = 1] = "NEWHIREJOBSTATUSID1";
    CLIENT_DASHBOARD[CLIENT_DASHBOARD["NEWHIREJOBSTATUSID100"] = 100] = "NEWHIREJOBSTATUSID100";
})(CLIENT_DASHBOARD || (CLIENT_DASHBOARD = {}));
export var CUSTOM_FORM_DATATYPE;
(function (CUSTOM_FORM_DATATYPE) {
    CUSTOM_FORM_DATATYPE["Date"] = "AAAA";
    CUSTOM_FORM_DATATYPE["Text"] = "AAAB";
    CUSTOM_FORM_DATATYPE["Numeric"] = "AAAC";
    CUSTOM_FORM_DATATYPE["Option"] = "AAAD";
    CUSTOM_FORM_DATATYPE["CheckBox"] = "AAAE";
    CUSTOM_FORM_DATATYPE["Signature"] = "AAAF";
    CUSTOM_FORM_DATATYPE["State"] = "AAAG";
    CUSTOM_FORM_DATATYPE["SSN"] = "AAAH";
    CUSTOM_FORM_DATATYPE["Account"] = "AAAI";
    CUSTOM_FORM_DATATYPE["RadioButton"] = "AAAJ";
    CUSTOM_FORM_DATATYPE["MaskedTextBox"] = "AAAK";
    CUSTOM_FORM_DATATYPE["County"] = "AAAP";
})(CUSTOM_FORM_DATATYPE || (CUSTOM_FORM_DATATYPE = {}));
export var CUSTOM_FORM_PROPERTY_TYPE;
(function (CUSTOM_FORM_PROPERTY_TYPE) {
    CUSTOM_FORM_PROPERTY_TYPE["Display"] = "AAAA";
    CUSTOM_FORM_PROPERTY_TYPE["Enabled"] = "AAAB";
    CUSTOM_FORM_PROPERTY_TYPE["SetStaticValue"] = "AAAC";
    CUSTOM_FORM_PROPERTY_TYPE["SetDynamicValue"] = "AAAD";
    CUSTOM_FORM_PROPERTY_TYPE["IsRequired"] = "AAAE";
    CUSTOM_FORM_PROPERTY_TYPE["Checked"] = "AAAF";
    CUSTOM_FORM_PROPERTY_TYPE["ArithmeticSum"] = "AAAG";
    CUSTOM_FORM_PROPERTY_TYPE["DynamicValidation"] = "AAAH";
})(CUSTOM_FORM_PROPERTY_TYPE || (CUSTOM_FORM_PROPERTY_TYPE = {}));
export var CUSTOM_FORM_EVENT_DATATYPE;
(function (CUSTOM_FORM_EVENT_DATATYPE) {
    CUSTOM_FORM_EVENT_DATATYPE["onChange"] = "AAAA";
    CUSTOM_FORM_EVENT_DATATYPE["onClick"] = "AAAB";
    CUSTOM_FORM_EVENT_DATATYPE["onKeyDown"] = "AAAC";
    CUSTOM_FORM_EVENT_DATATYPE["onFocus"] = "AAAD";
    CUSTOM_FORM_EVENT_DATATYPE["onBlur"] = "AAAE";
    CUSTOM_FORM_EVENT_DATATYPE["onLoad"] = "AAAF";
})(CUSTOM_FORM_EVENT_DATATYPE || (CUSTOM_FORM_EVENT_DATATYPE = {}));
export var CUSTOM_FORM_OPERATOR_DATATYPE;
(function (CUSTOM_FORM_OPERATOR_DATATYPE) {
    CUSTOM_FORM_OPERATOR_DATATYPE["EqualsTo"] = "AAAA";
    CUSTOM_FORM_OPERATOR_DATATYPE["NotEqualsTo"] = "AAAB";
    CUSTOM_FORM_OPERATOR_DATATYPE["GreaterThan"] = "AAAC";
    CUSTOM_FORM_OPERATOR_DATATYPE["LessThan"] = "AAAD";
    CUSTOM_FORM_OPERATOR_DATATYPE["LessThanEqualTo"] = "AAAE";
    CUSTOM_FORM_OPERATOR_DATATYPE["GreaterThanEqualTo"] = "AAAF";
    CUSTOM_FORM_OPERATOR_DATATYPE["Contains"] = "AAAG";
})(CUSTOM_FORM_OPERATOR_DATATYPE || (CUSTOM_FORM_OPERATOR_DATATYPE = {}));
export var CUSTOM_FORM_PROPERTY_VALUE;
(function (CUSTOM_FORM_PROPERTY_VALUE) {
    CUSTOM_FORM_PROPERTY_VALUE["Show"] = "AAAA";
    CUSTOM_FORM_PROPERTY_VALUE["Hide"] = "AAAB";
    CUSTOM_FORM_PROPERTY_VALUE["Enabled"] = "AAAC";
    CUSTOM_FORM_PROPERTY_VALUE["Disabled"] = "AAAD";
})(CUSTOM_FORM_PROPERTY_VALUE || (CUSTOM_FORM_PROPERTY_VALUE = {}));
export var URL_TYPE_CODES;
(function (URL_TYPE_CODES) {
    URL_TYPE_CODES["LEGACY"] = "AAAA";
})(URL_TYPE_CODES || (URL_TYPE_CODES = {}));
export var PUSH_CLIENT;
(function (PUSH_CLIENT) {
    PUSH_CLIENT[PUSH_CLIENT["CLIENT_ID"] = 124] = "CLIENT_ID";
})(PUSH_CLIENT || (PUSH_CLIENT = {}));
export var EXPENSE_CODES;
(function (EXPENSE_CODES) {
    EXPENSE_CODES["MILEAGE"] = "mileage";
    EXPENSE_CODES["PER_DIEM"] = "per diem";
    EXPENSE_CODES["MISC"] = "misc";
})(EXPENSE_CODES || (EXPENSE_CODES = {}));
export var EVENT_TRACKING_LEVEL;
(function (EVENT_TRACKING_LEVEL) {
    EVENT_TRACKING_LEVEL[EVENT_TRACKING_LEVEL["NONE"] = 0] = "NONE";
    EVENT_TRACKING_LEVEL[EVENT_TRACKING_LEVEL["ACCOUNT"] = 1] = "ACCOUNT";
    EVENT_TRACKING_LEVEL[EVENT_TRACKING_LEVEL["SUB_ACCOUNT"] = 2] = "SUB_ACCOUNT";
})(EVENT_TRACKING_LEVEL || (EVENT_TRACKING_LEVEL = {}));
export var VALIDATION_TYPE;
(function (VALIDATION_TYPE) {
    VALIDATION_TYPE["RegularExpression"] = "AAAF";
})(VALIDATION_TYPE || (VALIDATION_TYPE = {}));
export var L_VendorType;
(function (L_VendorType) {
    L_VendorType[L_VendorType["DOME"] = 1] = "DOME";
    L_VendorType[L_VendorType["INTR"] = 2] = "INTR";
    L_VendorType[L_VendorType["CRCD"] = 3] = "CRCD";
})(L_VendorType || (L_VendorType = {}));
export var MasterClientList;
(function (MasterClientList) {
    MasterClientList[MasterClientList["NEP"] = 34] = "NEP";
    MasterClientList[MasterClientList["WMEIMG"] = 16] = "WMEIMG";
    MasterClientList[MasterClientList["VERIZON"] = 30] = "VERIZON";
    MasterClientList[MasterClientList["BESTBUY"] = 48] = "BESTBUY";
    MasterClientList[MasterClientList["Oracle"] = 26] = "Oracle";
    MasterClientList[MasterClientList["QVC"] = 28] = "QVC";
    MasterClientList[MasterClientList["CitiGroup"] = 47] = "CitiGroup";
    MasterClientList[MasterClientList["DELL"] = 15] = "DELL";
})(MasterClientList || (MasterClientList = {}));
export var WORKER_1099_DOCUMENT_STATUS;
(function (WORKER_1099_DOCUMENT_STATUS) {
    WORKER_1099_DOCUMENT_STATUS[WORKER_1099_DOCUMENT_STATUS["CREATED"] = 1] = "CREATED";
    WORKER_1099_DOCUMENT_STATUS[WORKER_1099_DOCUMENT_STATUS["COMPLETED"] = 2] = "COMPLETED";
})(WORKER_1099_DOCUMENT_STATUS || (WORKER_1099_DOCUMENT_STATUS = {}));
export var WORKER_1099_DOCUMENT_CATEGORY;
(function (WORKER_1099_DOCUMENT_CATEGORY) {
    WORKER_1099_DOCUMENT_CATEGORY[WORKER_1099_DOCUMENT_CATEGORY["W9"] = 1] = "W9";
    WORKER_1099_DOCUMENT_CATEGORY[WORKER_1099_DOCUMENT_CATEGORY["DDA"] = 2] = "DDA";
    WORKER_1099_DOCUMENT_CATEGORY[WORKER_1099_DOCUMENT_CATEGORY["IC"] = 3] = "IC";
    WORKER_1099_DOCUMENT_CATEGORY[WORKER_1099_DOCUMENT_CATEGORY["GL COI"] = 4] = "GL COI";
    WORKER_1099_DOCUMENT_CATEGORY[WORKER_1099_DOCUMENT_CATEGORY["WC COI"] = 5] = "WC COI";
    WORKER_1099_DOCUMENT_CATEGORY[WORKER_1099_DOCUMENT_CATEGORY["Client Document"] = 6] = "Client Document";
})(WORKER_1099_DOCUMENT_CATEGORY || (WORKER_1099_DOCUMENT_CATEGORY = {}));
export var VendorPaymentStatus;
(function (VendorPaymentStatus) {
    VendorPaymentStatus[VendorPaymentStatus["VendorCreated"] = 0] = "VendorCreated";
    VendorPaymentStatus[VendorPaymentStatus["PayReelCreated"] = 20] = "PayReelCreated";
    VendorPaymentStatus[VendorPaymentStatus["ClientCreated"] = 30] = "ClientCreated";
    VendorPaymentStatus[VendorPaymentStatus["ReturnedToEmployee"] = 50] = "ReturnedToEmployee";
    VendorPaymentStatus[VendorPaymentStatus["VendorSigned"] = 100] = "VendorSigned";
    VendorPaymentStatus[VendorPaymentStatus["ReturnedToClient"] = 150] = "ReturnedToClient";
    VendorPaymentStatus[VendorPaymentStatus["PartiallyApproved"] = 160] = "PartiallyApproved";
    VendorPaymentStatus[VendorPaymentStatus["SupervisorApproved"] = 170] = "SupervisorApproved";
    VendorPaymentStatus[VendorPaymentStatus["ManagerQueue"] = 180] = "ManagerQueue";
    VendorPaymentStatus[VendorPaymentStatus["ManagerApproved"] = 200] = "ManagerApproved";
    VendorPaymentStatus[VendorPaymentStatus["PartialyBatched"] = 300] = "PartialyBatched";
    VendorPaymentStatus[VendorPaymentStatus["Batched"] = 400] = "Batched";
})(VendorPaymentStatus || (VendorPaymentStatus = {}));
export var CLIENT_APPROVAL_METHOD;
(function (CLIENT_APPROVAL_METHOD) {
    CLIENT_APPROVAL_METHOD[CLIENT_APPROVAL_METHOD["APPROVAL_METHOD_3"] = 3] = "APPROVAL_METHOD_3";
})(CLIENT_APPROVAL_METHOD || (CLIENT_APPROVAL_METHOD = {}));
export var MASExportStatus;
(function (MASExportStatus) {
    MASExportStatus[MASExportStatus["Open"] = 1] = "Open";
    MASExportStatus[MASExportStatus["Closed"] = 2] = "Closed";
})(MASExportStatus || (MASExportStatus = {}));
export var StatesEnum;
(function (StatesEnum) {
    StatesEnum[StatesEnum["California"] = 5] = "California";
    StatesEnum[StatesEnum["Oregon"] = 39] = "Oregon";
    StatesEnum[StatesEnum["NewYork"] = 36] = "NewYork";
    StatesEnum[StatesEnum["Minnesota"] = 25] = "Minnesota";
})(StatesEnum || (StatesEnum = {}));
export var InvoiceLayoutTypeEnum;
(function (InvoiceLayoutTypeEnum) {
    InvoiceLayoutTypeEnum[InvoiceLayoutTypeEnum["IMG"] = 1] = "IMG";
    InvoiceLayoutTypeEnum[InvoiceLayoutTypeEnum["BEBU"] = 2] = "BEBU";
    InvoiceLayoutTypeEnum[InvoiceLayoutTypeEnum["CONS"] = 3] = "CONS";
    InvoiceLayoutTypeEnum[InvoiceLayoutTypeEnum["DET"] = 4] = "DET";
    InvoiceLayoutTypeEnum[InvoiceLayoutTypeEnum["CITI"] = 5] = "CITI";
    InvoiceLayoutTypeEnum[InvoiceLayoutTypeEnum["VERZ"] = 6] = "VERZ";
    InvoiceLayoutTypeEnum[InvoiceLayoutTypeEnum["IMG2"] = 7] = "IMG2";
    InvoiceLayoutTypeEnum[InvoiceLayoutTypeEnum["IMG3"] = 8] = "IMG3";
    InvoiceLayoutTypeEnum[InvoiceLayoutTypeEnum["BNEW"] = 9] = "BNEW";
    InvoiceLayoutTypeEnum[InvoiceLayoutTypeEnum["QVC"] = 10] = "QVC";
    InvoiceLayoutTypeEnum[InvoiceLayoutTypeEnum["BBSI"] = 11] = "BBSI";
    InvoiceLayoutTypeEnum[InvoiceLayoutTypeEnum["BSI2"] = 12] = "BSI2";
})(InvoiceLayoutTypeEnum || (InvoiceLayoutTypeEnum = {}));
export var VENDOR_TAB_HEADINGS;
(function (VENDOR_TAB_HEADINGS) {
    VENDOR_TAB_HEADINGS[VENDOR_TAB_HEADINGS["VENDOR_INFO"] = 0] = "VENDOR_INFO";
    VENDOR_TAB_HEADINGS[VENDOR_TAB_HEADINGS["DD_INFO"] = 1] = "DD_INFO";
    VENDOR_TAB_HEADINGS[VENDOR_TAB_HEADINGS["VENDOR_DOCUMENTS"] = 2] = "VENDOR_DOCUMENTS";
    VENDOR_TAB_HEADINGS[VENDOR_TAB_HEADINGS["WEB_SETTINGS"] = 3] = "WEB_SETTINGS";
    VENDOR_TAB_HEADINGS[VENDOR_TAB_HEADINGS["DARWIN_INFORMATION"] = 4] = "DARWIN_INFORMATION";
})(VENDOR_TAB_HEADINGS || (VENDOR_TAB_HEADINGS = {}));
export var ExpenseReportStatus;
(function (ExpenseReportStatus) {
    ExpenseReportStatus[ExpenseReportStatus["NewExpenseReport"] = 0] = "NewExpenseReport";
    ExpenseReportStatus[ExpenseReportStatus["ReturnedToEmployee"] = 50] = "ReturnedToEmployee";
    ExpenseReportStatus[ExpenseReportStatus["EmployeeSigned"] = 100] = "EmployeeSigned";
    ExpenseReportStatus[ExpenseReportStatus["ReturnedToClient"] = 150] = "ReturnedToClient";
    ExpenseReportStatus[ExpenseReportStatus["PartiallyApproved"] = 160] = "PartiallyApproved";
    ExpenseReportStatus[ExpenseReportStatus["SupervisorApproved"] = 170] = "SupervisorApproved";
    ExpenseReportStatus[ExpenseReportStatus["ManagerQueue"] = 180] = "ManagerQueue";
    ExpenseReportStatus[ExpenseReportStatus["ManagerApproved"] = 200] = "ManagerApproved";
    ExpenseReportStatus[ExpenseReportStatus["PartialyBatched"] = 300] = "PartialyBatched";
    ExpenseReportStatus[ExpenseReportStatus["Batched"] = 400] = "Batched";
})(ExpenseReportStatus || (ExpenseReportStatus = {}));
export var ReportType;
(function (ReportType) {
    ReportType[ReportType["Home State"] = 1] = "Home State";
    ReportType[ReportType["Worked State"] = 2] = "Worked State";
    ReportType[ReportType["Billed State"] = 3] = "Billed State";
})(ReportType || (ReportType = {}));
export var clientNames;
(function (clientNames) {
    clientNames[clientNames["Invalid"] = -1] = "Invalid";
    clientNames[clientNames["OracleUSA"] = 2] = "OracleUSA";
    clientNames[clientNames["QVCRemoteProductions"] = 3] = "QVCRemoteProductions";
})(clientNames || (clientNames = {}));
export var NUMERIC_TYPES;
(function (NUMERIC_TYPES) {
    NUMERIC_TYPES[NUMERIC_TYPES["MAX_INT_LENGTH"] = 6] = "MAX_INT_LENGTH";
    NUMERIC_TYPES[NUMERIC_TYPES["MAX_DECIMAL_LENGTH"] = 2] = "MAX_DECIMAL_LENGTH";
})(NUMERIC_TYPES || (NUMERIC_TYPES = {}));
export var clientsList;
(function (clientsList) {
    clientsList[clientsList["BEST_BUY_CREATIVE_SERVICES"] = 94] = "BEST_BUY_CREATIVE_SERVICES";
})(clientsList || (clientsList = {}));
export var LInvoiceLayoutType;
(function (LInvoiceLayoutType) {
    LInvoiceLayoutType[LInvoiceLayoutType["IMG"] = 1] = "IMG";
    LInvoiceLayoutType["BEBU"] = "BEBU";
    LInvoiceLayoutType["CONS"] = "CONS";
    LInvoiceLayoutType["DET"] = "DET";
    LInvoiceLayoutType["CITI"] = "CITI";
    LInvoiceLayoutType["VERZ"] = "VERZ";
    LInvoiceLayoutType["IMG2"] = "IMG2";
    LInvoiceLayoutType["IMG3"] = "IMG3";
    LInvoiceLayoutType["BNEW"] = "BNEW";
    LInvoiceLayoutType["QVC"] = "QVC";
    LInvoiceLayoutType["BBSI"] = "BBSI";
    LInvoiceLayoutType["BSI2"] = "BSI2";
})(LInvoiceLayoutType || (LInvoiceLayoutType = {}));
export var MobileGridColumnTypes;
(function (MobileGridColumnTypes) {
    MobileGridColumnTypes["TEXT"] = "text";
    MobileGridColumnTypes["DATE"] = "date";
    MobileGridColumnTypes["LINK"] = "link";
    MobileGridColumnTypes["CURRENCY"] = "currency";
    MobileGridColumnTypes["AMOUNT"] = "amount";
    MobileGridColumnTypes["NUMBER"] = "number";
    MobileGridColumnTypes["H1"] = "h1";
    MobileGridColumnTypes["H2"] = "h2";
    MobileGridColumnTypes["ICON"] = "icon";
    MobileGridColumnTypes["STRING"] = "string";
})(MobileGridColumnTypes || (MobileGridColumnTypes = {}));
export var ONLOCATION_CLIENTS;
(function (ONLOCATION_CLIENTS) {
    ONLOCATION_CLIENTS[ONLOCATION_CLIENTS["OnLocation"] = 329] = "OnLocation";
    ONLOCATION_CLIENTS[ONLOCATION_CLIENTS["OnLocationManagement"] = 360] = "OnLocationManagement";
    ONLOCATION_CLIENTS[ONLOCATION_CLIENTS["OnLocationNonEvent"] = 369] = "OnLocationNonEvent";
    ONLOCATION_CLIENTS[ONLOCATION_CLIENTS["OnLocationGolf"] = 403] = "OnLocationGolf";
})(ONLOCATION_CLIENTS || (ONLOCATION_CLIENTS = {}));
export var USER_ROLES;
(function (USER_ROLES) {
    USER_ROLES[USER_ROLES["MANAGER"] = 1] = "MANAGER";
    USER_ROLES[USER_ROLES["SUPERVISOR"] = 2] = "SUPERVISOR";
})(USER_ROLES || (USER_ROLES = {}));
