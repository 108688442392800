var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useEffect } from "react";
import { Link } from "react-router-dom";
import { Grid, GridColumn as Column } from "@progress/kendo-react-grid";
import { useSecurityContext, Transform, TranslationConstant, createEncodedURL, } from "./index";
export var ManageRolesGrid = function () {
    var _a = useSecurityContext(), getAllRolesList = _a.getAllRolesList, fetchAllRolesList = _a.fetchAllRolesList;
    useEffect(function () {
        fetchAllRolesList();
    }, []);
    var redirectUrl = window.location.origin;
    var navigateUrl = function (cellprops) {
        switch (cellprops.dataItem.Name) {
            case "PayReel":
                return "/payreelusers";
                break;
            case "Employee":
                return "/employeesearch";
                break;
            case "Vendor":
                return createEncodedURL("/vendors", {
                    redirectedFrom: "manageVendors",
                });
                break;
            case "1099 Worker":
                return createEncodedURL("".concat(redirectUrl, "/searchwebuser"), {
                    UserRoleId: cellprops.dataItem.WebUserRoleId,
                });
                break;
            case "NewHireEmployee":
                return createEncodedURL("".concat(redirectUrl, "/searchwebuser"), {
                    UserRoleId: cellprops.dataItem.WebUserRoleId,
                });
                break;
            case "Client":
                return createEncodedURL("/clientusers", {
                    redirectedFrom: "manageRoles",
                });
                break;
            case "MasterClient":
                return "/masterclientusers";
                break;
            default:
                return "#";
        }
    };
    var createLinkField = function (cellprops) {
        switch (cellprops.columnIndex) {
            case 2: {
                if (cellprops.dataItem.WebUserRoleId === 10) {
                    return _jsx(Transform, { mkey: TranslationConstant.MANAGE_FEATURES });
                }
                return (cellprops.dataItem.WebUserRoleId !== 1 && (_jsx(Link, __assign({ to: "/managefeaturbyrole?role=".concat(cellprops.dataItem.WebUserRoleId), state: {
                        WebUserRoleId: cellprops.dataItem["WebUserRoleId"],
                        WebUserRoleName: cellprops.dataItem["Name"],
                    } }, { children: _jsx(Transform, { mkey: TranslationConstant.MANAGE_FEATURES }) }))));
            }
            case 3:
                return cellprops.dataItem.Name === "MasterClientReadOnly" ||
                    cellprops.dataItem.Name === "EmpAuthorizedI9Verifier" ? (_jsx(Transform, { mkey: TranslationConstant.MANAGE_USERS })) : (_jsx(Link, __assign({ to: navigateUrl(cellprops), target: cellprops.dataItem.Name === "1099 Worker" ||
                        cellprops.dataItem.Name === "NewHireEmployee"
                        ? "_blank"
                        : "_self" }, { children: _jsx(Transform, { mkey: TranslationConstant.MANAGE_USERS }) })));
        }
    };
    var ColumnCell = function (cellprops) {
        return (_jsx("td", __assign({ colSpan: cellprops.colSpan, role: "gridcell", "aria-colindex": cellprops.ariaColumnIndex, "aria-selected": cellprops.isSelected }, { children: createLinkField(cellprops) })));
    };
    var ManageCustomCell = function (props) {
        return _jsx(ColumnCell, __assign({}, props));
    };
    return (_jsx(_Fragment, { children: _jsx("div", __assign({ className: "general-grid" }, { children: _jsxs(Grid, __assign({ data: getAllRolesList }, { children: [_jsx(Column, { field: "Name", title: "Role Name", width: "250px" }), _jsx(Column, { field: "Description", title: "Description", width: "300px" }), _jsx(Column, { field: "", title: "", width: "140px", cell: ManageCustomCell }), _jsx(Column, { field: "", title: "", width: "120px", cell: ManageCustomCell })] })) })) }));
};
