export var ROUTE_1099_WORKERS = {
    HOME: "/",
    DOCUMENT_VIEW: "/documentview",
    EDIT_w9_FORM_1099_WORKER: "/edit1099WorkerForm",
    TAX_STATEMENTS: "/taxstatements",
    DASHBOARD: "/dashboard",
};
export var ROUTE_TIME_SHEETS = {
    MANAGE_UNSIGNED_TIME_SHEETS: "/manageunsignedtimesheets",
    CREATE_TIME_SHEET: "/createTimeSheet",
    HOME: "/",
    VIEW_TIME_SHEET: "/viewtimesheet",
    SEARCH_TIME_SHEET: "/searchtimesheets",
};
export var ROUTE_ADMIN_EMPLOYEE_SEARCH = {
    HOME: "/",
    EMPLOYEE_SEARCH: "/employeesearch",
};
export var CREDIT_CARDS_TRANSACTIONS_ROUTE = {
    APPROVE_CREDIT_CARD_TRANSACTIONS: "/approveCreditCardTransaction",
    HOME: "/",
};
export var CLIENT_USER_ROUTES = {
    HOME: "/",
    SEARCH_CLIENT_USER: "/clientusers",
};
export var HOME_BREADCRUMB = {
    id: "home",
    text: "Home",
    iconClass: "k-i-home",
    route: "/",
};
export var CLIENT_SETUP_ROUTES = {
    HOME: "/",
};
export var STATES_SETUP_ROUTES = {
    FUTA_RATES: "/statefutabaserates",
    SUTA_RATES: "/statesutabaserates",
    WC_RATES: "/statewcbaserates",
    SEXUAL_HARASSMENT_TRAINING_REQUIREMENTS_ROUTE: "/sexualHarassmentTrainingRequirements",
};
export var ADMIN_DASHBOARD_FOR_WORKER_1099 = {
    HOME: "/",
    ADMIN_DASHBOARD_ROUTE: "/1099WorkerDashboard",
    CREATE_NEW_REQUEST: "/newRequest",
    MANAGE_WORKER: "/manageWorkerDetails",
};
export var ADMIN_ROUTES = {
    ADD_VENDORS: "/addVendors",
    VENDORS: "/vendors",
    STATES_SETUP: "/statesSetup",
    DOCUMENT_VIEW: "/documentview",
    EDIT_STATE_SETUP: "/stateInfo",
    HOME: "/",
    TAXABLE_BASE_WAGES: "/taxableBaseWages",
    PAY_STUB_DETAILS: "/payStubDetails",
    EMPLOYER_POSTING: "/employerpostings",
    AGENCY_RESPONSIBLE: "/agencyresponsible",
    WORKERS_COMP_CODE: "/workerscompcodes",
};
