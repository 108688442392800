var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { AppConstant, BreadcrumbComponent, PageTitle, Transform, TranslationConstant, } from "./index";
export var EmployeeSupport = function () {
    var isCorpAplication = window.location.href.toLowerCase().indexOf(AppConstant.CORP_BASE_PATHNAME) > -1;
    var items = [
        {
            id: "dashboard",
            text: isCorpAplication ? "Home" : "Dashboard",
            iconClass: "k-i-home",
            route: "/",
        },
        {
            id: "support",
            text: "Support",
        },
    ];
    var data = {
        title: _jsx(Transform, { mkey: TranslationConstant.SUPPORT_PAGE_TITLE }),
    };
    var supportNumber = process.env.SUPPORT_CONTACT;
    var emailLink = process.env.SUPPORT_EMAIL;
    var subject = "PayReelOnline.com Support";
    var body = "";
    var supportEmail = "mailto:".concat(emailLink, "?subject=").concat(encodeURIComponent(subject), "&body=").concat(encodeURIComponent(body));
    return (_jsxs(_Fragment, { children: [_jsx(BreadcrumbComponent, { items: items }), _jsx(PageTitle, { data: data }), _jsx("div", { className: "grey-container shortcut-wrapper clearfix " }), _jsxs("div", __assign({ className: "support-container pad-x-20" }, { children: [_jsx("p", { children: "For further assistance, please email us anytime or call us Monday-Friday 10am-7pm Eastern time and we will be happy to help you." }), _jsxs("div", __assign({ className: "d-flex align-items-center gap-2" }, { children: [_jsx("i", { className: "fas fa-envelope font-weight-bold" }), _jsx("a", __assign({ href: supportEmail }, { children: emailLink }))] })), _jsxs("div", __assign({ className: "d-flex align-items-center gap-2" }, { children: [_jsx("i", { className: "fas fa-phone font-weight-bold" }), _jsx("a", __assign({ href: "tel:+".concat(process.env.supportNumber) }, { children: supportNumber }))] }))] }))] }));
};
