var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useState } from "react";
import { Form, Field, FormElement } from "@progress/kendo-react-form";
import { Dialog } from "@progress/kendo-react-dialogs";
import { useCurrentViewContext } from "store/common/currentViewContext";
import { Transform, TranslationConstant, FormButtons, requiredValidator, FormDropDownListWithValue, useTimesheetViewContext, ComponentConstant, createWeekDays, AppConstant, FieldWIthMultipleValidator, startTimeValidator, DialogBox, formatDate, timeValidator, FormMaskedTextBox, FormDatePicker, CustomCalendar, FormDropDownList, noValidationMsg } from "./index";
import AddNewLocation from "../../../Employee/Timesheets/AddNewLocation";
import { WEBUSERROLEID } from "common/constant/Enums";
import { postMissingTimeSheetDayDetail } from "services/timesheet";
export default function AddTimeForm(_a) {
    var _this = this;
    var handleClose = _a.handleClose, weekEndingDate = _a.weekEndingDate, EmployeeId = _a.EmployeeId, userProfile = _a.userProfile, TimeSheetId = _a.TimeSheetId, refreshGridDataHandler = _a.refreshGridDataHandler, selectedClient = _a.selectedClient, timeSheetDayDetails = _a.timeSheetDayDetails, employeeInformation = _a.employeeInformation;
    var _b = useTimesheetViewContext(), timesheetAccountsList = _b.timesheetAccountsList, workLocationsForEmployeeUsingAccountID = _b.workLocationsForEmployeeUsingAccountID, employeePositions = _b.employeePositions, sickLeaveHoursAvailable = _b.sickLeaveHoursAvailable, timesheetSubAccountsList = _b.timesheetSubAccountsList, clientSupervisors = _b.clientSupervisors, timeSheetDataEdit = _b.timeSheetDataEdit, workLocationId = _b.workLocationId, fetchTimeSheetAccounts = _b.fetchTimeSheetAccounts, getWorkLocationsForEmployeeUsingAccountID = _b.getWorkLocationsForEmployeeUsingAccountID, fetchEmployeePositions = _b.fetchEmployeePositions, getSickLeaveHoursAvailable = _b.getSickLeaveHoursAvailable, fetchTimeSheetSubAccounts = _b.fetchTimeSheetSubAccounts, getTimeSheetDayDetail = _b.getTimeSheetDayDetail, getNonWorkedHoursCodes = _b.getNonWorkedHoursCodes, getClientSupervisors = _b.getClientSupervisors, getWorkLocationByID = _b.getWorkLocationByID, getSubAccount = _b.getSubAccount, agreementDateRange = _b.agreementDateRange, fetchAgreementDateRange = _b.fetchAgreementDateRange, resetAccountSubAccounts = _b.resetAccountSubAccounts;
    var employeeList = useCurrentViewContext().employeeList;
    var _c = useState(EmployeeId), EmpID = _c[0], setEmpID = _c[1];
    var _d = useCurrentViewContext(), postSendEmailTemplate = _d.postSendEmailTemplate, resetShowProgress = _d.resetShowProgress;
    var employeeInfoDetails = JSON.parse(localStorage.getItem("employeeInformation"));
    var _e = useState(0), sickLeaveHoursAvl = _e[0], updateSickLeaveHoursAvl = _e[1];
    var _f = useState(false), addSickLeave = _f[0], setAddSickLeave = _f[1];
    var _g = useState([]), timesheetDateData = _g[0], updateTimesheetDateDate = _g[1];
    var _h = useState(null), workLocationID = _h[0], updateWorkLocationID = _h[1];
    var _j = useState(null), workLocationState = _j[0], updateWorkLocationState = _j[1];
    var _k = useState(null), accountId = _k[0], updateAccountID = _k[1];
    var _l = useState(true), disableDropdown = _l[0], updateDisableDropdown = _l[1];
    var _m = useState({}), daySelected = _m[0], updateDaySelected = _m[1];
    var _o = useState(null), selectedState = _o[0], setSelectedState = _o[1];
    var _p = useState(1), workedHours = _p[0], updateWorkedHours = _p[1];
    var _q = useState({
        timeIn: null,
        timeOut: null,
    }), timeInOut = _q[0], updateTimeInOut = _q[1];
    var _r = useState(null), isValidTimeIn = _r[0], updateIsValidTimeIn = _r[1];
    var _s = useState(false), modalState = _s[0], updateModalState = _s[1];
    var _t = useState(null), inputVal = _t[0], updateInpVal = _t[1];
    var _u = useState(null), isValidAvailableHours = _u[0], updateInValidAvailableHours = _u[1];
    var _v = useState(false), addNewLocation = _v[0], setAddNewLocation = _v[1];
    var _w = useState(null), subAccountValue = _w[0], setSubAccountValue = _w[1];
    var _x = useState({
        weekEndingDate: null,
        employee: null,
        dayWorked: null,
        accountID: null,
        subAccount: null,
        requisitionNumbersDDL: null,
        workLocation: null,
        position: null,
        hoursType: 1,
        timeIn: null,
        timeOut: null,
        meals: null,
        additionalHours: null,
        guaranteeHours: null,
        payCode: null,
        nonWorkedHours: null,
        udf1: null,
        udf2: null,
        udf3: null,
        ddlSupervisor: null,
        addSickLeave: false,
        availableHours: null,
    }), initialValues = _x[0], updateInitialValues = _x[1];
    var _y = useState({
        headingText: "",
        bodyText: "",
        acceptText: "",
        isErrorDialog: false,
        modalType: null,
        customClass: "",
    }), modalContent = _y[0], updateModalContent = _y[1];
    var _z = useState({}), timeSheetDayDataState = _z[0], setTimesheetDayDataState = _z[1];
    var _0 = useState(), selectedWeekendDate = _0[0], setSelectedWeekendDate = _0[1];
    var workDaySelecetion = function (e) {
        if (e.value === null) {
            updateDisableDropdown(true);
        }
        else {
            updateDisableDropdown(false);
            var dayIndex = timesheetDateData.filter(function (item) { return item.value === e.value; });
            updateDaySelected({
                value: e.value,
                dayIndex: dayIndex[0].dayIndex,
            });
        }
    };
    useEffect(function () {
        (function () { return __awaiter(_this, void 0, void 0, function () {
            var isAccountExist;
            var _a;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        if (!((timeSheetDayDetails === null || timeSheetDayDetails === void 0 ? void 0 : timeSheetDayDetails.timeSheetDayDetailID) !== null && daySelected.value)) return [3 /*break*/, 2];
                        return [4 /*yield*/, accountRefresh()];
                    case 1:
                        isAccountExist = _b.sent();
                        if (selectedClient === null || selectedClient === void 0 ? void 0 : selectedClient.subAccountUsed) {
                            subAccountRefresh((_a = isAccountExist[0]) === null || _a === void 0 ? void 0 : _a.value);
                        }
                        _b.label = 2;
                    case 2: return [2 /*return*/];
                }
            });
        }); })();
    }, [daySelected]);
    var accountRefresh = function () { return __awaiter(_this, void 0, void 0, function () {
        var res, isAccountExist;
        var _a, _b, _c;
        return __generator(this, function (_d) {
            switch (_d.label) {
                case 0: return [4 /*yield*/, fetchTimeSheetAccounts(userProfile.ClientId, EmployeeId, daySelected.value, true, (selectedClient === null || selectedClient === void 0 ? void 0 : selectedClient.eventTrackingLevelID) || 0)];
                case 1:
                    res = _d.sent();
                    isAccountExist = (_a = res === null || res === void 0 ? void 0 : res.data) === null || _a === void 0 ? void 0 : _a.filter(function (ele) {
                        var _a;
                        return (ele === null || ele === void 0 ? void 0 : ele.value) === ((_a = timeSheetDataEdit === null || timeSheetDataEdit === void 0 ? void 0 : timeSheetDataEdit.accountID) === null || _a === void 0 ? void 0 : _a.toString());
                    });
                    updateAccountID(((_b = isAccountExist[0]) === null || _b === void 0 ? void 0 : _b.value) || null);
                    updateInitialValues(__assign(__assign({}, initialValues), { accountID: ((_c = isAccountExist[0]) === null || _c === void 0 ? void 0 : _c.value) || null }));
                    return [2 /*return*/, isAccountExist];
            }
        });
    }); };
    var subAccountRefresh = function (isAccountExist) { return __awaiter(_this, void 0, void 0, function () {
        var res, isSubAccountExist;
        var _a, _b, _c;
        return __generator(this, function (_d) {
            switch (_d.label) {
                case 0: return [4 /*yield*/, fetchTimeSheetSubAccounts(userProfile.ClientId, EmployeeId, daySelected.value, selectedClient === null || selectedClient === void 0 ? void 0 : selectedClient.subAccountDescriptionAllowed, selectedClient === null || selectedClient === void 0 ? void 0 : selectedClient.subAccountMaskNEP, selectedClient === null || selectedClient === void 0 ? void 0 : selectedClient.eventTrackingLevelID)];
                case 1:
                    res = _d.sent();
                    isSubAccountExist = (_a = res === null || res === void 0 ? void 0 : res.data) === null || _a === void 0 ? void 0 : _a.filter(function (ele) {
                        var _a;
                        return (ele === null || ele === void 0 ? void 0 : ele.value) === ((_a = timeSheetDataEdit === null || timeSheetDataEdit === void 0 ? void 0 : timeSheetDataEdit.subAccountID) === null || _a === void 0 ? void 0 : _a.toString());
                    });
                    setSubAccountValue(((_b = isSubAccountExist[0]) === null || _b === void 0 ? void 0 : _b.value) || null);
                    updateInitialValues(__assign(__assign({}, initialValues), { accountID: isAccountExist, subAccountId: ((_c = isSubAccountExist[0]) === null || _c === void 0 ? void 0 : _c.value) || null }));
                    return [2 /*return*/];
            }
        });
    }); };
    var isEndeavorClient = false;
    useEffect(function () {
        (function () { return __awaiter(_this, void 0, void 0, function () {
            var selectedClient, response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        selectedClient = JSON.parse(localStorage.getItem("selectedClient")) || null;
                        isEndeavorClient =
                            WEBUSERROLEID.ENDEAVOR_CLIENT_ID === (selectedClient === null || selectedClient === void 0 ? void 0 : selectedClient.masterClientID);
                        if (!(EmployeeId && (agreementDateRange === null || agreementDateRange === void 0 ? void 0 : agreementDateRange.length) === 0)) return [3 /*break*/, 2];
                        return [4 /*yield*/, fetchAgreementDateRange(EmployeeId)];
                    case 1:
                        response = _a.sent();
                        setWeekDays(response.data);
                        return [3 /*break*/, 3];
                    case 2:
                        setWeekDays(agreementDateRange || []);
                        _a.label = 3;
                    case 3: return [2 /*return*/];
                }
            });
        }); })();
        return function () {
            resetAccountSubAccounts();
        };
    }, []);
    var setWeekDays = function (dateRangeObject) {
        var weekArrayList = createWeekDays(weekEndingDate, 7, dateRangeObject);
        updateTimesheetDateDate(weekArrayList);
        if (timeSheetDayDetails.timeSheetDayDetailID !== null) {
            setTimesheetDataForEdit();
        }
    };
    var setTimesheetDataForEdit = function () { return __awaiter(_this, void 0, void 0, function () {
        var response, selectedClient, timeSheetDayData, dateWorked, dayWorkedFormated, dayIndex, sickLeave, hoursType;
        var _a, _b, _c, _d, _e, _f, _g, _h, _j;
        return __generator(this, function (_k) {
            switch (_k.label) {
                case 0: return [4 /*yield*/, getTimeSheetDayDetail(timeSheetDayDetails.timeSheetDayDetailID)];
                case 1:
                    response = _k.sent();
                    selectedClient = JSON.parse(localStorage.getItem("selectedClient")) || null;
                    timeSheetDayData = response.data;
                    setTimesheetDayDataState(timeSheetDayData);
                    dateWorked = new Date(timeSheetDayDetails.dateWorked);
                    dayWorkedFormated = formatDate(dateWorked, "EEEE, MMMM dd, yyyy");
                    dayIndex = timesheetDateData.filter(function (item) { return item.value === dayWorkedFormated; });
                    if (!timeSheetDayDetails.isDuplicateTimesheet) {
                        updateDaySelected({
                            value: dayWorkedFormated,
                            dayIndex: (_a = dayIndex[0]) === null || _a === void 0 ? void 0 : _a.dayIndex,
                        });
                    }
                    fetchDropdownData("account", dayWorkedFormated, timeSheetDayData.accountID);
                    fetchDropdownData("position");
                    if ((selectedClient === null || selectedClient === void 0 ? void 0 : selectedClient.subAccountUsed) || ((_b = selectedClient === null || selectedClient === void 0 ? void 0 : selectedClient.clientDto) === null || _b === void 0 ? void 0 : _b.subAccountUsed)) {
                        fetchDropdownData("subAccount", dayWorkedFormated);
                        setSubAccountValue(timeSheetDayData === null || timeSheetDayData === void 0 ? void 0 : timeSheetDayData.subAccountID);
                    }
                    fetchDropdownData("location", null, timeSheetDayData.accountID, timeSheetDayData === null || timeSheetDayData === void 0 ? void 0 : timeSheetDayData.subAccountID);
                    if (timeSheetDayData === null || timeSheetDayData === void 0 ? void 0 : timeSheetDayData.clientUserID) {
                        getClientSupervisors(userProfile.ClientId);
                    }
                    sickLeave = timeSheetDayData.nonWorkedHoursCodeID == 10 ? true : false;
                    hoursType = showHideWorkNonWorkRadio() && timeSheetDayData.nonWorkedHoursCodeID !== null ? 2 : 1;
                    if (timeSheetDayData.nonWorkedHoursCodeID) {
                        fetchDropdownData("nonWorkedCodes");
                    }
                    updateTimeInOut({
                        timeIn: timeSheetDayData.timeIn
                            ? "".concat(timeSheetDayData.timeIn.slice(0, 2), ":").concat(timeSheetDayData.timeIn.slice(2))
                            : null,
                        timeOut: timeSheetDayData.timeOut
                            ? "".concat(timeSheetDayData.timeOut.slice(0, 2), ":").concat(timeSheetDayData.timeOut.slice(2))
                            : null,
                    });
                    updateInpVal(timeSheetDayData.nonWorkedHours);
                    updateInitialValues({
                        weekEndingDate: new Date(weekEndingDate),
                        employee: employeeList.filter(function (item) { return item.employeeID === EmpID; })[0],
                        dayWorked: !timeSheetDayDetails.isDuplicateTimesheet ? dayWorkedFormated : "",
                        accountID: (_c = timeSheetDayData.accountID) === null || _c === void 0 ? void 0 : _c.toString(),
                        subAccountId: (_d = timeSheetDayData.subAccountID) === null || _d === void 0 ? void 0 : _d.toString(),
                        requisitionNumbersDDL: (_e = timeSheetDayData.requisitionNumberID) === null || _e === void 0 ? void 0 : _e.toString(),
                        workLocation: (_f = timeSheetDayData.workLocationID) === null || _f === void 0 ? void 0 : _f.toString(),
                        position: (_g = timeSheetDayData.employeePositionID) === null || _g === void 0 ? void 0 : _g.toString(),
                        hoursType: hoursType,
                        fTimeIn: timeSheetDayData.timeIn
                            ? "".concat(timeSheetDayData.timeIn.slice(0, 2), ":").concat(timeSheetDayData.timeIn.slice(2))
                            : "",
                        fTimeOut: timeSheetDayData.timeOut
                            ? "".concat(timeSheetDayData.timeOut.slice(0, 2), ":").concat(timeSheetDayData.timeOut.slice(2))
                            : "",
                        meals: timeSheetDayData.mealHours,
                        additionalHours: timeSheetDayData.additionalHours,
                        guaranteeHours: (timeSheetDayData === null || timeSheetDayData === void 0 ? void 0 : timeSheetDayData.guaranteedHours) > 24 ? 24 : timeSheetDayData === null || timeSheetDayData === void 0 ? void 0 : timeSheetDayData.guaranteedHours,
                        payCode: (_h = timeSheetDayData.nonWorkedHoursCodeID) === null || _h === void 0 ? void 0 : _h.toString(),
                        nonWorkedHours: timeSheetDayData.nonWorkedHours,
                        udf1: timeSheetDayData.udF1,
                        udf2: timeSheetDayData.udF2,
                        udf3: timeSheetDayData.udF3,
                        ddlSupervisor: (_j = timeSheetDayData.clientUserID) === null || _j === void 0 ? void 0 : _j.toString(),
                        addSickLeave: sickLeave,
                        availableHours: sickLeave ? timeSheetDayData.nonWorkedHours : null,
                    });
                    updateWorkedHours(hoursType);
                    setAddSickLeave(sickLeave);
                    updateAccountID(timeSheetDayData.accountID);
                    updateWorkLocationID(timeSheetDayData.workLocationID);
                    getWorkLocationByID(timeSheetDayData.workLocationID);
                    if (sickLeave)
                        getSickLeaveHoursAvailable(EmpID, timeSheetDayData.workLocationID);
                    updateDisableDropdown(false);
                    return [2 /*return*/];
            }
        });
    }); };
    var fetchDropdownData = function (dropdownType, daySelect, account, subaccountId) {
        if (daySelect === void 0) { daySelect = daySelected.value; }
        if (account === void 0) { account = accountId; }
        return __awaiter(_this, void 0, void 0, function () {
            var subAccountResponse;
            var _a;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        if (!(dropdownType === "account" && timesheetAccountsList.length === 0)) return [3 /*break*/, 1];
                        fetchTimeSheetAccounts(userProfile.ClientId, EmpID, daySelect, true, (selectedClient === null || selectedClient === void 0 ? void 0 : selectedClient.eventTrackingLevelID) || 0);
                        return [3 /*break*/, 8];
                    case 1:
                        if (!(dropdownType === "weekEndingDate")) return [3 /*break*/, 2];
                        setWeekDays(agreementDateRange);
                        return [3 /*break*/, 8];
                    case 2:
                        if (!(dropdownType === "subAccount" &&
                            ((selectedClient === null || selectedClient === void 0 ? void 0 : selectedClient.imgSetup) === false ||
                                ((selectedClient === null || selectedClient === void 0 ? void 0 : selectedClient.imgSetup) === true && timesheetSubAccountsList.length === 0)))) return [3 /*break*/, 3];
                        fetchTimeSheetSubAccounts(userProfile.ClientId, EmpID, daySelect, selectedClient === null || selectedClient === void 0 ? void 0 : selectedClient.subAccountDescriptionAllowed, selectedClient === null || selectedClient === void 0 ? void 0 : selectedClient.subAccountMaskNEP, selectedClient === null || selectedClient === void 0 ? void 0 : selectedClient.eventTrackingLevelID);
                        return [3 /*break*/, 8];
                    case 3:
                        if (!(dropdownType === "employees")) return [3 /*break*/, 4];
                        return [3 /*break*/, 8];
                    case 4:
                        if (!(dropdownType === "location")) return [3 /*break*/, 7];
                        subAccountResponse = void 0;
                        if (!(selectedClient === null || selectedClient === void 0 ? void 0 : selectedClient.imgSetup)) return [3 /*break*/, 6];
                        return [4 /*yield*/, getSubAccount(subAccountValue === null ? subaccountId : subAccountValue)];
                    case 5:
                        subAccountResponse = _b.sent();
                        updateAccountID(subAccountResponse.data.accountID);
                        _b.label = 6;
                    case 6:
                        getWorkLocationsForEmployeeUsingAccountID(userProfile.ClientId, (selectedClient === null || selectedClient === void 0 ? void 0 : selectedClient.imgSetup) ? (_a = subAccountResponse === null || subAccountResponse === void 0 ? void 0 : subAccountResponse.data) === null || _a === void 0 ? void 0 : _a.accountID : account, EmpID);
                        return [3 /*break*/, 8];
                    case 7:
                        if (dropdownType === "position") {
                            fetchEmployeePositions(EmpID);
                        }
                        else if (dropdownType === "nonWorkedCodes") {
                            getNonWorkedHoursCodes(userProfile.ClientId);
                        }
                        else if (dropdownType === "clientSupervisors") {
                            getClientSupervisors(userProfile.ClientId);
                        }
                        _b.label = 8;
                    case 8: return [2 /*return*/];
                }
            });
        });
    };
    var handleAddTimeSubmit = function (dataItem) { return __awaiter(_this, void 0, void 0, function () {
        var dataObj, selectedClient_1, dataObjCommon, formatedWeekEndingDate, response, supportNumber, emailLink, validationErrorMsg, supportNumber, emailLink, validationErrorMsg, err_1;
        var _a, _b, _c, _d, _e, _f, _g;
        return __generator(this, function (_h) {
            switch (_h.label) {
                case 0:
                    if (!dataItem.isValid) return [3 /*break*/, 4];
                    dataObj = void 0;
                    selectedClient_1 = localStorage.getItem("selectedClient") !== "undefined"
                        ? JSON.parse(localStorage.getItem("selectedClient"))
                        : {};
                    dataObjCommon = {
                        timesheetDayDetailID: !timeSheetDayDetails.isDuplicateTimesheet
                            ? timeSheetDayDetails.timeSheetDayDetailID
                            : 0 || 0,
                        clientID: userProfile.ClientId,
                        employeePositionID: parseInt(dataItem.values.position),
                        masterwebuserID: userProfile.MasterWebUserId,
                        employeeID: dataItem.values.employee.employeeID,
                        timeSheetID: TimeSheetId !== null && TimeSheetId !== void 0 ? TimeSheetId : 0,
                        dateWorked: formatDate(new Date(daySelected.value), "yyyy-MM-ddTHH:mm:ss") || null,
                        dayIndex: daySelected.dayIndex,
                        timeIn: (_b = (_a = dataItem.values.fTimeIn) === null || _a === void 0 ? void 0 : _a.replace(":", "")) !== null && _b !== void 0 ? _b : "",
                        timeOut: (_d = (_c = dataItem.values.fTimeOut) === null || _c === void 0 ? void 0 : _c.replace(":", "")) !== null && _d !== void 0 ? _d : "",
                        accountID: dataItem.values.accountID
                            ? parseInt(dataItem.values.accountID)
                            : accountId,
                        subAccountID: subAccountValue || null,
                        udF1: dataItem.values.udf1 || "",
                        udF2: dataItem.values.udf2 || "",
                        udF3: dataItem.values.udf3 || "",
                        guaranteedHrs: dataItem.values.guaranteeHours || 0,
                        mealHours: dataItem.values.meals || 0,
                        additionalHours: dataItem.values.additionalHours || 0,
                        nonWorkedHoursCodeID: addSickLeave
                            ? 10
                            : workedHours === 1
                                ? null
                                : (_e = dataItem === null || dataItem === void 0 ? void 0 : dataItem.values) === null || _e === void 0 ? void 0 : _e.payCode,
                        nonWorkedHours: addSickLeave
                            ? (_f = dataItem === null || dataItem === void 0 ? void 0 : dataItem.values) === null || _f === void 0 ? void 0 : _f.availableHours
                            : workedHours === 1
                                ? null
                                : (_g = dataItem === null || dataItem === void 0 ? void 0 : dataItem.values) === null || _g === void 0 ? void 0 : _g.nonWorkedHours,
                        workLocationID: parseInt(dataItem.values.workLocation),
                        requisitionNumberID: dataItem.values.requisitionNumbersDDL || null,
                        vzSetup: false,
                        createdByFullName: "".concat(userProfile.FirstName, " ").concat(userProfile.LastName),
                        createdByUserRole: userProfile.WebUserRoleId.toString(),
                        timeSheetVzDayID: null,
                        vzPercentOfDay: 0,
                        accountAlias: (selectedClient_1 === null || selectedClient_1 === void 0 ? void 0 : selectedClient_1.accountAlias) || "",
                        onsiteTime: false,
                        clientUserID: dataItem.values.ddlSupervisor || null,
                    };
                    dataObj = dataObjCommon;
                    if (timeSheetDayDetails.isDuplicateTimesheet) {
                        timeSheetDataEdit["timeSheetDayDetailID"] = 0;
                    }
                    if (timeSheetDayDetails.timeSheetDayDetailID ||
                        timeSheetDayDetails.isDuplicateTimesheet) {
                        dataObj = __assign({}, dataObjCommon);
                    }
                    _h.label = 1;
                case 1:
                    _h.trys.push([1, 3, , 4]);
                    formatedWeekEndingDate = formatDate(new Date(dataItem.values.weekEndingDate), "MM/dd/yyyy");
                    return [4 /*yield*/, postMissingTimeSheetDayDetail(formatedWeekEndingDate, dataObj)];
                case 2:
                    response = _h.sent();
                    if (AppConstant.HTTP_SUCCESS_CODES.includes(response.status)) {
                        if (!response.data[0].isSuccess) {
                            supportNumber = process.env.SUPPORT_CONTACT;
                            emailLink = process.env.SUPPORT_EMAIL;
                            validationErrorMsg = response.data[0].validationMessage !== "" &&
                                response.data[0].validationMessage !== null
                                ? response.data[0].validationMessage
                                : "There is an error in adding timesheet. For further assistance, please contact on ".concat(emailLink, " or call ").concat(supportNumber, ".");
                            addTimeFailureDialog(validationErrorMsg);
                        }
                        else if (timeSheetDayDetails.timeSheetDayDetailID &&
                            !timeSheetDayDetails.isDuplicateTimesheet) {
                            addTimeSuccessDialog(_jsx(Transform, { mkey: TranslationConstant.TIME_UPDATED_SUCCESSFULLY }), 1);
                        }
                        else {
                            addTimeSuccessDialog(_jsx(Transform, { mkey: TranslationConstant.TIME_ADDED_SUCCESSFULLY }), 1);
                        }
                    }
                    else {
                        supportNumber = process.env.SUPPORT_CONTACT;
                        emailLink = process.env.SUPPORT_EMAIL;
                        validationErrorMsg = response.data[0].validationMessage !== "" &&
                            response.data[0].validationMessage !== null
                            ? response.data[0].validationMessage
                            : "There is an error in adding timesheet. For further assistance, please contact on ".concat(emailLink, " or call ").concat(supportNumber, ".");
                        addTimeFailureDialog(validationErrorMsg);
                        emailTriggerOnError(response.data);
                    }
                    return [3 /*break*/, 4];
                case 3:
                    err_1 = _h.sent();
                    console.log(err_1);
                    return [3 /*break*/, 4];
                case 4: return [2 /*return*/];
            }
        });
    }); };
    var addTimeSuccessDialog = function (successMessage, modalType) { return __awaiter(_this, void 0, void 0, function () {
        return __generator(this, function (_a) {
            updateModalContent({
                headingText: _jsx(Transform, { mkey: TranslationConstant.SUCCESS }),
                bodyText: (_jsxs("div", __assign({ className: "block subheading" }, { children: [_jsx("i", { className: "fa fa-check fa-lg" }), _jsx("span", { children: successMessage })] }))),
                cancelText: _jsx(Transform, { mkey: TranslationConstant.CLOSE_BUTTON }),
                isErrorDialog: false,
                modalType: modalType,
                customClass: "",
            });
            updateModalState(true);
            return [2 /*return*/];
        });
    }); };
    var addTimeFailureDialog = function (validationMessage) { return __awaiter(_this, void 0, void 0, function () {
        return __generator(this, function (_a) {
            updateModalContent({
                headingText: _jsx(Transform, { mkey: TranslationConstant.FAILED }),
                bodyText: (_jsxs("div", __assign({ className: "block subheading" }, { children: [_jsx("i", { className: "fa fa-times-circle fa-lg" }), _jsx("span", { children: validationMessage })] }))),
                acceptTxt: "",
                cancelText: _jsx(Transform, { mkey: TranslationConstant.CLOSE_BUTTON }),
                isErrorDialog: true,
                fixedWidth: true,
                customClass: "align-content",
            });
            updateModalState(true);
            return [2 /*return*/];
        });
    }); };
    var createEmailTemplate = function (dataObj) {
        var _a;
        return {
            UserName: "",
            FirstName: dataObj.firstName,
            LastName: dataObj.lastName,
            EmailAddress: "",
            ClientName: dataObj.clientName,
            PrimaryWorkStateId: 0,
            NotificationCode: dataObj.notificationCode,
            RedirectURI: "",
            Parameters: {
                TimeSheetID: ((_a = dataObj.timeSheetID) === null || _a === void 0 ? void 0 : _a.toString()) || "",
                WeekEndingDate: dataObj.weekEndingDate,
                AccountName: dataObj.accountName,
            },
        };
    };
    var emailTriggerOnError = function (errorObject) {
        errorObject.map(function (item) {
            var emailTemplate = createEmailTemplate(item);
            if (item.notificationCode) {
                resetShowProgress(true);
                postSendEmailTemplate(emailTemplate);
            }
        });
    };
    useEffect(function () {
        setSelectedState(selectedState);
    }, [selectedState]);
    useEffect(function () {
        if (timeInOut.timeIn !== null && timeInOut.timeOut !== null) {
            if (timeInOut.timeIn.replace(":", "") >= timeInOut.timeOut.replace(":", "")) {
                updateIsValidTimeIn(true);
            }
            else {
                updateIsValidTimeIn(false);
            }
        }
    }, [timeInOut]);
    var modalCloseHandler = function (e) {
        updateModalState(false);
        if (!modalContent.isErrorDialog && modalContent.modalType === 1) {
            handleClose();
        }
        refreshGridDataHandler();
    };
    useEffect(function () {
        if (isNaN(inputVal) || inputVal <= 0 || inputVal > sickLeaveHoursAvl) {
            updateInValidAvailableHours(true);
        }
        else {
            updateInValidAvailableHours(false);
        }
    }, [inputVal, sickLeaveHoursAvl]);
    useEffect(function () {
        updateSickLeaveHoursAvl(sickLeaveHoursAvailable.sickLeaveHoursAvailable);
    }, [sickLeaveHoursAvailable]);
    var workLocationHandler = function (e) { return __awaiter(_this, void 0, void 0, function () {
        return __generator(this, function (_a) {
            updateWorkLocationID(e.value);
            if (e.value) {
                getWorkLocationByID(e.value);
                if (addSickLeave)
                    getSickLeaveHoursAvailable(EmpID, e.value);
            }
            return [2 /*return*/];
        });
    }); };
    useEffect(function () {
        updateWorkLocationState(workLocationId.stateID);
    }, [workLocationId]);
    var showHideWorkNonWorkRadio = function () {
        var _a;
        var nonWorkedHoursAllowedLevelID = (selectedClient === null || selectedClient === void 0 ? void 0 : selectedClient.nonWorkedHoursAllowedLevelID) ||
            ((_a = selectedClient === null || selectedClient === void 0 ? void 0 : selectedClient.clientDto) === null || _a === void 0 ? void 0 : _a.nonWorkedHoursAllowedLevelID) ||
            0;
        var nonWorkedHoursAllowed = (employeeInfoDetails === null || employeeInfoDetails === void 0 ? void 0 : employeeInfoDetails.nonWorkedHoursAllowed) ||
            (employeeInformation === null || employeeInformation === void 0 ? void 0 : employeeInformation.nonWorkedHoursAllowed);
        if (nonWorkedHoursAllowedLevelID === 0) {
            return false;
        }
        else if (nonWorkedHoursAllowedLevelID === 1) {
            return true;
        }
        else if (nonWorkedHoursAllowedLevelID === 2 && nonWorkedHoursAllowed) {
            return true;
        }
        return false;
    };
    var updateValuesOnSelection = function (formRenderProps) {
        updateInitialValues(__assign(__assign({}, initialValues), { udf1: formRenderProps.valueGetter("udf1"), udf2: formRenderProps.valueGetter("udf2"), udf3: formRenderProps.valueGetter("udf3"), timeIn: formRenderProps.valueGetter("fTimeIn"), timeOut: formRenderProps.valueGetter("fTimeOut"), meals: formRenderProps.valueGetter("meals"), additionalHours: formRenderProps.valueGetter("additionalHours"), payCode: formRenderProps.valueGetter("payCode"), nonWorkedHours: formRenderProps.valueGetter("nonWorkedHours"), guaranteeHours: formRenderProps.valueGetter("guaranteeHours"), dayWorked: formRenderProps.valueGetter("dayWorked"), accountID: formRenderProps.valueGetter("accountID"), subAccountId: formRenderProps.valueGetter("subAccountId"), workLocation: formRenderProps.valueGetter("workLocation"), position: formRenderProps.valueGetter("position"), ddlSupervisor: formRenderProps.valueGetter("ddlSupervisor"), addSickLeave: formRenderProps.valueGetter("addSickLeave"), availableHours: formRenderProps.valueGetter("availableHours"), hoursType: formRenderProps.valueGetter("hoursType") }));
    };
    var CustomCalendarWrapper = function (props) {
        return (_jsx(CustomCalendar, __assign({ enabledDays: [6], enabledDateRanges: isEndeavorClient ? agreementDateRange : null }, props)));
    };
    var DatePickerField = function (props) {
        return _jsx(FormDatePicker, __assign({}, props, { calendar: CustomCalendarWrapper }));
    };
    return (_jsxs(Dialog, __assign({ title: timeSheetDayDetails.timeSheetDayDetailID != null ? _jsx(Transform, { mkey: TranslationConstant.EDIT_MISSING_TIME })
            : _jsx(Transform, { mkey: TranslationConstant.ADD_MISSING_TIME }), className: "common-dialog-box success-dialog hide-action-btns add-time-dialog" }, { children: [_jsx("div", __assign({ className: "flexible-wrapper-500" }, { children: addNewLocation ? (_jsx(AddNewLocation, { addNewLocation: addNewLocation, setAddNewLocation: setAddNewLocation, accountId: accountId, userProfile: userProfile, EmployeeId: EmpID })) : (_jsx(Form, { onSubmitClick: handleAddTimeSubmit, initialValues: initialValues, render: function (formRenderProps) { return (_jsx(FormElement, __assign({ className: "general-form border-0 m-0" }, { children: _jsxs("fieldset", __assign({ className: "k-form-fieldset row p-0" }, { children: [_jsx("div", __assign({ className: "col-12 col-sm-6 mb-3" }, { children: _jsx(Field, { id: "employee", label: _jsx(Transform, { mkey: TranslationConstant.SELECT_EMPLOYEE }), name: "employee", isRequired: true, component: FormDropDownList, defaultItem: {
                                            employeeName: "Select...",
                                            employeeID: 0,
                                        }, data: employeeList, disabled: timeSheetDayDetails.timeSheetDayDetailID != null ? true : false, textField: "employeeName", valueField: "employeeID", validator: requiredValidator, onChange: function (e) {
                                            setEmpID(e.value.employeeID);
                                        } }) })), _jsx("div", __assign({ className: "col-12 col-sm-6 mb-3" }, { children: _jsx(Field, { id: "weekEndingDate", label: _jsx(Transform, { mkey: TranslationConstant.LABEL_WEEK_END_DATE }), isRequired: true, validator: requiredValidator, readonlyInput: true, name: "weekEndingDate", disabled: timeSheetDayDetails.timeSheetDayDetailID != null ? true : false, component: DatePickerField, onChange: function (e) {
                                            weekEndingDate = e.value;
                                            setSelectedWeekendDate(e.value);
                                            setWeekDays(agreementDateRange);
                                        } }) })), _jsx("div", __assign({ className: "col-12 col-sm-6 mb-3" }, { children: _jsx(Field, { id: "dayWorked", name: "dayWorked", textField: "label", valueField: "value", label: _jsx(Transform, { mkey: TranslationConstant.DAY_WORKED }), isRequired: true, data: timesheetDateData, disabled: !selectedWeekendDate, validator: requiredValidator, defaultItem: {
                                            label: (_jsx(Transform, { mkey: TranslationConstant.LABEL_DEFAULT_OPTION_SELECT })),
                                            value: null,
                                        }, component: FormDropDownListWithValue, onChange: function (e) {
                                            resetAccountSubAccounts();
                                            updateAccountID(null);
                                            setSubAccountValue(null);
                                            formRenderProps.onChange("accountID", {
                                                value: null,
                                            });
                                            formRenderProps.onChange("subAccountId", {
                                                value: null,
                                            });
                                            workDaySelecetion(e);
                                            updateValuesOnSelection(formRenderProps);
                                        } }) })), !(selectedClient === null || selectedClient === void 0 ? void 0 : selectedClient.imgSetup) && (_jsx("div", __assign({ className: "col-12 col-sm-6 mb-3" }, { children: _jsx(Field, { id: "projectCode", label: (selectedClient === null || selectedClient === void 0 ? void 0 : selectedClient.accountAlias) ? (selectedClient.accountAlias) : (_jsx(Transform, { mkey: TranslationConstant.ACCOUNT_DEFAULT_LABEL })), textField: "text", valueField: "value", isRequired: true, data: timesheetAccountsList, validator: requiredValidator, name: "accountID", component: FormDropDownListWithValue, defaultItem: {
                                            text: (_jsx(Transform, { mkey: TranslationConstant.LABEL_DEFAULT_OPTION_SELECT })),
                                            value: null,
                                        }, isFilterableDropdown: true, disabled: disableDropdown, onOpen: function () { return fetchDropdownData("account"); }, onChange: function (e) {
                                            updateAccountID(e.value);
                                            formRenderProps.onChange("workLocation", {
                                                value: null,
                                            });
                                            formRenderProps.onChange("subAccount", {
                                                value: null,
                                            });
                                            updateValuesOnSelection(formRenderProps);
                                        } }) }))), (selectedClient === null || selectedClient === void 0 ? void 0 : selectedClient.subAccountUsed) && (_jsx("div", __assign({ className: "col-12 col-sm-6 mb-3" }, { children: _jsx(Field, { id: "subAccountId", label: (selectedClient === null || selectedClient === void 0 ? void 0 : selectedClient.subAccountAlias) ? (selectedClient.subAccountAlias) : (_jsx(Transform, { mkey: TranslationConstant.SUB_ACCOUNT_DEFAULT_LABEL })), textField: "text", valueField: "value", isFilterableDropdown: true, isRequired: true, data: timesheetSubAccountsList, validator: requiredValidator, name: "subAccountId", component: FormDropDownListWithValue, defaultItem: {
                                            text: (_jsx(Transform, { mkey: TranslationConstant.LABEL_DEFAULT_OPTION_SELECT })),
                                            value: null,
                                        }, disabled: disableDropdown, onOpen: function () { return fetchDropdownData("subAccount"); }, onChange: function (e) {
                                            setSubAccountValue(e.value);
                                            formRenderProps.onChange("workLocation", {
                                                value: null,
                                            });
                                            updateValuesOnSelection(formRenderProps);
                                        } }) }))), _jsx("div", __assign({ className: "col-12 col-sm-6 mb-3" }, { children: _jsx(Field, { id: "workLocation", textField: "text", valueField: "value", label: _jsx(Transform, { mkey: TranslationConstant.WORK_LOCATION }), isRequired: true, data: workLocationsForEmployeeUsingAccountID, validator: requiredValidator, name: "workLocation", component: FormDropDownListWithValue, defaultItem: {
                                            text: (_jsx(Transform, { mkey: TranslationConstant.LABEL_DEFAULT_OPTION_SELECT })),
                                            value: null,
                                        }, disabled: disableDropdown, onOpen: function () { return fetchDropdownData("location"); }, onChange: function (e) {
                                            workLocationHandler(e);
                                            updateValuesOnSelection(formRenderProps);
                                        } }) })), _jsx("div", __assign({ className: "col-12 col-sm-6 mb-3" }, { children: _jsx(Field, { id: "position", textField: "text", valueField: "value", label: _jsx(Transform, { mkey: TranslationConstant.LABEL_POSITION }), isRequired: true, data: employeePositions, validator: requiredValidator, name: "position", component: FormDropDownListWithValue, defaultItem: {
                                            text: (_jsx(Transform, { mkey: TranslationConstant.LABEL_DEFAULT_OPTION_SELECT })),
                                            value: null,
                                        }, disabled: disableDropdown, onOpen: function () { return fetchDropdownData("position"); }, onChange: function (e) {
                                            updateValuesOnSelection(formRenderProps);
                                        } }) })), _jsx("div", __assign({ className: "col-12 col-md-6 mb-3" }, { children: _jsx(FieldWIthMultipleValidator, { id: "timeIn", label: _jsx(Transform, { mkey: TranslationConstant.TIME_IN }), isRequired: true, validatorstatefield: isValidTimeIn, validator: [timeValidator, startTimeValidator], name: "fTimeIn", mask: "00:00", placeholder: ComponentConstant.TIME_IN_OUT_PLACEHOLDER, component: FormMaskedTextBox, onChange: function (e) {
                                            return updateTimeInOut(__assign(__assign({}, timeInOut), { timeIn: e.value }));
                                        }, blurHandler: function () {
                                            return updateInitialValues(__assign(__assign({}, initialValues), { fTimeIn: timeInOut.timeIn }));
                                        } }) })), _jsx("div", __assign({ className: "col-12 col-md-6 mb-3" }, { children: _jsx(Field, { id: "timeOut", name: "fTimeOut", isRequired: timeSheetDayDataState === null || timeSheetDayDataState === void 0 ? void 0 : timeSheetDayDataState.timeOut, label: _jsx(Transform, { mkey: TranslationConstant.TIME_OUT }), component: FormMaskedTextBox, validator: (timeSheetDayDataState === null || timeSheetDayDataState === void 0 ? void 0 : timeSheetDayDataState.timeOut) ? timeValidator : noValidationMsg, mask: "00:00", placeholder: ComponentConstant.TIME_IN_OUT_PLACEHOLDER, onChange: function (e) {
                                            return updateTimeInOut(__assign(__assign({}, timeInOut), { timeOut: e.value }));
                                        }, blurHandler: function () {
                                            return updateInitialValues(__assign(__assign({}, initialValues), { fTimeOut: timeInOut.timeOut }));
                                        } }) })), (_jsx("div", __assign({ className: "col-12 col-md-6 mb-3" }, { children: _jsx(Field, { id: "ddlSupervisor", name: "ddlSupervisor", textField: "text", valueField: "value", label: _jsx(Transform, { mkey: TranslationConstant.LABEL_SUPERVISOR_ADD_TIME }), isRequired: true, data: clientSupervisors, validator: requiredValidator, defaultItem: {
                                            text: (_jsx(Transform, { mkey: TranslationConstant.LABEL_DEFAULT_OPTION_SELECT })),
                                            value: null,
                                        }, component: FormDropDownListWithValue, onOpen: function () {
                                            return fetchDropdownData("clientSupervisors");
                                        }, onChange: function (e) {
                                            updateValuesOnSelection(formRenderProps);
                                        } }) }))), _jsx("div", __assign({ className: "k-form-buttons mt-4 pb-0 pe-0" }, { children: _jsx(FormButtons, { wrapperClass: "pe-0 pb-1", hideResetButton: true, customSubmitText: timeSheetDayDetails.timeSheetDayDetailID !== null
                                            ? TranslationConstant.SAVE_TIME
                                            : TranslationConstant.ADD_TIME, closeText: _jsx(Transform, { mkey: TranslationConstant.CLOSE_BUTTON }), showCancelButton: true, cancelHandler: handleClose }) }))] })) }))); } }, timeSheetDayDetails.timeSheetDayDetailID !== null
                    ? JSON.stringify(initialValues)
                    : null)) })), modalState && (_jsx(DialogBox, __assign({}, modalContent, { isDialogOpen: modalState, onModalChangeState: function (e) { return modalCloseHandler(e); } })))] })));
}
