var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React, { useEffect, useState } from "react";
import { Transform } from "helper/Transform";
import { TranslationConstant } from "common/constant";
import { Signature } from "@progress/kendo-react-inputs";
import "assets/css/signature-panel.scss";
export var SignaturePanel = function (props) {
    var _a = useState(false), openSignaturePad = _a[0], handleOpenSignaturePad = _a[1];
    var _b = React.useState(), value = _b[0], setValue = _b[1];
    var handleSignaturePanel = function () {
        handleOpenSignaturePad(!openSignaturePad);
    };
    var onChange = function (e) {
        setValue(e.value);
        props.handleCallback(e.value);
    };
    var onClear = function () {
        setValue(undefined);
    };
    useEffect(function () {
        var _a;
        if (((_a = props === null || props === void 0 ? void 0 : props.isSignatureExist) === null || _a === void 0 ? void 0 : _a.sign) === "") {
            setValue(undefined);
            handleOpenSignaturePad(false);
        }
    }, [props]);
    return (_jsx(_Fragment, { children: _jsxs("form", __assign({ noValidate: true, className: "signPadForm" }, { children: [props.signatureText, _jsx("input", { type: "button", className: "btn btn-info", value: "Click here to sign with mouse or finger.", onClick: handleSignaturePanel }), openSignaturePad && (_jsxs("div", __assign({ className: "signature-panel-wrapper" }, { children: [_jsxs("div", __assign({ className: "alert alert-warning" }, { children: [_jsx("p", __assign({ className: "mb-0" }, { children: _jsx(Transform, { mkey: TranslationConstant.USE_FINGER_OR_MOUSE_TEXT }) })), _jsxs("p", __assign({ className: "mb-0" }, { children: [_jsx("strong", { children: "It is not required" }), " to draw your signature.", " "] })), _jsxs("p", __assign({ className: "mb-0" }, { children: [_jsx("strong", { children: "If left blank," }), " a typed representaion of your signature will be saved."] }))] })), _jsxs("ul", __assign({ className: "sigNav" }, { children: [_jsx("li", __assign({ className: "drawIt" }, { children: _jsx(Transform, { mkey: TranslationConstant.DRAW_YOUR_SIGNATURE }) })), _jsx("li", __assign({ className: "clearButton", onClick: onClear }, { children: _jsx(Transform, { mkey: TranslationConstant.CLEAR_TEXT }) }))] })), _jsx(Signature, { id: props.id || "signaturepanel", value: value, onChange: onChange, smooth: true, maximizable: false, hideLine: true, style: { width: 500, height: 200 }, className: "sigCanvas", strokeWidth: 2 })] })))] })) }));
};
