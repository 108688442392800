var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useEffect } from "react";
import { ActionCell, GridColumn, KendoGrid, Transform, TranslationConstant, useSearchEmpViewContext, } from "../index";
export var ExpenseCodesGrid = function (_a) {
    var SessionData = _a.SessionData, UpdateEditFormData = _a.UpdateEditFormData, CallGridRefresher = _a.CallGridRefresher, UpdateGridRefresherCall = _a.UpdateGridRefresherCall;
    var _b = useSearchEmpViewContext(), fetchExpenseCodeGridData = _b.fetchExpenseCodeGridData, expenseCodeGridData = _b.expenseCodeGridData;
    var EditForm = function (data) {
        UpdateEditFormData(data);
        window.scrollTo({
            top: 0,
            behavior: "smooth",
        });
    };
    var initAPICall = function () {
        fetchExpenseCodeGridData(SessionData.ClientId);
    };
    useEffect(function () {
        if (SessionData.ClientId && !CallGridRefresher) {
            initAPICall();
        }
    }, [SessionData.ClientId]);
    useEffect(function () {
        if (CallGridRefresher) {
            refreshGridDataHandler();
            UpdateGridRefresherCall(false);
        }
    }, [CallGridRefresher]);
    var refreshGridDataHandler = function () {
        initAPICall();
    };
    return (_jsx(_Fragment, { children: _jsxs(KendoGrid, __assign({ data: expenseCodeGridData, refreshMethod: refreshGridDataHandler, customFilterTitles: { isActive: "Status" } }, { children: [_jsx(GridColumn, { field: "", title: "Edit", width: "80px", cell: function (cell) { return (_jsx(ActionCell, { cellprops: cell, icon: "fa fa-edit fa-lg", title: _jsx(Transform, { mkey: TranslationConstant.EDIT }), onClick: function () { return EditForm(cell.dataItem); } })); }, filterable: false }), _jsx(GridColumn, { field: "code", title: "Code", width: "120px" }), _jsx(GridColumn, { field: "description", title: "Description", width: "150px" }), _jsx(GridColumn, { field: "glAccountNumber", title: "GL Account Number", width: "120px" }), _jsx(GridColumn, { field: "miscItemCode", title: "Misc Item Code", width: "100px" }), _jsx(GridColumn, { field: "taxable", title: "Taxable", width: "100px" }), _jsx(GridColumn, { field: "receiptsNeeded", title: "Receipts Needed", width: "100px" }), _jsx(GridColumn, { field: "isActive", title: "Status", width: "100px" })] })) }));
};
