var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useEffect, useState } from "react";
import { Form, Field, FormElement } from "@progress/kendo-react-form";
import { FormButtons, FormInput, requiredValidator, useSecurityContext } from "./index";
export var SecurityManageFeaturesEditForm = function (props) {
    var handleCancel = props.handleCancel, handleFeatureSave = props.handleFeatureSave, featureData = props.featureData, onFailurePatchUpdate = props.onFailurePatchUpdate;
    var patchUpdateFeature = useSecurityContext().patchUpdateFeature;
    var _a = useState(featureData), featureItemData = _a[0], updateFeatureItem = _a[1];
    useEffect(function () {
        updateFeatureItem(featureData);
    }, [featureData]);
    var handleSubmit = function (dataItem) { return __awaiter(void 0, void 0, void 0, function () {
        var dataToPatch, response, featureItemData_1, e_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (!dataItem.isValid) return [3 /*break*/, 4];
                    dataToPatch = {
                        FeatureId: dataItem.values.id,
                        FeatureName: dataItem.values.title,
                        FeatureDesc: dataItem.values.FeatureDesc || "",
                    };
                    response = void 0;
                    _a.label = 1;
                case 1:
                    _a.trys.push([1, 3, , 4]);
                    return [4 /*yield*/, patchUpdateFeature(dataToPatch)];
                case 2:
                    response = _a.sent();
                    if (response.data) {
                        featureItemData_1 = featureData;
                        featureItemData_1.title = dataItem.values.title;
                        featureItemData_1.FeatureDesc = dataItem.values.FeatureDesc;
                        featureItemData_1.expanded = false;
                        featureItemData_1.edit = false;
                        handleFeatureSave(featureItemData_1);
                    }
                    else {
                        props.onFailurePatchUpdate();
                    }
                    return [3 /*break*/, 4];
                case 3:
                    e_1 = _a.sent();
                    props.onFailurePatchUpdate();
                    return [3 /*break*/, 4];
                case 4: return [2 /*return*/];
            }
        });
    }); };
    var handleCancelBtn = function () {
        handleCancel(featureData);
    };
    var handleCloseBtn = function () {
        var featureItemData = featureData;
        featureItemData.expanded = false;
        featureItemData.edit = false;
        handleCancel(featureData);
    };
    return (_jsx(_Fragment, { children: _jsx(Form, { onSubmitClick: handleSubmit, initialValues: featureItemData, render: function () { return (_jsxs(FormElement, __assign({ className: "general-form" }, { children: [_jsxs("fieldset", __assign({ className: "k-form-fieldset row" }, { children: [_jsx("div", __assign({ className: "col-xs-12 col-md-6 mb-3" }, { children: _jsx(Field, { id: "featureName", name: "title", isRequired: true, validator: requiredValidator, component: FormInput, maxLength: 50, label: "Feature Name", placeholder: "Feature Name", isFocusableElement: true }) })), _jsx("div", __assign({ className: "col-xs-12 col-md-6 mb-3" }, { children: _jsx(Field, { id: "description", name: "FeatureDesc", maxLength: 50, label: "Description", component: FormInput, placeholder: "Description" }) }))] })), _jsx("div", __assign({ className: "mf-close-icon", onClick: handleCloseBtn }, { children: _jsx("i", { className: "fa fa-close" }) })), _jsx(FormButtons, { resetid: "cancel", resetHandler: handleCancelBtn })] }))); } }, JSON.stringify(featureItemData)) }));
};
