import { httpPromise } from "services/common/httpPromise";
import { ServiceConstant } from "common/constant";
export var getAdminDashboardWidget = function (webUserRole, indexNumber) {
    return httpPromise.get("".concat(ServiceConstant.ADMIN_DASHBOARD_WIDGET_PATH, "/").concat(webUserRole, "/").concat(indexNumber));
};
export var getEmployeeTaskGrid = function (newHireJobID) {
    var isAdminLoggedInAsEmployee = localStorage.getItem("isAdminLoggedInAsEmployee")
        ? true
        : false;
    return httpPromise.get("".concat(ServiceConstant.EMPLOYEE_TASK_GRID_API_PATH, "=").concat(newHireJobID, "&isAdminLogged=").concat(isAdminLoggedInAsEmployee));
};
export var declineNewHireDoc = function (newHireDocumentID) {
    return httpPromise.patch("".concat(ServiceConstant.DECLINE_NEW_HIRE_DOCUMENT_API_PATH, "?DocumentID=").concat(newHireDocumentID));
};
export var getPaymentType = function (jobId) {
    return httpPromise.get("".concat(ServiceConstant.PAYMENT_TYPES_BY_JOB_ID_API_PATH, "?jobId=").concat(jobId));
};
export var getEmployeeDetails = function (webUserId) {
    return httpPromise.get("".concat(ServiceConstant.EMPLOYEE_DETAILS_API, "=").concat(webUserId));
};
export var updateEmployeeProfileData = function (webUserId, empDataObj) {
    return httpPromise.put("".concat(ServiceConstant.UPDATE_EMPLOYEE_DETAILS_API, "=").concat(webUserId), empDataObj);
};
export var getDirectDepositAuthInfo = function (id) {
    return httpPromise.get("".concat(ServiceConstant.DIRECT_DEPOSIT_AUTH_API, "?newHireDocumentid=").concat(id));
};
export var uploadDirectDepositFile = function (groupData, id) {
    return httpPromise.post("".concat(ServiceConstant.DIRECT_DEPOSIT_FILE_UPLOAD_API, "?newHireDocumentID=").concat(id), groupData);
};
export var signandsaveDirectDeposit = function (groupData) {
    return httpPromise.post("".concat(ServiceConstant.DIRECT_DEPOSIT_SIGN_SAVE_API), groupData);
};
export var getEmployerPosterDocument = function (employeeStateId) {
    return httpPromise.get("".concat(ServiceConstant.GET_EMPLOYER_POSTER_DOCUMENT_DATA, "?stateID=").concat(employeeStateId));
};
export var UpdatePaymentTypeForDocuments = function (newHireJobID, paymentTypeValue) {
    return httpPromise.put("".concat(ServiceConstant.UPDATE_PAYMENT_TYPES_DOCUMENTS_API_PATH, "?newHireJobID=").concat(newHireJobID, "&paymentTypeValue=").concat(paymentTypeValue));
};
export var checkPaymentTypeDropdown = function (newHireJobID) {
    return httpPromise.get("".concat(ServiceConstant.ADD_NEW_JOB_API_PATH, "/").concat(newHireJobID));
};
export var getCompletedNewHireDocuments = function (newHireJobID) {
    return httpPromise.get("".concat(ServiceConstant.COMPLETED_NEW_HIRE_DOCS_API_PATH, "=").concat(newHireJobID));
};
export var updateCompletedDocument = function (applicationID, docDataObj) {
    return httpPromise.post("".concat(ServiceConstant.UPDATED_NEW_HIRE_DOCUMENT_API, "=").concat(applicationID), docDataObj, { responseType: "blob" });
};
export var getClientFormToComplete = function () {
    return httpPromise.get(ServiceConstant.GET_CLIENT_FORMS_TO_COMPLETE_API);
};
export var getEmployeeIncompletePaperwork = function (clientID, indexNumber) {
    return httpPromise.get("".concat(ServiceConstant.GET_EMPLOYEE_WITH_INCOMPLETE_PAPERWORK_API, "?clientID=").concat(clientID, "&indexNumber=").concat(indexNumber));
};
export var getIncompleteEmployeeAgreement = function (clientUserID) {
    return httpPromise.get(ServiceConstant.GET_INCOMPLETE_EMPLOYEE_AGREEMENT_API + clientUserID);
};
export var newHireDocForExternalAgent = function () {
    return httpPromise.get(ServiceConstant.NEW_HIRE_DOCUMENT_EXTERNAL_AGENT);
};
export var getTempAgreementInfo = function (employmentAgreementID) {
    return httpPromise.get("".concat(ServiceConstant.GET_TEMP_AGREEMENT_INFO_API, "?employmentAgreementID=").concat(employmentAgreementID));
};
export var getResendEmployeeEmail = function (newHireJobID) {
    return httpPromise.get("".concat(ServiceConstant.GET_RESEND_EMPLOYEE_EMAIL_DETAILS_API, "?newHireJobID=").concat(newHireJobID));
};
export var getEmployeeInformation = function (encryptedDocID) {
    return httpPromise.get("".concat(ServiceConstant.EMPLOYEE_INFORMATION_API, "?encryptedDocID=").concat(encryptedDocID));
};
export var saveEmployeeInfoTemporary = function (encryptedDocID, employeeData) {
    return httpPromise.patch("".concat(ServiceConstant.SAVE_EMPLOYEE_INFO_TEMP, "?encryptedDocID=").concat(encryptedDocID), employeeData);
};
export var generateEmployeePdf = function (encryptedDocID) {
    return httpPromise.get("".concat(ServiceConstant.GENERATE_EMPLOYEE_DATA_PDF, "?encryptedDocID=").concat(encryptedDocID));
};
export var uploadVisaDoc = function (formData) {
    return httpPromise.post("".concat(ServiceConstant.UPLOAD_VISA_DOCUMENT), formData);
};
export var signAndSubmitEmployeeInfo = function (encryptedDocID, fileUploadResponse) {
    return httpPromise.post("".concat(ServiceConstant.SIGN_SUBMIT_EMPLOYEE_INFO, "?encryptedDocID=").concat(encryptedDocID), fileUploadResponse);
};
export var ShowEmployeeCompleteDocument = function (newHireJobID) {
    return httpPromise.get("".concat(ServiceConstant.SHOW_EMPLOYEE_DOCUMENTS_COMPLETED, "?newHireJobID=").concat(newHireJobID));
};
export var uploadNewYorkMinorTrustFile = function (groupData, id) {
    return httpPromise.post("".concat(ServiceConstant.NewYork_MINOR_TRUST_FILE_UPLOAD, "?newHireDocumentID=").concat(id), groupData);
};
export var signandsaveNewYorkMinorTrust = function (groupData) {
    return httpPromise.post("".concat(ServiceConstant.NewYork_MINOR_TRUST_SIGN_SAVE), groupData);
};
export var uploadNewYorkWorkPermit = function (groupData, id) {
    return httpPromise.post("".concat(ServiceConstant.NEW_YORK_WORK_PERMIT_FILE_UPLOAD, "?newHireDocumentID=").concat(id), groupData);
};
export var uploadCaliforniaMinorTrustFile = function (groupData, id) {
    return httpPromise.post("".concat(ServiceConstant.CALIFORNIA_MINOR_TRUST_FILE_UPLOAD, "?newHireDocumentID=").concat(id), groupData);
};
export var signandsaveCaliforniaMinorTrust = function (groupData) {
    return httpPromise.post("".concat(ServiceConstant.CALIFORNIA_MINOR_TRUST_SIGN_SAVE), groupData);
};
export var getEnablePaymentDropDown = function (newHireJobID) {
    return httpPromise.get("".concat(ServiceConstant.PAYMENT_DROPDOWN_ENABLE_CHECK_API).concat(newHireJobID));
};
export var uploadCaliforniaWorkPermit = function (groupData, id) {
    return httpPromise.post("".concat(ServiceConstant.CALIFORNIA_WORK_PERMIT_FILE_UPLOAD, "?newHireDocumentID=").concat(id), groupData);
};
export var GetEmployeeDocPdf = function (newHireJobID) {
    return httpPromise.get("".concat(ServiceConstant.EMPLOYEE_DOC_PDF_API).concat(newHireJobID));
};
export var signandsaveGeorgiaForm = function (groupData) {
    return httpPromise.post("".concat(ServiceConstant.GEORGIA_FORM_POST_API), groupData);
};
export var getGeorgiaFile = function (id) {
    return httpPromise.get("".concat(ServiceConstant.GEORGIA_FORM_POST_API, "?newHireDocumentID=").concat(id));
};
export var getNyEmpNoticeExFile = function (id) {
    return httpPromise.get("".concat(ServiceConstant.NY_EMP_NOTICE_EMPT_API, "?newHireDocumentID=").concat(id));
};
export var signandsaveNYExeEmpNoticeForm = function (groupData) {
    return httpPromise.post("".concat(ServiceConstant.NY_EMP_NOTICE_EMPT_API), groupData);
};
export var getNYNoticeDayRateEmpFile = function (id) {
    return httpPromise.get("".concat(ServiceConstant.NY_DAY_RATE_EMP_API, "?newHireDocumentID=").concat(id));
};
export var postNYNoticeDayRateEmpFile = function (groupData) {
    return httpPromise.post("".concat(ServiceConstant.NY_DAY_RATE_EMP_API), groupData);
};
export var getCANoticeToEmpFile = function (id) {
    return httpPromise.get("".concat(ServiceConstant.CA_NOTICE_TO_EMP_API, "?newHireDocumentID=").concat(id));
};
export var signandsaveCaliforniaNoticeToEmpForm = function (groupData) {
    return httpPromise.post("".concat(ServiceConstant.CA_NOTICE_TO_EMP_API), groupData);
};
export var getContentTVReleaseFile = function (id) {
    return httpPromise.get("".concat(ServiceConstant.CONTENT_TV_REL_API, "?newHireDocumentID=").concat(id));
};
export var signandsaveContentTVReleaseForm = function (groupData) {
    return httpPromise.post("".concat(ServiceConstant.CONTENT_TV_REL_API), groupData);
};
export var signandsaveSexualHarassmentCertForm = function (groupData) {
    return httpPromise.post("".concat(ServiceConstant.SEXUAL_HARASSMENT_CERT_API), groupData);
};
export var uploadSexualHarassmentCertificate = function (groupData) {
    return httpPromise.post("".concat(ServiceConstant.SEXUAL_HARASSMENT_CERT_API, "/upload"), groupData);
};
export var getNYHourlyRateNoticeFile = function (id) {
    return httpPromise.get("".concat(ServiceConstant.NY_EMP_NOTICE_HOURLY_RATE, "?newHireDocumentID=").concat(id));
};
export var signandsaveNYHourlyRateNoticeForm = function (groupData) {
    return httpPromise.post("".concat(ServiceConstant.NY_EMP_NOTICE_HOURLY_RATE), groupData);
};
export var getVerifyFirstBgFile = function (id) {
    return httpPromise.get("".concat(ServiceConstant.VERIFY_FIRST_BG_CHECK_API, "?newHireDocumentID=").concat(id));
};
export var signandsaveVerifyFirstBgForm = function (groupData) {
    return httpPromise.post("".concat(ServiceConstant.VERIFY_FIRST_BG_FORM_POST_API), groupData);
};
export var getNYEmpNoticeSalary = function (id) {
    return httpPromise.get("".concat(ServiceConstant.NY_EMP_TO_NOTICE_SALARY_API, "?newHireDocumentID=").concat(id));
};
export var postNYEmpNoticeSalary = function (groupData) {
    return httpPromise.post("".concat(ServiceConstant.NY_EMP_TO_NOTICE_SALARY_API), groupData);
};
export var getMinnesotaWTFile = function (id) {
    return httpPromise.get("".concat(ServiceConstant.MINNESOTA_WAGE_THEFT_GET_POST_API, "?newHireDocumentID=").concat(id));
};
export var signandsaveMinnesotaForm = function (groupData) {
    return httpPromise.post("".concat(ServiceConstant.MINNESOTA_WAGE_THEFT_GET_POST_API), groupData);
};
export var getEndHrlyNnExEmpAgr = function (id) {
    return httpPromise.get("".concat(ServiceConstant.END_HOURLY_NON_EX_EMP_AGR_CLIENT_GET_POST_API, "?newHireDocumentID=").concat(id));
};
export var postEndHrlyNnExEmpAgr = function (groupData) {
    return httpPromise.post("".concat(ServiceConstant.END_HOURLY_NON_EX_EMP_AGR_CLIENT_GET_POST_API), groupData);
};
export var getEndOnlocExEmpAgrPdfDoc = function (id) {
    return httpPromise.get("".concat(ServiceConstant.END_ONLOC_EX_EMP_AGR_API, "?newHireDocumentID=").concat(id));
};
export var postEndOnlocExEmpAgrForm = function (groupData) {
    return httpPromise.post("".concat(ServiceConstant.END_ONLOC_EX_EMP_AGR_API), groupData);
};
export var getEndExmptEmpdetails = function (id) {
    return httpPromise.get("".concat(ServiceConstant.END_EXEMPT_EMPL_AGR_API, "?newHireDocumentID=").concat(id));
};
export var postEndExmptEmpdetails = function (groupData) {
    return httpPromise.post("".concat(ServiceConstant.END_EXEMPT_EMPL_AGR_API), groupData);
};
export var getEndLocNonExmptEmpAgrFile = function (id) {
    return httpPromise.get("".concat(ServiceConstant.END_LOC_NON_EXM_AGR_API, "?newHireDocumentID=").concat(id));
};
export var postEndLocNonExmptEmpAgr = function (groupData) {
    return httpPromise.post("".concat(ServiceConstant.END_LOC_NON_EXM_AGR_API), groupData);
};
export var getEndNonExDlEmpAgrPdf = function (id) {
    return httpPromise.get("".concat(ServiceConstant.END_NON_EX_DL_EMP_AGR_API, "?newHireDocumentID=").concat(id));
};
export var postEndNonExDlEmpAgrForm = function (groupData) {
    return httpPromise.post("".concat(ServiceConstant.END_NON_EX_DL_EMP_AGR_API), groupData);
};
export var getMississippiW4PdfDoc = function (id) {
    return httpPromise.get("".concat(ServiceConstant.MISSISSIPPI_WFOUR_API, "?newHireDocumentID=").concat(id));
};
export var postMississippiW4Details = function (groupData) {
    return httpPromise.post("".concat(ServiceConstant.MISSISSIPPI_WFOUR_API), groupData);
};
export var uploadMississippiExemptFile = function (groupData, id) {
    return httpPromise.post("".concat(ServiceConstant.MISSISSIPPI_WFOUR_API, "/upload"), groupData);
};
