import { httpPromise } from "services/common/httpPromise";
import { ServiceConstant } from "common/constant";
export var getWebUsersList = function (queryString) {
    return httpPromise.get("".concat(ServiceConstant.WEB_USERS_LIST_API_PATH, "?").concat(queryString));
};
export var getWebUsersDetailsbyID = function (paramsObj) {
    return httpPromise.get(ServiceConstant.GET_WEB_USER_DETAILS_BY_ID_API, { params: paramsObj });
};
export var updateWebUserDetails = function (groupdata) {
    return httpPromise.put(ServiceConstant.UPDATE_WEB_USER_DETAILS_API, groupdata);
};
export var postEmailToWebUser = function (EmailObj) {
    return httpPromise.post(ServiceConstant.SEND_EMAIL_TEMPLATE_API_PATH, EmailObj);
};
export var createWebUser = function (paramsObj) {
    return httpPromise.post(ServiceConstant.CREATE_WEB_USER_API + '?createWebUserToken=' + paramsObj.createWebUserToken, paramsObj);
};
export var onBoardingHRDocuments = function (applicationId, token) {
    return httpPromise.get("".concat(ServiceConstant.ONBOARDING_HR_DOCUMENTS_API, "?applicationID=").concat(applicationId, "&token=").concat(token));
};
