var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import { Grid } from "components/shared";
import { getSelectedState, } from "@progress/kendo-react-grid";
import { getter } from "@progress/kendo-data-query";
import { useState, useCallback, useEffect } from "react";
import { useBatchesImportContext } from "store/batchimport/batchimport";
var DATA_ITEM_KEY = "creditCardItemID";
var SELECTED_FIELD = "selected";
var idGetter = getter(DATA_ITEM_KEY);
export default function CreditCardsGrid(_a) {
    var _b = _a.colums, colums = _b === void 0 ? [] : _b, _c = _a.gridData, gridData = _c === void 0 ? [] : _c, handleRefresh = _a.handleRefresh, _d = _a.multiSelect, multiSelect = _d === void 0 ? false : _d, _e = _a.tabCode, tabCode = _e === void 0 ? "" : _e;
    var _f = useState(gridData), GridData = _f[0], setGridData = _f[1];
    var _g = useBatchesImportContext(), selectedExportsData = _g.selectedExportsData, setSelectedExportsData = _g.setSelectedExportsData;
    var _h = useState(selectedExportsData["".concat(tabCode)] || {}), selectedRecords = _h[0], setSelectedRecords = _h[1];
    useEffect(function () {
        setGridData(gridData);
    }, [gridData]);
    var updateSelectedRows = function (values) {
        var selectedRows = __assign({}, selectedExportsData);
        selectedRows["".concat(tabCode)] = values;
        setSelectedExportsData(selectedRows);
    };
    var getColumns = function () {
        return multiSelect
            ? [
                {
                    title: "",
                    field: SELECTED_FIELD,
                    width: "40px",
                    filterable: false,
                    headerSelectionValue: GridData.findIndex(function (item) { return !selectedRecords[idGetter(item)]; }) === -1,
                },
            ]
            : [];
    };
    var Columns = __spreadArray(__spreadArray(__spreadArray([], getColumns(), true), colums, true), [
        {
            field: "manualPaymentType",
            title: "Type",
            width: "100px",
        },
        {
            field: "refID",
            title: "Ref ID",
            width: "100px",
        },
        {
            field: "lastName",
            title: "Last Name",
            width: "120px",
        },
        {
            field: "firstName",
            title: "First Name",
            width: "120px",
        },
        {
            field: "amount",
            title: "Trans Amount",
            width: "120px",
            format: "{0:c}",
        },
        {
            field: "invoicedAmount",
            title: "Invoiced Amount",
            width: "120px",
            format: "{0:c}",
        },
    ], false);
    var onSelectionChange = useCallback(function (event) {
        var newSelectedState = getSelectedState({
            event: event,
            selectedState: selectedRecords,
            dataItemKey: DATA_ITEM_KEY,
        });
        setSelectedRecords(newSelectedState);
        updateSelectedRows(newSelectedState);
    }, [selectedRecords]);
    var onHeaderSelectionChange = useCallback(function (event) {
        var checkboxElement = event.syntheticEvent.target;
        var checked = checkboxElement.checked;
        var newSelectedState = {};
        event.dataItems.forEach(function (item) {
            newSelectedState[idGetter(item)] = checked;
        });
        setSelectedRecords(newSelectedState);
        updateSelectedRows(newSelectedState);
    }, []);
    return (_jsx(_Fragment, { children: multiSelect ? (_jsx(Grid, { columns: Columns, gridData: GridData.map(function (item) {
                var _a;
                return (__assign(__assign({}, item), (_a = {}, _a[SELECTED_FIELD] = selectedRecords[idGetter(item)], _a)));
            }), dataItemKey: DATA_ITEM_KEY, selectedField: SELECTED_FIELD, selectable: {
                enabled: true,
                drag: false,
                cell: false,
                mode: "multiple",
            }, onSelectionChange: onSelectionChange, onHeaderSelectionChange: onHeaderSelectionChange, handleRefresh: handleRefresh })) : (_jsx(Grid, { handleRefresh: handleRefresh, columns: Columns, gridData: GridData })) }));
}
