var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import Grid from "components/shared/Grid";
import { ActionCell, DateFilterCell, maskedPhoneNumberCell, } from "components/shared/GridCommonCells";
import { localizedMessages, setDateToMidnight } from "components/shared/utils";
import useProjectsStore from "store/projects/projectsStore";
import useUpdateDialogContent from "hooks/useUpdateDialogContent";
import { DialogBox, Transform, TranslationConstant } from "components/shared";
import { DAY_LENGTH_OPTIONS, PROJECT_WORKER_CUSTOM_FIELDS } from "../constants";
import { WEBUSERROLEID } from "common/constant/Enums";
// Set a consistent column width for reuse in the grid
var colMinWidth = "80px";
// Main WorkersGrid component definition
export var WorkersGrid = function (_a) {
    var editWorkerData = _a.editWorkerData, handleRefreshGrid = _a.handleRefreshGrid, loggedInDetails = _a.loggedInDetails;
    // State to handle dialog visibility and content
    var _b = useUpdateDialogContent(), setDialogContent = _b.setDialogContent, showDialog = _b.showDialog, setShowDialog = _b.setShowDialog, dialogContent = _b.dialogContent;
    // State management for project worker actions
    var _c = useProjectsStore(), removeProjectWorker = _c.removeProjectWorker, projectWorkers = _c.projectWorkers, reviewProjectWorker = _c.reviewProjectWorker;
    // Check if logged in user is Payreel admin
    var isPayreelAdmin = (loggedInDetails === null || loggedInDetails === void 0 ? void 0 : loggedInDetails.WebUserRoleId) === WEBUSERROLEID.PAYREEL_ADMIN;
    // format grid data when project workers change
    var gridData = (projectWorkers === null || projectWorkers === void 0 ? void 0 : projectWorkers.map(function (item) { return (__assign(__assign({}, item), { startDate: setDateToMidnight(item.startDate), isNeedReview: (item === null || item === void 0 ? void 0 : item.isNeedReview)
            ? localizedMessages.NEEDS_REVIEW
            : localizedMessages.REVIEWED })); })) || [];
    // Function to handle the removal confirmation popup
    var removeConfirmationPopup = function (_a) {
        var dataItem = _a.dataItem;
        return __awaiter(void 0, void 0, void 0, function () {
            return __generator(this, function (_b) {
                setDialogContent({
                    headingText: TranslationConstant.CONFIRM,
                    displayMsg: TranslationConstant.REMOVE_WORKER_CONFIRMATION,
                    acceptTextKey: TranslationConstant.REMOVE,
                    acceptHandler: function () { return __awaiter(void 0, void 0, void 0, function () {
                        return __generator(this, function (_a) {
                            switch (_a.label) {
                                case 0: return [4 /*yield*/, removeProjectWorker(dataItem.projectWorkerId)];
                                case 1:
                                    _a.sent(); // Remove worker on confirmation
                                    handleRefreshGrid(); // Refresh the grid
                                    setShowDialog(false);
                                    return [2 /*return*/];
                            }
                        });
                    }); },
                });
                setShowDialog(true);
                return [2 /*return*/];
            });
        });
    };
    // Function to map and display custom cell data
    var MapCellWithData = function (dataItem, cellName) {
        var _a;
        if (cellName === "dayLengthId") {
            var dayLengthName = (_a = DAY_LENGTH_OPTIONS.find(function (item) { return item.value === dataItem.dayLengthId; })) === null || _a === void 0 ? void 0 : _a.text;
            return _jsx("td", { children: dayLengthName });
        }
        return _jsx("td", { children: dataItem[cellName] ? localizedMessages.YES : localizedMessages.NO });
    };
    // Handle marking the worker as needing review
    var handleNeedsReview = function (_a) {
        var dataItem = _a.dataItem;
        return __awaiter(void 0, void 0, void 0, function () {
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0: return [4 /*yield*/, reviewProjectWorker(dataItem.projectWorkerId)];
                    case 1:
                        _b.sent();
                        handleRefreshGrid();
                        return [2 /*return*/];
                }
            });
        });
    };
    // Define the grid columns
    var columnsData = __spreadArray(__spreadArray([
        {
            field: "",
            title: localizedMessages.EDIT,
            width: colMinWidth,
            filterable: false,
            cell: function (cell) { return (_jsx(ActionCell, { cellprops: cell, title: localizedMessages.EDIT, icon: "fa fa-edit fa-lg m-0", onClick: function () { return editWorkerData(cell.dataItem); } })); },
        },
        {
            field: "",
            title: localizedMessages.REMOVE,
            width: colMinWidth,
            filterable: false,
            cell: function (cell) { return (_jsx(ActionCell, { cellprops: cell, title: localizedMessages.REMOVE, icon: "", onClick: removeConfirmationPopup })); },
        }
    ], (isPayreelAdmin
        ? [
            {
                field: "isNeedReview",
                title: localizedMessages.NEEDS_REVIEW,
                width: "120px",
                cell: function (cell) {
                    return cell.dataItem.isNeedReview === localizedMessages.REVIEWED ? (_jsx("td", { children: localizedMessages.REVIEWED })) : (_jsx(ActionCell, { cellprops: cell, title: localizedMessages.NEEDS_REVIEW, icon: "", onClick: handleNeedsReview }));
                },
            },
        ]
        : []), true), [
        {
            field: "projectWorkerStatusName",
            title: localizedMessages.LABEL_STATUS,
            width: "180px",
        },
        {
            field: "firstName",
            title: localizedMessages.LABEL_FIRST_NAME,
            width: "120px",
        },
        {
            field: "lastName",
            title: localizedMessages.LABEL_LAST_NAME,
            width: "120px",
        },
        {
            field: "email",
            title: localizedMessages.LABEL_EMAIL,
            width: "150px",
        },
        {
            field: "phoneNumber",
            title: localizedMessages.LABEL_PHONE_NUMBER,
            width: "120px",
            cell: maskedPhoneNumberCell,
        },
        {
            field: "classification",
            title: localizedMessages.LABEL_CLASSIFICATION,
            width: "120px",
        },
        {
            field: "drivingRequired",
            title: localizedMessages.DRIVING_REQUIRED,
            width: "150px",
            cell: function (cell) { return MapCellWithData(cell.dataItem, "drivingRequired"); },
        },
        {
            field: "minor",
            title: localizedMessages.MINOR,
            width: "120px",
            cell: function (cell) { return MapCellWithData(cell.dataItem, "minor"); },
        },
        {
            field: "minorParentGuardianName",
            title: localizedMessages.MINOR_PARENT_GUARDIAN_NAME,
            width: "180px",
        },
        {
            field: "clientSupervisor1Name",
            title: localizedMessages.LABEL_SUPERVISOR_1,
            width: "180px",
        },
        {
            field: "clientSupervisor2Name",
            title: localizedMessages.LABEL_SUPERVISOR_2,
            width: "180px",
        },
        {
            field: "positionName",
            title: localizedMessages.LABEL_POSITION,
            width: "120px",
        },
        {
            field: "companyName",
            title: localizedMessages.LABEL_COMPANY_NAME,
            width: "180px",
        },
        {
            field: "rateTypeName",
            title: localizedMessages.LABEL_EMPLOYEE_RATE_TYPE,
            width: "120px",
        },
        {
            field: "rate",
            title: localizedMessages.LABEL_EMPLOYEE_RATE,
            format: "{0:0.0000}",
            width: "120px",
        },
        {
            field: "dayLengthId",
            title: localizedMessages.LABEL_DAILY_LENGTH,
            width: "120px",
            cell: function (cell) { return MapCellWithData(cell.dataItem, "dayLengthId"); },
        },
        {
            field: "startDate",
            title: localizedMessages.LABEL_START_DATE,
            width: "100px",
            filter: "date",
            format: "{0:MM/dd/yy}",
            filterCell: DateFilterCell,
        },
    ], false);
    // Add dynamic custom fields to the columns
    PROJECT_WORKER_CUSTOM_FIELDS.forEach(function (customField, idx) {
        columnsData.push({
            field: customField.customFieldName,
            title: customField.displayLabel,
            width: "120px",
            filterable: false,
            cell: function (cell) {
                var _a, _b, _c;
                return (_jsx("td", { children: (_c = (_b = (_a = cell.dataItem) === null || _a === void 0 ? void 0 : _a.projectWorkerCustomFields) === null || _b === void 0 ? void 0 : _b[idx]) === null || _c === void 0 ? void 0 : _c.customFieldValue }));
            },
        });
    });
    // Add notes column
    columnsData.push({
        field: "notes",
        title: localizedMessages.LABEL_NOTES,
        width: "150px",
    });
    // Render the grid and dialog components
    return (_jsxs(_Fragment, { children: [_jsx(Grid, { columns: columnsData, gridData: gridData, customFilterTitles: {
                    projectStatusName: localizedMessages.LABEL_STATUS,
                    dayLengthId: localizedMessages.LABEL_DAILY_LENGTH,
                    rateTypeName: localizedMessages.LABEL_EMPLOYEE_RATE_TYPE,
                    positionName: localizedMessages.LABEL_POSITION,
                    clientSupervisor2Name: localizedMessages.SECONDARY_SUPERVISOR,
                    clientSupervisor1Name: localizedMessages.APPROVING_SUPERVISOR,
                    minorParentGuardianName: localizedMessages.MINOR_PARENT_GUARDIAN_NAME,
                    minor: localizedMessages.MINOR,
                    drivingRequired: localizedMessages.DRIVING_REQUIRED,
                    classification: localizedMessages.LABEL_CLASSIFICATION,
                    email: localizedMessages.LABEL_EMAIL,
                }, handleRefresh: handleRefreshGrid }), _jsx(DialogBox, __assign({}, dialogContent, { isDialogOpen: showDialog, onModalChangeState: function () { return setShowDialog(false); }, cancelText: _jsx(Transform, { mkey: TranslationConstant.CLOSE_BUTTON }) }))] }));
};
