import { httpPromise } from "services/common/httpPromise";
import { ServiceConstant } from "common/constant";
export var getClientUserList = function (groupData) {
    return httpPromise.get("".concat(ServiceConstant.GET_CLIENT_USER_API_PATH, "/SearchClientUsers?").concat(groupData));
};
export var getClientSupervisorManagerList = function (clientId) {
    return httpPromise.get("".concat(ServiceConstant.GET_CLIENT_USER_API_PATH, "/GetManagerSupervisorClientUsers?clientId=").concat(clientId));
};
export var getClientWorkersList = function (queryString) {
    return httpPromise.get("".concat(ServiceConstant.CLIENT_WORKERS_API, "/GetWorkersList?").concat(queryString));
};
export var postSelectedSnM = function (groupData) {
    return httpPromise.put("".concat(ServiceConstant.CLIENT_WORKERS_API, "/UpdateSupervisorManager?"), groupData);
};
export var exportWorkerData = function (status, clientID) {
    if (clientID) {
        return httpPromise.get("".concat(ServiceConstant.CLIENT_WORKERS_API, "/ExportWorkerData?status=").concat(status, "&clientID=").concat(clientID));
    }
    return httpPromise.get("".concat(ServiceConstant.CLIENT_WORKERS_API, "/ExportWorkerData?status=").concat(status));
};
export var getClientUserData = function (clientUserId) {
    return httpPromise.get("".concat(ServiceConstant.GET_CLIENT_USER_API_PATH, "/GetEditClientUserInfo?clientUserID=").concat(clientUserId));
};
export var getClientUserWebSettingData = function (clientUserId) {
    return httpPromise.get("".concat(ServiceConstant.GET_CLIENT_USER_API_PATH, "/GetEditClientUserWebSettings?clientUserID=").concat(clientUserId));
};
export var saveClientUserData = function (groupdata) {
    return httpPromise.post("".concat(ServiceConstant.GET_CLIENT_USER_API_PATH, "/PostClientUserInfoAsync"), groupdata);
};
export var saveClientUserWebSettingsData = function (groupdata) {
    return httpPromise.post("".concat(ServiceConstant.GET_CLIENT_USER_API_PATH, "/PostClientUserWebSettingsAsync"), groupdata);
};
export var getRevalidationWorkerList = function (queryStr) {
    return httpPromise.get("".concat(ServiceConstant.CLIENT_WORKERS_API, "/GetRevalidationWorkersList?").concat(queryStr));
};
export var getSearchClients = function (queryString) {
    return httpPromise.get("".concat(ServiceConstant.GET_CLIENT_USER_API_PATH, "/SearchClients?").concat(queryString));
};
export var editClient = function (data) {
    return httpPromise.put("".concat(ServiceConstant.CORP_CLIENT_BASE_API_PATH, "/EditClient"), data);
};
export var getRevalidationWorkersListExport = function (clientID, status, reValidatedYear) {
    return httpPromise.get("".concat(ServiceConstant.CLIENT_WORKERS_API, "/GetRevalidationWorkersListExport?clientId=").concat(clientID, "&status=").concat(status, "&reValidatedYear=").concat(reValidatedYear));
};
export var getDownloadWorkerData = function (ClientId) {
    var endpoint = "".concat(ServiceConstant.CLIENT_WORKERS_API, "/DownloadWorkerData");
    if (ClientId) {
        endpoint += "?clientID=".concat(ClientId);
    }
    return httpPromise.get(endpoint);
};
