var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { create } from "zustand";
import { getAllRolesRequest, getRoleFeaturesRequest, getSecurityRoleFeature, updateFeatureRequest, updateRoleFeaturesRequest, getDistinctFeature, getRoleFeatureMappings, getRoleFeatureMapping, saveRoleFeatures, createFeatureMappings, } from "services/security";
import { WEBUSERROLEID } from "common/constant/Enums";
export var useSecurityContext = create(function (set, get) { return ({
    getAllRolesList: [],
    getRoleFeaturesList: [],
    getSecurityRoleFeatureList: [],
    roleListForSearchWebUser: [],
    distinctFeaturesList: [],
    roleFeatureMappingList: [],
    featureRoleList: [],
    fetchAllRolesList: function () { return __awaiter(void 0, void 0, void 0, function () {
        var response, listOfAllowedRoles, roleListFiltered;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, getAllRolesRequest()];
                case 1:
                    response = _a.sent();
                    listOfAllowedRoles = [
                        WEBUSERROLEID.NEW_HIRE_EMPLOYEE,
                        WEBUSERROLEID.NEW_HIRE_WORKER,
                        WEBUSERROLEID.WORKER_1099,
                    ];
                    roleListFiltered = response.data.filter(function (item) {
                        return listOfAllowedRoles.includes(item.WebUserRoleId);
                    });
                    set(function (state) { return (__assign(__assign({}, state), { getAllRolesList: response.data, roleListForSearchWebUser: roleListFiltered })); });
                    return [2 /*return*/];
            }
        });
    }); },
    fetchRoleFeaturesList: function (applicationType) { return __awaiter(void 0, void 0, void 0, function () {
        var response;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, getRoleFeaturesRequest(applicationType)];
                case 1:
                    response = _a.sent();
                    set(function (state) { return (__assign(__assign({}, state), { getRoleFeaturesList: response.data })); });
                    return [2 /*return*/];
            }
        });
    }); },
    patchUpdateFeature: function (featureToPatch) { return __awaiter(void 0, void 0, void 0, function () {
        var response;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, updateFeatureRequest(featureToPatch)];
                case 1:
                    response = _a.sent();
                    return [2 /*return*/, response];
            }
        });
    }); },
    fetchSecurityRoleFeatureList: function (roleId, clientId) { return __awaiter(void 0, void 0, void 0, function () {
        var response;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, getSecurityRoleFeature(roleId, clientId)];
                case 1:
                    response = _a.sent();
                    set(function (state) { return (__assign(__assign({}, state), { getSecurityRoleFeatureList: response.data })); });
                    return [2 /*return*/];
            }
        });
    }); },
    updateRoleFeatures: function (featuresToUpdate) { return __awaiter(void 0, void 0, void 0, function () {
        var response;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, updateRoleFeaturesRequest(featuresToUpdate)];
                case 1:
                    response = _a.sent();
                    return [2 /*return*/, response];
            }
        });
    }); },
    resetDropDownValuesList: function () {
        set(function (state) { return (__assign(__assign({}, state), { getAllRolesList: [], getRoleFeaturesList: [], getSecurityRoleFeatureList: [], distinctFeaturesList: [], roleFeatureMappingList: [], featureRoleList: [] })); });
    },
    fetchDistinctFeature: function (ApplicationId, RoleId) { return __awaiter(void 0, void 0, void 0, function () {
        var response;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, getDistinctFeature(ApplicationId, RoleId)];
                case 1:
                    response = _a.sent();
                    set(function (state) { return (__assign(__assign({}, state), { distinctFeaturesList: response.data })); });
                    return [2 /*return*/, response];
            }
        });
    }); },
    fetchFeatureRoles: function (RoleId, ClientId, clientUserTypeId, IsSecSetup) { return __awaiter(void 0, void 0, void 0, function () {
        var response;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, getRoleFeatureMappings(RoleId, ClientId, clientUserTypeId, IsSecSetup)];
                case 1:
                    response = _a.sent();
                    set(function (state) { return (__assign(__assign({}, state), { featureRoleList: response.data })); });
                    return [2 /*return*/, response];
            }
        });
    }); },
    fetchRoleFeatureMapping: function (roleId, applicationId, clientId) { return __awaiter(void 0, void 0, void 0, function () {
        var response;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, getRoleFeatureMapping(roleId, applicationId, clientId)];
                case 1:
                    response = _a.sent();
                    set(function (state) { return (__assign(__assign({}, state), { roleFeatureMappingList: response.data })); });
                    return [2 /*return*/, response];
            }
        });
    }); },
    resetRoleFeatureMapping: function () { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            set(function (state) { return (__assign(__assign({}, state), { roleFeatureMappingList: [] })); });
            return [2 /*return*/];
        });
    }); },
    saveRoleFeatures: function (dataObj) { return __awaiter(void 0, void 0, void 0, function () { return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, saveRoleFeatures(dataObj)];
            case 1: return [2 /*return*/, _a.sent()];
        }
    }); }); },
    createFeatureMappings: function (clientId) { return __awaiter(void 0, void 0, void 0, function () {
        var response;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, createFeatureMappings(clientId)];
                case 1:
                    response = _a.sent();
                    return [2 /*return*/, response];
            }
        });
    }); },
}); });
