var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React, { useEffect, useState } from "react";
import { Grid, GridColumn as Column } from "@progress/kendo-react-grid";
import { DialogBox, SecurityManageFeaturesEditForm, Transform, TranslationConstant, useSecurityContext, } from "./index";
export var ManageFeaturesGrid = function (props) {
    var bussinessChannelName = props.bussinessChannelName, selectedRole = props.selectedRole;
    var _a = useSecurityContext(), distinctFeaturesList = _a.distinctFeaturesList, fetchDistinctFeature = _a.fetchDistinctFeature, resetDropDownValuesList = _a.resetDropDownValuesList;
    var _b = React.useState(distinctFeaturesList), bussinessChanneldata = _b[0], setBussinessChannelData = _b[1];
    var _c = useState(false), modalState = _c[0], updateModalState = _c[1];
    var _d = useState({
        headingText: "",
        bodyText: "",
        isErrorDialog: false,
    }), modalContent = _d[0], updateModalContent = _d[1];
    useEffect(function () {
        if (bussinessChannelName && selectedRole) {
            fetchDistinctFeature(bussinessChannelName, selectedRole);
            setBussinessChannelData(distinctFeaturesList);
        }
        else {
            resetDropDownValuesList();
            setBussinessChannelData([]);
        }
    }, [bussinessChannelName, selectedRole]);
    useEffect(function () {
        setBussinessChannelData(distinctFeaturesList);
    }, [distinctFeaturesList]);
    var onSuccessPatchUpdate = function () {
        updateModalContent({
            headingText: _jsx(Transform, { mkey: TranslationConstant.FEATURE_MAPPING_SUCCESS_HEADING }),
            bodyText: (_jsxs("div", __assign({ className: "block subheading" }, { children: [_jsx("i", { className: "fa fa-check fa-lg" }), _jsx("span", { children: _jsx(Transform, { mkey: TranslationConstant.MANAGE_FEATURE_UPDATE_SUCCESS_MESSAGE }) })] }))),
            isErrorDialog: false,
        });
        updateModalState(true);
    };
    var onFailurePatchUpdate = function () {
        updateModalContent({
            headingText: _jsx(Transform, { mkey: TranslationConstant.FEATURE_MAPPING_FAILURE_HEADING }),
            bodyText: (_jsxs("div", __assign({ className: "block subheading" }, { children: [_jsx("i", { className: "fa fa-times-circle fa-lg" }), _jsx("span", { children: _jsx(Transform, { mkey: TranslationConstant.MANAGE_FEATURE_UPDATE_FAILURE_MESSAGE }) })] }))),
            isErrorDialog: true,
        });
        updateModalState(true);
    };
    var hideModal = function () {
        updateModalState(false);
    };
    var createField = function (fieldProps) {
        switch (fieldProps.columnIndex) {
            case 0:
                return _jsx("i", { className: "fa fa-pencil", onClick: function () { return editFeature(fieldProps); } });
            case 1:
                return _jsx("i", { className: "fa fa-pencil", onClick: function () { return editFeature(fieldProps); } });
        }
    };
    var ManageFeatureCell = function (props) {
        return (_jsx("td", __assign({ colSpan: props.colSpan, role: "gridcell", "aria-colindex": props.ariaColumnIndex, "aria-selected": props.isSelected }, { children: createField(props) })));
    };
    var ManageFeatureCustomCell = function (props) {
        return _jsx(ManageFeatureCell, __assign({}, props));
    };
    var handleCancel = function (dataItem) {
        setBussinessChannelData(__spreadArray(__spreadArray([], dataItem, true), bussinessChanneldata, true));
    };
    var handleFeatureSave = function (dataItem) {
        setBussinessChannelData(__spreadArray(__spreadArray([], dataItem, true), bussinessChanneldata, true));
        onSuccessPatchUpdate();
    };
    var expandChange = function (dataItem) {
        if (dataItem.edit === true && dataItem.expanded === true) {
            dataItem.expanded = !dataItem.expanded;
        }
        dataItem.expanded = !dataItem.expanded;
        dataItem.edit = false;
        setBussinessChannelData(__spreadArray(__spreadArray([], bussinessChanneldata, true), dataItem, true));
    };
    var editFeature = function (fieldProps) {
        fieldProps.dataItem.expanded = true;
        fieldProps.dataItem.edit = true;
        setBussinessChannelData(__spreadArray([], bussinessChanneldata, true));
    };
    var ChildDetailComponent = function (props) {
        var dataItem = props.dataItem;
        return (_jsx("section", __assign({ className: "manage-feature-form-level-2", "aria-level": 2 }, { children: _jsx(SecurityManageFeaturesEditForm, { featureData: dataItem, handleCancel: handleCancel, handleFeatureSave: handleFeatureSave, onFailurePatchUpdate: onFailurePatchUpdate }) })));
    };
    var DetailComponent = function (props) {
        var dataItem = props.dataItem;
        var childItems = props.dataItem.children;
        return dataItem.edit ? (_jsx("section", __assign({ className: "manage-feature-form-level-1", "aria-level": 1 }, { children: _jsx(SecurityManageFeaturesEditForm, { featureData: dataItem, handleCancel: handleCancel, handleFeatureSave: handleFeatureSave, onFailurePatchUpdate: onFailurePatchUpdate }) }))) : (_jsx(_Fragment, { children: childItems && childItems !== undefined && childItems.length > 0 && (_jsx("div", __assign({ className: "general-grid security-grid", "aria-level": 2 }, { children: _jsxs(Grid, __assign({ data: childItems && childItems, expandField: "expanded", detail: ChildDetailComponent }, { children: [_jsx(Column, { field: "", title: "", cell: ManageFeatureCustomCell, width: "25px" }), _jsx(Column, { field: "title", title: "Feature Name", width: "300px" }), _jsx(Column, { field: "FeatureDesc", title: "Description", width: "500px" })] })) }))) }));
    };
    var HierarchyDetailColumnCell = function (props) {
        if (props.dataItem.children == undefined || props.dataItem.children.length === 0) {
            return _jsx("td", { className: "k-hierarchy-cell empty-cell", width: "25px" });
        }
        return (_jsx("td", __assign({ className: "k-hierarchy-cell", width: "25px" }, { children: props.dataItem.expanded && !props.dataItem.edit ? (_jsx("a", { onClick: function () { return props.expandChange(props.dataItem); }, className: "k-icon k-minus", href: "#" })) : (_jsx("a", { onClick: function () { return props.expandChange(props.dataItem); }, className: "k-icon k-plus", href: "#" })) })));
    };
    var HierarchyCell = function (props) {
        return _jsx(HierarchyDetailColumnCell, __assign({}, props, { expandChange: expandChange }));
    };
    var formWidthCalculate = function () {
        var gridHeaderElement = document.getElementsByClassName("k-grid-header")[0] || null;
        var generalFormElement = document.querySelectorAll(".general-form") || null;
        if (gridHeaderElement && generalFormElement.length) {
            var gridHeaderWidth_1 = gridHeaderElement.clientWidth;
            generalFormElement.forEach(function (element) {
                var gutterMargin = parseInt(element.parentElement.attributes["aria-level"].value) === 2 ? 60 : 30;
                element.style.maxWidth = "".concat(gridHeaderWidth_1 - gutterMargin, "px");
            });
        }
    };
    useEffect(function () {
        formWidthCalculate();
        var resizeListener = function () {
            formWidthCalculate();
        };
        window.addEventListener("resize", resizeListener);
        return function () {
            window.removeEventListener("resize", resizeListener);
        };
    });
    return (_jsxs(_Fragment, { children: [_jsx("div", __assign({ className: "general-grid security-grid feature-grid" }, { children: _jsxs(Grid, __assign({ data: bussinessChanneldata, detail: DetailComponent, expandField: "expanded" }, { children: [_jsx(Column, { field: "", cell: HierarchyCell, width: "25px" }), _jsx(Column, { field: "", cell: ManageFeatureCustomCell, width: "25px" }), _jsx(Column, { field: "title", title: "Feature Name", width: "300px" }), _jsx(Column, { field: "FeatureDesc", title: "Description", width: "500px" })] })) })), modalState && (_jsx(DialogBox, { headingText: modalContent.headingText, bodyText: modalContent.bodyText, cancelText: _jsx(Transform, { mkey: TranslationConstant.CLOSE_BUTTON }), isErrorDialog: modalContent.isErrorDialog, isDialogOpen: modalState, onModalChangeState: hideModal }))] }));
};
