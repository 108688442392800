var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useEffect, useMemo, useState } from "react";
import { Card, CardHeader, CardTitle, CardBody } from "@progress/kendo-react-layout";
import { Button, DropDownButton } from "@progress/kendo-react-buttons";
import { Input } from "@progress/kendo-react-inputs";
import { Hint } from "@progress/kendo-react-labels";
import { sortAscIcon, sortAscSmallIcon, sortDescSmallIcon, moreVerticalIcon, } from "@progress/kendo-svg-icons";
import { SvgIcon } from "@progress/kendo-react-common";
import { formatRenderData, dynamicFieldSearch, dynamicSorting, isEmptyOrNull, } from "components/shared/utils";
import { Transform, TranslationConstant } from "components/shared";
import { MobileGridColumnTypes } from "common/constant/Enums";
var renderValues = function (value) {
    return isEmptyOrNull(value) ? "--" : value;
};
var MobileGridCard = function (_a) {
    var _b;
    var data = _a.data, subGridData = _a.subGridData, subGridColumnData = _a.subGridColumnData, gridColumnData = _a.gridColumnData, actionCallBack = _a.actionCallBack, actionItems = _a.actionItems, _c = _a.expandable, expandable = _c === void 0 ? false : _c, _d = _a.isTabView, isTabView = _d === void 0 ? false : _d, handleExpandChange = _a.handleExpandChange, _e = _a.showPay, showPay = _e === void 0 ? false : _e;
    var _f = useState(null), expandedCard = _f[0], setExpandedCard = _f[1];
    var _g = useState(10), displayCount = _g[0], setDisplayCount = _g[1];
    var _h = useState([]), cardData = _h[0], setCardData = _h[1];
    var _j = useState(null), sortByField = _j[0], setSortByField = _j[1];
    var _k = useState(null), sortOrder = _k[0], setSortOrder = _k[1];
    var _l = useState([]), sortDropDownList = _l[0], setSortDropDownList = _l[1];
    var _m = useState(false), showHidePay = _m[0], setShowHidePay = _m[1];
    useEffect(function () {
        var sortableColumns = gridColumnData.filter(function (column) { return column.sortable; });
        setSortDropDownList(sortableColumns);
    }, [gridColumnData]);
    var formattedData = useMemo(function () {
        return data.map(function (item) {
            var formattedItem = __assign({}, item);
            gridColumnData.forEach(function (column) {
                formattedItem[column.field] = formatRenderData(formattedItem[column.field], column.type);
            });
            return formattedItem;
        });
    }, [data, gridColumnData]);
    var subGridFormatedData = useMemo(function () {
        return subGridData === null || subGridData === void 0 ? void 0 : subGridData.map(function (item) {
            var formattedItem = __assign({}, item);
            subGridColumnData === null || subGridColumnData === void 0 ? void 0 : subGridColumnData.forEach(function (column) {
                if (formattedItem[column.field]) {
                    formattedItem[column.field] = formatRenderData(formattedItem[column.field], column.type);
                }
            });
            return formattedItem;
        });
    }, [subGridData, subGridColumnData]);
    var handleSorting = function (_a) {
        var item = _a.item;
        var order = "asc";
        if ((sortByField === null || sortByField === void 0 ? void 0 : sortByField.field) === item.field && sortOrder === "desc") {
            setSortByField(null);
            setSortOrder(null);
            setCardData(__spreadArray([], formattedData, true));
        }
        else {
            if ((sortByField === null || sortByField === void 0 ? void 0 : sortByField.field) === item.field && sortOrder) {
                order = sortOrder === "asc" ? "desc" : "asc";
            }
            else {
                setSortByField(item);
            }
            setSortOrder(order);
            var sortedData = cardData.sort(function (a, b) {
                var type = item.type || "string";
                var field = item.field;
                var comparison = dynamicSorting(a[field], b[field], type);
                return order === "asc" ? comparison : -comparison;
            });
            setCardData(__spreadArray([], sortedData, true));
        }
    };
    useEffect(function () {
        setCardData(formattedData);
    }, [formattedData]);
    var handleExpand = function (id, dataItem) { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, handleExpandChange(dataItem)];
                case 1:
                    _a.sent();
                    if (expandedCard === id) {
                        setExpandedCard(null);
                        return [2 /*return*/];
                    }
                    setExpandedCard(id);
                    return [2 /*return*/];
            }
        });
    }); };
    var handleLoadMore = function () {
        setDisplayCount(displayCount + 10);
    };
    var renderTitle = function (dataObj) {
        var column = gridColumnData === null || gridColumnData === void 0 ? void 0 : gridColumnData.find(function (column) { return column.heading === MobileGridColumnTypes.H1; });
        if (column) {
            var dataItemValue = Object.prototype.hasOwnProperty.call(dataObj, column.field)
                ? dataObj[column.field]
                : null;
            return (_jsx("div", __assign({ className: "d-flex justify-content-between" }, { children: _jsx("span", { children: _jsx(CardTitle, { children: dataItemValue }) }) })));
        }
        return null;
    };
    var renderSubTitle = function (dataObj) {
        var column = gridColumnData === null || gridColumnData === void 0 ? void 0 : gridColumnData.find(function (column) { return column.heading === MobileGridColumnTypes.H2; });
        if (column) {
            if ((column === null || column === void 0 ? void 0 : column.type) === "link") {
                return renderLinkCell(column, dataObj);
            }
            var dataItemValue = Object.prototype.hasOwnProperty.call(dataObj, column.field)
                ? dataObj[column.field]
                : null;
            return (_jsxs("div", __assign({ className: "d-flex justify-content-between" }, { children: [_jsx(CardTitle, { children: column.title }), _jsx(CardTitle, { children: dataItemValue })] })));
        }
        return null;
    };
    var renderLinkCell = function (column, dataItem) {
        return (_jsxs("div", __assign({ className: "d-flex justify-content-between" }, { children: [_jsx("p", __assign({ className: "label" }, { children: column.title })), _jsxs("p", __assign({ className: "value link-cell" }, { children: [" ", column === null || column === void 0 ? void 0 : column.actionCell(dataItem)] }))] })));
    };
    var handleSearch = function (e) {
        if (e.target.value === "") {
            setCardData(formattedData);
            return;
        }
        var searchValue = e.target.value;
        var results = dynamicFieldSearch(formattedData, searchValue, gridColumnData);
        setCardData(results);
    };
    var renderSortLabel = function (item) {
        if (!item)
            return null;
        else if (sortByField && sortOrder && item.field === sortByField.field) {
            if (sortOrder === "asc") {
                return (_jsxs(_Fragment, { children: [_jsx(SvgIcon, { className: "sort-icon", icon: sortAscSmallIcon, size: "small" }), " ", item.title] }));
            }
            return (_jsxs(_Fragment, { children: [_jsx(SvgIcon, { className: "sort-icon", icon: sortDescSmallIcon, size: "small" }), " ", item.title] }));
        }
        return _jsx(_Fragment, { children: item.title });
    };
    return (_jsxs("div", __assign({ className: "mobile-grid-card-conatiner ".concat(isTabView ? "" : "pad-x-15 top-border-mobile"), "data-testid": "mobile-grid-card" }, { children: [_jsxs("div", __assign({ className: "d-flex gap-2 search-box-container" }, { children: [_jsxs("span", __assign({ style: { flex: 1 } }, { children: [_jsx(Input, { onChange: handleSearch, type: "search", placeholder: "Type to filter results" }), _jsx(Hint, __assign({ className: "hint-text" }, { children: "Filter by name, date, hours, etc." }))] })), _jsx("span", { children: _jsx(DropDownButton, { popupSettings: { popupAlign: { horizontal: "right", vertical: "top" } }, textField: "title", className: "sorting-button", svgIcon: sortAscIcon, itemRender: function (props) { return (_jsx(_Fragment, { children: renderSortLabel(props.item) })); }, onItemClick: function (e) { return handleSorting(e); }, items: sortDropDownList }) })] })), showPay && (_jsx("div", __assign({ className: "show-hide-pay px-1", onClick: function () { return setShowHidePay(!showHidePay); } }, { children: !showHidePay ? (_jsx(Transform, { mkey: TranslationConstant.SHOW_PAY_TEXT })) : (_jsx(Transform, { mkey: TranslationConstant.HIDE_PAY_TEXT })) }))), cardData.length > 0 ? ((_b = cardData.slice(0, displayCount)) === null || _b === void 0 ? void 0 : _b.map(function (dataItem, idx) {
                return (_jsxs(Card, __assign({ className: "my-2", "data-testid": "card-item" }, { children: [_jsxs(CardHeader, { children: [_jsxs("div", __assign({ className: "d-flex justify-content-between" }, { children: [_jsx("span", { children: renderTitle(dataItem) }), _jsx("span", { children: actionItems.length > 0 && (_jsx(DropDownButton, { popupSettings: {
                                                    popupAlign: {
                                                        horizontal: "right",
                                                        vertical: "top",
                                                    },
                                                }, textField: "text", svgIcon: moreVerticalIcon, className: "card-action-button", itemRender: function (props) { return _jsx(_Fragment, { children: "".concat(props.item.text) }); }, items: actionItems, onItemClick: function (e) {
                                                    return actionCallBack(e.item.action, dataItem);
                                                }, fillMode: "flat" })) })] })), renderSubTitle(dataItem)] }), _jsxs(CardBody, { children: [gridColumnData === null || gridColumnData === void 0 ? void 0 : gridColumnData.map(function (column) {
                                    if ((column === null || column === void 0 ? void 0 : column.heading) !== MobileGridColumnTypes.H1 &&
                                        (column === null || column === void 0 ? void 0 : column.heading) !== MobileGridColumnTypes.H2 &&
                                        (column === null || column === void 0 ? void 0 : column.type) !== MobileGridColumnTypes.ICON &&
                                        !(column === null || column === void 0 ? void 0 : column.isShowPayType) &&
                                        (column === null || column === void 0 ? void 0 : column.type) !== MobileGridColumnTypes.LINK) {
                                        return (_jsxs("div", __assign({ className: "d-flex justify-content-between" }, { children: [_jsx("p", __assign({ className: "label" }, { children: column.title })), _jsxs("p", __assign({ className: "value" }, { children: [" ", renderValues(dataItem["".concat(column === null || column === void 0 ? void 0 : column.field)])] }))] })));
                                    }
                                    else if ((column === null || column === void 0 ? void 0 : column.type) === MobileGridColumnTypes.ICON) {
                                        return (_jsxs("div", __assign({ className: "d-flex justify-content-between" }, { children: [_jsx("p", __assign({ className: "label" }, { children: column.title })), dataItem["".concat(column === null || column === void 0 ? void 0 : column.field)] ? (_jsxs("p", __assign({ className: "value" }, { children: [" ", _jsx("i", { className: "".concat(column.iconClass) })] }))) : ("--")] })));
                                    }
                                    else if ((column === null || column === void 0 ? void 0 : column.isShowPayType) && showHidePay) {
                                        return (_jsxs("div", __assign({ className: "d-flex justify-content-between" }, { children: [_jsx("p", __assign({ className: "label" }, { children: column.title })), _jsxs("p", __assign({ className: "value" }, { children: [" ", renderValues(dataItem["".concat(column === null || column === void 0 ? void 0 : column.field)])] }))] })));
                                    }
                                    else if ((column === null || column === void 0 ? void 0 : column.type) === MobileGridColumnTypes.LINK) {
                                        return renderLinkCell(column, dataItem);
                                    }
                                    return null;
                                }), expandedCard === idx &&
                                    (subGridFormatedData === null || subGridFormatedData === void 0 ? void 0 : subGridFormatedData.map(function (subDataItem) {
                                        return (_jsx("div", __assign({ className: "top-border-mobile-gray" }, { children: subGridColumnData === null || subGridColumnData === void 0 ? void 0 : subGridColumnData.map(function (column) {
                                                return (_jsxs("div", __assign({ className: "d-flex justify-content-between" }, { children: [_jsx("p", __assign({ className: "label" }, { children: column.title })), _jsxs("p", __assign({ className: "value" }, { children: [" ", renderValues(subDataItem["".concat(column === null || column === void 0 ? void 0 : column.field)])] }))] })));
                                            }) })));
                                    })), expandable && (_jsx("div", __assign({ className: "d-flex justify-content-end" }, { children: _jsx("span", __assign({ className: "view-more", "data-testid": "expandable", onClick: function () {
                                            return expandedCard === idx
                                                ? setExpandedCard(null)
                                                : handleExpand(idx, dataItem);
                                        } }, { children: _jsx("i", { className: "fa ".concat(expandedCard === idx
                                                ? "fa-chevron-circle-up"
                                                : "fa-chevron-circle-down"), "aria-hidden": "true" }) })) })))] })] }), idx));
            })) : (_jsx(Card, __assign({ style: { marginTop: "10px" } }, { children: _jsx(CardBody, { children: _jsx("p", __assign({ className: "value text-center py-4" }, { children: "No Data Found" })) }) }))), displayCount < (cardData === null || cardData === void 0 ? void 0 : cardData.length) && (_jsx("div", __assign({ className: "d-flex justify-content-center py-3" }, { children: _jsx(Button, __assign({ className: "load-more-card", onClick: handleLoadMore, "aria-label": "Load More" }, { children: "Load More" })) })))] }), "${showHidePay}"));
};
export default MobileGridCard;
