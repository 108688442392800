import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { Transform, TranslationConstant, BreadcrumbComponent, PageTitle, Changei9Verifierform, AppConstant, } from "./index";
export var Changei9Verifier = function () {
    var items = [
        {
            id: "home",
            text: "Home",
            iconClass: "k-i-home",
            route: AppConstant.ONBOARDING_APPLICATION_ADMIN_PATH,
        },
        {
            id: "changei9verifier",
            text: "i-9 verifier",
        },
    ];
    var data = {
        title: _jsx(Transform, { mkey: TranslationConstant.CHANGE_I9_VERIFIER_HEADING }),
    };
    return (_jsxs(_Fragment, { children: [_jsx(BreadcrumbComponent, { items: items }), _jsx(PageTitle, { data: data }), _jsx(Changei9Verifierform, {})] }));
};
