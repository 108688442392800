var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import * as React from "react";
import { Breadcrumb, } from "@progress/kendo-react-layout";
import { useNavigate } from "react-router-dom";
import { removeMenuSelection } from "./utils";
import { useCurrentViewContext } from "store/common/currentViewContext";
import { AppConstant } from "common/constant";
export var BreadcrumbComponent = function (props) {
    var _a = React.useState(props.items), data = _a[0], setData = _a[1];
    var Navigate = useNavigate();
    var defaultLandingRoute = useCurrentViewContext().defaultLandingRoute;
    var handleItemSelect = function (event) {
        var isCORPApplication = location.pathname.indexOf(AppConstant.CORP_BASE_PATHNAME) > -1;
        var itemIndex = data.findIndex(function (curValue) { return curValue.id === event.id; });
        var newData = data.slice(0, itemIndex + 1);
        setData(newData);
        if (data[itemIndex].route) {
            removeMenuSelection();
            var redirectionRoute = data[itemIndex].route;
            if (redirectionRoute === "/") {
                var redirectionVal = isCORPApplication
                    ? localStorage.getItem("defaultLandingPagePRO")
                    : localStorage.getItem("defaultLandingPage") || "";
                redirectionRoute = "/".concat(redirectionVal);
            }
            Navigate(redirectionRoute, { state: { userData: data[itemIndex].userdata } });
        }
    };
    var handleKeyDown = function (event) {
        if (event.nativeEvent.keyCode === 13) {
            var itemIndex = data.findIndex(function (curValue) { return curValue.id === event.id; });
            var newData = data.slice(0, itemIndex + 1);
            setData(newData);
        }
    };
    return (_jsx("div", __assign({ className: "d-none d-sm-block" }, { children: _jsx(Breadcrumb, { data: data, onItemSelect: handleItemSelect, onKeyDown: handleKeyDown }) })));
};
