import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import React from "react";
import { Transform, TranslationConstant, BreadcrumbComponent, PageTitle, SearchWebUserGrid, SearchWebUserForm, AppConstant, } from "./index";
export var SearchWebUser = function () {
    var _a = React.useState(null), userSearch = _a[0], updateUserSearch = _a[1];
    var items = [
        {
            id: "home",
            text: "Home",
            iconClass: "k-i-home",
            route: AppConstant.ONBOARDING_APPLICATION_ADMIN_PATH,
        },
        {
            id: "Search-Web-Users",
            text: "Search Web Users",
        },
    ];
    var UserSearchdata = function (data) {
        updateUserSearch(data);
    };
    var data = {
        title: _jsx(Transform, { mkey: TranslationConstant.SEARCH_WEB_USERS_TITLE }),
    };
    return (_jsxs(_Fragment, { children: [_jsx(BreadcrumbComponent, { items: items }), _jsx(PageTitle, { data: data }), _jsx(SearchWebUserForm, { UserSearchdata: UserSearchdata }), _jsx("div", { children: _jsx(SearchWebUserGrid, { userData: userSearch }) }, JSON.stringify(userSearch))] }));
};
