import { httpPromise } from "services/common/httpPromise";
import { ServiceConstant } from "common/constant";
export var getRoleFeaturesRequest = function (applicationType) {
    return httpPromise.get("".concat(ServiceConstant.GET_ROLES_FEATURE_API_PATH, "=").concat(applicationType));
};
export var getAllRolesRequest = function () {
    return httpPromise.get(ServiceConstant.GET_ROLES_API_PATH);
};
export var updateFeatureRequest = function (featureToPatch) {
    return httpPromise.patch(ServiceConstant.UPDATE_FEATURE_API_PATH, featureToPatch);
};
export var getSecurityRoleFeature = function (roleId, clientId) {
    return httpPromise.get("".concat(ServiceConstant.SECURITY_ROLES_FEATURE_API_PATH, "?RoleId=").concat(roleId, "&ClientId=").concat(clientId));
};
export var updateRoleFeaturesRequest = function (featuresToUpdate) {
    return httpPromise.post(ServiceConstant.UPDATE_ROLE_FEATURES_API_PATH, featuresToUpdate);
};
export var getDistinctFeature = function (ApplicationId, RoleId) {
    return httpPromise.get("".concat(ServiceConstant.SECURITY_BASE_API_PATH, "/GetDistinctFeatures?ApplicationId=").concat(ApplicationId, "&RoleId=").concat(RoleId));
};
export var getRoleFeatureMappings = function (RoleId, ClientId, clientUserTypeId, IsSecSetup) {
    return httpPromise.get("".concat(ServiceConstant.SECURITY_BASE_API_PATH, "/GetRoleFeatureMappings?RoleId=").concat(RoleId, "&ClientId=").concat(ClientId, "&clientUserTypeId=").concat(clientUserTypeId, "&IsSecSetup=").concat(IsSecSetup));
};
export var getRoleFeatureMapping = function (roleId, applicationId, clientId) {
    return httpPromise.get("".concat(ServiceConstant.CORP_SECURITY_BASE_API_PATH, "/GetRoleFeatureMappings?roleId=").concat(roleId, "&applicationId=").concat(applicationId, "&clientId=").concat(clientId));
};
export var saveRoleFeatures = function (dataObj) {
    return httpPromise.post("".concat(ServiceConstant.CORP_SECURITY_BASE_API_PATH, "/UpdateRoleFeatures"), dataObj);
};
export var createFeatureMappings = function (clientId) {
    return httpPromise.post("".concat(ServiceConstant.CORP_SECURITY_BASE_API_PATH, "/CreateFeatureMappings?clientId=").concat(clientId));
};
