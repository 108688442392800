import { httpPromise } from "services/common/httpPromise";
import { ServiceConstant } from "common/constant";
export var getManageAttributeGroupList = function () {
    return httpPromise.get(ServiceConstant.MANAGE_ATTRIBUTE_GROUP_API);
};
export var getAcroFieldMappingDataList = function (id) {
    return httpPromise.get("".concat(ServiceConstant.ACRO_FIELD_MAPPING_DATA_API, "?customFormID=").concat(id));
};
export var getDefaultSystemFieldAttribute = function (id) {
    return httpPromise.get("".concat(ServiceConstant.DEFAULT_SYSTEM_FIELD_ATTRIBUTE_API, "?customFormID=").concat(id));
};
export var getFormGroupAttributeById = function (formAttributeId) {
    return httpPromise.get("".concat(ServiceConstant.MANAGE_ATTRIBUTE_GROUP_API, "/").concat(formAttributeId));
};
export var postManageAttributeGroupList = function (groupdata) {
    return httpPromise.post(ServiceConstant.MANAGE_ATTRIBUTE_GROUP_API, groupdata);
};
export var deleteFormGroupAttributeById = function (id) {
    return httpPromise.delete("".concat(ServiceConstant.MANAGE_ATTRIBUTE_GROUP_API, "?id=").concat(id));
};
export var checkForUniqueAttrGroup = function (groupdata) {
    return httpPromise.get(ServiceConstant.CHECK_UNIQUE_ATTR_GROUP_API + groupdata);
};
export var editFormGroupAttributeById = function (groupdata) {
    return httpPromise.patch("".concat(ServiceConstant.MANAGE_ATTRIBUTE_GROUP_API, "/").concat(groupdata.formAttributeGroupId), groupdata);
};
export var getFormAttributeById = function (formAttributeId) {
    return httpPromise.get("".concat(ServiceConstant.MANAGE_ATTRIBUTE_ID_API, "/").concat(formAttributeId));
};
export var deleteFormAttributeById = function (id) {
    return httpPromise.delete("".concat(ServiceConstant.MANAGE_ATTRIBUTE_DLT_API, "/").concat(id));
};
export var deleteManageAttributeMapping = function (data) {
    return httpPromise.put(ServiceConstant.MANAGE_ATTRIBUTE_MAPPING_DLT_API, data);
};
export var checkForUniqueAttribute = function (groupdata) {
    return httpPromise.get("".concat(ServiceConstant.CHECK_UNIQUE_ATTR_API, "?attributeName=").concat(groupdata));
};
export var checkForValidFormat = function (groupdata) {
    return httpPromise.get("".concat(ServiceConstant.CHECK_VALID_FORMAT, "?attributeOptions=").concat(groupdata));
};
export var editFormAttributeById = function (groupdata) {
    return httpPromise.patch("".concat(ServiceConstant.MANAGE_ATTRIBUTE_PATCH_API, "/").concat(groupdata.attributeID), groupdata);
};
export var getManageAttributeList = function () {
    return httpPromise.get(ServiceConstant.MANAGE_ATTRIBUTE_API);
};
export var postManageAttributes = function (groupdata) {
    return httpPromise.post(ServiceConstant.MANAGE_ATTRIBUTES_API, groupdata);
};
export var postAddNewMapping = function (data) {
    return httpPromise.post(ServiceConstant.POST_NEW_MAPPING_API, data);
};
export var getGroupDropDownList = function () {
    return httpPromise.get(ServiceConstant.CLIENT_GROUP_DROPDOWN_LIST_PATH);
};
export var getAttributesDropDownList = function (groupdata) {
    return httpPromise.get("".concat(ServiceConstant.GET_ATT_DROPDOWN_LIST_PATH, "?attributeGroupID=").concat(groupdata.attributeGroupID, "&customFormID=").concat(groupdata.customFormID));
};
export var getDataTypeList = function () {
    return httpPromise.get(ServiceConstant.CLIENT_DATA_TYPE_LIST_PATH);
};
export var getAttributeTypeList = function () {
    return httpPromise.get(ServiceConstant.CLIENT_ATTRIBUTE_TYPE_LIST_PATH);
};
export var getManageAttributeMappingOnLoadData = function (customFormID) {
    return httpPromise.get("".concat(ServiceConstant.MANAGE_ATTRIBUTE_MAPPING_ON_LOAD, "?customFormID=").concat(customFormID));
};
export var getDocumentCategoryList = function () {
    return httpPromise.get(ServiceConstant.NEW_HIRE_DOCUMENT_CATEGORY_API_PATH);
};
export var getDocumentWorkflowList = function () {
    return httpPromise.get(ServiceConstant.NEW_HIRE_DOCUMENT_WORKFLOW_API_PATH);
};
export var getDocumentTypeList = function () {
    return httpPromise.get(ServiceConstant.DOCUMENT_TYPE);
};
export var getStateLists = function () {
    return httpPromise.get(ServiceConstant.STATES_API_PATH);
};
export var getMangeUploadClientLists = function () {
    return httpPromise.get(ServiceConstant.CUSTOM_FORM_UPLOAD_API_PATH);
};
export var publishCustomForm = function (groupData) {
    return httpPromise.put("".concat(ServiceConstant.PUBLISH_CUSTOM_FORM_API_PATH, "/").concat(groupData.customFormID, "?customFormID=").concat(groupData.customFormID, "&isPublished=").concat(groupData.isPublished));
};
export var postMangeUploadClientLists = function (groupdata) {
    return httpPromise.post(ServiceConstant.CUSTOM_FORM_UPLOAD_API_PATH, groupdata);
};
export var getCustomFormWithIDData = function (customFormID) {
    return httpPromise.get("".concat(ServiceConstant.CUSTOM_FORM_UPLOAD_API_PATH, "/").concat(customFormID));
};
export var patchCustomFormData = function (patchToUpdate) {
    return httpPromise.put("".concat(ServiceConstant.CUSTOM_FORM_UPLOAD_API_PATH, "/").concat(patchToUpdate.customFormID), patchToUpdate);
};
export var postSystemFieldMappingData = function (groupdata) {
    return httpPromise.post(ServiceConstant.SAVE_SYSTEM_FIELDS_API, groupdata);
};
export var getCustomFormSearch = function (param) {
    return httpPromise.get("".concat(ServiceConstant.CUSTOM_SEARCH, "?").concat(param));
};
export var patchManageAttrData = function (dataObj) {
    return httpPromise.put("".concat(ServiceConstant.POST_NEW_MAPPING_API), dataObj);
};
export var uploadNewFormDocument = function (formData) {
    return httpPromise.post(ServiceConstant.CUSTOM_FORM_FILE_UPLOAD_API_PATH, formData);
};
export var getCustomFormAttributeValidation = function (customFormAttrMappingID) {
    return httpPromise.get("".concat(ServiceConstant.CUSTOM_FORM_GET_ATTRIBUTE_VALIDATIONS, "?customFormAttrMappingID=").concat(customFormAttrMappingID));
};
export var postAttributeValidationResult = function (attributeData) {
    return httpPromise.post(ServiceConstant.CUSTOM_FORM_GET_ATTRIBUTE_VALIDATIONS, attributeData);
};
export var fetchSectionDropDownList = function () {
    return httpPromise.get(ServiceConstant.ONBOARDING_SECTION_LIST_API_PATH);
};
export var getSectionList = function () {
    return httpPromise.get(ServiceConstant.MANAGE_SECTION_API);
};
export var getSectionTypeList = function () {
    return httpPromise.get("".concat(ServiceConstant.MANAGE_SECTION_API, "/sectiontype"));
};
export var postManageSectionList = function (groupdata) {
    return httpPromise.post(ServiceConstant.MANAGE_SECTION_API, groupdata);
};
export var editSectionList = function (customFormSection) {
    return httpPromise.put("".concat(ServiceConstant.MANAGE_SECTION_API, "/").concat(customFormSection.SectionId), customFormSection);
};
export var deleteSection = function (sectionId) {
    return httpPromise.put("".concat(ServiceConstant.MANAGE_SECTION_API, "/deletesection/").concat(sectionId));
};
export var getAttrActions = function () {
    return httpPromise.get(ServiceConstant.GET_ATTR_ACTION_API);
};
export var getAttrEvents = function () {
    return httpPromise.get(ServiceConstant.GET_ATTR_EVENT_API);
};
export var getAttrOperator = function () {
    return httpPromise.get(ServiceConstant.GET_ATTR_OPERATOR_API);
};
export var getAttrManipulationById = function (customFormAttrMappingID, isCalculation) {
    return httpPromise.get("".concat(ServiceConstant.GET_ATTR_MANIPULATION_DATA_API, "?customformAttrmappingId=").concat(customFormAttrMappingID, "&isCalculation=").concat(isCalculation));
};
export var deleteAttrManipulationById = function (manipulationId, manipulationResultId) {
    return httpPromise.put("".concat(ServiceConstant.DELETE_ATTR_MANIPULATION_API, "?attributeManipulationId=").concat(manipulationId, "&manipulationResultId=").concat(manipulationResultId));
};
export var getAttrForEditbyId = function (attributeManipulationId, manipulationResultId) {
    return httpPromise.get("".concat(ServiceConstant.GET_EDIT_SAVE_ATTR_MANIPULATION_API, "?attributeManipulationId=").concat(attributeManipulationId, "&manipulationResultId=").concat(manipulationResultId));
};
export var saveAttrManipulation = function (attrData) {
    return httpPromise.post(ServiceConstant.GET_EDIT_SAVE_ATTR_MANIPULATION_API, attrData);
};
export var updateAttrManipulation = function (attrData) {
    return httpPromise.put(ServiceConstant.GET_EDIT_SAVE_ATTR_MANIPULATION_API, attrData);
};
export var postCustomDynamicFormData = function (data) {
    return httpPromise.post("".concat(ServiceConstant.DYNAMIC_FORM_RENDER_API_PATH, "/SignAndSave"), data);
};
export var fetchDynamicFormRenderData = function (newHireDocumentID, getFromNewHireDocument) {
    return httpPromise.get("".concat(ServiceConstant.DYNAMIC_FORM_RENDER_API_PATH, "?newHireDocumentID=").concat(newHireDocumentID, "&getFromNewHireDocument=").concat(getFromNewHireDocument));
};
export var getCustomFormNewHireDocumentType = function () {
    return httpPromise.get("".concat(ServiceConstant.MANAGE_NEW_HIRE_DOCUMENT_TYPE_API, "/customformtypes"));
};
export var getAttrActionValues = function () {
    return httpPromise.get(ServiceConstant.GET_ATTR_ACTION_VALUE_API);
};
export var postEmployeeWorkvisaNotApprove = function (data) {
    return httpPromise.post("".concat(ServiceConstant.EMPLOYEE_WORK_VISA_NOT_APPROVE_API), data);
};
export var postEmployeeWorkvisaApprove = function (data) {
    return httpPromise.post("".concat(ServiceConstant.EMPLOYEE_WORK_VISA_APPROVE_API), data);
};
export var getWorkVisaDocumentfileinfo = function (newHireDocid) {
    return httpPromise.get(ServiceConstant.WORK_VISA_DOCUMENT_INFO_API + newHireDocid);
};
export var getCountyLists = function () {
    return httpPromise.get(ServiceConstant.COUNTY_API_PATH);
};
