var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Transform } from "helper/Transform";
import { TranslationConstant } from "common/constant";
import { gridFieldMapper } from "components/shared/utils";
import { formatDate } from "@progress/kendo-intl";
export var GridFilters = function (props) {
    var filter = props.filter, showFilter = props.showFilter, setShowHideFilter = props.setShowHideFilter, customFilterTitles = props.customFilterTitles;
    var getFieldName = function (fieldname) {
        if (customFilterTitles && customFilterTitles[fieldname])
            return customFilterTitles[fieldname];
        return gridFieldMapper[fieldname];
    };
    var renderValue = function (item) {
        if (item.operator === "eq") {
            var formatItem = formatDate(new Date(item.value), "MM/dd/yy");
            if (formatItem !== "01/01/70") {
                return formatItem;
            }
            return item.value;
        }
        return item.value;
    };
    return (_jsxs("div", __assign({ className: "filter-section" }, { children: [_jsx("div", __assign({ className: "filters-applied" }, { children: filter && (filter === null || filter === void 0 ? void 0 : filter.length) !== 0 && (_jsxs(_Fragment, { children: [_jsxs("p", { children: [_jsx(Transform, { mkey: TranslationConstant.APPLIED_FILTERS }), ":"] }), _jsx("ul", { children: filter.map(function (item, index) { return (_jsxs("li", { children: [_jsx("label", { children: getFieldName(item.field) }), ": ", renderValue(item)] }, index)); }) })] })) })), _jsx("span", __assign({ className: "filter-btn", onClick: function () { return setShowHideFilter(!showFilter); } }, { children: !showFilter ? (_jsx(Transform, { mkey: TranslationConstant.SHOW_FILTERS_TEXT })) : (_jsx(Transform, { mkey: TranslationConstant.HIDE_FILTERS_TEXT })) }))] })));
};
