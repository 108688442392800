import { httpPromise } from "services/common";
import { ServiceConstant } from "common/constant";
export var getWorker1099Details = function (workerId) {
    return httpPromise.get("".concat(ServiceConstant.CLIENT_WORKERS_API, "/WorkerDetails?workerId=").concat(workerId));
};
export var postWorker1099Documents = function (payload) {
    return httpPromise.post("".concat(ServiceConstant.CLIENT_WORKERS_API, "/AddDocument"), payload);
};
export var updateWorker1099Details = function (postDetails) {
    return httpPromise.post("".concat(ServiceConstant.CLIENT_WORKERS_API, "/UpdateWorker"), postDetails);
};
export var downloadWorker1099Document = function (fileUrl, fileName) {
    return httpPromise.post("".concat(ServiceConstant.CLIENT_WORKERS_API, "/DownloadDocument?fileUrl=").concat(fileUrl, "&fileName=").concat(fileName), {}, { responseType: "blob" });
};
export var getWorker1099PendingDocuments = function (workerID) {
    return httpPromise.get("".concat(ServiceConstant.CLIENT_WORKERS_API, "/GetPendingRequiredWorkerDocuments?workerId=").concat(workerID));
};
export var getWorker1099DocumentsType = function () {
    return httpPromise.get("".concat(ServiceConstant.CLIENT_WORKERS_API, "/GetWorkerDocumentTypes"));
};
export var getStatesFor1099Worker = function () {
    return httpPromise.get("".concat(ServiceConstant.STATES_API_PATH, "/"));
};
export var getCitiesFromState = function (stateId) {
    return httpPromise.get("".concat(ServiceConstant.GET_CITY_API_PATH, "/GetCitiesFromStates?stateID=").concat(stateId));
};
export var getZIPcodeFromCity = function (cityId) {
    return httpPromise.get("".concat(ServiceConstant.GET_ZIPCODE_API_PATH, "/GetZipCodeFromCity?cityID=").concat(cityId));
};
export var signAndSaveForW9Form = function (formDetails) {
    return httpPromise.post("".concat(ServiceConstant.CLIENT_WORKERS_API, "/SignandSave"), formDetails);
};
export var getW9FormRevalidationDocuments = function (workerId) {
    return httpPromise.get("".concat(ServiceConstant.CLIENT_WORKERS_API, "/GetW9FormRevalidationDocuments?workerID=").concat(workerId));
};
export var getSavedModifiedW9FormDetail = function (_a) {
    var workerId = _a.workerId, createdBy = _a.createdBy, reValidatedYear = _a.reValidatedYear, status = _a.status;
    return httpPromise.get("".concat(ServiceConstant.CLIENT_WORKERS_API, "/GetSaveModifiedW9FormDetail?workerID=").concat(workerId, "&createdBy=").concat(createdBy, "&status=").concat(status, "&reValidatedYear=").concat(reValidatedYear));
};
export var uploadWorkerSupportiveDocument = function (dataObj) {
    return httpPromise.post("".concat(ServiceConstant.CLIENT_WORKERS_API, "/UploadWorkerSupportiveDocument"), dataObj);
};
export var signAndSaveDDAForm = function (dataObj) {
    return httpPromise.post("".concat(ServiceConstant.CLIENT_WORKERS_API, "/SignandSaveDDAForm"), dataObj);
};
export var createNewUser = function (dataObj) {
    return httpPromise.post("".concat(ServiceConstant.CLIENT_WORKERS_API, "/SaveWorker"), dataObj);
};
export var getRequiredNotes = function (workerId) {
    return httpPromise.get("".concat(ServiceConstant.CLIENT_WORKERS_API, "/GetRequiredDocumentNotes?workerId=").concat(workerId));
};
export var getRequiredDocuments = function (workerId) {
    return httpPromise.get("".concat(ServiceConstant.CLIENT_WORKERS_API, "/GetRequireDocuments?workerId=").concat(workerId));
};
export var updateDocumentStatus = function (dataObj) {
    return httpPromise.post("".concat(ServiceConstant.CLIENT_WORKERS_API, "/UpdateDocumentStatus"), dataObj);
};
export var deleteWorkerDocument = function (documentId, workerId) {
    return httpPromise.delete("".concat(ServiceConstant.CLIENT_WORKERS_API, "/DeleteWorkerDocument?documentId=").concat(documentId, "&webUserID=").concat(workerId));
};
export var updateWorkerStatus = function (dataObj) {
    return httpPromise.post("".concat(ServiceConstant.CLIENT_WORKERS_API, "/UpdateWorkerStatus"), dataObj);
};
export var getActiveStates = function () {
    return httpPromise.get("".concat(ServiceConstant.CORP_STATE_API_PATH, "/GetActiveStates"));
};
export var getAllActiveStates = function () {
    return httpPromise.get("".concat(ServiceConstant.CORP_STATE_API_PATH, "/GetAllActiveStates"));
};
