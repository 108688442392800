var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React from "react";
import { Link } from "react-router-dom";
import { Grid, GridColumn as Column } from "@progress/kendo-react-grid";
import { process as theProcess } from "@progress/kendo-data-query";
import { getTempAgreementInfo, getResendEmployeeEmail } from "services/dashboard";
import { Transform, AppConstant, ComponentConstant, TranslationConstant, GridRefresher, GridFilters, FormHeading, TabsComponent, useDashboardContext, useCurrentViewContext, DialogBox, CLIENT_DASHBOARD, getGridItemCount, URL_TYPE_CODES, } from "./index";
var initialDataState = {
    sort: [{ field: "document", dir: "asc" }],
    skip: 0,
    take: AppConstant.GRID_ITEM_TAKE_SIZE,
};
export var CLientBoardGrid = function (props) {
    var _a = useCurrentViewContext(), postSendEmailTemplate = _a.postSendEmailTemplate, userAccessType = _a.userAccessType;
    var _b = useDashboardContext(), clientFormsToComplete = _b.clientFormsToComplete, fetchClientFormsToComplete = _b.fetchClientFormsToComplete, employeeIncompletePaperwork = _b.employeeIncompletePaperwork, fetchEmployeeIncompletePaperwork = _b.fetchEmployeeIncompletePaperwork, incompleteEmployeeAgreement = _b.incompleteEmployeeAgreement, fetchIncompleteEmployeeAgreement = _b.fetchIncompleteEmployeeAgreement;
    var oidcUserProfile = JSON.parse(atob(localStorage.getItem("".concat(AppConstant.LOCAL_STORAGE_USER_PROFILE, ":").concat(process.env.AUTHORITY_URL, ":").concat(process.env.CLIENT_ID))));
    var _c = React.useState(initialDataState), dataStateFirst = _c[0], setDataStateFirst = _c[1];
    var _d = React.useState(initialDataState), dataStateSecond = _d[0], setDataStateSecond = _d[1];
    var _e = React.useState(initialDataState), dataStateThird = _e[0], setDataStateThird = _e[1];
    var _f = React.useState(), showFilterFirst = _f[0], setShowHideFilterFirst = _f[1];
    var _g = React.useState([]), filterFirst = _g[0], setFilterFirst = _g[1];
    var _h = React.useState(), showFilterSecond = _h[0], setShowHideFilterSecond = _h[1];
    var _j = React.useState([]), filterSecond = _j[0], setFilterSecond = _j[1];
    var _k = React.useState([]), filterThird = _k[0], setFilterThird = _k[1];
    var _l = React.useState(), showFilterThird = _l[0], setShowHideFilterThird = _l[1];
    var _m = React.useState(false), modalState = _m[0], changeModalState = _m[1];
    var _o = React.useState({
        headingText: "",
        bodyText: "",
        isErrorDialog: false,
    }), modalContent = _o[0], updateModalContent = _o[1];
    React.useEffect(function () {
        var Grid_Item_Count = getGridItemCount();
        setDataStateFirst(__assign(__assign({}, dataStateFirst), { take: Grid_Item_Count }));
        setDataStateSecond(__assign(__assign({}, dataStateSecond), { take: Grid_Item_Count }));
        setDataStateThird(__assign(__assign({}, dataStateThird), { take: Grid_Item_Count }));
    }, []);
    var hideModal = function () {
        changeModalState(false);
    };
    var onEmailSendSuccess = function () {
        updateModalContent({
            headingText: _jsx(Transform, { mkey: TranslationConstant.EMAIL_SENT_TITLE }),
            bodyText: (_jsxs("div", __assign({ className: "block subheading" }, { children: [_jsx("i", { className: "fa fa-check fa-lg" }), _jsx("span", { children: _jsx(Transform, { mkey: TranslationConstant.EMAIL_RESEND_EMPLOYEE_AGREEMENT }) })] }))),
            cancelText: _jsx(Transform, { mkey: TranslationConstant.CLOSE_BUTTON }),
            isErrorDialog: false,
        });
        changeModalState(true);
    };
    var onEmailSendFailureDueToMapping = function () {
        updateModalContent({
            headingText: _jsx(Transform, { mkey: TranslationConstant.EMAIL_FAILURE_TITLE }),
            bodyText: (_jsxs("div", __assign({ className: "block subheading" }, { children: [_jsx("i", { className: "fa fa-times-circle fa-lg" }), _jsx("span", { children: _jsx(Transform, { mkey: TranslationConstant.EMAIL_SEND_FAILED }) })] }))),
            cancelText: _jsx(Transform, { mkey: TranslationConstant.CLOSE_BUTTON }),
            isErrorDialog: true,
        });
        changeModalState(true);
    };
    React.useEffect(function () {
        fetchClientFormsToComplete();
        fetchEmployeeIncompletePaperwork(oidcUserProfile.ClientId, oidcUserProfile.IndexNumber);
        fetchIncompleteEmployeeAgreement(oidcUserProfile.IndexNumber);
    }, []);
    var formHeadingData = {
        title: _jsx(Transform, { mkey: TranslationConstant.EMPLOYEE_BOARD_HEADING }),
    };
    var gridRefresher = function () {
        fetchClientFormsToComplete();
        fetchEmployeeIncompletePaperwork(oidcUserProfile.ClientId, oidcUserProfile.IndexNumber);
        fetchIncompleteEmployeeAgreement(oidcUserProfile.IndexNumber);
        changeModalState(false);
    };
    var sendEmailIncompletePaperwork = function (dataItem, statusID) { return __awaiter(void 0, void 0, void 0, function () {
        var response, EmailObj, e_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    EmailObj = {
                        UserName: "",
                        firstName: dataItem.firstName,
                        lastName: dataItem.lastName,
                        emailAddress: dataItem.emailAddress,
                        ClientName: "",
                        PrimaryWorkStateId: "0",
                        NotificationCode: statusID === CLIENT_DASHBOARD.NEWHIREJOBSTATUSID75
                            ? "ResetWebUserPasswordEmail"
                            : "EmployeeResendCreateUserEmail",
                        RedirectURI: "",
                        Parameters: {
                            Password: dataItem.password,
                            SourceApplication: "Onboarding",
                        },
                    };
                    _a.label = 1;
                case 1:
                    _a.trys.push([1, 3, , 4]);
                    return [4 /*yield*/, postSendEmailTemplate(EmailObj)];
                case 2:
                    response = _a.sent();
                    if (AppConstant.HTTP_SUCCESS_CODES.includes(response.status) &&
                        response.data === true) {
                        onEmailSendSuccess();
                    }
                    else {
                        onEmailSendFailureDueToMapping();
                    }
                    return [3 /*break*/, 4];
                case 3:
                    e_1 = _a.sent();
                    changeModalState(false);
                    return [3 /*break*/, 4];
                case 4: return [2 /*return*/];
            }
        });
    }); };
    var EmailHandlerIncompletePaperwork = function (dataItem) { return __awaiter(void 0, void 0, void 0, function () {
        var response;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, getResendEmployeeEmail(dataItem.newHireJobID)];
                case 1:
                    response = _a.sent();
                    if (AppConstant.HTTP_SUCCESS_CODES.includes(response.status)) {
                        sendEmailIncompletePaperwork(response.data, response.data.newHireJobStatusID);
                    }
                    return [2 /*return*/];
            }
        });
    }); };
    var sendEmailIncompleteAgreement = function (dataItem, TempID) { return __awaiter(void 0, void 0, void 0, function () {
        var EmailObjAgreement, response, e_2;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    EmailObjAgreement = {
                        UserName: "",
                        firstName: dataItem.firstName,
                        lastName: dataItem.lastName,
                        emailAddress: dataItem.emailAddress,
                        ClientName: "",
                        PrimaryWorkStateId: "0",
                        NotificationCode: "SendTempAgreementLink",
                        RedirectURI: "",
                        Parameters: {
                            MasterWebUserId: JSON.stringify(dataItem.masterWebUserID),
                            DocumentActionType: "3",
                            TempAgreementID: JSON.stringify(TempID),
                            SourceApplication: "Onboarding",
                        },
                    };
                    _a.label = 1;
                case 1:
                    _a.trys.push([1, 3, , 4]);
                    return [4 /*yield*/, postSendEmailTemplate(EmailObjAgreement)];
                case 2:
                    response = _a.sent();
                    if (AppConstant.HTTP_SUCCESS_CODES.includes(response.status) &&
                        response.data === true) {
                        onEmailSendSuccess();
                    }
                    else {
                        onEmailSendFailureDueToMapping();
                    }
                    return [3 /*break*/, 4];
                case 3:
                    e_2 = _a.sent();
                    changeModalState(false);
                    return [3 /*break*/, 4];
                case 4: return [2 /*return*/];
            }
        });
    }); };
    var EmailHandlerIncompleteAgreement = function (dataItem) { return __awaiter(void 0, void 0, void 0, function () {
        var response;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, getTempAgreementInfo(dataItem.tempAgreementID)];
                case 1:
                    response = _a.sent();
                    if (AppConstant.HTTP_SUCCESS_CODES.includes(response.status)) {
                        sendEmailIncompleteAgreement(response.data, dataItem.tempAgreementID);
                    }
                    return [2 /*return*/];
            }
        });
    }); };
    var CompleteFormCell = function (cellprops) {
        return (_jsx("td", __assign({ colSpan: cellprops.colSpan, role: "gridcell", "aria-colindex": cellprops.ariaColumnIndex, "aria-selected": cellprops.isSelected }, { children: cellprops.dataItem.routeUrl && (_jsx(_Fragment, { children: cellprops.dataItem.newHireDocumentFormTypeCode ===
                    URL_TYPE_CODES.LEGACY ? (_jsx("span", __assign({ onClick: function () { return dialogForRefeshMessage(cellprops.dataItem); } }, { children: _jsxs(Link, __assign({ to: cellprops.dataItem.routeUrl, className: userAccessType === AppConstant.READ_ONLY_USER_ACCESS_VALUE
                            ? "access-disabled"
                            : "", target: "_blank" }, { children: [_jsx("i", { className: "fa fa-edit fa-lg" }), ComponentConstant.COMPLETE_THIS_FORM] })) }))) : (_jsxs(Link, __assign({ to: "/".concat(cellprops.dataItem.routeUrl), className: userAccessType === AppConstant.READ_ONLY_USER_ACCESS_VALUE
                        ? "access-disabled"
                        : "" }, { children: [_jsx("i", { className: "fa fa-edit fa-lg" }), ComponentConstant.COMPLETE_THIS_FORM] }))) })) })));
    };
    var completeFormCustomCell = function (props) {
        return _jsx(CompleteFormCell, __assign({}, props));
    };
    var dialogForRefeshMessage = function (cellObj) {
        updateModalContent({
            headingText: _jsx(Transform, { mkey: TranslationConstant.REFRESH_HEADING }),
            bodyText: (_jsxs("div", __assign({ className: "block subheading" }, { children: [_jsx("i", { className: "fa fa-info-circle fa-lg" }), _jsxs("span", { children: ["Please fill the ", _jsx("b", { children: cellObj.name }), " form prior to hit the refresh"] })] }))),
            acceptText: _jsx(Transform, { mkey: TranslationConstant.REFRESH_GRID_BTN_TEXT }),
            acceptHandler: gridRefresher,
            isErrorDialog: false,
        });
        if (userAccessType !== AppConstant.READ_ONLY_USER_ACCESS_VALUE) {
            changeModalState(true);
        }
    };
    var SendEmailFormPaperworkCell = function (props) {
        return (_jsx("td", __assign({ colSpan: props.colSpan, role: "gridcell", "aria-colindex": props.ariaColumnIndex, "aria-selected": props.isSelected }, { children: (props.dataItem["newHireJobStatusID"] === CLIENT_DASHBOARD.NEWHIREJOBSTATUSID75 ||
                props.dataItem["newHireJobStatusID"] === CLIENT_DASHBOARD.NEWHIREJOBSTATUSID1 ||
                props.dataItem["newHireJobStatusID"] ===
                    CLIENT_DASHBOARD.NEWHIREJOBSTATUSID100) && (_jsxs(Link, __assign({ to: location.search, className: userAccessType === AppConstant.READ_ONLY_USER_ACCESS_VALUE
                    ? "access-disabled"
                    : "", onClick: function (e) {
                    e.preventDefault();
                    EmailHandlerIncompletePaperwork(props.dataItem);
                }, state: { isDocumentForApprovalReq: true } }, { children: [_jsx("i", { className: "fa fa-paper-plane" }), _jsx(Transform, { mkey: TranslationConstant.RESEND_EMPLOYEE_ONBOARDING_LINK_EMAIL })] }))) })));
    };
    var SendEmailFormPaperworkCustomCell = function (props) {
        return _jsx(SendEmailFormPaperworkCell, __assign({}, props));
    };
    var SendEmailFormCell = function (props) {
        return (_jsx("td", __assign({ colSpan: props.colSpan, role: "gridcell", "aria-colindex": props.ariaColumnIndex, "aria-selected": props.isSelected }, { children: _jsxs(Link, __assign({ to: location.search, className: userAccessType === AppConstant.READ_ONLY_USER_ACCESS_VALUE
                    ? "access-disabled"
                    : "", onClick: function (e) {
                    e.preventDefault();
                    EmailHandlerIncompleteAgreement(props.dataItem);
                } }, { children: [_jsx("i", { className: "fa fa-paper-plane" }), _jsx(Transform, { mkey: TranslationConstant.RESEND_EMPLOYEE_AGREEMENT_EMAIL })] })) })));
    };
    var SendEmailFormCustomCell = function (props) {
        return _jsx(SendEmailFormCell, __assign({}, props));
    };
    var formsToCompleteTab = [
        {
            title: _jsx(Transform, { mkey: TranslationConstant.FORMS_TO_COMPLETE_HEADING }),
            children: (_jsxs(_Fragment, { children: [_jsx(GridFilters, { filter: filterFirst, showFilter: showFilterFirst, setShowHideFilter: setShowHideFilterFirst }), _jsxs("div", __assign({ className: "general-grid no-padding grid-without-paging" }, { children: [_jsxs(Grid, __assign({ sortable: true, filterable: showFilterFirst, pageable: true, style: { height: clientFormsToComplete.length > 10 ? "400px" : "auto" }, data: theProcess(clientFormsToComplete, dataStateFirst) }, dataStateFirst, { onDataStateChange: function (e) {
                                    setDataStateFirst(e.dataState);
                                    setFilterFirst(e.dataState.filter && e.dataState.filter.filters);
                                } }, { children: [_jsx(Column, { field: "firstName", title: "First Name", width: "150px" }), _jsx(Column, { field: "lastName", title: "Last Name", width: "150px" }), _jsx(Column, { field: "name", title: "Document", width: "150px" }), _jsx(Column, { field: "accountDescription", title: "Account-Description", width: "150px" }), _jsx(Column, { field: "", title: "Click to Complete", width: "150px", filterable: false, cell: completeFormCustomCell })] })), _jsx(GridRefresher, { refreshGridDataHandler: gridRefresher })] }))] })),
        },
    ];
    var incompleteOnboardingTab = [
        {
            title: _jsx(Transform, { mkey: TranslationConstant.INCOMPLETE_ONBOARDING_PAPERWORK_HEADING }),
            children: (_jsxs(_Fragment, { children: [_jsx(GridFilters, { filter: filterSecond, showFilter: showFilterSecond, setShowHideFilter: setShowHideFilterSecond }), _jsxs("div", __assign({ className: "general-grid no-padding grid-without-paging" }, { children: [_jsxs(Grid, __assign({ sortable: true, filterable: showFilterSecond, pageable: true, style: {
                                    height: employeeIncompletePaperwork.length > 10 ? "400px" : "auto",
                                }, data: theProcess(employeeIncompletePaperwork, dataStateSecond) }, dataStateSecond, { onDataStateChange: function (e) {
                                    setDataStateSecond(e.dataState);
                                    setFilterSecond(e.dataState.filter && e.dataState.filter.filters);
                                } }, { children: [_jsx(Column, { field: "firstName", title: "First Name", width: "120px" }), _jsx(Column, { field: "lastName", title: "Last Name", width: "120px" }), _jsx(Column, { field: "accountDescription", title: "Account-Description", width: "150px" }), _jsx(Column, { field: "status", title: "Status", width: "150px" }), _jsx(Column, { field: "", title: "Click to Send Mail", width: "180px", filterable: false, cell: SendEmailFormPaperworkCustomCell })] })), _jsx(GridRefresher, { gridIndex: 1, refreshGridDataHandler: gridRefresher })] }))] })),
        },
    ];
    var incompleteEmployeeAgreementTab = [
        {
            title: _jsx(Transform, { mkey: TranslationConstant.INCOMPLETE_EMPLOYEE_AGREEMENT_HEADING }),
            children: (_jsxs(_Fragment, { children: [_jsx(GridFilters, { filter: filterThird, showFilter: showFilterThird, setShowHideFilter: setShowHideFilterThird }), _jsxs("div", __assign({ className: "general-grid no-padding grid-without-paging" }, { children: [_jsxs(Grid, __assign({ sortable: true, filterable: showFilterThird, pageable: true, style: {
                                    height: incompleteEmployeeAgreement.length > 10 ? "400px" : "auto",
                                }, data: theProcess(incompleteEmployeeAgreement, dataStateThird) }, dataStateThird, { onDataStateChange: function (e) {
                                    setDataStateThird(e.dataState);
                                    setFilterThird(e.dataState.filter && e.dataState.filter.filters);
                                } }, { children: [_jsx(Column, { field: "firstName", title: "First Name", width: "150px" }), _jsx(Column, { field: "lastName", title: "Last Name", width: "150px" }), _jsx(Column, { field: "status", title: "Status", width: "150px" }), _jsx(Column, { field: "", title: "Click to Send Mail", width: "150px", filterable: false, cell: SendEmailFormCustomCell })] })), _jsx(GridRefresher, { gridIndex: 2, refreshGridDataHandler: gridRefresher })] }))] })),
        },
    ];
    return (_jsxs(_Fragment, { children: [_jsx(FormHeading, { data: formHeadingData }), _jsx("div", __assign({ className: "admin-panel-wrapper admin-agent-task employee-task-grid client-dashboard" }, { children: _jsxs("div", __assign({ className: "col-12 col-sm-12 col-lg-12" }, { children: [_jsx(TabsComponent, { tabsMenu: formsToCompleteTab, selectedIndex: 0 }), _jsx(TabsComponent, { tabsMenu: incompleteOnboardingTab, selectedIndex: 0 }), _jsx(TabsComponent, { tabsMenu: incompleteEmployeeAgreementTab, selectedIndex: 0 })] })) })), modalState && (_jsx(DialogBox, { headingText: modalContent.headingText, bodyText: modalContent.bodyText, acceptText: modalContent.acceptText, acceptHandler: modalContent.acceptHandler, cancelText: modalContent.cancelText, isErrorDialog: modalContent.isErrorDialog, isDialogOpen: modalState, onModalChangeState: hideModal }))] }));
};
