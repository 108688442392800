var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import * as React from "react";
import { TabStrip, TabStripTab } from "@progress/kendo-react-layout";
import "assets/css/tabs-strip.scss";
export var TabsComponent = function (props) {
    var selectedIndex = props.selectedIndex, tabInformation = props.tabInformation, onSelectHandler = props.onSelectHandler;
    var _a = React.useState([]), tabsMenu = _a[0], updateTabs = _a[1];
    var _b = React.useState(selectedIndex), selected = _b[0], setSelected = _b[1];
    var handleSelect = function (e) {
        setSelected(e.selected);
        if (onSelectHandler) {
            onSelectHandler(e.selected);
        }
    };
    React.useEffect(function () {
        updateTabs(props.tabsMenu);
    });
    return (_jsxs(_Fragment, { children: [tabInformation && _jsx("div", __assign({ className: "tab-text-information" }, { children: tabInformation })), _jsx(TabStrip, __assign({ selected: selected, onSelect: handleSelect }, { children: tabsMenu.map(function (item, index) {
                    var isVisible = item.visible === false ? false : true;
                    return (isVisible && (_jsx(TabStripTab, __assign({ title: item.title, disabled: item.disabled }, { children: item.children }), index)));
                }) }))] }));
};
