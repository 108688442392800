var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useState } from "react";
import { Form, Field, FormElement } from "@progress/kendo-react-form";
import { Transform, TranslationConstant, requiredValidator, FormRadioGroup, FormButtons, } from "./index";
var directDepositDataList = [
    {
        label: _jsx(Transform, { mkey: TranslationConstant.DIRECT_DEPOSIT }),
        value: "directdeposit",
    },
    {
        label: _jsx(Transform, { mkey: TranslationConstant.PAYCARD }),
        value: "paycard",
    },
];
var DirectDepositDialogContent = function (props) {
    var _a = useState(directDepositDataList[0].value), selectedOption = _a[0], setSelectedOption = _a[1];
    var handleDirectDepositOrPayCardSubmit = function (e) {
        props.handleSubmit(e, selectedOption);
        props.handleClose();
    };
    return (_jsx(_Fragment, { children: _jsx(Form, { onSubmitClick: handleDirectDepositOrPayCardSubmit, render: function (formRenderProps) { return (_jsxs(FormElement, __assign({ className: "general-form border-0 mx-0 pb-0 mb-0" }, { children: [_jsx(Field, { id: "directDepositOrPayCard", label: _jsx(Transform, { mkey: TranslationConstant.SELECT_ITEM_TO_UPDATE }), isRequired: true, validator: requiredValidator, name: "directDepositOrPayCard", component: FormRadioGroup, data: directDepositDataList, defaultValue: directDepositDataList[0].value, layout: "horizontal", onChange: function (e) {
                            setSelectedOption(e.value);
                        } }), _jsx("div", __assign({ className: "k-form-buttons mt-2 pb-0 pe-0" }, { children: _jsx(FormButtons, { wrapperClass: "pe-0 pb-1", hideResetButton: true, customSubmitText: TranslationConstant.LABEL_SUBMIT, closeText: _jsx(Transform, { mkey: TranslationConstant.CLOSE_BUTTON }), showCancelButton: true, cancelHandler: props.handleClose }) }))] }))); } }) }));
};
export default DirectDepositDialogContent;
