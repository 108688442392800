import { useState, useEffect } from "react";
var useWindowDimension = function () {
    var _a = useState({
        width: window.innerWidth,
        height: window.innerHeight,
    }), windowDimension = _a[0], setWindowDimension = _a[1];
    useEffect(function () {
        var handleResize = function () {
            setWindowDimension({
                width: window.innerWidth,
                height: window.innerHeight,
            });
        };
        window.addEventListener("resize", handleResize);
        return function () {
            window.removeEventListener("resize", handleResize);
        };
    }, []);
    return windowDimension;
};
export default useWindowDimension;
