var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useJobsViewContext } from "store/jobs/jobs";
import { useCurrentViewContext } from "store/common/currentViewContext";
import { Transform } from "helper/Transform";
import { TranslationConstant } from "common/constant";
import { BreadcrumbComponent } from "components/shared/BreadCrumb";
import { createEncodedURL, decodedURL, getUserDetailsFromLocalStorage, } from "components/shared/utils";
import { DocumentViewer } from "components/shared/DocumentViewer";
import PageTitle from "components/shared/PageTitle";
import EmployeeInfoDetails from "components/onboarding/Jobs/Common/EmployeeInfoDetails";
import { WEBUSERROLEID } from "common/constant/Enums";
var emailTemplate = function (paramobj) {
    return {
        UserName: "",
        FirstName: "",
        LastName: "",
        EmailAddress: "tech-alerts@payreelonline.com",
        ClientName: "",
        PrimaryWorkStateId: 0,
        NotificationCode: "SupportingDocLoaderIssue",
        RedirectURI: "",
        Parameters: {
            FileName: paramobj.FileName,
            NewHireDocumentId: paramobj.NewHireDocumentId,
            ExceptionMessage: paramobj.ExceptionMessage,
        },
    };
};
var DocumentView = function () {
    var queryStringObject = decodedURL();
    var Navigate = useNavigate();
    var _a = useJobsViewContext(), fetchSupportingDocument = _a.fetchSupportingDocument, showSupportingDocument = _a.showSupportingDocument, fetchSupportingDocCount = _a.fetchSupportingDocCount;
    var postSendEmailTemplate = useCurrentViewContext().postSendEmailTemplate;
    var isDocumentForApprovalReq = queryStringObject.isDocumentForApprovalReq, employeeDetails = queryStringObject.employeeDetails, fileUrl = queryStringObject.fileUrl, backButtonPath = queryStringObject.backButtonPath, newHireJobID = queryStringObject.newHireJobID, newHireDocumentID = queryStringObject.newHireDocumentID, isAddOnboardingFilePrefix = queryStringObject.isAddOnboardingFilePrefix, isAddAWSDirPathInBucket = queryStringObject.isAddAWSDirPathInBucket, isEmpPrintDocument = queryStringObject.isEmpPrintDocument, backButtonData = queryStringObject.backButtonData, backButtonUrl = queryStringObject.backButtonUrl, urlCreationRequired = queryStringObject.urlCreationRequired, applicationID = queryStringObject.applicationID, useBackButtonUrl = queryStringObject.useBackButtonUrl;
    var _b = useState(null), previousUrl = _b[0], updatePreviousUrl = _b[1];
    var _c = useState(false), showSupportDocViewer = _c[0], updateShowSupportDocViewer = _c[1];
    var WebUserRoleId = getUserDetailsFromLocalStorage().WebUserRoleId;
    var items = [
        {
            id: "home",
            text: "Home",
            iconClass: "k-i-home",
            route: WebUserRoleId === WEBUSERROLEID.WORKER_1099 ? backButtonPath : "/",
        },
        {
            id: "DocumentView",
            text: "Document View",
        },
    ];
    var data = {
        title: _jsx(Transform, { mkey: TranslationConstant.DOCUMENT_VIEW_HEADING }),
    };
    useEffect(function () {
        if (newHireDocumentID)
            fetchSupportingDocCount(newHireDocumentID);
    }, []);
    var backButtonHandler = function () {
        if (useBackButtonUrl) {
            Navigate(createEncodedURL(backButtonUrl, __assign({}, backButtonData)));
        }
        else {
            var url = (previousUrl === null || previousUrl === void 0 ? void 0 : previousUrl.path)
                ? previousUrl.path + previousUrl.query
                : backButtonPath
                    ? backButtonPath
                    : urlCreationRequired
                        ? createEncodedURL(backButtonUrl, JSON.parse(backButtonData))
                        : "/";
            Navigate(url);
        }
    };
    var createBackButtonLink = function (linkItem) {
        var prevUrl = JSON.parse(linkItem);
        updatePreviousUrl({
            path: prevUrl === null || prevUrl === void 0 ? void 0 : prevUrl.path,
            query: prevUrl === null || prevUrl === void 0 ? void 0 : prevUrl.query,
        });
    };
    var supportingDocumentActionHandler = function () { return __awaiter(void 0, void 0, void 0, function () {
        var e_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 2, , 3]);
                    return [4 /*yield*/, fetchSupportingDocument(newHireDocumentID)];
                case 1:
                    _a.sent();
                    updateShowSupportDocViewer(true);
                    return [3 /*break*/, 3];
                case 2:
                    e_1 = _a.sent();
                    updateShowSupportDocViewer(false);
                    return [3 /*break*/, 3];
                case 3: return [2 /*return*/];
            }
        });
    }); };
    var viewDocumentActionHandler = function () {
        updateShowSupportDocViewer(false);
    };
    var sendEmailOnEmpDocError = function (paramObj) {
        paramObj.map(function (item) { return postSendEmailTemplate(emailTemplate(item)); });
    };
    return (_jsxs(_Fragment, { children: [_jsx(BreadcrumbComponent, { items: items }), _jsx(PageTitle, { data: data }), _jsx(EmployeeInfoDetails, { hideSpacing: true, employeeDetails: employeeDetails, backButtonHandler: backButtonHandler, additionalButtons: {
                    supportingDocuments: showSupportingDocument && !showSupportDocViewer,
                    supportingDocumentHandler: supportingDocumentActionHandler,
                    viewDocuments: showSupportingDocument && showSupportDocViewer,
                    viewDocumentHandler: viewDocumentActionHandler,
                } }), _jsx(DocumentViewer, { fileUrl: fileUrl, isDocumentForApprovalReq: isDocumentForApprovalReq, backButtonLink: createBackButtonLink, isAddOnboardingFilePrefix: isAddOnboardingFilePrefix, isAddAWSDirPathInBucket: isAddAWSDirPathInBucket, showSupportDocViewer: showSupportDocViewer, isEmpPrintDocument: isEmpPrintDocument, newHireJobID: newHireJobID, sendEmailOnEmpDocError: sendEmailOnEmpDocError, applicationID: applicationID })] }));
};
export default DocumentView;
