import { httpPromise } from "services/common/httpPromise";
import { ServiceConstant } from "common/constant";
export var postGlobalClientData = function (clientID) {
    return httpPromise.post("".concat(ServiceConstant.CORP_API_BASE_PATH, "/GlobalData/SetGlobalData?clientID=").concat(clientID));
};
export var getPayrollDetails = function (clientID) {
    return httpPromise.get("".concat(ServiceConstant.CORP_API_BASE_PATH, "/ReleasePayroll?clientID=").concat(clientID));
};
export var putPayrollRelease = function (clientID) {
    return httpPromise.put("".concat(ServiceConstant.CORP_API_BASE_PATH, "/ReleasePayroll?clientID=").concat(clientID));
};
export var getManualPayments = function (clientID) {
    return httpPromise.get("".concat(ServiceConstant.CORP_API_BASE_PATH, "/PayrollAllocationManualPayments?clientID=").concat(clientID));
};
export var getTimeSheetsData = function (isVZClient, clientID) {
    var endPoint = isVZClient ? "PayrollTimesheetVerizon" : "PayrollTimesheet";
    return httpPromise.get("".concat(ServiceConstant.CORP_API_BASE_PATH, "/").concat(endPoint, "?clientID=").concat(clientID));
};
export var getCreditCardTransactions = function (clientID) {
    return httpPromise.get("".concat(ServiceConstant.CORP_API_BASE_PATH, "/PayrollAllocationCreditCard/PayrollCreditCards?clientId=").concat(clientID));
};
export var getExpenseReportsData = function (clientID) {
    return httpPromise.get("".concat(ServiceConstant.CORP_API_BASE_PATH, "/PayrollAllocationExpenseReports/payrollexpensereports?clientId=").concat(clientID));
};
export var getVendorPayments = function (clientID) {
    return httpPromise.get("".concat(ServiceConstant.CORP_API_BASE_PATH, "/PayrollAllocationVendorPayment/PayrollAllocationVendorPayment?clientID=").concat(clientID));
};
export var getReleasePayroll = function (type, clientID) {
    var endPoint = "";
    switch (type) {
        case "getTimesheets":
            endPoint = "PayrollTimesheet/releasetimesheets";
            break;
        case "getExpenses":
            endPoint = "PayrollAllocationExpenseReports/releaseexpensereports";
            break;
        case "getVendorPayments":
            endPoint = "PayrollAllocationVendorPayment/releasevendorpayment";
            break;
        case "getManuallPayments":
            endPoint = "PayrollAllocationManualPayments/releasemanualpayments";
            break;
        case "getCreditCardTransactions":
            endPoint = "PayrollAllocationCreditCard/ReleasePayrollCreditCards";
            break;
    }
    return httpPromise.put("".concat(ServiceConstant.CORP_API_BASE_PATH, "/").concat(endPoint, "?clientId=").concat(clientID));
};
export var getBillingSummary = function (isVZClient, clientID) {
    var endPoint = isVZClient
        ? "PayrollAllocationBillingSummary/BillingSummaryVZ"
        : "PayrollAllocationBillingSummary";
    return httpPromise.get("".concat(ServiceConstant.CORP_API_BASE_PATH, "/").concat(endPoint, "?clientId=").concat(clientID));
};
export var getBillingDetails = function (isVZClient, clientID, id) {
    var endPoint = isVZClient
        ? "PayrollAllocationBillingSummary/BillingSummaryDetailsVZ?clientId=".concat(clientID, "&subAccountID=").concat(id)
        : "PayrollAllocationBillingSummary/bestbuybillingsummarydetails?clientId=".concat(clientID, "&accountId=").concat(id);
    return httpPromise.get("".concat(ServiceConstant.CORP_API_BASE_PATH, "/").concat(endPoint));
};
export var getReleasePayrollVZHoursAllocation = function (timeSheetId) {
    return httpPromise.get("".concat(ServiceConstant.CORP_API_BASE_PATH, "/ReleasePayrollVZHoursAllocation?timeSheetId=").concat(timeSheetId));
};
export var getTimeSheetHourAllocationExpandDetails = function (queryString) {
    return httpPromise.get("".concat(ServiceConstant.CORP_API_BASE_PATH, "/ReleasePayrollVZHoursAllocation/timeSheetHourAllocationDetails?").concat(queryString));
};
export var getWorkedProjects = function (queryString) {
    console.log({ queryString: queryString });
    return httpPromise.get("".concat(ServiceConstant.CORP_API_BASE_PATH, "/ReleasePayrollVZHoursAllocationDetail/subaccountsforallocationdetail?").concat(queryString));
};
export var deleteAllocation = function (timeSheetId) {
    return httpPromise.put("".concat(ServiceConstant.CORP_API_BASE_PATH, "/ReleasePayrollVZHoursAllocation?timeSheetId=").concat(timeSheetId));
};
export var getWokredDetails = function (data) {
    return httpPromise.post("".concat(ServiceConstant.CORP_API_BASE_PATH, "/ReleasePayrollVZHoursAllocationDetail"), data);
};
export var postHoursAllocation = function (data) {
    return httpPromise.post("".concat(ServiceConstant.CORP_API_BASE_PATH, "/ReleasePayrollVZHoursAllocationDetail/addallocation"), data);
};
export var postCompleteAllocation = function (timeSheetId) {
    return httpPromise.put("".concat(ServiceConstant.CORP_API_BASE_PATH, "/ReleasePayrollVZHoursAllocationDetail/completeAllocation?timeSheetId=").concat(timeSheetId));
};
