var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { WEBUSERROLEID } from "common/constant/Enums";
var FormHeading = function (props) {
    return (_jsxs("div", __assign({ className: "form-heading-tile ".concat(props.border ? "with-border" : "", " ").concat(props.addOnClass ? props.addOnClass : "") }, { children: [props.data && props.data.title && (_jsxs("div", __assign({ className: "form-heading-title" }, { children: [props.data.title, ((props.userRoleId &&
                        props.userRoleId ===
                            (WEBUSERROLEID.NEW_HIRE_EMPLOYEE || WEBUSERROLEID.Employee)) ||
                        props.IconClass) && (_jsx("div", __assign({ className: "float-right" }, { children: _jsxs("a", __assign({ onClick: props.openModal, style: { fontWeight: "bold" } }, { children: [_jsx("i", { className: props.IconClass ? "".concat(props.IconClass) : "fa fa-download", "aria-hidden": "true" }), " ", props.data.tooltip] })) })))] }))), (props.data.subTitle || props.children) && (_jsxs("div", __assign({ className: "form-body" }, { children: [props.data.subTitle && _jsx("p", { children: props.data.subTitle }), props.children] })))] })));
};
export default FormHeading;
