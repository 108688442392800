import { jsx as _jsx } from "react/jsx-runtime";
import { Transform, TranslationConstant } from "./ManageFeatures/index";
export var applicationDefaultItem = {
    channelName: _jsx(Transform, { mkey: TranslationConstant.LABEL_DEFAULT_OPTION_SELECT }),
    channelID: null,
};
export var roleDefaultItem = {
    Name: _jsx(Transform, { mkey: TranslationConstant.LABEL_DEFAULT_OPTION_SELECT }),
    WebUserRoleId: null,
};
export var applicationTypeData = [
    {
        channelName: "Payroll",
        channelID: 1,
    },
    {
        channelName: "Onboarding",
        channelID: 2,
    },
];
export var initialFeatureList = {
    updated: false,
    featuresList: null,
};
export var clientUserTypeData = [
    {
        clientUserType: "Manager",
        clientUserTypeId: 1,
    },
    {
        clientUserType: "Supervisor",
        clientUserTypeId: 2,
    },
    {
        clientUserType: "Client Worker",
        clientUserTypeId: 3,
    },
];
export var clientUserDefaultItem = {
    clientUserType: _jsx(Transform, { mkey: TranslationConstant.LABEL_DEFAULT_OPTION_SELECT }),
    clientUserTypeId: null,
};
export var AccessTypeIdDataList = [
    {
        label: "Full Access",
        value: 1,
    },
    {
        label: "Read Only",
        value: 2,
    },
    {
        label: "No Access",
        value: 3,
    },
];
export var DashboardAccessTypeIdDataList = [
    {
        label: "Full Access",
        value: 1,
    },
    {
        label: "Read Only",
        value: 2,
    },
    {
        label: "No Access",
        value: 3,
        disabled: true,
    },
];
export var clientDefaultItem = {
    clientName: _jsx(Transform, { mkey: TranslationConstant.LABEL_DEFAULT_OPTION_CLIENT }),
    clientID: null,
};
export var masterClientDefaultItem = {
    text: _jsx(Transform, { mkey: TranslationConstant.LABEL_DEFAULT_MASTER_CLIENT }),
    value: null,
};
