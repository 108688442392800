var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useEffect, useState } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { PanelBar, PanelBarItem } from "@progress/kendo-react-layout";
import { useCurrentViewContext } from "store/common/currentViewContext";
import { Transform } from "helper/Transform";
import { AppConstant, TranslationConstant } from "common/constant";
import { DialogBox } from "components/shared/DialogBox";
import { getWindowDimensions, removeMenuSelection } from "components/shared//utils";
var adminPathsList = [
    "".concat(window.location.pathname, "/timesheets"),
    "".concat(window.location.pathname, "/clientsearchemployee"),
    "".concat(window.location.pathname, "/vendorpayments"),
];
var pageLoadAccesstype;
var LeftMenu = function (props) {
    var isStateOpen = props.isStateOpen, sidebarMenu = props.sidebarMenu, handleSideBarToggle = props.handleSideBarToggle, LeftMenuData = props.LeftMenuData, handleMenuChange = props.handleMenuChange, onLogoutHandler = props.onLogoutHandler;
    var _a = useCurrentViewContext(), setShowProgress = _a.setShowProgress, setUserAccessType = _a.setUserAccessType;
    var navigate = useNavigate();
    var location = useLocation();
    var logoutDialogContent = {
        headingText: _jsx(Transform, { mkey: TranslationConstant.LOGOUT_CONFIRM_HEADING }),
        bodyText: (_jsxs("div", __assign({ className: "block subheading" }, { children: [_jsx("i", { className: "fa fa-info-circle fa-lg" }), _jsx("span", { children: _jsx(Transform, { mkey: TranslationConstant.LOGOUT_SURE_TEXT }) })] }))),
        cancelText: _jsx(Transform, { mkey: TranslationConstant.NO }),
        acceptText: _jsx(Transform, { mkey: TranslationConstant.YES }),
    };
    var _b = useState(false), modalState = _b[0], changeModalState = _b[1];
    var _c = useState("#"), activeRoute = _c[0], setActiveRoute = _c[1];
    var element = document.getElementsByClassName("k-panelbar-item")[0];
    var defaultSelectionMenu = function () {
        if ((window.location.pathname === "/" ||
            window.location.pathname === AppConstant.CORP_BASE_PATHNAME ||
            window.location.pathname === "".concat(AppConstant.CORP_BASE_PATHNAME, "/")) &&
            element &&
            !localStorage.getItem("adminClientLoggedIn") &&
            !localStorage.getItem("masterClientLoggedIn")) {
            removeMenuSelection();
            element.classList.add("k-selected");
        }
    };
    useEffect(function () {
        defaultSelectionMenu();
        setUserAccessType(Number(localStorage.getItem("currentRouteAccessType")));
    }, [LeftMenuData]);
    useEffect(function () {
        var search = location.search, pathname = location.pathname;
        var route = search ? "".concat(pathname).concat(search) : pathname;
        setActiveRoute(route);
        defaultSelectionMenu();
    }, [location, element]);
    var onModalChangeState = function (value) {
        changeModalState(value);
    };
    var logOut = function () {
        navigate("/signout-oidc");
        setShowProgress(true);
        onLogoutHandler();
        changeModalState(false);
    };
    var createIcon = function (icon) {
        return "menu-icon fa ".concat(icon, " fa-lg");
    };
    var handleSelect = function (event) {
        removeMenuSelection();
        if (event.target.props.route) {
            navigate(event.target.props.route, { replace: true });
        }
        if (getWindowDimensions().width < 576 && event.target.props.children === undefined)
            handleMenuChange(false);
    };
    var setSelectedMenuItem = function (itemroute) {
        var currentPathName = window.location.pathname;
        if (itemroute &&
            itemroute.children.length === 0 &&
            ("/".concat(itemroute.route) === currentPathName ||
                "".concat(AppConstant.CORP_BASE_PATHNAME, "/").concat(itemroute.route) === currentPathName ||
                (currentPathName === AppConstant.CORP_BASE_PATHNAME &&
                    adminPathsList.includes("".concat(AppConstant.CORP_BASE_PATHNAME, "/").concat(itemroute.route))))) {
            pageLoadAccesstype = itemroute.AccessTypeId;
            return true;
        }
        else if (itemroute && currentPathName.indexOf(itemroute.route) > 0) {
            pageLoadAccesstype = itemroute.AccessTypeId;
            return false;
        }
        return false;
    };
    var handleRouteChange = function (item) {
        var _a;
        if (item && ((_a = item.children) === null || _a === void 0 ? void 0 : _a.length) === 0) {
            setUserAccessType(item.AccessTypeId);
            localStorage.setItem("currentRouteAccessType", item.AccessTypeId);
        }
    };
    useEffect(function () {
        setUserAccessType(pageLoadAccesstype);
    }, [pageLoadAccesstype]);
    return (_jsxs(_Fragment, { children: [_jsx("div", __assign({ className: "".concat(isStateOpen ? "menu-container" : "", " ").concat(sidebarMenu ? "sidebar-mini" : "sidebar-full") }, { children: _jsxs("div", __assign({ className: "panelbar-wrapper panel-open-".concat(isStateOpen, " vertical-sleekbar") }, { children: [_jsxs("div", __assign({ className: "size-toggle" }, { children: [_jsxs("button", __assign({ type: "button", className: "navbar-toggle", onClick: handleSideBarToggle }, { children: [_jsx("span", { className: "icon-bar" }), _jsx("span", { className: "icon-bar" }), _jsx("span", { className: "icon-bar" })] })), _jsx("a", __assign({ href: "#", className: "btn btn-sm pull-right logoutConfirm", onClick: function (e) {
                                        e.preventDefault();
                                        onModalChangeState(true);
                                    } }, { children: _jsx("i", { className: "fa fa-power-off" }) }))] })), _jsx(PanelBar, __assign({ onSelect: handleSelect, className: "".concat(props.theme ? "menu-theme-2" : "menu-default-theme") }, { children: LeftMenuData &&
                                LeftMenuData.map(function (item, index) {
                                    return item.IsActive &&
                                        item.AccessTypeId !== 3 && (_jsx(PanelBarItem, __assign({ title: _jsx(Link, __assign({ to: item.route || activeRoute }, { children: _jsxs("div", __assign({ className: "custom-link", onClick: function () { return handleRouteChange(item); } }, { children: [item.icon && (_jsx("span", { role: "presentation", className: "k-panelbar-item-icon menu-icon fa ".concat(item.icon, " fa-lg") })), _jsx("span", __assign({ className: "" }, { children: _jsx("a", { children: item.title }) }))] })) })), disabled: item.disabled, className: "".concat(setSelectedMenuItem(item) ? "k-selected" : "") }, { children: item.children &&
                                            item.children.length > 0 &&
                                            item.children.map(function (inneritem, innerIndex) {
                                                return inneritem.IsActive &&
                                                    inneritem.AccessTypeId !== 3 && (_jsx(PanelBarItem, __assign({ title: _jsx(Link, __assign({ to: inneritem.route }, { children: _jsx("div", __assign({ className: "custom-link-leve-1", onClick: function () {
                                                                return handleRouteChange(inneritem);
                                                            } }, { children: _jsx("span", __assign({ className: "" }, { children: _jsx("a", { children: inneritem.title }) })) })) })), disabled: inneritem.disabled, iconClass: inneritem.icon &&
                                                        createIcon(inneritem.icon), className: "".concat(setSelectedMenuItem(inneritem)
                                                        ? "k-selected"
                                                        : "") }, { children: inneritem.children &&
                                                        inneritem.children.length > 0 &&
                                                        inneritem.children.map(function (innerItem, innerChildIndex) {
                                                            return innerItem.IsActive &&
                                                                innerItem.AccessTypeId !==
                                                                    3 && (_jsx(PanelBarItem, { title: _jsx(Link, __assign({ to: inneritem.route }, { children: _jsx("div", __assign({ className: "custom-link-leve-1", onClick: function () {
                                                                            return handleRouteChange(innerItem);
                                                                        } }, { children: _jsx("span", __assign({ className: "" }, { children: _jsx("a", { children: innerItem.title }) })) })) })), iconClass: innerItem.icon &&
                                                                    createIcon(innerItem.icon), disabled: innerItem.disabled, className: "".concat(setSelectedMenuItem(innerItem)
                                                                    ? "k-selected"
                                                                    : "") }, innerChildIndex));
                                                        }) }), innerIndex));
                                            }) }), index));
                                }) }))] })) })), _jsx(DialogBox, { headingText: logoutDialogContent.headingText, bodyText: logoutDialogContent.bodyText, cancelText: logoutDialogContent.cancelText, acceptText: logoutDialogContent.acceptText, isDialogOpen: modalState, onModalChangeState: onModalChangeState, acceptHandler: logOut })] }));
};
export default LeftMenu;
