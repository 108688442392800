var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import { useEffect, useState } from "react";
import "assets/css/grid.scss";
var GridRefresher = function (props) {
    var _a = useState(0), offsetTopPosition = _a[0], updateOffsetTopPosition = _a[1];
    var offsetPositionCalculate = function () {
        var pagerElement = document.getElementsByClassName("k-pager-info")[props.gridIndex || 0] || null;
        var pagerElementWithNoPadding = document.querySelectorAll(".general-grid.no-padding");
        var pagerElementWithTwoGrids = document.querySelectorAll(".employee-task-grid");
        if (pagerElement) {
            var pagerElementTopPosition = pagerElement.offsetTop;
            var extraSpacing = pagerElementWithNoPadding.length || pagerElementWithTwoGrids.length ? 17 : 21;
            updateOffsetTopPosition(pagerElementTopPosition + extraSpacing);
        }
    };
    useEffect(function () {
        offsetPositionCalculate();
        var resizeListener = function () {
            offsetPositionCalculate();
        };
        window.addEventListener("resize", resizeListener);
        return function () {
            window.removeEventListener("resize", resizeListener);
        };
    });
    return (_jsx("div", __assign({ className: "grid-refresher", style: { top: offsetTopPosition }, "data-testid": "refresh-button" }, { children: _jsx("a", __assign({ href: "javascript:void(0);", className: "k-pager-refresh k-link", title: "Refresh", "aria-label": "Refresh", onClick: props.refreshGridDataHandler }, { children: _jsx("span", { className: "k-icon k-i-reload" }) })) })));
};
export default GridRefresher;
