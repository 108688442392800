var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React, { useState } from 'react';
import { Form, Field, FormElement } from '@progress/kendo-react-form';
import { useNavigate } from 'react-router-dom';
import { PanelBar, PanelBarItem, } from "@progress/kendo-react-layout";
import { requiredValidator, Transform, TranslationConstant, FileUpload, AppConstant, DialogBox, useCurrentViewContext } from "./index";
export var NewYorkMinorWorkPermitForm = function (_a) {
    var newHireDocumentID = _a.newHireDocumentID;
    var _b = useCurrentViewContext(), setShowProgress = _b.setShowProgress, uploadNewYorkWorkPermit = _b.uploadNewYorkWorkPermit; // TODO -import your api and related functions here instead of these.
    var _c = useState(false), isUploadDocumentFailure = _c[0], updateDocumentFailureStatus = _c[1];
    var _d = useState(false), isFileUploadError = _d[0], updateFileUploadError = _d[1];
    var _e = useState(""), fileName = _e[0], setFileName = _e[1];
    var _f = useState(""), fileUrl = _f[0], setFileUrl = _f[1];
    var _g = useState(null), defaultUploadedFile = _g[0], setDefaultUploadedFile = _g[1];
    var _h = React.useState(false), modalState = _h[0], changeModalState = _h[1];
    var _j = React.useState({
        headingText: '',
        bodyText: '',
        isErrorDialog: false
    }), modalContent = _j[0], updateModalContent = _j[1];
    var Navigate = useNavigate();
    var hideModal = function () {
        changeModalState(false);
    };
    var acceptHandle = function () {
        changeModalState(false);
        Navigate("/employee");
    };
    var onUploadFailureHandler = function (event) {
        updateDocumentFailureStatus(true);
    };
    var onUploadCompleteHandler = function (event) {
        setShowProgress(true);
    };
    var onDocumentUploadFailure = function () {
        updateModalContent({
            headingText: _jsx(Transform, { mkey: TranslationConstant.UPDATE_FAILED }),
            bodyText: (_jsxs("div", __assign({ className: "block subheading" }, { children: [_jsx("i", { className: "fa fa-times-circle fa-lg" }), _jsx("span", { children: _jsx(Transform, { mkey: TranslationConstant.INCORRECT_UPLOADED_FILE }) })] }))),
            cancelText: _jsx(Transform, { mkey: TranslationConstant.CLOSE_BUTTON }),
            isErrorDialog: true
        });
        changeModalState(true);
    };
    var onDocumentUploadSucess = function () {
        updateModalContent({
            headingText: _jsx(Transform, { mkey: TranslationConstant.IMG_UPLOAD_SUCCESS_HEADING }),
            bodyText: (_jsxs("div", __assign({ className: "block subheading" }, { children: [_jsx("i", { className: "fa fa-check fa-lg" }), _jsx("span", { children: _jsx(Transform, { mkey: TranslationConstant.IMG_UPLOAD_SUCCESS }) })] }))),
            acceptText: _jsx(Transform, { mkey: TranslationConstant.RETURN_TO_DASHBOARD }),
            acceptHandler: acceptHandle,
            isErrorDialog: false
        });
        changeModalState(true);
    };
    var onFileUploadHandler = function (event) { return __awaiter(void 0, void 0, void 0, function () {
        var formData, response, e_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (!(event.newState[0].validationErrors && event.newState[0].validationErrors.length)) return [3 /*break*/, 1];
                    updateFileUploadError(true);
                    setShowProgress(false);
                    return [3 /*break*/, 5];
                case 1:
                    updateFileUploadError(false);
                    formData = new FormData();
                    formData.append('file', event.newState[0].getRawFile());
                    response = void 0;
                    _a.label = 2;
                case 2:
                    _a.trys.push([2, 4, , 5]);
                    return [4 /*yield*/, uploadNewYorkWorkPermit(formData, newHireDocumentID)];
                case 3:
                    response = _a.sent();
                    if (AppConstant.HTTP_SUCCESS_CODES.includes(response.status)) {
                        setFileName(response.data.fileName);
                        setFileUrl(response.data.fileUrl);
                        onDocumentUploadSucess();
                        updateDocumentFailureStatus(false);
                    }
                    else if (AppConstant.HTTP_BAD_REQUEST_CODES.includes(response.status) ||
                        AppConstant.HTTP_INTERNAL_SERVER_ERROR.includes(response.status)) {
                        updateDocumentFailureStatus(true);
                        onDocumentUploadFailure();
                    }
                    setShowProgress(false);
                    return [3 /*break*/, 5];
                case 4:
                    e_1 = _a.sent();
                    onDocumentUploadFailure();
                    setShowProgress(false);
                    return [3 /*break*/, 5];
                case 5: return [2 /*return*/];
            }
        });
    }); };
    return (_jsxs(_Fragment, { children: [_jsx("div", __assign({ className: "alert alert-danger mxb20 mb-2" }, { children: _jsx(Transform, { mkey: TranslationConstant.REQ_NEW_YORK_MINOR_WORK_DOC }) })), _jsx(Form, { render: function (formRenderProps) { return (_jsx(PanelBar, __assign({ className: "steps-pannel" }, { children: _jsx(PanelBarItem, __assign({ title: _jsxs(_Fragment, { children: [_jsxs("strong", { children: [_jsx(Transform, { mkey: TranslationConstant.STEP }), " - 1\u00A0", _jsx(Transform, { mkey: TranslationConstant.UPLOAD_FILE })] }), "\u00A0", _jsx(Transform, { mkey: TranslationConstant.CLICK_TO_EXPAND_TITLE })] }), expanded: true }, { children: _jsx(FormElement, __assign({ className: 'general-form' }, { children: _jsx("fieldset", __assign({ className: 'k-form-fieldset row pt-2' }, { children: _jsx(Field, { id: "documentUpload", name: "documentUpload", className: "col-xs-12 col-md-12 mb-3 ".concat(isFileUploadError ? '' : 'file-uploaded'), component: FileUpload, label: _jsx(Transform, { mkey: TranslationConstant.UPLOAD_DOC_CAL_WORK_PEMIT }), isRequired: true, defaultFiles: defaultUploadedFile, multiple: false, saveField: 'file', restrictions: {
                                        allowedExtensions: AppConstant.MANUALLY_DOCUMENT_UPLOAD_ALLOWED_FILE_EXTENSIONS,
                                    }, withCredentials: false, pathToUpload: null, onFileAdd: onFileUploadHandler, validator: requiredValidator, onUploadFailureOrError: onUploadFailureHandler, onUploadCompleteHandler: onUploadCompleteHandler }) })) })) })) }))); } }), modalState &&
                _jsx(DialogBox, { headingText: modalContent.headingText, bodyText: modalContent.bodyText, cancelText: modalContent.cancelText, isErrorDialog: modalContent.isErrorDialog, acceptText: modalContent.acceptText, acceptHandler: modalContent.acceptHandler, isDialogOpen: modalState, onModalChangeState: hideModal })] }));
};
