var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React from 'react';
import { Link } from "react-router-dom";
import { Grid, GridColumn as Column, } from "@progress/kendo-react-grid";
import { process } from "@progress/kendo-data-query";
import { Transform, AppConstant, ComponentConstant, TranslationConstant, GridRefresher, GridFilters } from "./index";
var initialDataState = { sort: [{ field: "document", dir: "asc" }], skip: 0, take: AppConstant.GRID_ITEM_TAKE_SIZE };
export var AdminTaskGrid = function (props) {
    var adminTasks = props.adminTasks, adminHeading = props.adminHeading;
    var _a = React.useState(initialDataState), dataState = _a[0], setDataState = _a[1];
    var _b = React.useState(), showFilter = _b[0], setShowHideFilter = _b[1];
    var _c = React.useState([]), filter = _c[0], setFilter = _c[1];
    var CompleteFormCell = function (props) {
        return (_jsx("td", __assign({ colSpan: props.colSpan, role: "gridcell", "aria-colindex": props.ariaColumnIndex, "aria-selected": props.isSelected }, { children: !props.dataItem['isFormCompleted'] &&
                _jsxs(Link, __assign({ to: "/documentview", state: { isDocumentForApprovalReq: true } }, { children: [_jsx("i", { className: "fa fa-edit fa-lg" }), ComponentConstant.COMPLETE_THIS_FORM] })) })));
    };
    var completeFormCustomCell = function (props) {
        return _jsx(CompleteFormCell, __assign({}, props));
    };
    return (_jsxs(_Fragment, { children: [_jsx("div", { className: "spacing-15" }), _jsxs("div", __assign({ className: "admin-panel-wrapper admin-agent-task" }, { children: [_jsx("div", __assign({ className: "stats-wrapper" }, { children: _jsx("div", __assign({ className: "row" }, { children: _jsx("div", __assign({ className: "col-sm-12 col-md-12 col-lg-12" }, { children: _jsxs("div", __assign({ className: "panel-stat3 bg-info clearfix bounceIn animation-delay4" }, { children: [_jsx("h5", { children: adminHeading }), _jsx("i", __assign({ className: "fa fa-arrow-circle-o-down" }, { children: "\u00A0\u00A0" })), _jsx("span", __assign({ className: "m-left-xs" }, { children: _jsx(Transform, { mkey: TranslationConstant.EMPLOYEE_BOARD_HEADING }) })), _jsx("div", __assign({ className: "stat-icon" }, { children: _jsx("i", { className: "fa fa-edit fa-2x" }) })), _jsx("div", __assign({ className: "loading-overlay" }, { children: _jsx("i", { className: "loading-icon fa fa-refresh fa-spin fa-lg" }) }))] })) })) })) })), _jsx("div", __assign({ className: "col-12 col-sm-12 col-lg-12" }, { children: _jsxs("div", __assign({ className: "admin-task-wrapper " }, { children: [_jsx("div", __assign({ className: "alert alert-info" }, { children: _jsx("strong", { children: _jsx(Transform, { mkey: TranslationConstant.FORMS_TO_COMPLETE_HEADING }) }) })), _jsx(GridFilters, { filter: filter, showFilter: showFilter, setShowHideFilter: setShowHideFilter }), _jsxs("div", __assign({ className: "general-grid no-padding" }, { children: [_jsxs(Grid, __assign({ sortable: true, filterable: showFilter, pageable: {
                                                buttonCount: AppConstant.BUTTON_COUNT_GRID,
                                                info: true,
                                                type: 'numeric',
                                                pageSizes: AppConstant.PAGE_SIZE,
                                                previousNext: true
                                            }, data: process(adminTasks, dataState) }, dataState, { onDataStateChange: function (e) {
                                                setDataState(e.dataState);
                                                setFilter(e.dataState.filter && e.dataState.filter.filters);
                                            } }, { children: [_jsx(Column, { field: "firstName", title: "First Name", width: "150px" }), _jsx(Column, { field: "lastName", title: "Last Name", width: "150px" }), _jsx(Column, { field: "document", title: "Document", width: "150px" }), _jsx(Column, { field: "", title: "Click to Edit", width: "150px", filterable: false, cell: completeFormCustomCell })] })), _jsx(GridRefresher, {})] }))] })) }))] }))] }));
};
