var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useEffect, useRef, useState } from "react";
import { useCurrentViewContext } from "store/common/currentViewContext";
import { AppConstant } from "common/constant/index";
import Spinner from "components/shared/Spinner";
import { TopHeader } from "components/shared/TopHeader";
import { getWindowDimensions, removeMenuSelection } from "components/shared/utils";
import { Footer } from "components/shared/Footer";
import LeftMenu from "components/shared/LeftMenu";
import SessionExtendDialog from "components/shared/SessionExtendDialog";
import "assets/css/panelbar.scss";
import { useNavigate } from "react-router";
var CorpMainPage = function (props) {
    var _a = useCurrentViewContext(), showProgress = _a.showProgress, leftMenuListingPro = _a.leftMenuListingPro, isLeftMenuLoaded = _a.isLeftMenuLoaded, fetchLeftMenuList = _a.fetchLeftMenuList, resetMenuLoadedState = _a.resetMenuLoadedState, setDefaultRoute = _a.setDefaultRoute;
    var navigate = useNavigate();
    var wrapperRef = useRef(null);
    var isCORPApplication = location.pathname.indexOf(AppConstant.CORP_BASE_PATHNAME) > -1;
    var localStorageKey = "".concat(AppConstant.LOCAL_STORAGE_USER_PROFILE, ":").concat(process.env.AUTHORITY_URL, ":").concat(process.env.CLIENT_ID, ":pro");
    var isLoggedInUser = localStorage.getItem(localStorageKey);
    var _b = useState(getWindowDimensions().width < 576 ? false : true), isStateOpen = _b[0], isStateOpenFn = _b[1];
    var _c = useState(false), sidebarMenu = _c[0], sidebarMenuToggler = _c[1];
    var _d = useState(false), isMenuLoaded = _d[0], setIsMenuLoaded = _d[1];
    var _e = useState("onboarding"), appName = _e[0], updateAppName = _e[1];
    useEffect(function () {
        var resizeListener = function () {
            isStateOpenFn(getWindowDimensions().width < 576 ? false : true);
        };
        window.addEventListener("resize", resizeListener);
        updateAppName(isCORPApplication ? "CORP" : "onboarding");
        return function () {
            window.removeEventListener("resize", resizeListener);
        };
    }, [isCORPApplication]);
    useEffect(function () {
        fetchMenuListing();
    }, [isLoggedInUser]);
    var fetchMenuListing = function () { return __awaiter(void 0, void 0, void 0, function () {
        var loggedInUserInfo, oidcStorage, roleId, applicationId, clientId, response, activeRoutesList, childIndex, index_1, defaultRoute, err_1;
        var _a, _b, _c;
        return __generator(this, function (_d) {
            switch (_d.label) {
                case 0:
                    if (!isLoggedInUser) return [3 /*break*/, 5];
                    loggedInUserInfo = JSON.parse(atob(isLoggedInUser));
                    oidcStorage = JSON.parse(localStorage.getItem("oidc.user:".concat(process.env.AUTHORITY_URL, ":").concat(process.env.CLIENT_ID)));
                    roleId = loggedInUserInfo && loggedInUserInfo.WebUserRoleId, applicationId = 1, clientId = loggedInUserInfo && loggedInUserInfo.ClientId;
                    _d.label = 1;
                case 1:
                    _d.trys.push([1, 3, 4, 5]);
                    return [4 /*yield*/, fetchLeftMenuList(roleId, applicationId, clientId)];
                case 2:
                    response = _d.sent();
                    activeRoutesList = response.data.filter(function (item) {
                        if (item.AccessTypeId !== AppConstant.NO_ACCESS_ROLE &&
                            item.IsActive &&
                            item.children.length === 0) {
                            return item;
                        }
                        else if (item.AccessTypeId !== AppConstant.NO_ACCESS_ROLE &&
                            item.IsActive &&
                            item.children.length > 0) {
                            var innerChildList = item.children.filter(function (inneritem) {
                                if (inneritem.AccessTypeId !== AppConstant.NO_ACCESS_ROLE)
                                    return inneritem;
                            });
                            item.children = innerChildList;
                            return item;
                        }
                    });
                    if (activeRoutesList.length > 0 &&
                        (window.location.pathname === "/" ||
                            window.location.pathname === AppConstant.CORP_BASE_PATHNAME ||
                            window.location.pathname === "".concat(AppConstant.CORP_BASE_PATHNAME, "/"))) {
                        childIndex = 0;
                        index_1 = localStorage.getItem("masterClientLoggedIn") ||
                            localStorage.getItem("adminClientLoggedIn")
                            ? 1
                            : 0;
                        defaultRoute = activeRoutesList[index_1].children.length
                            ? (_b = (_a = activeRoutesList[index_1]) === null || _a === void 0 ? void 0 : _a.children[childIndex]) === null || _b === void 0 ? void 0 : _b.route
                            : (_c = activeRoutesList[index_1]) === null || _c === void 0 ? void 0 : _c.route;
                        navigate(defaultRoute, { replace: false });
                        setDefaultRoute(defaultRoute);
                        localStorage.setItem("defaultLandingPagePRO", defaultRoute);
                        setTimeout(function () {
                            var _a;
                            removeMenuSelection();
                            var element = document.getElementsByClassName("k-panelbar-item")[index_1];
                            (_a = element === null || element === void 0 ? void 0 : element.classList) === null || _a === void 0 ? void 0 : _a.add("k-selected");
                        }, 50);
                    }
                    return [3 /*break*/, 5];
                case 3:
                    err_1 = _d.sent();
                    console.log(err_1);
                    return [3 /*break*/, 5];
                case 4:
                    setIsMenuLoaded(true);
                    return [7 /*endfinally*/];
                case 5: return [2 /*return*/];
            }
        });
    }); };
    var useOutsideAlerter = function (ref) {
        useEffect(function () {
            function handleClickOutside(event) {
                if (ref.current &&
                    !ref.current.contains(event.target) &&
                    getWindowDimensions().width < 576) {
                    isStateOpenFn(false);
                }
            }
            document.addEventListener("mousedown", handleClickOutside);
            return function () {
                document.removeEventListener("mousedown", handleClickOutside);
            };
        }, [ref]);
    };
    useOutsideAlerter(wrapperRef);
    var handleMenuChange = function () {
        isStateOpenFn(!isStateOpen);
    };
    var onLogoutHandler = function () {
        resetMenuLoadedState();
    };
    var handleSideBarToggle = function () {
        sidebarMenuToggler(!sidebarMenu);
    };
    return (_jsxs(_Fragment, { children: [_jsxs("div", __assign({ className: "main d-flex flex-column ".concat(!isStateOpen ? "no-menu" : sidebarMenu ? "sidebar-mini-open" : "") }, { children: [_jsx(TopHeader, { appname: appName, handleMenuChange: handleMenuChange, onLogoutHandler: onLogoutHandler }), _jsxs("div", __assign({ className: "viewport" }, { children: [_jsx("div", __assign({ className: "left-menu-wrapper corp-menu", ref: wrapperRef }, { children: _jsx(LeftMenu, { LeftMenuData: leftMenuListingPro, isStateOpen: isStateOpen, sidebarMenu: sidebarMenu, handleSideBarToggle: handleSideBarToggle, handleMenuChange: handleMenuChange, onLogoutHandler: onLogoutHandler }) })), _jsx("div", __assign({ className: "".concat(isStateOpen ? "main-container" : "col-12", " ").concat(sidebarMenu ? "sidebar-mini-menu" : "") }, { children: isMenuLoaded ? props.children : _jsx(Spinner, {}) }))] })), _jsx(Footer, {}), showProgress && _jsx(Spinner, {}), isLeftMenuLoaded === false && (_jsx("div", __assign({ className: "main-loader" }, { children: _jsx(Spinner, {}) })))] })), _jsx(SessionExtendDialog, {})] }));
};
export default CorpMainPage;
