import { httpPromise } from "services/common/httpPromise";
import { AppConstant, ServiceConstant } from "common/constant";
export var getClientDropDownList = function () {
    return httpPromise.get(ServiceConstant.CLIENT_DROPDOWN_LIST_PATH);
};
export var getAccountDropDownList = function (clientID, AccountDescriptionAllowed) {
    if (AccountDescriptionAllowed) {
        return httpPromise.get("".concat(ServiceConstant.ACCOUNT_DROPDOWN_LIST_PATH, "?ClientId=").concat(clientID, "&AccountDescriptionAllowed=").concat(AccountDescriptionAllowed));
    }
    else {
        return httpPromise.get("".concat(ServiceConstant.ACCOUNT_DROPDOWN_LIST_PATH, "/").concat(clientID));
    }
};
export var getWorkStateDropDownList = function () {
    return httpPromise.get(ServiceConstant.STATES_API_PATH);
};
export var getSupervisorList = function (clientID) {
    return httpPromise.get("".concat(ServiceConstant.CLIENT_USER_LIST_PATH, "/clientsupervisors/").concat(clientID));
};
export var getClientUserList = function (clientID) {
    return httpPromise.get("".concat(ServiceConstant.CLIENT_USER_LIST_PATH, "/").concat(clientID));
};
export var getUserStatusList = function () {
    return httpPromise.get(ServiceConstant.USER_STATUS_DROPDOWN_LIST_PATH);
};
export var getDocumentStatusDropDownList = function () {
    return httpPromise.get(ServiceConstant.DOCUMENT_STATUS_DROPDOWN_LIST_PATH);
};
export var getDocumentTypeDropDownList = function () {
    return httpPromise.get(ServiceConstant.DOCUMENT_TYPE_DROPDOWN_LIST_PATH);
};
export var getUploadDocumentTypeDropDownList = function (clientID) {
    return httpPromise.get("".concat(ServiceConstant.DOCUMENT_TYPE_DROPDOWN_LIST_PATH, "?clientID=").concat(clientID));
};
export var getJobStatusDropDownList = function () {
    return httpPromise.get(ServiceConstant.JOB_STATUS_DROPDOWN_LIST_PATH);
};
export var getUserRoleList = function () {
    return httpPromise.get(ServiceConstant.USER_ROLE_DROPDOWN_LIST_PATH);
};
export var getManageNewHireDocumentTypeList = function () {
    return httpPromise.get(ServiceConstant.MANAGE_NEW_HIRE_DOCUMENT_TYPE_API);
};
export var getLeftMenuList = function (RoleId, ApplicationId, ClientId, IsAccessClient) {
    if (IsAccessClient === void 0) { IsAccessClient = false; }
    return httpPromise.get("".concat(ServiceConstant.LEFT_MENU_API_PATH, "?RoleId=").concat(RoleId, "&ApplicationId=").concat(ApplicationId, "&ClientId=").concat(ClientId, "&IsAccessClient=").concat(IsAccessClient));
};
export var getLoggedInUserDetails = function (userName, ApplicationId, clientId) {
    var queryString = "";
    if (clientId) {
        queryString = "&ApplicationId=".concat(ApplicationId, "&clientId=").concat(clientId);
    }
    else {
        queryString = "&ApplicationId=".concat(ApplicationId);
    }
    return httpPromise.get("".concat(ServiceConstant.USER_DETAILS_API_PATH, "=").concat(userName).concat(queryString, "&IsUserLogin=true"));
};
export var getStateDropDownList = function () {
    return httpPromise.get(ServiceConstant.STATES_API_PATH);
};
export var getDocumentURLByKey = function (documentKey, isAddOnboardingFilePrefix, isAddAWSDirPathInBucket, applicationID) {
    if (isAddOnboardingFilePrefix === void 0) { isAddOnboardingFilePrefix = true; }
    if (isAddAWSDirPathInBucket === void 0) { isAddAWSDirPathInBucket = true; }
    var localStorageKey = localStorage.getItem("oidc.user:".concat(process.env.AUTHORITY_URL, ":").concat(process.env.CLIENT_ID));
    if (!applicationID) {
        applicationID = localStorageKey ? JSON.parse(localStorageKey).profile.ApplicationId : 2;
    }
    var rootUrl = applicationID == 1
        ? ServiceConstant.DOCUMENT_BY_KEY_API_CORP_PATH
        : ServiceConstant.DOCUMENT_BY_KEY_API_PATH;
    return httpPromise.get("".concat(rootUrl, "?key=").concat(documentKey, "&applicationID=").concat(applicationID, "&isAddOnboardingFilePrefix=").concat(isAddOnboardingFilePrefix, "&isAddAWSDirPathInBucket=").concat(isAddAWSDirPathInBucket));
};
export var sendEmailTemplate = function (dataObj) {
    return httpPromise.post(ServiceConstant.SEND_EMAIL_TEMPLATE_API_PATH, dataObj);
};
export var onboardingReports = function (dataObj) {
    return httpPromise.post(ServiceConstant.ONBOARDING_REPORT_DOWNLOAD_API_PATH, dataObj, {
        responseType: "blob",
    });
};
export var getManageNewHireDocumentStatusList = function () {
    return httpPromise.get(ServiceConstant.MANAGE_NEW_HIRE_DOCUMENT_STATUS);
};
export var getSearchnewhireDocumentList = function (queryString) {
    return httpPromise.get("".concat(ServiceConstant.SEARCH_NEW_HIRE_DOCUMENTS_API_LIST, "?").concat(queryString));
};
export var getNewHireJobIDByEmpId = function (indexNumber) {
    return httpPromise.get("".concat(ServiceConstant.NEW_HIRE_JOB_ID_API_PATH, "?newHireEmployeeId=").concat(indexNumber));
};
export var postSendEmailTemplate = function (EmailObj) {
    return httpPromise.post(ServiceConstant.SEND_EMAIL_TEMPLATE_API_PATH, EmailObj);
};
export var getExpiredVisaList = function () {
    return httpPromise.get(ServiceConstant.EXPIRED_VISA_LIST_API_PATH);
};
export var getViewHRDocumentList = function (clientId) {
    var applicationID = JSON.parse(localStorage.getItem("oidc.user:".concat(process.env.AUTHORITY_URL, ":").concat(process.env.CLIENT_ID))).profile.ApplicationId;
    var Data = JSON.parse(localStorage.getItem("oidc.user:".concat(process.env.AUTHORITY_URL, ":").concat(process.env.CLIENT_ID)));
    return httpPromise.get("".concat(ServiceConstant.VIEW_HR_DOCUMENTS_API_PATH, "?clientID=").concat(clientId, "&applicationID=").concat(applicationID));
};
export var uploadNewHRDocument = function (formData) {
    var applicationID = JSON.parse(localStorage.getItem("oidc.user:".concat(process.env.AUTHORITY_URL, ":").concat(process.env.CLIENT_ID))).profile.ApplicationId;
    return httpPromise.post("".concat(ServiceConstant.HR_DOCUMENT_FILE_UPLOAD_API_PATH, "?applicationID=").concat(applicationID), formData);
};
export var submitHRDocument = function (formData) {
    return httpPromise.post(ServiceConstant.HR_DOCUMENT_FILE_SUBMIT_API_PATH, formData);
};
export var updateHRDocument = function (formData) {
    return httpPromise.patch(ServiceConstant.HR_DOCUMENT_FILE_SUBMIT_API_PATH, formData);
};
export var viewHRDocumentById = function (hrDocId) {
    return httpPromise.get("".concat(ServiceConstant.HR_DOCUMENT_FILE_SUBMIT_API_PATH, "/").concat(hrDocId));
};
export var getEmployeeInformation = function (newHireDocumentID) {
    return httpPromise.get("".concat(ServiceConstant.GET_EMPLOYEE_INFORMATION_API_PATH, "?newHireDocumentID=").concat(newHireDocumentID));
};
export var getAdminPayreelTasks = function () {
    return httpPromise.get(ServiceConstant.GET_ADMIN_PAYREEL_TASK);
};
export var newHireDocForExternalAgent = function () {
    return httpPromise.get(ServiceConstant.NEW_HIRE_DOCUMENT_EXTERNAL_AGENT);
};
export var getAdminAgentTasks = function () {
    return httpPromise.get(ServiceConstant.ADMIN_AGENT_TASKS_API);
};
export var sendEmail = function (dataObj) {
    return httpPromise.post(ServiceConstant.SEND_EMAIL_API_PATH, dataObj);
};
export var getCorpDocumentBykey = function (queryString) {
    return httpPromise.get("".concat(ServiceConstant.CORP_DOCUMENT_API_PATH, "/GetFileByteArray?").concat(queryString));
};
export var corpReports = function (dataObj) {
    return httpPromise.post("".concat(ServiceConstant.CORP_REPORT_DOWNLOAD_API_PATH, "/GetCorpReportAsync"), dataObj, {
        timeout: AppConstant.AXIOS_API_TIMEOUT,
    });
};
export var newHireDocumentFileInfo = function (newHireDocumentID) {
    return httpPromise.get("".concat(ServiceConstant.NEW_HIRE_DOCUMENT_API_PATH, "/newHireDocumentFileInfo?newHireDocumentID=").concat(newHireDocumentID));
};
export var getAccountsList = function (clientId, descriptionAllowed) {
    return httpPromise.get("".concat(ServiceConstant.CLIENT_DIVISION_LIST_API, "/GetAccounts?clientID=").concat(clientId, "&accountDescriptionAllowed=").concat(descriptionAllowed));
};
export var getSubAccounts = function (queryString) {
    return httpPromise.get("".concat(ServiceConstant.CORP_SUB_ACCOUNTS_API_PATH, "/GetSubAccounts?").concat(queryString));
};
export var getVzPagedSubAccountsAsync = function (queryString) {
    return httpPromise.get("".concat(ServiceConstant.CORP_SUB_ACCOUNTS_API_PATH, "/GetVzPagedSubAccountsAsync?").concat(queryString));
};
export var getCountyDropDownList = function (stateId) {
    return httpPromise.get("".concat(ServiceConstant.COUNTY_API_PATH, "?stateID=").concat(stateId));
};
export var getClientsNameList = function (clientID, searchText, ifClient) {
    if (ifClient) {
        return httpPromise.get("".concat(ServiceConstant.CORP_SUB_ACCOUNTS_API_PATH, "/GetVzSubAccountClientList?clientID=").concat(clientID, "&inputText=").concat(searchText));
    }
    else {
        return httpPromise.get("".concat(ServiceConstant.CORP_SUB_ACCOUNTS_API_PATH, "/GetVzSubAccountContactList?clientID=").concat(clientID, "&inputText=").concat(searchText));
    }
};
export var uploadProjectDocument = function (formData) {
    return httpPromise.post("".concat(ServiceConstant.UPLOAD_PROJECT_FILE_API_PATH, "/UploadProjects"), formData);
};
export var getClientInvoicingDetail = function (clientID) {
    return httpPromise.get("".concat(ServiceConstant.GET_CLIENT_INVOICING_API_PATH, "/GetClientInvoicingDetail?clientID=").concat(clientID));
};
export var getEmployeeList = function (clientId) {
    return httpPromise.get("".concat(ServiceConstant.CORP_EMPLOYEE_API_PATH, "/GetEmployees?clientId=").concat(clientId));
};
export var getRExportList = function (clientID) {
    return httpPromise.get("".concat(ServiceConstant.GET_AR_EXPORT_API_PATH, "/GetARExport?clientId=").concat(clientID));
};
export var getVendorsList = function (clientId) {
    return httpPromise.get("".concat(ServiceConstant.SEARCH_VENDORS_API_PATH, "/GetVendors?clientId=").concat(clientId));
};
export var addFlashMessageHistory = function (flashMessageID) {
    return httpPromise.post("".concat(ServiceConstant.CORP_API_BASE_PATH, "/FlashMessage/AddFlashMessageHistory?flashMessageID=").concat(flashMessageID));
};
export var getBatchList = function (clientId) {
    return httpPromise.get("".concat(ServiceConstant.GET_BATCH_API_PATH, "/GetBatches?clientId=").concat(clientId));
};
export var uploadIngestReportJson = function (formData) {
    return httpPromise.post("".concat(ServiceConstant.REPORT_API_PATH, "/UploadReportAsync"), formData, {
        timeout: AppConstant.BULK_IMPORT_API_TIMEOUT,
    });
};
export var archiveEmployeeNote = function (queryFormData) {
    return httpPromise.delete("".concat(ServiceConstant.CORP_EMPLOYEE_API_PATH, "/ArchiveEmployeeNote?").concat(queryFormData));
};
export var postEmployeeNote = function (groupData) {
    return httpPromise.post("".concat(ServiceConstant.CORP_EMPLOYEE_API_PATH, "/PostEmployeeNote"), groupData);
};
export var getEmployeeNotes = function (queryString) {
    return httpPromise.get("".concat(ServiceConstant.CORP_EMPLOYEE_API_PATH, "/GetEmployeeNotes?").concat(queryString));
};
export var getViewEmployeeArchiveNotes = function (employeeId) {
    return httpPromise.get("".concat(ServiceConstant.CORP_EMPLOYEE_API_PATH, "/ViewEmployeeArchiveNotes?employeeId=").concat(employeeId));
};
export var getDarwinInformation = function (employeeId) {
    return httpPromise.get("".concat(ServiceConstant.CORP_EMPLOYEE_API_PATH, "/GetEmployeeDarwinInfo?employeeId=").concat(employeeId));
};
export var getDarwinEmployeePaystub = function (queryString) {
    return httpPromise.get("".concat(ServiceConstant.CORP_EMPLOYEE_API_PATH, "/GetEmployeeDarwinPaystubs?").concat(queryString));
};
export var getEmployeementAgreements = function (queryString) {
    return httpPromise.get("".concat(ServiceConstant.CORP_EMPLOYEE_API_PATH, "/GetEmployeeAgreements?").concat(queryString));
};
export var postEmployeementAgreements = function (groupData) {
    return httpPromise.post("".concat(ServiceConstant.CORP_EMPLOYEE_API_PATH, "/PostEmployeeAgreement"), groupData);
};
export var processTempEmpAgreements = function (groupData) {
    return httpPromise.post("".concat(ServiceConstant.CORP_EMPLOYEE_API_PATH, "/ProcessTempAgreementToEmployee"), groupData);
};
export var getEmpAgreementPositions = function (queryString) {
    return httpPromise.get("".concat(ServiceConstant.CORP_EMPLOYEE_API_PATH, "/GetEmployeePositions?").concat(queryString));
};
export var getClientAccountsList = function (clientId, isOracleLedger, filterText) {
    if (isOracleLedger) {
        return httpPromise.get("".concat(ServiceConstant.CLIENT_DIVISION_LIST_API, "/GetClientAccounts?clientID=").concat(clientId, "&isOracleLedger=").concat(isOracleLedger, "&filterText=").concat(filterText));
    }
    else {
        return httpPromise.get("".concat(ServiceConstant.CLIENT_DIVISION_LIST_API, "/GetClientAccounts?clientID=").concat(clientId));
    }
};
export var getSubAccountList = function (clientId) {
    return httpPromise.get("".concat(ServiceConstant.CORP_SUB_ACCOUNTS_API_PATH, "/GetSubAccountsByClientID?clientID=").concat(clientId));
};
export var getVendorsListByClientId = function (clientId) {
    return httpPromise.get("".concat(ServiceConstant.SEARCH_VENDORS_API_PATH, "/GetVendorsByClientID?clientID=").concat(clientId));
};
export var getAccountsOracleSearch = function (queryString) {
    return httpPromise.get("".concat(ServiceConstant.CLIENT_DIVISION_LIST_API, "/SearchAccountsOracle?").concat(queryString));
};
export var getGridsAccountData = function (queryString) {
    return httpPromise.get("".concat(ServiceConstant.CLIENT_DIVISION_LIST_API, "/GetAccountData?").concat(queryString));
};
export var getTimeSheetDays = function (queryString) {
    return httpPromise.get("".concat(ServiceConstant.CORP_TIMESHEET_API_PATH, "/GetTimeSheetDays?").concat(queryString));
};
export var getEmployeesForEmployeeEarning = function (clientId) {
    return httpPromise.get("".concat(ServiceConstant.CORP_EMPLOYEE_API_PATH, "/GetEmployeesForEmployeeEarning?clientID=").concat(clientId));
};
export var getMasterClients = function (clientId) {
    return httpPromise.get("".concat(ServiceConstant.CORP_WORK_ORDERS_API_PATH, "/GetQVCWorkOrders?clientID=").concat(clientId));
};
export var updateQVCWorkOrder = function (qvcWorkOrderID, dataobj) {
    return httpPromise.post("".concat(ServiceConstant.CORP_WORK_ORDERS_API_PATH, "/SaveQVCWorkOrder?qVCWorkOrderID=").concat(qvcWorkOrderID), dataobj);
};
export var getQVCWorkOrderDetails = function (queryString) {
    return httpPromise.get("".concat(ServiceConstant.CORP_WORK_ORDERS_API_PATH, "/GetQVCWorkOrderDetails?").concat(queryString));
};
export var setWorkOrderCompleteStatus = function (qvcWorkOrderID) {
    return httpPromise.put("".concat(ServiceConstant.CORP_WORK_ORDERS_API_PATH, "/SetWorkOrderCompleteStatus?qVCWorkOrderID=").concat(qvcWorkOrderID));
};
export var getActiveStates = function () {
    return httpPromise.get("".concat(ServiceConstant.CORP_STATE_API_PATH, "/GetActiveStates"));
};
export var getSelectedEmployeeInfo = function (employeeId, isAdminLogged, ignoreEmployeeRepeatI9) {
    return httpPromise.get("".concat(ServiceConstant.CORP_EMPLOYEE_API_PATH, "/").concat(employeeId, "?isAdminLogged=").concat(isAdminLogged, "&ignoreEmployeeRepeatI9=").concat(ignoreEmployeeRepeatI9));
};
export var updateQVCWorkOrderDetail = function (qVCWorkOrderDetailID, dataobj) {
    return httpPromise.post("".concat(ServiceConstant.CORP_WORK_ORDER_DETAIL_API_PATH, "/SaveQVCWorkOrderDetail?qVCWorkOrderDetailID=").concat(qVCWorkOrderDetailID), dataobj);
};
export var getQVCWorkOrderDetailById = function (qvcWorkOrderDetailID) {
    return httpPromise.get("".concat(ServiceConstant.CORP_WORK_ORDER_DETAIL_API_PATH, "/GetQVCWorkOrderDetail?qVCWorkOrderDetailID=").concat(qvcWorkOrderDetailID));
};
export var deleteQVCWorkOrderDetail = function (qVCWorkOrderDetailID) {
    return httpPromise.delete("".concat(ServiceConstant.CORP_WORK_ORDER_DETAIL_API_PATH, "/DeleteQVCWorkOrderDetail?qVCWorkOrderDetailID=").concat(qVCWorkOrderDetailID));
};
export var autoGenQVCTimeSheets = function (dataObj) {
    return httpPromise.post("".concat(ServiceConstant.CORP_WORK_ORDERS_API_PATH, "/AutoGenQVCTimeSheets"), dataObj, {
        timeout: AppConstant.AXIOS_API_TIMEOUT_FOR_10_MINS,
    });
};
export var getStatus30ReportData = function (queryString) {
    return httpPromise.get("".concat(ServiceConstant.CORP_TIMESHEET_API_PATH, "/GetStatus30ReportData?").concat(queryString));
};
export var getQVCWorkOrder = function (qVCWorkOrderID) {
    return httpPromise.get("".concat(ServiceConstant.CORP_WORK_ORDERS_API_PATH, "/GetQVCWorkOrder?qVCWorkOrderID=").concat(qVCWorkOrderID));
};
export var getInvoiceCount = function (masarExportID) {
    return httpPromise.get("".concat(ServiceConstant.CORP_INVOICING_API_PATH, "/GetInvoiceCount?arExportId=").concat(masarExportID));
};
export var getAdminReportList = function () {
    return httpPromise.get("".concat(ServiceConstant.REPORT_API_PATH, "/GetAdminReportList"));
};
export var getActiveClientsList = function () {
    return httpPromise.get("".concat(ServiceConstant.CORP_CLIENT_BASE_API_PATH, "/ActiveClients"));
};
export var saveReturnTimesheetToClient = function (queryString, eventType) {
    var apiUrl = "".concat(ServiceConstant.CORP_API_BASE_PATH);
    switch (eventType) {
        case "timesheet": {
            apiUrl += "/Timesheet/ReturnTimesheetToClient?".concat(queryString);
            break;
        }
        case "expense": {
            apiUrl += "/ExpenseReports/ReturnExpenseToClient?".concat(queryString);
            break;
        }
        case "vendor": {
            apiUrl += "/Vendor/ReturnVendorPaymentToClient?".concat(queryString);
            break;
        }
        case "manual": {
            apiUrl += "/ManualPayments/ReturnManualPaymentToClient?".concat(queryString);
            break;
        }
        default:
            break;
    }
    return httpPromise.put(apiUrl);
};
export var downloadExport = function () {
    return httpPromise.get("".concat(ServiceConstant.CORP_API_BASE_PATH, "/SymmetryPayrollPointBatchExport/DownloadExportsForSymmetryPayrollPoint"));
};
export var getActiveEndevourClients = function () {
    return httpPromise.get("".concat(ServiceConstant.CORP_CLIENT_BASE_API_PATH, "/GetActiveEndevourClients"));
};
export var getRetirementPlanEligibleStates = function () {
    return httpPromise.get("".concat(ServiceConstant.REPORT_API_PATH, "/GetRetirementPlanEligibleStates"));
};
export var getRetirementPlanEligibleYears = function () {
    return httpPromise.get("".concat(ServiceConstant.REPORT_API_PATH, "/GetRetirementPlanEligibleYears"));
};
export var getPerformanceComplianceItems = function (clientId) {
    return httpPromise.get("".concat(ServiceConstant.NEW_HIRE_DOCUMENT_TYPE_CORP_API_BASE_PATH, "/GetPerformanceComplianceItems?clientId=").concat(clientId));
};
export var getNewHireJobsByClientId = function (clientId) {
    return httpPromise.get("".concat(ServiceConstant.NEW_HIRE_JOB_CORP_API_BASE_PATH, "/GetNewHireJobsByClientId?clientId=").concat(clientId));
};
export var getEmployeeByClientList = function (clientId) {
    return httpPromise.get("".concat(ServiceConstant.CORP_API_BASE_PATH, "/Employee/GetEmployeeByClientList?clientId=").concat(clientId));
};
export var getVendorsByClientList = function (clientId) {
    return httpPromise.get("".concat(ServiceConstant.CORP_API_BASE_PATH, "/Vendor/GetVendorsByClientList?clientId=").concat(clientId));
};
export var getAccountsByClientList = function (clientId) {
    return httpPromise.get("".concat(ServiceConstant.CORP_API_BASE_PATH, "/Account/GetAccountByClientList?clientId=").concat(clientId));
};
export var getSecretDataFields = function () {
    return httpPromise.get("".concat(ServiceConstant.CORP_API_BASE_PATH, "/SecretDataFieldMapping/GetSecretDataFields"));
};
export var getActiveLogos = function () {
    return httpPromise.get("".concat(ServiceConstant.PAYREEL_LOGOS_API_PATH, "/getActiveLogos"));
};
export var editClientInvoicingDetail = function (dataObject) {
    return httpPromise.put("".concat(ServiceConstant.GET_CLIENT_INVOICING_API_PATH, "/EditClientInvoicingDetail"), dataObject);
};
export var addClientWithInvoicingDetail = function (dataObject) {
    return httpPromise.post("".concat(ServiceConstant.GET_CLIENT_INVOICING_API_PATH, "/AddClientWithInvoicingDetail"), dataObject);
};
export var GetEmployeeExpensesReportHighLowPerDiemCsvData = function (accountID) {
    return httpPromise.get("".concat(ServiceConstant.CORP_EXPENSE_REPORTS_API_PATH, "/GetEmployeeExpensesReportHighLowPerDiemCsvData?accountID=").concat(accountID));
};
export var DownloadEmployeeExpensesReportHighLowPerDiemCsv = function (accountID) {
    return httpPromise.get("".concat(ServiceConstant.CORP_EXPENSE_REPORTS_API_PATH, "/DownloadEmployeeExpensesReportHighLowPerDiemCsv?accountID=").concat(accountID));
};
export var getInvoicedDeliveryDetails = function (clientID) {
    return httpPromise.get("".concat(ServiceConstant.GET_CLIENT_INVOICING_API_PATH, "/GetInvoicedDeliveryDetails?clientID=").concat(clientID));
};
export var checkActiveRecords = function (clientInvoicingDetailId) {
    return httpPromise.get("".concat(ServiceConstant.INVOICE_DELIVERY_SETTING_API_PATH, "/CheckActiveRecords?clientInvoicingDetailId=").concat(clientInvoicingDetailId));
};
export var getInvoiceReports = function (clientId) {
    return httpPromise.get("".concat(ServiceConstant.INVOICE_DETAIL_REPORT_API_PATH, "/GetReports?clientId=").concat(clientId));
};
export var saveInvoiceDeliveryDetail = function (dataObject) {
    return httpPromise.post("".concat(ServiceConstant.INVOICE_DELIVERY_SETTING_API_PATH, "/SaveInvoiceDeliveryDetail?clientId"), dataObject);
};
export var deleteInvoiceDeliveryDetail = function (invoiceDeliverySettingID, webUserId) {
    return httpPromise.delete("".concat(ServiceConstant.INVOICE_DELIVERY_SETTING_API_PATH, "/deleteInvoiceDeliveryDetail?invoiceDeliverySettingID=").concat(invoiceDeliverySettingID, "&webUserId=").concat(webUserId));
};
export var getInvoiceEmailCategoryTypes = function () {
    return httpPromise.get("".concat(ServiceConstant.INVOICE_EMAIL_CATEGORY_TYPE, "/GetInvoiceEmailCategoryTypes"));
};
export var validateMaxAndMinAmountAsync = function (dataObject) {
    return httpPromise.get("".concat(ServiceConstant.INVOICE_DELIVERY_SETTING_API_PATH, "/ValidateMaxAndMinAmountAsync?minAmount=").concat(dataObject.minAmount, "&maxAmount=").concat(dataObject.maxAmount, "&invoiceDeliverySettingID=").concat(dataObject.invoiceDeliverySettingID, "&clientInvoicingInfoID=").concat(dataObject.clientInvoicingInfoID));
};
export var getAllClientsCoordinators = function () {
    return httpPromise.get("".concat(ServiceConstant.CORP_PAYREEL_USERS_BASE_API_PATH, "/GetAllClientsCoordinators"));
};
export var getClientReportsMapping = function (clientId) {
    return httpPromise.get("".concat(ServiceConstant.REPORT_API_PATH, "/GetClientReportsMapping?clientId=").concat(clientId, "&IsPayreelReport=false"));
};
export var getPayreelReportsMapping = function (clientId) {
    return httpPromise.get("".concat(ServiceConstant.REPORT_API_PATH, "/GetClientReportsMapping?clientId=").concat(clientId, "&IsPayreelReport=true"));
};
export var checkIfMultipleInvoiceIsAllowed = function (clientID) {
    return httpPromise.get("".concat(ServiceConstant.GET_CLIENT_INVOICING_API_PATH, "/CheckIfMultipleInvoiceIsAllowed?clientId=").concat(clientID));
};
export var updateClientReportsMapping = function (dataObj, clientId) {
    return httpPromise.post("".concat(ServiceConstant.REPORT_API_PATH, "/UpdateClientReportsMapping?clientId=").concat(clientId), dataObj);
};
export var getPerformanceReportGraphDetails = function (formDataObj) {
    return httpPromise.post("".concat(ServiceConstant.REPORT_API_PATH, "/GetPerformanceReportGraph"), formDataObj);
};
export var getNepWSLogCompanyDivisions = function () {
    return httpPromise.get("".concat(ServiceConstant.REPORT_API_PATH, "/GetNepWSLogCompanyDivisions"));
};
