var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useEffect } from "react";
import { useCurrentViewContext } from "store/common/currentViewContext";
import { Transform } from "helper/Transform";
import { TranslationConstant } from "common/constant";
import { DialogBox } from "components/shared/DialogBox";
var ErrorOnApiFailure = function (props) {
    var _a = useCurrentViewContext(), setApiFailure = _a.setApiFailure, setShowProgress = _a.setShowProgress;
    var _b = React.useState(false), modalState = _b[0], changeModalState = _b[1];
    useEffect(function () {
        changeModalState(props.isApiFailure);
    }, [props]);
    var hideModal = function () {
        changeModalState(false);
        setApiFailure(false);
        setShowProgress(false);
    };
    return (_jsx(DialogBox, { customClass: "error-api-dialog-box", headingText: "An Error Occurred", bodyText: _jsxs("div", __assign({ className: "block subheading" }, { children: [_jsx("i", { className: "fa fa-times-circle fa-lg" }), _jsx("span", { children: "An error occurred." }), _jsxs("span", __assign({ className: "block" }, { children: ["Please contact PayReel administrator at ", process.env.SUPPORT_EMAIL, "."] }))] })), cancelText: _jsx(Transform, { mkey: TranslationConstant.CLOSE_BUTTON }), isErrorDialog: true, isDialogOpen: modalState, onModalChangeState: hideModal }));
};
export default ErrorOnApiFailure;
