var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { create } from "zustand";
import { sendEmailTemplate } from "services/common";
import { ChangeI9VerifierDetails, FedralI9AgentSignAndSave, getClientData, I9ReverificationApproveBtn, getClientUserData, getAllClientsByMasterWebUserId, } from "services/i9reverification/i9reverification";
import { getI9ReverificationNewClients, getI9ReverificationExistingClients, I9ReverificationDeleteClients, GetClientsByMasterWebUserId, UpdateStartDateExistingClient, UpdateExistingClient, i9ReverificationGetApprovals, FedralI9SignAndSave, FedralI9ListData, SaveI9ProfilePic, deleteSupportingDocument, } from "services/i9reverification";
var createi9EmailTemplate = function (fullname, emailAddress, clientName) {
    return {
        UserName: "",
        FirstName: fullname.split(" ")[0],
        LastName: fullname.split(" ")[1],
        EmailAddress: emailAddress,
        ClientName: clientName,
        PrimaryWorkStateId: 0,
        NotificationCode: "Email To Agents For Repeat I9 Approval",
        RedirectURI: "",
        Parameters: {},
    };
};
export var useI9VerificationViewContext = create(function (set, get) { return ({
    i9ReverificationNewClientList: [],
    i9ReverificationExistingClientList: [],
    i9clientDropDownList: [],
    i9ApprovalList: [],
    i9PendingList: [],
    fedralI9ListData: [],
    clientData: {},
    clientListByMasterWebUser: [],
    fetchI9ReverificationNewClients: function () { return __awaiter(void 0, void 0, void 0, function () {
        var response;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, getI9ReverificationNewClients()];
                case 1:
                    response = _a.sent();
                    set(function (state) { return (__assign(__assign({}, state), { i9ReverificationNewClientList: response.data })); });
                    return [2 /*return*/];
            }
        });
    }); },
    fetchI9ReverificationExistingClients: function () { return __awaiter(void 0, void 0, void 0, function () {
        var response;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, getI9ReverificationExistingClients()];
                case 1:
                    response = _a.sent();
                    set(function (state) { return (__assign(__assign({}, state), { i9ReverificationExistingClientList: response.data })); });
                    return [2 /*return*/];
            }
        });
    }); },
    deleteI9ReverificationClient: function (employeeRepeateI9ID) { return __awaiter(void 0, void 0, void 0, function () {
        var response;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, I9ReverificationDeleteClients(employeeRepeateI9ID)];
                case 1:
                    response = _a.sent();
                    return [2 /*return*/, response];
            }
        });
    }); },
    getClientsByMasterWebUserId: function (masterWebUserId) { return __awaiter(void 0, void 0, void 0, function () {
        var response;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, GetClientsByMasterWebUserId(masterWebUserId)];
                case 1:
                    response = _a.sent();
                    set(function (state) { return (__assign(__assign({}, state), { clientListByMasterWebUser: response.data })); });
                    return [2 /*return*/];
            }
        });
    }); },
    updateClientStartDate: function (employeeRepeateI9ID, date) { return __awaiter(void 0, void 0, void 0, function () {
        var response;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, UpdateStartDateExistingClient(employeeRepeateI9ID, date)];
                case 1:
                    response = _a.sent();
                    return [2 /*return*/, response];
            }
        });
    }); },
    updateExistingClient: function (dataObject) { return __awaiter(void 0, void 0, void 0, function () {
        var response, emailTemplate, emailTemplate, emailTemplate;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, UpdateExistingClient(dataObject)];
                case 1:
                    response = _a.sent();
                    if (response.data.sendEmailToAgents) {
                        if (response.data.primaryAgentEmailAddress !== null &&
                            response.data.backupAgentEmailAddress !== null &&
                            response.data.primaryAgentEmailAddress ===
                                response.data.backupAgentEmailAddress &&
                            response.data.primaryAgentFullName !== null) {
                            emailTemplate = createi9EmailTemplate(response.data.primaryAgentFullName, response.data.primaryAgentEmailAddress, response.data.clientName);
                            sendEmailTemplate(emailTemplate);
                        }
                        else if (response.data.primaryAgentEmailAddress !== null &&
                            response.data.backupAgentEmailAddress !== null &&
                            response.data.primaryAgentEmailAddress !== response.data.backupAgentEmailAddress) {
                            if (response.data.primaryAgentEmailAddress !== null &&
                                response.data.primaryAgentFullName !== null) {
                                emailTemplate = createi9EmailTemplate(response.data.primaryAgentFullName, response.data.primaryAgentEmailAddress, response.data.clientName);
                                sendEmailTemplate(emailTemplate);
                            }
                            if (response.data.backupAgentEmailAddress !== null &&
                                response.data.backupAgentFullName !== null) {
                                emailTemplate = createi9EmailTemplate(response.data.backupAgentFullName, response.data.backupAgentEmailAddress, response.data.clientName);
                                sendEmailTemplate(emailTemplate);
                            }
                        }
                    }
                    return [2 /*return*/, response];
            }
        });
    }); },
    sendEmailToAgent: function (emailTemplate) { return __awaiter(void 0, void 0, void 0, function () {
        var response;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, sendEmailTemplate(emailTemplate)];
                case 1:
                    response = _a.sent();
                    return [2 /*return*/, response];
            }
        });
    }); },
    fetchi9ReverificationGetApprovals: function (listType) { return __awaiter(void 0, void 0, void 0, function () {
        var response;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, i9ReverificationGetApprovals(listType)];
                case 1:
                    response = _a.sent();
                    if (listType === 1) {
                        set(function (state) { return (__assign(__assign({}, state), { i9PendingList: response.data })); });
                    }
                    else {
                        set(function (state) { return (__assign(__assign({}, state), { i9ApprovalList: response.data })); });
                    }
                    return [2 /*return*/];
            }
        });
    }); },
    i9ReverificationApproveBtn: function (employeeRepeatI9ID) { return __awaiter(void 0, void 0, void 0, function () {
        var response;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, I9ReverificationApproveBtn(employeeRepeatI9ID)];
                case 1:
                    response = _a.sent();
                    return [2 /*return*/, response];
            }
        });
    }); },
    ChangeI9VerifierDetails: function (paramsObj) { return __awaiter(void 0, void 0, void 0, function () {
        var response;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, ChangeI9VerifierDetails(paramsObj)];
                case 1:
                    response = _a.sent();
                    return [2 /*return*/, response];
            }
        });
    }); },
    putFedralI9SignAndSave: function (fedralI9DataObj) { return __awaiter(void 0, void 0, void 0, function () {
        var response;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, FedralI9SignAndSave(fedralI9DataObj)];
                case 1:
                    response = _a.sent();
                    return [2 /*return*/, response];
            }
        });
    }); },
    getFedralI9ListData: function () { return __awaiter(void 0, void 0, void 0, function () {
        var response;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, FedralI9ListData()];
                case 1:
                    response = _a.sent();
                    set(function (state) { return (__assign(__assign({}, state), { fedralI9ListData: response.data })); });
                    return [2 /*return*/];
            }
        });
    }); },
    postSaveI9ProfilePic: function (formData) { return __awaiter(void 0, void 0, void 0, function () {
        var response;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, SaveI9ProfilePic(formData)];
                case 1:
                    response = _a.sent();
                    return [2 /*return*/, response];
            }
        });
    }); },
    deleteSupportingDoc: function (newHireSupportingDocumentID) { return __awaiter(void 0, void 0, void 0, function () {
        var response;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, deleteSupportingDocument(newHireSupportingDocumentID)];
                case 1:
                    response = _a.sent();
                    return [2 /*return*/, response];
            }
        });
    }); },
    getClientData: function (clientId) { return __awaiter(void 0, void 0, void 0, function () {
        var response;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, getClientData(clientId)];
                case 1:
                    response = _a.sent();
                    set(function (state) { return (__assign(__assign({}, state), { clientData: response.data })); });
                    return [2 /*return*/, response];
            }
        });
    }); },
    putFedralI9AgentSignAndSave: function (fedralI9DataObj) { return __awaiter(void 0, void 0, void 0, function () {
        var response;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, FedralI9AgentSignAndSave(fedralI9DataObj)];
                case 1:
                    response = _a.sent();
                    return [2 /*return*/, response];
            }
        });
    }); },
    fetchclientuser: function (clientId) { return __awaiter(void 0, void 0, void 0, function () {
        var response;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, getClientUserData(clientId)];
                case 1:
                    response = _a.sent();
                    return [2 /*return*/, response];
            }
        });
    }); },
    getAllClientsByMasterWebUserId: function (masterWebUserId) { return __awaiter(void 0, void 0, void 0, function () {
        var response;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, getAllClientsByMasterWebUserId(masterWebUserId)];
                case 1:
                    response = _a.sent();
                    set(function (state) { return (__assign(__assign({}, state), { i9clientDropDownList: response.data })); });
                    return [2 /*return*/];
            }
        });
    }); },
}); });
