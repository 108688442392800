var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React from "react";
import { Form, Field, FormElement } from "@progress/kendo-react-form";
import { useNavigate } from "react-router-dom";
import { Transform, ComponentConstant, TranslationConstant, FormInput, FormHeading, FormButtons, requiredValidator, emailValidator, phoneValidator, FormMaskedTextBox, useI9VerificationViewContext, AppConstant, DialogBox, useWebUsersViewContext, useJobsViewContext, getUserDetailsFromLocalStorage, } from "./index";
export var Changei9Verifierform = function (props) {
    var Navigate = useNavigate();
    var ChangeI9VerifierDetails = useI9VerificationViewContext().ChangeI9VerifierDetails;
    var postEmailToWebUser = useWebUsersViewContext().postEmailToWebUser;
    var _a = useJobsViewContext(), I9AgentDetails = _a.I9AgentDetails, fetchI9AgentDetails = _a.fetchI9AgentDetails;
    var userDetails = getUserDetailsFromLocalStorage();
    var webUserID = userDetails.WebUserId;
    var newHireJobID = JSON.parse(atob(localStorage.getItem("".concat(AppConstant.LOCAL_STORAGE_EMPLOYEE_DATA, ":").concat(process.env.AUTHORITY_URL, ":").concat(process.env.CLIENT_ID)))).newHireJobID;
    var _b = React.useState(false), modalState = _b[0], changeModalState = _b[1];
    var _c = React.useState(false), gotopreviouspage = _c[0], setgotopreviouspage = _c[1];
    var _d = React.useState({
        headingText: "",
        bodyText: "",
        acceptText: "",
        isErrorDialog: false,
    }), modalContent = _d[0], updateModalContent = _d[1];
    var handleSubmit = function (dataItem) {
        if (!dataItem.isValid) {
            return false;
        }
        else {
            updateverifier(dataItem);
        }
    };
    var updateverifier = function (dataItem) { return __awaiter(void 0, void 0, void 0, function () {
        var response, dataobj, agentresponse, e_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    dataobj = {
                        newHireJobID: newHireJobID,
                        webUserID: webUserID,
                        firstName: dataItem.values.firstName,
                        lastName: dataItem.values.lastName,
                        phoneNumber: dataItem.values.phoneNumber,
                        emailAddress: dataItem.values.emailAddress,
                    };
                    if (!(dataobj.emailAddress === userDetails.EmailAddress)) return [3 /*break*/, 1];
                    onUpdateFailure(TranslationConstant.CAN_NOT_ASSIGN_YOURSELF_I9_VERIFIER);
                    return [3 /*break*/, 7];
                case 1:
                    _a.trys.push([1, 6, , 7]);
                    return [4 /*yield*/, ChangeI9VerifierDetails(dataobj)];
                case 2:
                    response = _a.sent();
                    if (!AppConstant.HTTP_SUCCESS_CODES.includes(response.status)) return [3 /*break*/, 4];
                    EmailtoAuthorizedRepresentative(response.data);
                    return [4 /*yield*/, fetchI9AgentDetails(newHireJobID)];
                case 3:
                    agentresponse = _a.sent();
                    if (AppConstant.HTTP_SUCCESS_CODES.includes(agentresponse.status)) {
                        sendEmail(agentresponse.data);
                    }
                    return [3 /*break*/, 5];
                case 4:
                    onUpdateFailure(TranslationConstant.I9_VERIFIER_FAILED_MESSAGE);
                    _a.label = 5;
                case 5: return [3 /*break*/, 7];
                case 6:
                    e_1 = _a.sent();
                    onUpdateFailure(TranslationConstant.I9_VERIFIER_FAILED_MESSAGE);
                    changeModalState(false);
                    return [3 /*break*/, 7];
                case 7: return [2 /*return*/];
            }
        });
    }); };
    var onUpdateSuccess = function () {
        updateModalContent({
            headingText: _jsx(Transform, { mkey: TranslationConstant.SUCCESSFULLY_CHANGED }),
            bodyText: (_jsxs("div", __assign({ className: "block subheading" }, { children: [_jsx("i", { className: "fa fa-check fa-lg" }), _jsx("span", { children: _jsx(Transform, { mkey: TranslationConstant.I9_VERIFIER_SUCCESS_MESSAGE }) })] }))),
            acceptText: _jsx(Transform, { mkey: TranslationConstant.RETURN_TO_DASHBOARD }),
            isErrorDialog: false,
        });
        changeModalState(true);
        handlenavigate();
    };
    var onUpdateFailure = function (msg) {
        updateModalContent({
            headingText: _jsx(Transform, { mkey: TranslationConstant.CHANGE_FAILED }),
            bodyText: (_jsxs("div", __assign({ className: "block subheading" }, { children: [_jsx("i", { className: "fa fa-times-circle fa-lg" }), _jsx("span", { children: _jsx(Transform, { mkey: msg }) })] }))),
            acceptText: _jsx(Transform, { mkey: TranslationConstant.CLOSE_BUTTON }),
            isErrorDialog: true,
        });
        changeModalState(true);
    };
    var formHeadingData = {
        title: _jsx(Transform, { mkey: TranslationConstant.CHANGE_I9_VERIFIER_FORM_HEADING }),
    };
    var handlecancel = function () {
        Navigate(-1);
    };
    var hideModal = function () {
        if (gotopreviouspage) {
            Navigate(-1);
        }
        else {
            changeModalState(false);
        }
    };
    var handlenavigate = function () {
        setgotopreviouspage(!gotopreviouspage);
    };
    var sendEmail = function (data) { return __awaiter(void 0, void 0, void 0, function () {
        var response, EmailObj, e_2;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    EmailObj = {
                        UserName: "",
                        firstName: data.firstName,
                        lastName: data.lastName,
                        emailAddress: data.emailAddress,
                        PrimaryWorkStateId: "0",
                        NotificationCode: "ResendEmailI9Verifier",
                        RedirectURI: "",
                        Parameters: {
                            AgentEmail: data.agentEmail,
                            AgentPassword: data.agentPassword,
                            SourceApplication: "Onboarding",
                        },
                    };
                    _a.label = 1;
                case 1:
                    _a.trys.push([1, 3, , 4]);
                    return [4 /*yield*/, postEmailToWebUser(EmailObj)];
                case 2:
                    response = _a.sent();
                    if (AppConstant.HTTP_SUCCESS_CODES.includes(response.status)) {
                        //onUpdateSuccess();
                    }
                    else {
                        onEmailSendFailure();
                    }
                    return [3 /*break*/, 4];
                case 3:
                    e_2 = _a.sent();
                    changeModalState(false);
                    return [3 /*break*/, 4];
                case 4: return [2 /*return*/];
            }
        });
    }); };
    var EmailtoAuthorizedRepresentative = function (dataItem) { return __awaiter(void 0, void 0, void 0, function () {
        var response, EmailObj, e_3;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    EmailObj = {
                        UserName: "",
                        firstName: dataItem.agentFirstName,
                        lastName: dataItem.agentLastName,
                        emailAddress: dataItem.emailAddress,
                        PrimaryWorkStateId: "0",
                        NotificationCode: "InviteEmployeeAuthorizedRepresentative",
                        RedirectURI: "",
                        Parameters: {
                            AgentEmailAddress: dataItem.agentEmail,
                            SourceApplication: "Onboarding",
                        },
                    };
                    _a.label = 1;
                case 1:
                    _a.trys.push([1, 3, , 4]);
                    return [4 /*yield*/, postEmailToWebUser(EmailObj)];
                case 2:
                    response = _a.sent();
                    if (AppConstant.HTTP_SUCCESS_CODES.includes(response.status)) {
                        onUpdateSuccess();
                    }
                    else {
                        onEmailSendFailure();
                    }
                    return [3 /*break*/, 4];
                case 3:
                    e_3 = _a.sent();
                    changeModalState(false);
                    return [3 /*break*/, 4];
                case 4: return [2 /*return*/];
            }
        });
    }); };
    var onEmailSendFailure = function () {
        updateModalContent({
            headingText: _jsx(Transform, { mkey: TranslationConstant.EMAIL_FAILURE_TITLE }),
            bodyText: (_jsxs("div", __assign({ className: "block subheading" }, { children: [_jsx("i", { className: "fa fa-times-circle fa-lg" }), _jsx("span", { children: _jsx(Transform, { mkey: TranslationConstant.EMAIL_SEND_FAILED }) })] }))),
            acceptText: _jsx(Transform, { mkey: TranslationConstant.CLOSE_BUTTON }),
            isErrorDialog: true,
        });
        changeModalState(true);
    };
    return (_jsxs(_Fragment, { children: [_jsx(FormHeading, { data: formHeadingData }), _jsx(Form, { onSubmitClick: handleSubmit, render: function () { return (_jsxs(FormElement, __assign({ className: "general-form search-doc-form" }, { children: [_jsxs("fieldset", __assign({ className: "k-form-fieldset row" }, { children: [_jsx("div", __assign({ className: "col-xs-12 col-md-6 mb-4" }, { children: _jsx(Field, { id: "firstName", name: "firstName", component: FormInput, label: _jsx(Transform, { mkey: TranslationConstant.LABEL_FIRST_NAME }), maxLength: 100, isRequired: true, validator: requiredValidator, placeholder: ComponentConstant.PLACEHOLDER_LABEL_FIRST_NAME }) })), _jsx("div", __assign({ className: "col-xs-12 col-md-6 mb-4" }, { children: _jsx(Field, { id: "lastName", name: "lastName", component: FormInput, label: _jsx(Transform, { mkey: TranslationConstant.LABEL_LAST_NAME }), maxLength: 100, isRequired: true, validator: requiredValidator, placeholder: ComponentConstant.PLACEHOLDER_LABEL_LAST_NAME }) })), _jsx("div", __assign({ className: "col-xs-12 col-md-6 mb-4" }, { children: _jsx(Field, { id: "email_id", name: "emailAddress", type: "email", isRequired: true, maxLength: 75, component: FormInput, label: _jsx(Transform, { mkey: TranslationConstant.LABEL_EMAIL_ADDRESS }), validator: emailValidator, placeholder: ComponentConstant.PLACEHOLDER_EMAIL_ADDRESS }) })), _jsx("div", __assign({ className: "col-xs-12 col-md-6 mb-4" }, { children: _jsx(Field, { id: "phoneNumber", name: "phoneNumber", label: _jsx(Transform, { mkey: TranslationConstant.LABEL_PHONE_NUMBER }), mask: "(000) 000-0000", component: FormMaskedTextBox, isRequired: true, validator: phoneValidator, placeholder: ComponentConstant.LABEL_PHONE_NUMBER }) }))] })), _jsx(FormButtons, { hideResetButton: true, showCancelButton: true, cancelHandler: function () {
                                handlecancel();
                            } })] }))); } }), modalState && (_jsx(DialogBox, { headingText: modalContent.headingText, bodyText: modalContent.bodyText, acceptText: modalContent.acceptText, isErrorDialog: modalContent.isErrorDialog, isDialogOpen: modalState, onModalChangeState: hideModal, acceptHandler: hideModal }))] }));
};
