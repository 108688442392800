var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import { Calendar, CalendarCell } from "@progress/kendo-react-dateinputs";
import { useEffect, useRef } from "react";
/**
 * Adds two numbers together.
 * @props enabledDays[] - it will be array of days number
 * Sunday=0,Monday=1,Tuesday=2,......and Saturday=6
 */
function isValidDayOfMonth(day) {
    return day >= 1 && day <= 31;
}
var adjustEndDateToEndOfWeek = function (date) {
    var adjustedDate = new Date(date);
    adjustedDate.setDate(adjustedDate.getDate() + (6 - adjustedDate.getDay()));
    return adjustedDate;
};
var CustomCalendarCell = function (props) {
    if (isValidDayOfMonth(parseInt(props.formattedValue))) {
        //  console.log({ props })
        var isDateEnabled = function (date) {
            for (var _i = 0, _a = props.enabledDateRanges; _i < _a.length; _i++) {
                var range = _a[_i];
                var startDate = new Date(range.startDate);
                var endDate = new Date(adjustEndDateToEndOfWeek(range.endDate));
                if (date >= startDate && date <= endDate) {
                    return false;
                }
            }
            return true;
        };
        var isDisabled = !props.enabledDays.includes(props === null || props === void 0 ? void 0 : props.value.getDay());
        if (props.enabledDateRanges) {
            isDisabled =
                isDateEnabled(new Date(props.value)) ||
                    !props.enabledDays.includes(props === null || props === void 0 ? void 0 : props.value.getDay());
        }
        return (_jsx(CalendarCell, __assign({}, props, { isDisabled: isDisabled, isToday: false, isFocused: false })));
    }
    return _jsx(CalendarCell, __assign({}, props));
};
var CustomCalendar = function (props) {
    var enabledDays = props.enabledDays, _a = props.enabledDateRanges, enabledDateRanges = _a === void 0 ? null : _a;
    var preventDetaulMethods = function (event) {
        event.stopPropagation();
        event.preventDefault();
    };
    var ref = useRef(null);
    useEffect(function () {
        var _a, _b, _c, _d, _e, _f;
        (_b = (_a = ref === null || ref === void 0 ? void 0 : ref.current) === null || _a === void 0 ? void 0 : _a.element) === null || _b === void 0 ? void 0 : _b.addEventListener("wheel", preventDetaulMethods);
        (_d = (_c = ref === null || ref === void 0 ? void 0 : ref.current) === null || _c === void 0 ? void 0 : _c.element) === null || _d === void 0 ? void 0 : _d.addEventListener("keydown", preventDetaulMethods);
        (_f = (_e = ref === null || ref === void 0 ? void 0 : ref.current) === null || _e === void 0 ? void 0 : _e.element) === null || _f === void 0 ? void 0 : _f.addEventListener("keypress", preventDetaulMethods);
        return function () {
            var _a, _b, _c, _d, _e, _f;
            (_b = (_a = ref === null || ref === void 0 ? void 0 : ref.current) === null || _a === void 0 ? void 0 : _a.element) === null || _b === void 0 ? void 0 : _b.removeEventListener("wheel", preventDetaulMethods);
            (_d = (_c = ref === null || ref === void 0 ? void 0 : ref.current) === null || _c === void 0 ? void 0 : _c.element) === null || _d === void 0 ? void 0 : _d.removeEventListener("keydown", preventDetaulMethods);
            (_f = (_e = ref === null || ref === void 0 ? void 0 : ref.current) === null || _e === void 0 ? void 0 : _e.element) === null || _f === void 0 ? void 0 : _f.removeEventListener("keypress", preventDetaulMethods);
        };
    }, []);
    return (_jsx(Calendar, __assign({}, props, { cell: function (cellProps) { return (_jsx(CustomCalendarCell, __assign({ enabledDays: enabledDays, enabledDateRanges: enabledDateRanges }, cellProps))); }, ref: ref, className: "disable-today" })));
};
export default CustomCalendar;
