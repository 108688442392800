import { jsx as _jsx } from "react/jsx-runtime";
import { CLIENT_SETUP_ROUTES } from "common/constant/CustomRoutes";
import { TranslationConstant } from "common/constant";
import { Transform } from "helper/Transform";
export var NON_WORKED_HOUR_BREADCRUMB_ITEM = [
    {
        id: "home",
        text: "Home",
        iconClass: "k-i-home",
        route: CLIENT_SETUP_ROUTES.HOME,
    },
    {
        id: "nonworkedhrscodes",
        text: "Non Worked Hours Codes",
    },
];
export var NON_WORKED_HOUR_CODE_PAGE_TITLE = {
    title: _jsx(Transform, { mkey: TranslationConstant.LABEL_NON_WORKED_HOURS_CODES_TITLE }),
};
export var FORM_HEADING = {
    title: _jsx(Transform, { mkey: TranslationConstant.VENDOR_DOCUMENT_HEADING }),
};
export var EARNINGS_CODE_TYPE_RADIO_OPTION = [
    {
        label: _jsx(Transform, { mkey: TranslationConstant.RADIO_OPTION_VA }),
        value: "VA",
    },
    {
        label: _jsx(Transform, { mkey: TranslationConstant.RADIO_OPTION_HO }),
        value: "HO",
    },
    {
        label: _jsx(Transform, { mkey: TranslationConstant.RADIO_OPTION_TD }),
        value: "TD",
    },
    {
        label: _jsx(Transform, { mkey: TranslationConstant.RADIO_OPTION_JD }),
        value: "JD",
    },
    {
        label: _jsx(Transform, { mkey: TranslationConstant.RADIO_OPTION_AP }),
        value: "AP",
    },
    {
        label: _jsx(Transform, { mkey: TranslationConstant.RADIO_OPTION_MP }),
        value: "MP",
    },
    {
        label: _jsx(Transform, { mkey: TranslationConstant.RADIO_OPTION_HR }),
        value: "HR",
    },
    {
        label: _jsx(Transform, { mkey: TranslationConstant.RADIO_OPTION_SL }),
        value: "SL",
    },
];
export var EXPENSE_CODES_BREADCRUMB_ITEM = [
    {
        id: "home",
        text: "Home",
        iconClass: "k-i-home",
        route: CLIENT_SETUP_ROUTES.HOME,
    },
    {
        id: "expensecodes",
        text: "Expense Codes",
    },
];
export var EXPENSE_CODE_PAGE_TITLE = {
    title: _jsx(Transform, { mkey: TranslationConstant.EXPENSE_CODES_TITILE }),
};
export var COMMON_STATUS_TYPE_RADIO = [
    {
        label: _jsx(Transform, { mkey: TranslationConstant.RADIO_ACTIVE_STATUS }),
        value: "Active",
    },
    {
        label: _jsx(Transform, { mkey: TranslationConstant.RADIO_INACTIVE_STATUS }),
        value: "Inactive",
    },
];
export var MISC_ITEM_CODE_TYPE_RADIO = [
    {
        label: _jsx(Transform, { mkey: TranslationConstant.RADIO_EXPREIMB_C }),
        value: "EXPREIMB-C",
    },
    {
        label: _jsx(Transform, { mkey: TranslationConstant.RADIO_EQUIP_C }),
        value: "EQUIP-C",
    },
];
export var COMMON_RADIO_TYPE_OPTIONS = [
    {
        label: _jsx(Transform, { mkey: TranslationConstant.YES }),
        value: true,
    },
    {
        label: _jsx(Transform, { mkey: TranslationConstant.NO }),
        value: false,
    },
];
export var INACTIVATE_ALL_EMPLOYEES_BREADCRUMB_ITEM = [
    {
        id: "home",
        text: "Home",
        iconClass: "k-i-home",
        route: CLIENT_SETUP_ROUTES.HOME,
    },
    {
        id: "inactivateallemployees",
        text: "Inactivate All Employees",
    },
];
export var INACTIVATE_ALL_EMPLOYEES_PAGE_TITLE = {
    title: _jsx(Transform, { mkey: TranslationConstant.INACTIVATE_ALL_EMPLOYEES_PAGE_TITLE }),
};
export var FORM_HEADING_WARNING = {
    title: _jsx(Transform, { mkey: TranslationConstant.INACTIVATE_ALL_EMPLOYEES_FORM_HEADING_WARNING }),
};
export var StatusTypeDataList = [
    {
        label: _jsx(Transform, { mkey: TranslationConstant.RADIO_ACTIVE_STATUS }),
        value: 1,
    },
    {
        label: _jsx(Transform, { mkey: TranslationConstant.RADIO_INACTIVE_STATUS }),
        value: 2,
    },
];
export var DROP_DOWN_DEFAULT_ITEM = {
    text: _jsx(Transform, { mkey: TranslationConstant.LABEL_DEFAULT_OPTION_SELECT }),
    value: null,
};
export var COUNTRY_DROP_DOWN_DEFAULT_ITEM = {
    text: _jsx(Transform, { mkey: TranslationConstant.LABEL_COUNTRY }),
    value: null,
};
export var STATE_DROP_DOWN_DEFAULT_ITEM = {
    text: _jsx(Transform, { mkey: TranslationConstant.LABEL_STATE }),
    value: null,
};
export var CITY_DROP_DOWN_DEFAULT_ITEM = {
    text: _jsx(Transform, { mkey: TranslationConstant.LABEL_CITY }),
    value: null,
};
export var ZIP_DROP_DOWN_DEFAULT_ITEM = {
    text: _jsx(Transform, { mkey: TranslationConstant.LABEL_ZIP }),
    value: null,
};
export var PER_DIEM_LEVEL_ID = [
    {
        label: _jsx(Transform, { mkey: TranslationConstant.PER_DIEM_LEVEL_LOW_LABEL }),
        value: 1,
    },
    {
        label: _jsx(Transform, { mkey: TranslationConstant.PER_DIEM_LEVEL_HIGH_LABEL }),
        value: 2,
    },
];
