var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React, { useEffect } from 'react';
import { Link } from "react-router-dom";
import { Grid, GridColumn as Column, } from "@progress/kendo-react-grid";
import { process as kendrprocess } from "@progress/kendo-data-query";
import { Transform, AppConstant, TranslationConstant, GridFilters, GridRefresher, TabsComponent, useDashboardContext, ComponentConstant, FormHeading, getGridItemCount, setGridItemCount, useCurrentViewContext } from "./index";
var initialDataState = { sort: [{ field: "no-value", dir: "asc" }], skip: 0, take: AppConstant.GRID_ITEM_TAKE_SIZE };
export var EmployeeAuthorisedTaskGrid = function (props) {
    var _a = useDashboardContext(), externalAgentsDocList = _a.externalAgentsDocList, fetchNewHireDocForExternalAgents = _a.fetchNewHireDocForExternalAgents;
    var userAccessType = useCurrentViewContext().userAccessType;
    var _b = React.useState(initialDataState), dataState = _b[0], setDataState = _b[1];
    var _c = React.useState(), showFilter = _c[0], setShowHideFilter = _c[1];
    var _d = React.useState([]), filter = _d[0], setFilter = _d[1];
    useEffect(function () {
        var Grid_Item_Count = getGridItemCount();
        setDataState(__assign(__assign({}, dataState), { take: Grid_Item_Count }));
        fetchEmpTasks();
    }, []);
    var fetchEmpTasks = function () {
        fetchNewHireDocForExternalAgents();
    };
    var CompleteFormCell = function (cellprops) {
        return (_jsx(_Fragment, { children: _jsx("td", __assign({ colSpan: cellprops.colSpan, role: "gridcell", "aria-colindex": cellprops.ariaColumnIndex, "aria-selected": cellprops.isSelected }, { children: cellprops.dataItem.fileURL && _jsxs(Link, __assign({ to: '/' + cellprops.dataItem.fileURL, className: userAccessType === AppConstant.READ_ONLY_USER_ACCESS_VALUE ? 'access-disabled' : '' }, { children: [_jsx("i", { className: "fa fa-edit fa-lg" }), ComponentConstant.COMPLETE_THIS_FORM] })) })) }));
    };
    var completeFormCustomCell = function (props) {
        return _jsx(CompleteFormCell, __assign({}, props));
    };
    var gridRefresher = function () {
        fetchEmpTasks();
    };
    var formHeadingData = {
        title: _jsx(Transform, { mkey: TranslationConstant.EMPLOYEE_BOARD_HEADING }),
        tooltip: _jsx(Transform, { mkey: TranslationConstant.DOWNLOAD_DOCUMENTS })
    };
    var employeeGridTabs = [
        {
            title: _jsx(Transform, { mkey: TranslationConstant.REQUIRED_FORMS_HEADING }),
            children: _jsxs(_Fragment, { children: [_jsx(GridFilters, { filter: filter, showFilter: showFilter, setShowHideFilter: setShowHideFilter }), _jsxs("div", __assign({ className: "general-grid" }, { children: [_jsxs(Grid, __assign({ sortable: true, filterable: showFilter, pageable: {
                                    buttonCount: AppConstant.BUTTON_COUNT_GRID,
                                    info: true,
                                    type: 'numeric',
                                    pageSizes: AppConstant.PAGE_SIZE,
                                    previousNext: true
                                }, data: kendrprocess(externalAgentsDocList, dataState) }, dataState, { onDataStateChange: function (e) {
                                    var _a, _b;
                                    if (((_a = e.dataState) === null || _a === void 0 ? void 0 : _a.take) !== dataState.take) {
                                        setGridItemCount((_b = e.dataState) === null || _b === void 0 ? void 0 : _b.take);
                                    }
                                    setDataState(e.dataState);
                                    setFilter(e.dataState.filter && e.dataState.filter.filters);
                                } }, { children: [_jsx(Column, { field: "firstName", title: "First Name", width: "150px" }), _jsx(Column, { field: "lastName", title: "Last Name", width: "150px" }), _jsx(Column, { field: "name", title: "Document", width: "150px" }), _jsx(Column, { field: "", title: "Click to Complete", width: "170px", filterable: false, cell: completeFormCustomCell })] })), _jsx(GridRefresher, { refreshGridDataHandler: gridRefresher })] }))] })
        },
    ];
    return (_jsx(_Fragment, { children: _jsxs("div", __assign({ className: "admin-panel-wrapper admin-agent-task employee-task-grid i9agent" }, { children: [_jsx(FormHeading, { data: formHeadingData }), _jsx(TabsComponent, { tabsMenu: employeeGridTabs, selectedIndex: 0 })] })) }));
};
