var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { AppBar, AppBarSection } from "@progress/kendo-react-layout";
import { useCurrentViewContext } from "store/common/currentViewContext";
import { Transform } from "helper/Transform";
import { AppConstant, ComponentConstant, TranslationConstant } from "common/constant";
import { getWindowDimensions, getUserDetailsFromLocalStorage } from "components/shared/utils";
import { DialogBox } from "components/shared/DialogBox";
import { WEBUSERROLEID } from "common/constant/Enums";
import "assets/css/header.scss";
import ClientAppBarDropdown from "./ClientAppBarDropdown";
import { useI9VerificationViewContext } from "store/i9reverification/i9reverification";
export var TopHeader = function (props) {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j;
    var _k = useCurrentViewContext(), setApplicationName = _k.setApplicationName, applicationName = _k.applicationName, setShowProgress = _k.setShowProgress;
    var getClientData = useI9VerificationViewContext().getClientData;
    var wrapperRef = useRef(null);
    var navigate = useNavigate();
    var localStorageKey = "".concat(AppConstant.LOCAL_STORAGE_USER_PROFILE, ":").concat(process.env.AUTHORITY_URL, ":").concat(process.env.CLIENT_ID);
    var userDetails = (localStorage.getItem(localStorageKey) &&
        JSON.parse(atob(localStorage.getItem(localStorageKey)))) ||
        null;
    var WebUserRoleId = (userDetails || {}).WebUserRoleId;
    var logoutDialogContent = {
        headingText: _jsx(Transform, { mkey: TranslationConstant.LOGOUT_CONFIRM_HEADING }),
        bodyText: (_jsxs("div", __assign({ className: "block subheading" }, { children: [_jsx("i", { className: "fa fa-info-circle fa-lg" }), _jsx("span", { children: _jsx(Transform, { mkey: TranslationConstant.LOGOUT_SURE_TEXT }) })] }))),
        cancelText: _jsx(Transform, { mkey: TranslationConstant.NO }),
        acceptText: _jsx(Transform, { mkey: TranslationConstant.YES }),
    };
    var _l = useState(false), menuOpenState = _l[0], menuStateHandler = _l[1];
    var _m = useState(false), modalState = _m[0], changeModalState = _m[1];
    var _o = useState(getWindowDimensions().width < 680), isMobileDevice = _o[0], updateDevice = _o[1];
    var _p = useState(false), applicationDropdownState = _p[0], setApplicationDropdownState = _p[1];
    var _q = useState(false), clientDropdownState = _q[0], setClientDropdownState = _q[1];
    var _r = useState(""), userName = _r[0], updateUserName = _r[1];
    var userProfilePro = getUserDetailsFromLocalStorage("pro");
    useEffect(function () {
        var resizeListener = function () {
            updateDevice(getWindowDimensions().width < 680);
        };
        window.addEventListener("resize", resizeListener);
        if ((userProfilePro === null || userProfilePro === void 0 ? void 0 : userProfilePro.WebUserRoleId) === WEBUSERROLEID.CLIENT &&
            !localStorage.getItem("selectedClient")) {
            fetchClientData();
        }
        return function () {
            window.removeEventListener("resize", resizeListener);
        };
    }, []);
    var fetchClientData = function () { return __awaiter(void 0, void 0, void 0, function () {
        var response;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, getClientData(userProfilePro === null || userProfilePro === void 0 ? void 0 : userProfilePro.ClientId)];
                case 1:
                    response = _a.sent();
                    localStorage.setItem("selectedClient", JSON.stringify(response.data));
                    return [2 /*return*/];
            }
        });
    }); };
    useEffect(function () {
        if (userDetails) {
            var profileName = decodeURIComponent(escape("".concat(userDetails === null || userDetails === void 0 ? void 0 : userDetails.FirstName, " ").concat(userDetails === null || userDetails === void 0 ? void 0 : userDetails.LastName)));
            updateUserName(profileName);
        }
    }, [userDetails]);
    var useOutsideAlerter = function (ref) {
        useEffect(function () {
            function handleClickOutside(event) {
                if (ref.current && !ref.current.contains(event.target)) {
                    setApplicationDropdownState(false);
                    setClientDropdownState(false);
                    menuStateHandler(false);
                }
            }
            document.addEventListener("mousedown", handleClickOutside);
            return function () {
                document.removeEventListener("mousedown", handleClickOutside);
            };
        }, [ref]);
    };
    useOutsideAlerter(wrapperRef);
    var menuChangeStateHandler = function () {
        menuStateHandler(!menuOpenState);
    };
    var redirectToHome = function () {
        navigate(ComponentConstant.ONBOARDING_PATH, { replace: true });
    };
    var onModalChangeState = function (value) {
        changeModalState(value);
    };
    var logOut = function () {
        navigate("/signout-oidc", { replace: true });
        setShowProgress(true);
        props.onLogoutHandler();
        changeModalState(false);
    };
    var chooseApplicationClickHandler = function () {
        setApplicationDropdownState(!applicationDropdownState);
    };
    var selectApplicationHandler = function (appname) {
        window.open(appname);
        chooseApplicationClickHandler();
        setApplicationName(appname);
    };
    var renderClientDropdown = function () {
        var isCorpAplication = window.location.href.toLowerCase().indexOf(AppConstant.CORP_BASE_PATHNAME) > -1;
        return (_jsx(_Fragment, { children: (userProfilePro === null || userProfilePro === void 0 ? void 0 : userProfilePro.WebUserRoleId) === WEBUSERROLEID.Employee &&
                userProfilePro &&
                (userProfilePro === null || userProfilePro === void 0 ? void 0 : userProfilePro.MasterWebUserId) &&
                isCorpAplication && (_jsx(ClientAppBarDropdown, { handleClick: function () { return setClientDropdownState(!clientDropdownState); }, masterWebUserId: userProfilePro.MasterWebUserId, ClientId: userProfilePro.ClientId, clientDropdownState: clientDropdownState })) }));
    };
    var showSelectedApplication = function () {
        if (applicationName === AppConstant.CORP_TYPE_APPLICATION) {
            return _jsx(Transform, { mkey: TranslationConstant.GO_TO_PAYREEL_ONLINE });
        }
        else if (applicationName !== AppConstant.CORP_TYPE_APPLICATION) {
            return _jsx(Transform, { mkey: TranslationConstant.GO_TO_ONBOARDING });
        }
    };
    var showMoreApplications = function () {
        var _a, _b, _c, _d;
        var showCaretIcon = (applicationName === AppConstant.CORP_TYPE_APPLICATION &&
            (((_a = userDetails === null || userDetails === void 0 ? void 0 : userDetails.Parameters) === null || _a === void 0 ? void 0 : _a.ShowOnBoardingLink) === "false" ||
                ((_b = userDetails === null || userDetails === void 0 ? void 0 : userDetails.Parameters) === null || _b === void 0 ? void 0 : _b.ShowICAdvisorLink) === "false")) ||
            (applicationName !== AppConstant.CORP_TYPE_APPLICATION &&
                (((_c = userDetails === null || userDetails === void 0 ? void 0 : userDetails.Parameters) === null || _c === void 0 ? void 0 : _c.ShowCorpLink) === "false" ||
                    ((_d = userDetails === null || userDetails === void 0 ? void 0 : userDetails.Parameters) === null || _d === void 0 ? void 0 : _d.ShowICAdvisorLink) === "false"));
        return !showCaretIcon;
    };
    return (_jsxs(_Fragment, { children: [_jsxs(AppBar, __assign({ className: "header-wrapper" }, { children: [_jsxs(AppBarSection, { children: [_jsx("h1", __assign({ className: "payreel-logo", onClick: redirectToHome }, { children: "\u00A0" })), _jsxs("button", __assign({ type: "button", className: "navbar-toggle pull-left hide-menu", onClick: props.handleMenuChange }, { children: [_jsx("span", { className: "icon-bar" }), _jsx("span", { className: "icon-bar" }), _jsx("span", { className: "icon-bar" })] }))] }), !isMobileDevice ? (_jsxs("div", __assign({ className: "user-wrapper" }, { children: [_jsxs(AppBarSection, { children: [_jsxs("div", __assign({ className: "d-flex", ref: wrapperRef }, { children: [renderClientDropdown(), (WebUserRoleId === WEBUSERROLEID.PAYREEL_ADMIN ||
                                                ((_a = userDetails === null || userDetails === void 0 ? void 0 : userDetails.Parameters) === null || _a === void 0 ? void 0 : _a.ShowCorpLink) === "true") &&
                                                showMoreApplications() &&
                                                ((userDetails === null || userDetails === void 0 ? void 0 : userDetails.WebUserRoleId) !== WEBUSERROLEID.VENDOR ||
                                                    (userDetails === null || userDetails === void 0 ? void 0 : userDetails.WebUserRoleId) !==
                                                        WEBUSERROLEID.WORKER_1099) && (_jsxs("div", __assign({ className: "application-dropdown" }, { children: [_jsxs("div", __assign({ className: "application-selector", onClick: chooseApplicationClickHandler }, { children: [_jsx("span", { children: showSelectedApplication() }), _jsxs("span", { children: [" ", _jsx("i", { className: "bi bi-caret-down" })] })] })), _jsxs("ul", __assign({ className: "nav-notification ".concat(applicationDropdownState ? "opened" : "closed") }, { children: [applicationName ===
                                                                AppConstant.CORP_TYPE_APPLICATION &&
                                                                ((_b = userDetails === null || userDetails === void 0 ? void 0 : userDetails.Parameters) === null || _b === void 0 ? void 0 : _b.ShowOnBoardingLink) ===
                                                                    undefined &&
                                                                ((_c = userDetails === null || userDetails === void 0 ? void 0 : userDetails.Parameters) === null || _c === void 0 ? void 0 : _c.ShowOnBoardingLink) !==
                                                                    "false" && (_jsx("li", __assign({ onClick: function () {
                                                                    selectApplicationHandler("/");
                                                                } }, { children: _jsx("span", { children: _jsx(Transform, { mkey: TranslationConstant.GO_TO_ONBOARDING }) }) }))), applicationName !==
                                                                AppConstant.CORP_TYPE_APPLICATION &&
                                                                ((_d = userDetails === null || userDetails === void 0 ? void 0 : userDetails.Parameters) === null || _d === void 0 ? void 0 : _d.ShowCorpLink) ===
                                                                    "true" && (_jsx("li", __assign({ onClick: function (e) {
                                                                    selectApplicationHandler(AppConstant.CORP_BASE_PATHNAME);
                                                                } }, { children: _jsx("span", { children: _jsx(Transform, { mkey: TranslationConstant.GO_TO_PAYREEL_ONLINE }) }) }))), ((_e = userDetails === null || userDetails === void 0 ? void 0 : userDetails.Parameters) === null || _e === void 0 ? void 0 : _e.ShowICAdvisorLink) ===
                                                                "true" && (_jsx("li", __assign({ onClick: function () {
                                                                    window.open("/ICAdvisor", "_blank");
                                                                } }, { children: _jsx("a", __assign({ href: "javascript:void(0);", target: "_blank" }, { children: _jsx(Transform, { mkey: TranslationConstant.GO_TO_IC_ADVISOR }) })) })))] }))] })))] })), _jsxs("h6", { children: [_jsxs("span", __assign({ className: "d-sm-block wel-text" }, { children: [_jsx(Transform, { mkey: TranslationConstant.WELCOME }), " "] })), _jsxs("span", __assign({ className: "user-desktop-name" }, { children: [_jsx("i", { className: "fa fa-user" }), userName] }))] })] }), _jsx(AppBarSection, { children: _jsx("h6", { children: _jsx("a", __assign({ href: "javascript:void(0);", title: "Click here to Logout", onClick: function (e) {
                                            e.preventDefault();
                                            onModalChangeState(true);
                                        } }, { children: _jsx(Transform, { mkey: TranslationConstant.LOGOUT }) })) }) })] }))) : (_jsx("div", __assign({ className: "user-wrapper mobile-menu" }, { children: _jsx(AppBarSection, { children: _jsxs("div", __assign({ className: "d-flex align-items-center", ref: wrapperRef }, { children: [renderClientDropdown(), _jsxs("h6", { children: [_jsxs("a", __assign({ href: "javascript:void(0);", onClick: function (e) {
                                                    e.preventDefault();
                                                    menuChangeStateHandler();
                                                } }, { children: [_jsx("i", { className: "fa fa-user" }), userName] })), _jsx("i", { className: "bi bi-caret-down bi-caret-down-1", onClick: menuChangeStateHandler })] }), _jsx("div", __assign({ className: "dropdown-menu-account account-menu-open-".concat(menuOpenState) }, { children: _jsxs("ul", __assign({ className: "nav-notification" }, { children: [applicationName === AppConstant.CORP_TYPE_APPLICATION &&
                                                    (WebUserRoleId === WEBUSERROLEID.PAYREEL_ADMIN ||
                                                        (((_f = userDetails === null || userDetails === void 0 ? void 0 : userDetails.Parameters) === null || _f === void 0 ? void 0 : _f.ShowOnBoardingLink) ===
                                                            undefined &&
                                                            ((_g = userDetails === null || userDetails === void 0 ? void 0 : userDetails.Parameters) === null || _g === void 0 ? void 0 : _g.ShowOnBoardingLink) !==
                                                                "false")) && (_jsx("li", __assign({ onClick: function () {
                                                        selectApplicationHandler("/");
                                                    } }, { children: _jsx("span", { children: _jsx(Transform, { mkey: TranslationConstant.GO_TO_ONBOARDING }) }) }))), applicationName !== AppConstant.CORP_TYPE_APPLICATION &&
                                                    ((_h = userDetails === null || userDetails === void 0 ? void 0 : userDetails.Parameters) === null || _h === void 0 ? void 0 : _h.ShowCorpLink) === "true" && (_jsx("li", __assign({ onClick: function (e) {
                                                        selectApplicationHandler(AppConstant.CORP_BASE_PATHNAME);
                                                    } }, { children: _jsx("span", { children: _jsx(Transform, { mkey: TranslationConstant.GO_TO_PAYREEL_ONLINE }) }) }))), ((_j = userDetails === null || userDetails === void 0 ? void 0 : userDetails.Parameters) === null || _j === void 0 ? void 0 : _j.ShowICAdvisorLink) === "true" && (_jsx("li", __assign({ onClick: function () {
                                                        window.open("/ICAdvisor", "_blank");
                                                    } }, { children: _jsx("a", __assign({ href: "javascript:void(0);" }, { children: _jsx(Transform, { mkey: TranslationConstant.GO_TO_IC_ADVISOR }) })) }))), _jsx("li", __assign({ onClick: function (e) {
                                                        e.preventDefault();
                                                        onModalChangeState(true);
                                                    } }, { children: _jsx("a", __assign({ href: "javascript:void(0);", title: "Click here to Logout", onClick: function (e) {
                                                            e.preventDefault();
                                                            onModalChangeState(true);
                                                        } }, { children: _jsx(Transform, { mkey: TranslationConstant.LOGOUT }) })) }))] })) }))] })) }) })))] })), _jsx(DialogBox, { headingText: logoutDialogContent.headingText, bodyText: logoutDialogContent.bodyText, cancelText: logoutDialogContent.cancelText, acceptText: logoutDialogContent.acceptText, isDialogOpen: modalState, onModalChangeState: onModalChangeState, acceptHandler: logOut })] }));
};
