import { useEffect } from "react";
import { useLocation } from "react-router-dom";
export default function ScrollToTop() {
    var pathname = useLocation().pathname;
    useEffect(function () {
        document.documentElement.scrollTo({
            top: 0,
            left: 0,
        });
    }, [pathname]);
    return null;
}
