import { httpPromise } from "services/common/httpPromise";
import { ServiceConstant } from "common/constant";
export var getTimesheetList = function (EmployeeID, TimeSheetStatusFilter) {
    return httpPromise.get("".concat(ServiceConstant.CORP_TIMESHEET_API_PATH, "/GetTimeSheets?EmployeeID=").concat(EmployeeID, "&TimeSheetStatusFilter=").concat(TimeSheetStatusFilter));
};
export var getTimeSheetDetails = function (queryString) {
    return httpPromise.get("".concat(ServiceConstant.CORP_TIMESHEET_API_PATH, "/GetTimeSheetDayDetail?").concat(queryString));
};
export var addNewTimeSheet = function (postData) {
    return httpPromise.post("".concat(ServiceConstant.CORP_TIMESHEET_API_PATH), postData);
};
export var deleteTimesheet = function (timesheetId) {
    return httpPromise.delete("".concat(ServiceConstant.CORP_TIMESHEET_API_PATH, "?timesheetId=").concat(timesheetId));
};
export var deleteTimesheetDetails = function (timeSheetDayDetailID) {
    return httpPromise.delete("".concat(ServiceConstant.CORP_TIMESHEET_DAY_DETAILS_API_PATH, "?timeSheetDayDetailid=").concat(timeSheetDayDetailID));
};
export var deleteTimesheetClockDetails = function (timeSheetDayDetailID) {
    return httpPromise.delete("".concat(ServiceConstant.CLOCKMGMT_TIMESHEET_DAY_DETAILS_API_PATH, "/DeleteTimeClock?timeSheetDayDetailid=").concat(timeSheetDayDetailID));
};
export var getTimeSheetStatusHistory = function (queryString) {
    return httpPromise.get("".concat(ServiceConstant.CORP_TIMESHEET_API_PATH, "/GetTimeSheetStatusHistory?").concat(queryString));
};
export var recallTimesheet = function (postData) {
    return httpPromise.post("".concat(ServiceConstant.CORP_TIMESHEET_API_PATH, "/ReCallTimeSheet"), postData);
};
export var fetchTimeSheetAccounts = function (clientId, employeeID, dayWorked, accountDescriptionAllowed, eventTrackingLevelID) {
    return httpPromise.get("".concat(ServiceConstant.CORP_TIMESHEET_DAY_DETAILS_API_PATH, "/TimeSheetAccounts?clientID=").concat(clientId, "&employeeID=").concat(employeeID, "&dayWorked=").concat(dayWorked, "&accountDescriptionAllowed=").concat(accountDescriptionAllowed, "&eventTrackingLevelID=").concat(eventTrackingLevelID));
};
export var fetchTimeSheetSubAccounts = function (clientId, employeeID, dayWorked, subAccountDescriptionAllowed, nepMask, eventTrackingLevelID) {
    var queryStr = "clientID=".concat(clientId, "&employeeID=").concat(employeeID, "&dayWorked=").concat(dayWorked, "&subAccountDescriptionAllowed=").concat(subAccountDescriptionAllowed, "&nepMask=").concat(nepMask, "&eventTrackingLevelID=").concat(eventTrackingLevelID);
    if (dayWorked == null)
        queryStr = "clientID=".concat(clientId, "&employeeID=").concat(employeeID, "&subAccountDescriptionAllowed=").concat(subAccountDescriptionAllowed, "&nepMask=").concat(nepMask, "&eventTrackingLevelID=").concat(eventTrackingLevelID);
    return httpPromise.get("".concat(ServiceConstant.CORP_TIMESHEET_DAY_DETAILS_API_PATH, "/TimeSheetSubAccounts?").concat(queryStr));
};
export var getWorkLocationsForEmployeeUsingAccountID = function (clientId, accountID, employeeID) {
    return httpPromise.get("".concat(ServiceConstant.GET_WORK_LOCATION_API_PATH, "/GetWorkLocationsForEmployeeUsingAccountID?clientID=").concat(clientId, "&accountID=").concat(accountID, "&employeeID=").concat(employeeID));
};
export var fetchEmployeePositions = function (employeeID) {
    return httpPromise.get("".concat(ServiceConstant.CORP_TIMESHEET_DAY_DETAILS_API_PATH, "/GetEmployeePositions?employeeID=").concat(employeeID));
};
export var getSickLeaveHoursAvailable = function (employeeID, workLocationID) {
    return httpPromise.get("".concat(ServiceConstant.CORP_TIMESHEET_DAY_DETAILS_API_PATH, "/GetSickLeaveHoursAvailable?employeeID=").concat(employeeID, "&workLocationID=").concat(workLocationID));
};
export var getCitiesFromStates = function (stateID) {
    return httpPromise.get("".concat(ServiceConstant.GET_CITY_API_PATH, "/GetCitiesFromStates?stateID=").concat(stateID));
};
export var getZipcodeFromCity = function (cityID) {
    return httpPromise.get("".concat(ServiceConstant.GET_ZIPCODE_API_PATH, "/GetZipcodeFromCity?cityID=").concat(cityID));
};
export var getZipCode = function (inputText) {
    return httpPromise.get("".concat(ServiceConstant.GET_ZIPCODE_API_PATH, "/GetZipCode?inputText=").concat(inputText));
};
export var getCityFromZipCode = function (zipCode) {
    return httpPromise.get("".concat(ServiceConstant.GET_CITY_API_PATH, "/GetCityFromZipCode?zipCode=").concat(zipCode));
};
export var postTimeSheetDayDetail = function (timesheetData) {
    return httpPromise.post("".concat(ServiceConstant.CORP_TIMESHEET_DAY_DETAILS_API_PATH), timesheetData);
};
export var postMultipleTimeSheetDayDetail = function (timesheetDataArray) {
    return httpPromise.post("".concat(ServiceConstant.CORP_TIMESHEET_DAY_DETAILS_API_PATH, "/SaveAsync"), timesheetDataArray);
};
export var postMissingTimeSheetDayDetail = function (weekEndingDate, timesheetData) {
    return httpPromise.post("".concat(ServiceConstant.CLOCKMGMT_TIMESHEET_DAY_DETAILS_API_PATH, "/SaveMissingTime?weekEndingDate=").concat(weekEndingDate), timesheetData);
};
export var saveWorkLocation = function (timesheetData) {
    return httpPromise.post("".concat(ServiceConstant.GET_WORK_LOCATION_API_PATH, "/SaveWorkLocation"), timesheetData);
};
export var getStateFromZipCode = function (zipCode) {
    return httpPromise.get("".concat(ServiceConstant.CORP_STATE_API_PATH, "/GetStateFromZipCode?zipCode=").concat(zipCode));
};
export var getTimeSheetDayDetail = function (timeSheetDayDetailID) {
    return httpPromise.get("".concat(ServiceConstant.CORP_TIMESHEET_DAY_DETAILS_API_PATH, "/GetTimeSheetDayDetail?timeSheetDayDetailID=").concat(timeSheetDayDetailID));
};
export var getNonWorkedHoursCodes = function (clientID) {
    return httpPromise.get("".concat(ServiceConstant.CORP_TIMESHEET_DAY_DETAILS_API_PATH, "/GetNonWorkedHoursCodes?clientID=").concat(clientID));
};
export var getClientSupervisors = function (clientID) {
    return httpPromise.get("".concat(ServiceConstant.CORP_TIMESHEET_DAY_DETAILS_API_PATH, "/GetClientSupervisors?clientID=").concat(clientID));
};
export var getRequisitionNumbersFromParam = function (queryString) {
    return httpPromise.get("".concat(ServiceConstant.CORP_REQUISITION_NUMBER_API, "/GetRequisitionNumbersFromParam?").concat(queryString));
};
export var timeSheetSignAndSubmit = function (postData) {
    return httpPromise.post("".concat(ServiceConstant.CORP_TIMESHEET_API_PATH, "/SignAndSave"), postData);
};
export var fetchEmployeeInformation = function (employeeId, ignoreEmployeeRepeatI9) {
    if (ignoreEmployeeRepeatI9 === void 0) { ignoreEmployeeRepeatI9 = false; }
    var isAdminLoggedInAsEmployee = localStorage.getItem("isAdminLoggedInAsEmployee")
        ? true
        : false;
    return httpPromise.get("".concat(ServiceConstant.CORP_EMPLOYEE_API_PATH, "/").concat(employeeId, "?isAdminLogged=").concat(isAdminLoggedInAsEmployee, "&ignoreEmployeeRepeatI9=").concat(ignoreEmployeeRepeatI9));
};
export var fetchClientTimesheet = function (querystring) {
    return httpPromise.get("".concat(ServiceConstant.CORP_TIMESHEET_API_PATH, "/SearchTimeSheets?").concat(querystring));
};
export var gettimesheetsforapproval = function (queryString) {
    return httpPromise.get("".concat(ServiceConstant.CORP_TIMESHEET_API_PATH, "/GetTimeSheetsForApproval?").concat(queryString));
};
export var fetchClientcostcenter = function (clientid) {
    return httpPromise.get("".concat(ServiceConstant.CORP_TIMESHEET_DAY_DETAILS_API_PATH, "/TimeSheetAccounts?clientID=").concat(clientid));
};
export var fetchtimesheetstatus = function () {
    return httpPromise.get("".concat(ServiceConstant.GET_TIME_SHEET_STATUS_API));
};
export var getWorkLocationByID = function (workLocationID) {
    return httpPromise.get("".concat(ServiceConstant.GET_WORK_LOCATION_API_PATH, "/GetWorkLocationByID?workLocationID=").concat(workLocationID));
};
export var updateDirectDepositOrPaycard = function (groupData) {
    return httpPromise.post("".concat(ServiceConstant.CORP_EMPLOYEE_API_PATH, "/UpdatePaymentAsync"), groupData);
};
export var updateW4Async = function (groupData) {
    return httpPromise.post("".concat(ServiceConstant.CORP_EMPLOYEE_API_PATH, "/UpdateW4Async"), groupData);
};
export var postOnLoadPopupSignUp = function (formData) {
    return httpPromise.post("".concat(ServiceConstant.CORP_EMPLOYEE_API_PATH, "/SubmitDocumentAsync"), formData);
};
export var saveTimesheetVZDay = function (timesheetData) {
    return httpPromise.post("".concat(ServiceConstant.CORP_TIMESHEET_VZ_API_PATH, "/SaveTimeSheetVzDay"), timesheetData);
};
export var saveTimesheetVZWorkItem = function (timesheetData) {
    return httpPromise.post("".concat(ServiceConstant.CORP_TIMESHEET_VZ_API_PATH, "/SaveVzWorkItem"), timesheetData);
};
export var getTimeSheetVZ = function (timeSheetID) {
    return httpPromise.get("".concat(ServiceConstant.CORP_TIMESHEET_VZ_API_PATH, "/GetTimeSheetVz?timeSheetID=").concat(timeSheetID));
};
export var getTimeSheetVZDay = function (timeSheetVZDayID, userRole) {
    return httpPromise.get("".concat(ServiceConstant.CORP_TIMESHEET_VZ_API_PATH, "/GetTimeSheetVZDay?timeSheetVZDayID=").concat(timeSheetVZDayID, "&userRole=").concat(userRole));
};
export var getTimeSheetVZDayDetails = function (timeSheetVZDayID) {
    return httpPromise.get("".concat(ServiceConstant.CORP_TIMESHEET_VZ_API_PATH, "/GetTimeSheetVZDayDetails?timeSheetVZDayID=").concat(timeSheetVZDayID));
};
export var getVZDayDetailPercentUsed = function (timeSheetID, timeSheetVZDayID) {
    return httpPromise.get("".concat(ServiceConstant.CORP_TIMESHEET_VZ_API_PATH, "/GetVZDayDetailPercentUsed?timeSheetID=").concat(timeSheetID, "&timeSheetVZDayID=").concat(timeSheetVZDayID));
};
export var updateSickLeaveNoticeReadDateAsync = function (employeeId) {
    return httpPromise.post("".concat(ServiceConstant.CORP_EMPLOYEE_API_PATH, "/UpdateSickLeaveNoticeReadDateAsync?employeeId=").concat(employeeId));
};
export var getSubAccount = function (subAccountId) {
    return httpPromise.get("".concat(ServiceConstant.CORP_SUB_ACCOUNTS_API_PATH, "/GetSubAccount?subAccountId=").concat(subAccountId));
};
export var deleteTimesheetDayVZ = function (timeSheetVzDayId) {
    return httpPromise.delete("".concat(ServiceConstant.CORP_TIMESHEET_VZ_API_PATH, "/DeleteTimeSheetDayVZ?timeSheetVzDayId=").concat(timeSheetVzDayId));
};
export var deleteTimesheetDayDetailVZ = function (timeSheetDayDetailID) {
    return httpPromise.delete("".concat(ServiceConstant.CORP_TIMESHEET_VZ_API_PATH, "/DeleteTimeSheetDayDetailVZ?timeSheetDayDetailID=").concat(timeSheetDayDetailID));
};
export var getVZDay = function (timeSheetVZDayID) {
    return httpPromise.get("".concat(ServiceConstant.CORP_TIMESHEET_VZ_API_PATH, "/GetVZDay?timeSheetVZDayID=").concat(timeSheetVZDayID));
};
export var deleteAllTimeSheetDayDetailsVZ = function (timeSheetVZDayID) {
    return httpPromise.delete("".concat(ServiceConstant.CORP_TIMESHEET_VZ_API_PATH, "/DeleteAllTimeSheetDayDetailsVZ?timeSheetVzDayId=").concat(timeSheetVZDayID));
};
export var approveTimesheets = function (postData) {
    return httpPromise.post("".concat(ServiceConstant.CORP_TIMESHEET_API_PATH, "/ApproveTimeSheets"), postData);
};
export var approveTimesheet = function (postData) {
    return httpPromise.post("".concat(ServiceConstant.CORP_TIMESHEET_API_PATH, "/ApproveViewTimeSheet"), postData);
};
export var returnTimesheetToEmployee = function (queryString) {
    return httpPromise.put("".concat(ServiceConstant.CORP_TIMESHEET_API_PATH, "/ReturnTimeSheetToEmployee?").concat(queryString));
};
export var timeSheetSaveAccount = function (postData) {
    return httpPromise.post("".concat(ServiceConstant.CORP_TIMESHEET_DAY_DETAILS_API_PATH, "/TimeSheetSaveAccount"), postData);
};
export var getTimeSheetEmpcmnt = function (id) {
    return httpPromise.get("".concat(ServiceConstant.CORP_TIMESHEET_API_PATH, "/GetTimeSheetEmployeeComment?timeSheetID=").concat(id));
};
export var getAccountsList = function (queryString) {
    return httpPromise.get("".concat(ServiceConstant.CLIENT_DIVISION_LIST_API, "/GetAccounts?").concat(queryString));
};
export var getWeekEndingDateTimeSheet = function (id) {
    return httpPromise.get("".concat(ServiceConstant.CORP_TIMESHEET_API_PATH, "/GetWeekEndingDatesForUnsignedTimesheets?clientId=").concat(id));
};
export var getTimeSheetDayDetailsAccounts = function (queryStr) {
    return httpPromise.get("".concat(ServiceConstant.CORP_TIMESHEET_DAY_DETAILS_API_PATH, "/TimeSheetAccounts?").concat(queryStr));
};
export var getUnsignedTimeSheetforEmployee = function (queryStr) {
    return httpPromise.get("".concat(ServiceConstant.CORP_TIMESHEET_API_PATH, "/GetTimeSheetsForEmployeeApprovalOnClient?").concat(queryStr));
};
export var getTimeSheetDetailsByTimeSheetId = function (timesheetId) {
    return httpPromise.get("".concat(ServiceConstant.CORP_TIMESHEET_API_PATH, "/GetTimeSheets/").concat(timesheetId));
};
export var updateNoticeReadDate = function (employeeId, type) {
    return httpPromise.post("".concat(ServiceConstant.CORP_EMPLOYEE_API_PATH, "/UpdateNoticeReadDate?employeeId=").concat(employeeId, "&type=").concat(type));
};
export var updateEmployeeRepeatI9 = function (dataObj) {
    return httpPromise.post("".concat(ServiceConstant.EMPLOYEE_REPEAT_I9_API_PATH, "/updateEmployeeRepeatI9"), dataObj);
};
export var redirectEmployeeRepeatI9 = function (dataObj) {
    return httpPromise.get("".concat(ServiceConstant.EMPLOYEE_REPEAT_I9_API_PATH, "/RedirectEmployeeRepeatI9?employeeId=").concat(dataObj.employeeId, "&roleId=").concat(dataObj.roleId));
};
export var getAgreementDateRange = function (employeeId) {
    return httpPromise.get("".concat(ServiceConstant.CORP_EMPLOYEE_API_PATH, "/GetAgreementsDateRangeByEmpID?employeeID=").concat(employeeId));
};
export var getSignAgreementUrl = function (dataObj) {
    return httpPromise.get("".concat(ServiceConstant.CORP_EMPLOYEE_API_PATH, "/RedirectEmployeeAsync?employeeId=").concat(dataObj.employeeId, "&roleId=").concat(dataObj.roleId));
};
export var getProjectsForWorkDescriptions = function (timesheetId) {
    return httpPromise.get("".concat(ServiceConstant.CORP_TIMESHEET_DAY_DETAILS_API_PATH, "/GetProjectsForWorkDescriptions?timeSheetId=").concat(timesheetId));
};
export var saveWorkDescription = function (dataObj) {
    return httpPromise.put("".concat(ServiceConstant.CORP_TIMESHEET_DAY_DETAILS_API_PATH, "/SaveWorkDescription"), dataObj);
};
export var timeSheetPreSignAndSubmitValidation = function (timesheetId) {
    return httpPromise.get("".concat(ServiceConstant.CORP_TIMESHEET_API_PATH, "/timeSheetPreSignAndSubmitValidation?timeSheetID=").concat(timesheetId));
};
export var getWaivableMealPenaltiesDt = function (timesheetId) {
    return httpPromise.get("".concat(ServiceConstant.CORP_TIMESHEET_API_PATH, "/GetWaivableMealPenaltiesDt?timeSheetID=").concat(timesheetId));
};
export var waiveMealPenalty = function (dataObj) {
    return httpPromise.post("".concat(ServiceConstant.CORP_TIMESHEET_DAY_DETAILS_API_PATH, "/waiveMealPenalty"), dataObj);
};
export var possibleOnsiteMealBreaksExist = function (timesheetId) {
    return httpPromise.get("".concat(ServiceConstant.CORP_TIMESHEET_API_PATH, "/PossibleOnsiteMealBreaksExist?timeSheetID=").concat(timesheetId));
};
export var submitMealBreakOnsiteAsync = function (dataObj) {
    return httpPromise.post("".concat(ServiceConstant.CORP_TIMESHEET_DAY_DETAILS_API_PATH, "/SubmitMealBreakOnsiteAsync"), dataObj);
};
export var saveReCalculateTimeSheet = function (groupData) {
    return httpPromise.post("".concat(ServiceConstant.CORP_TIMESHEET_DAY_DETAILS_API_PATH, "/ReCalculateTimeSheet"), groupData);
};
export var validateVZTimeSheet = function (timeSheetID) {
    return httpPromise.get("".concat(ServiceConstant.CORP_TIMESHEET_VZ_API_PATH, "/ValidateVZTimeSheet?timeSheetID=").concat(timeSheetID));
};
export var postTimecardBackup = function (groupData) {
    return httpPromise.post("".concat(ServiceConstant.CORP_TIMESHEET_API_PATH, "/UploadTimeCardBackUp"), groupData);
};
export var mealPenaltiesWaivableExist = function (timesheetId) {
    return httpPromise.get("".concat(ServiceConstant.CORP_TIMESHEET_API_PATH, "/MealPenaltiesWaivableExist?timeSheetID=").concat(timesheetId));
};
