var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
export var EmployeeAuthorisedInformation = function () {
    return (_jsxs("div", __assign({ className: "panel-stat3 i9agent-board" }, { children: [_jsx("h5", { children: "Welcome Authorized Representative!" }), _jsx("span", { children: "Before you begin, please confirm that:" }), _jsxs("ul", { children: [_jsx("li", { children: "The Employee is with you, in person" }), _jsx("li", { children: "The Employee has the original copies of their identification documents with them" })] }), _jsxs("p", __assign({ className: "m-left-xs" }, { children: [_jsx("span", { children: "The purpose of this step is to ensure that the Employee can provide authentic documentation to prove 1) their identity and 2) that they are authorized to work in the US." }), _jsx("span", { children: "To do this, please confirm the information entered in Section 1 of the I-9 form matches the information on the documentation. Next, confirm the person standing in front of you matches the pictures on the identification. Lastly, please review the documentation to confirm they appear authentic to you." }), _jsx("span", { children: "Click Complete this form to complete and sign Section 2 of the form." }), _jsx("span", { children: "Once completed, you will receive a confirmation email." })] }))] })));
};
