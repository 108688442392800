var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { GridColumn as Column } from "@progress/kendo-react-grid";
import { Tooltip } from "@progress/kendo-react-tooltip";
import { AppConstant, useSearchEmpViewContext, getGridItemCount, createEncodedURL, GridOData, KendoGrid, Transform, TranslationConstant, ActionCell, MasterClientList, WEBUSERROLEID, } from "../index";
var initialDataState = {
    skip: 0,
    take: AppConstant.GRID_ITEM_TAKE_SIZE,
};
export var ClientSearchEmployeeGrid = function (_a) {
    var SessionData = _a.SessionData, ClientSearchEmpData = _a.ClientSearchEmpData, SelectedClientData = _a.SelectedClientData, IsAdminAccess = _a.IsAdminAccess;
    var navigate = useNavigate();
    var _b = useState({
        data: [],
        total: 0,
    }), gridData = _b[0], setGridData = _b[1];
    var _c = useState(false), forceReload = _c[0], setForceReload = _c[1];
    var _d = useState(initialDataState), dataState = _d[0], setDataState = _d[1];
    var _e = useSearchEmpViewContext(), getClientEmployeesList = _e.getClientEmployeesList, ClientEmpdataList = _e.ClientEmpdataList;
    var setInitialStates = function () {
        var Grid_Item_Count = getGridItemCount();
        setDataState(__assign(__assign({}, dataState), { take: Grid_Item_Count, skip: 0 }));
    };
    useEffect(function () {
        setInitialStates();
    }, []);
    useEffect(function () {
        setInitialStates();
    }, [ClientSearchEmpData]);
    var dataReceived = function (results) {
        setGridData(results);
    };
    var VieworEditEmployee = function (dataItem) {
        IsAdminAccess
            ? navigate(createEncodedURL("/admineditemployee", {
                EmployeeID: dataItem === null || dataItem === void 0 ? void 0 : dataItem.employeeID,
                ClientName: dataItem === null || dataItem === void 0 ? void 0 : dataItem.clientName,
                FirstName: dataItem === null || dataItem === void 0 ? void 0 : dataItem.firstName,
                LastName: dataItem === null || dataItem === void 0 ? void 0 : dataItem.lastName,
                ClientID: SessionData === null || SessionData === void 0 ? void 0 : SessionData.ClientId,
                formRedirectData: ClientSearchEmpData,
            }))
            : navigate(createEncodedURL("/viewemployeeinfo", __assign({ EmployeeID: dataItem === null || dataItem === void 0 ? void 0 : dataItem.employeeID }, ClientSearchEmpData)));
    };
    var dataStateHandle = function (e) {
        setDataState(e);
    };
    return (_jsxs(_Fragment, { children: [_jsx(Tooltip, __assign({ openDelay: 100 }, { children: _jsxs(KendoGrid, __assign({ data: gridData, removeProcessData: true, dataStateHandle: dataStateHandle, refreshMethod: function () { return setForceReload(!forceReload); }, customFilterTitles: { name: "Organization", itSecurityID: "IT Security ID" } }, { children: [_jsx(Column, { field: "", title: IsAdminAccess ? "Edit" : "View", width: "80px", filterable: false, cell: function (cell) { return (_jsx(ActionCell, { cellprops: cell, icon: IsAdminAccess ? "fa fa-edit fa-lg" : "fa fa-eye fa-lg", title: IsAdminAccess ? (_jsx(Transform, { mkey: TranslationConstant.EDIT })) : (_jsx(Transform, { mkey: TranslationConstant.VIEW })), onClick: function () { return VieworEditEmployee(cell.dataItem); } })); } }), ((SessionData === null || SessionData === void 0 ? void 0 : SessionData.WebUserRoleId) === WEBUSERROLEID.CLIENT ||
                            (SessionData === null || SessionData === void 0 ? void 0 : SessionData.WebUserRoleId) === WEBUSERROLEID.PAYREEL_ADMIN) && (_jsx(Column, { field: "employeeIdentificationNumber", title: "EID", width: "80px" })), _jsx(Column, { field: "lastName", title: "Last Name", width: "150px" }), _jsx(Column, { field: "firstName", title: "First Name", width: "150px" }), SessionData.WebUserRoleId !== WEBUSERROLEID.MASTER_CLIENT_READ_ONLY && (_jsx(Column, { field: "ssn", title: "SSN", width: "90px", className: "text-end" })), SessionData.WebUserRoleId !== WEBUSERROLEID.MASTER_CLIENT_READ_ONLY && (_jsx(Column, { field: "phoneNumber", title: "Phone Number", width: "120px", className: "text-end" })), _jsx(Column, { field: "emailAddress", title: "Email Address", width: "200px" }), SessionData.WebUserRoleId === WEBUSERROLEID.MASTER_CLIENT_READ_ONLY && (_jsx(Column, { field: "status", title: "Status", width: "100px" })), _jsx(Column, { field: "name", title: "Organization", width: "150px" }), (SelectedClientData === null || SelectedClientData === void 0 ? void 0 : SelectedClientData.masterClientID) === MasterClientList.WMEIMG &&
                            SessionData.WebUserRoleId !== WEBUSERROLEID.MASTER_CLIENT_READ_ONLY && (_jsx(Column, { field: "itSecurityID", title: "IT Security ID", width: "120px" })), SessionData.WebUserRoleId === WEBUSERROLEID.MASTER_CLIENT_READ_ONLY &&
                            (SessionData === null || SessionData === void 0 ? void 0 : SessionData.ClientId) === MasterClientList.WMEIMG && (_jsx(Column, { field: "itSecurityID", title: "IT Security ID", width: "120px" }))] })) })), ClientSearchEmpData && (_jsx("div", { children: _jsx(GridOData, { dataState: dataState, onDataReceived: dataReceived, contextMethod: function (queryParam) { return getClientEmployeesList(queryParam); }, paramObj: ClientSearchEmpData || {} }) }, "".concat(forceReload)))] }));
};
