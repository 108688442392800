var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { create } from "zustand";
import { getTimesheetList, getTimeSheetDetails, addNewTimeSheet, deleteTimesheet, deleteTimesheetDetails, deleteTimesheetClockDetails, getTimeSheetStatusHistory, recallTimesheet, fetchTimeSheetAccounts, getWorkLocationsForEmployeeUsingAccountID, fetchEmployeePositions, getSickLeaveHoursAvailable, getCitiesFromStates, getZipcodeFromCity, getZipCode, getCityFromZipCode, timeSheetSignAndSubmit, postTimeSheetDayDetail, postMultipleTimeSheetDayDetail, postMissingTimeSheetDayDetail, saveWorkLocation, getStateFromZipCode, fetchTimeSheetSubAccounts, getTimeSheetDayDetail, getNonWorkedHoursCodes, getClientSupervisors, fetchEmployeeInformation, fetchClientTimesheet, fetchClientcostcenter, fetchtimesheetstatus, getWorkLocationByID, updateDirectDepositOrPaycard, updateW4Async, postOnLoadPopupSignUp, saveTimesheetVZDay, saveTimesheetVZWorkItem, getTimeSheetVZDay, getTimeSheetVZDayDetails, getTimeSheetVZ, updateSickLeaveNoticeReadDateAsync, getVZDayDetailPercentUsed, getSubAccount, deleteTimesheetDayVZ, deleteTimesheetDayDetailVZ, getVZDay, deleteAllTimeSheetDayDetailsVZ, gettimesheetsforapproval, approveTimesheets, approveTimesheet, returnTimesheetToEmployee, timeSheetSaveAccount, getTimeSheetEmpcmnt, getAccountsList, getWeekEndingDateTimeSheet, getTimeSheetDayDetailsAccounts, getUnsignedTimeSheetforEmployee, updateNoticeReadDate, updateEmployeeRepeatI9, redirectEmployeeRepeatI9, getAgreementDateRange, getSignAgreementUrl, getProjectsForWorkDescriptions, saveWorkDescription, timeSheetPreSignAndSubmitValidation, getWaivableMealPenaltiesDt, waiveMealPenalty, possibleOnsiteMealBreaksExist, submitMealBreakOnsiteAsync, saveReCalculateTimeSheet, validateVZTimeSheet, postTimecardBackup, mealPenaltiesWaivableExist, } from "services/timesheet/timesheet";
import { ComponentConstant } from "common/constant";
import { PUSH_CLIENT } from "common/constant/Enums";
export var useTimesheetViewContext = create(function (set, get) { return ({
    timesheetList: [],
    timeSheetDetails: [],
    timeSheetStatusHistoryList: [],
    timesheetAccountsList: [],
    workLocationsForEmployeeUsingAccountID: [],
    employeePositions: [],
    sickLeaveHoursAvailable: [],
    citiesFromStates: [],
    citiesFromStatesObj: [],
    zipcodeFromCity: [],
    zipCodes: [],
    cityFromZipCodes: [],
    stateFromZipCode: {},
    timesheetSubAccountsList: [],
    timeSheetDataEdit: [],
    nonWorkedHoursCodes: [],
    clientSupervisors: [],
    clienttimesheetdata: [],
    ClientcostcenterDropdown: [],
    timesheetstatusall: [],
    timesheetstatuswithselectall: [],
    workLocationId: {},
    employeeInformation: {},
    timesheetVzList: [],
    timeSheetVzDayList: [],
    timesheetVzDetailsList: [],
    dayPercentageUsed: null,
    subAccountList: [],
    getVZDayList: {},
    timesheetApprovalList: [],
    TimeSheetEmpComntList: [],
    timeSheetDayDetailsAccountsList: [],
    weekEndingDateTimeSheetList: [],
    manageUnsignedTimeSheetforEmployeeList: [],
    agreementDateRange: [],
    workLocationByIdList: [],
    workDescriptionProjectList: [],
    waivableMealPenaltiesList: [],
    onSiteMealBreakExistList: [],
    empInfoAPITriggered: false,
    fetchTimesheetList: function (EmployeeID, TimeSheetStatusFilter) { return __awaiter(void 0, void 0, void 0, function () {
        var response;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, getTimesheetList(EmployeeID, TimeSheetStatusFilter)];
                case 1:
                    response = _a.sent();
                    set(function (state) { return (__assign(__assign({}, state), { timesheetList: response.data })); });
                    return [2 /*return*/];
            }
        });
    }); },
    resetTimesheetList: function () { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            set(function (state) { return (__assign(__assign({}, state), { timesheetList: [] })); });
            return [2 /*return*/];
        });
    }); },
    fetchTimesheetDetails: function (queryString) { return __awaiter(void 0, void 0, void 0, function () {
        var response;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, getTimeSheetDetails(queryString)];
                case 1:
                    response = _a.sent();
                    set(function (state) { return (__assign(__assign({}, state), { timeSheetDetails: response.data })); });
                    return [2 /*return*/];
            }
        });
    }); },
    resetTimeSheetDetails: function () { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            set(function (state) { return (__assign(__assign({}, state), { timeSheetDetails: [] })); });
            return [2 /*return*/];
        });
    }); },
    createTimeSheet: function (postData) { return __awaiter(void 0, void 0, void 0, function () { return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, addNewTimeSheet(postData)];
            case 1: return [2 /*return*/, _a.sent()];
        }
    }); }); },
    deleteTimesheets: function (timesheetId) { return __awaiter(void 0, void 0, void 0, function () {
        var response;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, deleteTimesheet(timesheetId)];
                case 1:
                    response = _a.sent();
                    return [2 /*return*/, response];
            }
        });
    }); },
    deleteTimesheetDayDetails: function (timeSheetDayDetailID) { return __awaiter(void 0, void 0, void 0, function () {
        var response;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, deleteTimesheetDetails(timeSheetDayDetailID)];
                case 1:
                    response = _a.sent();
                    return [2 /*return*/, response];
            }
        });
    }); },
    deleteTimesheetClockDayDetails: function (timeSheetDayDetailID) { return __awaiter(void 0, void 0, void 0, function () {
        var response;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, deleteTimesheetClockDetails(timeSheetDayDetailID)];
                case 1:
                    response = _a.sent();
                    return [2 /*return*/, response];
            }
        });
    }); },
    getTimeSheetStatusHistory: function (queryString) { return __awaiter(void 0, void 0, void 0, function () {
        var response;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, getTimeSheetStatusHistory(queryString)];
                case 1:
                    response = _a.sent();
                    set(function (state) { return (__assign(__assign({}, state), { timeSheetStatusHistoryList: response.data })); });
                    return [2 /*return*/, response];
            }
        });
    }); },
    recallTimesheet: function (postData) { return __awaiter(void 0, void 0, void 0, function () { return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, recallTimesheet(postData)];
            case 1: return [2 /*return*/, _a.sent()];
        }
    }); }); },
    fetchTimeSheetAccounts: function (clientId, EmployeeID, dayWorked, accountDescriptionAllowed, eventTrackingLevelID) { return __awaiter(void 0, void 0, void 0, function () {
        var response;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, fetchTimeSheetAccounts(clientId, EmployeeID, dayWorked, accountDescriptionAllowed, eventTrackingLevelID)];
                case 1:
                    response = _a.sent();
                    set(function (state) { return (__assign(__assign({}, state), { timesheetAccountsList: response.data })); });
                    return [2 /*return*/, response];
            }
        });
    }); },
    fetchTimeSheetSubAccounts: function (clientId, EmployeeID, dayWorked, subAccountDescriptionAllowed, nepMask, eventTrackingLevelID) { return __awaiter(void 0, void 0, void 0, function () {
        var response;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, fetchTimeSheetSubAccounts(clientId, EmployeeID, dayWorked, subAccountDescriptionAllowed, nepMask, eventTrackingLevelID)];
                case 1:
                    response = _a.sent();
                    set(function (state) { return (__assign(__assign({}, state), { timesheetSubAccountsList: response.data })); });
                    return [2 /*return*/, response];
            }
        });
    }); },
    getWorkLocationsForEmployeeUsingAccountID: function (clientId, accountID, EmployeeID) { return __awaiter(void 0, void 0, void 0, function () {
        var response;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, getWorkLocationsForEmployeeUsingAccountID(clientId, accountID, EmployeeID)];
                case 1:
                    response = _a.sent();
                    set(function (state) { return (__assign(__assign({}, state), { workLocationsForEmployeeUsingAccountID: response.data })); });
                    return [2 /*return*/];
            }
        });
    }); },
    fetchEmployeePositions: function (EmployeeID) { return __awaiter(void 0, void 0, void 0, function () {
        var response;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, fetchEmployeePositions(EmployeeID)];
                case 1:
                    response = _a.sent();
                    set(function (state) { return (__assign(__assign({}, state), { employeePositions: response.data })); });
                    return [2 /*return*/];
            }
        });
    }); },
    getSickLeaveHoursAvailable: function (EmployeeID, workLocationID) { return __awaiter(void 0, void 0, void 0, function () {
        var response;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, getSickLeaveHoursAvailable(EmployeeID, workLocationID)];
                case 1:
                    response = _a.sent();
                    set(function (state) { return (__assign(__assign({}, state), { sickLeaveHoursAvailable: response.data })); });
                    return [2 /*return*/];
            }
        });
    }); },
    getCitiesFromStates: function (stateId) { return __awaiter(void 0, void 0, void 0, function () {
        var response;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, getCitiesFromStates(stateId)];
                case 1:
                    response = _a.sent();
                    set(function (state) { return (__assign(__assign({}, state), { citiesFromStates: response.data, citiesFromStatesObj: response.data })); });
                    return [2 /*return*/, response];
            }
        });
    }); },
    getZipcodeFromCity: function (cityID) { return __awaiter(void 0, void 0, void 0, function () {
        var response;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, getZipcodeFromCity(cityID)];
                case 1:
                    response = _a.sent();
                    set(function (state) { return (__assign(__assign({}, state), { zipcodeFromCity: response.data })); });
                    return [2 /*return*/];
            }
        });
    }); },
    getZipCode: function (inputText) { return __awaiter(void 0, void 0, void 0, function () {
        var response;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, getZipCode(inputText)];
                case 1:
                    response = _a.sent();
                    set(function (state) { return (__assign(__assign({}, state), { zipCodes: response.data })); });
                    return [2 /*return*/];
            }
        });
    }); },
    getCityFromZipCode: function (inputText) { return __awaiter(void 0, void 0, void 0, function () {
        var response;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, getCityFromZipCode(inputText)];
                case 1:
                    response = _a.sent();
                    set(function (state) { return (__assign(__assign({}, state), { cityFromZipCodes: response.data })); });
                    return [2 /*return*/];
            }
        });
    }); },
    signAndSaveTimehseet: function (postData) { return __awaiter(void 0, void 0, void 0, function () { return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, timeSheetSignAndSubmit(postData)];
            case 1: return [2 /*return*/, _a.sent()];
        }
    }); }); },
    postTimeSheetDayDetail: function (timesheetData) { return __awaiter(void 0, void 0, void 0, function () {
        var response;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, postTimeSheetDayDetail(timesheetData)];
                case 1:
                    response = _a.sent();
                    return [2 /*return*/, response];
            }
        });
    }); },
    saveMultipleTimeSheetDayDetails: function (timesheetDataArray) { return __awaiter(void 0, void 0, void 0, function () {
        var response;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, postMultipleTimeSheetDayDetail(timesheetDataArray)];
                case 1:
                    response = _a.sent();
                    return [2 /*return*/, response];
            }
        });
    }); },
    postMissingTimeSheetDayDetail: function (weekEndingDate, timesheetData) { return __awaiter(void 0, void 0, void 0, function () {
        var response;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, postMissingTimeSheetDayDetail(weekEndingDate, timesheetData)];
                case 1:
                    response = _a.sent();
                    return [2 /*return*/, response];
            }
        });
    }); },
    saveWorkLocation: function (timesheetData) { return __awaiter(void 0, void 0, void 0, function () {
        var response;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, saveWorkLocation(timesheetData)];
                case 1:
                    response = _a.sent();
                    return [2 /*return*/, response];
            }
        });
    }); },
    getStateFromZipCode: function (zipcode) { return __awaiter(void 0, void 0, void 0, function () {
        var response;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, getStateFromZipCode(zipcode)];
                case 1:
                    response = _a.sent();
                    set(function (state) { return (__assign(__assign({}, state), { stateFromZipCode: response.data })); });
                    return [2 /*return*/, response];
            }
        });
    }); },
    getTimeSheetDayDetail: function (timeSheetDayDetailID) { return __awaiter(void 0, void 0, void 0, function () {
        var response;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, getTimeSheetDayDetail(timeSheetDayDetailID)];
                case 1:
                    response = _a.sent();
                    set(function (state) { return (__assign(__assign({}, state), { timeSheetDataEdit: response.data })); });
                    return [2 /*return*/, response];
            }
        });
    }); },
    getNonWorkedHoursCodes: function (clientID) { return __awaiter(void 0, void 0, void 0, function () {
        var response;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, getNonWorkedHoursCodes(clientID)];
                case 1:
                    response = _a.sent();
                    set(function (state) { return (__assign(__assign({}, state), { nonWorkedHoursCodes: response.data })); });
                    return [2 /*return*/];
            }
        });
    }); },
    getClientSupervisors: function (clientID) { return __awaiter(void 0, void 0, void 0, function () {
        var response;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, getClientSupervisors(clientID)];
                case 1:
                    response = _a.sent();
                    set(function (state) { return (__assign(__assign({}, state), { clientSupervisors: response.data })); });
                    return [2 /*return*/];
            }
        });
    }); },
    getEmployeeInformation: function (employeeId, ignoreEmployeeRepeatI9) {
        if (ignoreEmployeeRepeatI9 === void 0) { ignoreEmployeeRepeatI9 = false; }
        return __awaiter(void 0, void 0, void 0, function () {
            var response, enableItem, isEmployeeRepeatI9, data_1;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!(useTimesheetViewContext.getState().empInfoAPITriggered === false)) return [3 /*break*/, 2];
                        useTimesheetViewContext.getState().resetEmployeeInfoAPITriggered(true);
                        return [4 /*yield*/, fetchEmployeeInformation(employeeId, ignoreEmployeeRepeatI9)];
                    case 1:
                        response = _a.sent();
                        enableItem = true;
                        isEmployeeRepeatI9 = response.data.params.PageName === "EmployeeRepeatI9";
                        if (isEmployeeRepeatI9) {
                            enableItem = false;
                        }
                        else if (response.data.clientID === PUSH_CLIENT.CLIENT_ID) {
                            enableItem = false;
                        }
                        else if (!response.data.isEditTimesheet) {
                            enableItem = false;
                        }
                        data_1 = __assign(__assign({}, response.data), { isEmployeeRepeatI9: isEmployeeRepeatI9, enableItem: enableItem });
                        localStorage.setItem("employeeInformation", JSON.stringify(data_1));
                        set(function (state) { return (__assign(__assign({}, state), { employeeInformation: data_1, empInfoAPITriggered: false })); });
                        _a.label = 2;
                    case 2: return [2 /*return*/];
                }
            });
        });
    },
    resetEmployeeInfoAPITriggered: function (value) {
        set(function (state) { return (__assign(__assign({}, state), { empInfoAPITriggered: value })); });
    },
    resetStateValue: function () {
        set(function (state) { return (__assign(__assign({}, state), { stateFromZipCode: {} })); });
    },
    fetchClientTimesheet: function (querystring) { return __awaiter(void 0, void 0, void 0, function () {
        var response;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, fetchClientTimesheet(querystring)];
                case 1:
                    response = _a.sent();
                    set(function (state) { return (__assign(__assign({}, state), { clienttimesheetdata: response.data })); });
                    return [2 /*return*/, response];
            }
        });
    }); },
    fetchClientcostcenter: function (clientid) { return __awaiter(void 0, void 0, void 0, function () {
        var response;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, fetchClientcostcenter(clientid)];
                case 1:
                    response = _a.sent();
                    set(function (state) { return (__assign(__assign({}, state), { ClientcostcenterDropdown: response.data })); });
                    return [2 /*return*/, response];
            }
        });
    }); },
    fetchtimesheetstatus: function () { return __awaiter(void 0, void 0, void 0, function () {
        var response;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, fetchtimesheetstatus()];
                case 1:
                    response = _a.sent();
                    set(function (state) { return (__assign(__assign({}, state), { timesheetstatusall: response.data, timesheetstatuswithselectall: __spreadArray([
                            {
                                text: ComponentConstant.CHECK_ALL_DROPDOWN_VALUE,
                                value: null,
                            }
                        ], response.data, true) })); });
                    return [2 /*return*/];
            }
        });
    }); },
    getWorkLocationByID: function (workLocationID) { return __awaiter(void 0, void 0, void 0, function () {
        var response;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, getWorkLocationByID(workLocationID)];
                case 1:
                    response = _a.sent();
                    set(function (state) { return (__assign(__assign({}, state), { workLocationId: response.data })); });
                    return [2 /*return*/];
            }
        });
    }); },
    updateDirectDepositPaycard: function (groupData) { return __awaiter(void 0, void 0, void 0, function () {
        var response;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, updateDirectDepositOrPaycard(groupData)];
                case 1:
                    response = _a.sent();
                    return [2 /*return*/, response];
            }
        });
    }); },
    updateFederalStateW4: function (groupData) { return __awaiter(void 0, void 0, void 0, function () {
        var response;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, updateW4Async(groupData)];
                case 1:
                    response = _a.sent();
                    return [2 /*return*/, response];
            }
        });
    }); },
    postOnLoadPopupSignUp: function (formData) { return __awaiter(void 0, void 0, void 0, function () { return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, postOnLoadPopupSignUp(formData)];
            case 1: return [2 /*return*/, _a.sent()];
        }
    }); }); },
    saveTimesheetVZDay: function (postData) { return __awaiter(void 0, void 0, void 0, function () {
        var response;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, saveTimesheetVZDay(postData)];
                case 1:
                    response = _a.sent();
                    return [2 /*return*/, response];
            }
        });
    }); },
    saveTimesheetVZWorkItem: function (postData) { return __awaiter(void 0, void 0, void 0, function () {
        var response;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, saveTimesheetVZWorkItem(postData)];
                case 1:
                    response = _a.sent();
                    return [2 /*return*/, response];
            }
        });
    }); },
    getTimeSheetVZ: function (timeSheetID) { return __awaiter(void 0, void 0, void 0, function () {
        var response;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, getTimeSheetVZ(timeSheetID)];
                case 1:
                    response = _a.sent();
                    set(function (state) { return (__assign(__assign({}, state), { timeSheetDetails: response.data })); });
                    return [2 /*return*/];
            }
        });
    }); },
    getTimeSheetVZDay: function (timeSheetVZDayID, userRole) { return __awaiter(void 0, void 0, void 0, function () {
        var response;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, getTimeSheetVZDay(timeSheetVZDayID, userRole)];
                case 1:
                    response = _a.sent();
                    set(function (state) { return (__assign(__assign({}, state), { timeSheetVzDayList: response.data })); });
                    return [2 /*return*/];
            }
        });
    }); },
    getTimeSheetVZDayDetails: function (timeSheetVZDayID) { return __awaiter(void 0, void 0, void 0, function () {
        var response;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, getTimeSheetVZDayDetails(timeSheetVZDayID)];
                case 1:
                    response = _a.sent();
                    set(function (state) { return (__assign(__assign({}, state), { timesheetVzDetailsList: response.data })); });
                    return [2 /*return*/];
            }
        });
    }); },
    getVZDayDetailPercentUsed: function (timeSheetID, timeSheetVZDayID) { return __awaiter(void 0, void 0, void 0, function () {
        var response;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, getVZDayDetailPercentUsed(timeSheetID, timeSheetVZDayID)];
                case 1:
                    response = _a.sent();
                    set(function (state) { return (__assign(__assign({}, state), { dayPercentageUsed: response.data })); });
                    return [2 /*return*/, response];
            }
        });
    }); },
    updateSickLeaveNoticeReadDate: function (employeeId) { return __awaiter(void 0, void 0, void 0, function () {
        var response;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, updateSickLeaveNoticeReadDateAsync(employeeId)];
                case 1:
                    response = _a.sent();
                    return [2 /*return*/, response];
            }
        });
    }); },
    getSubAccount: function (subAccountId) { return __awaiter(void 0, void 0, void 0, function () {
        var response;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, getSubAccount(subAccountId)];
                case 1:
                    response = _a.sent();
                    return [2 /*return*/, response];
            }
        });
    }); },
    deleteTimesheetDayVZ: function (timeSheetVzDayId) { return __awaiter(void 0, void 0, void 0, function () {
        var response;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, deleteTimesheetDayVZ(timeSheetVzDayId)];
                case 1:
                    response = _a.sent();
                    return [2 /*return*/, response];
            }
        });
    }); },
    deleteTimesheetDayDetailVZ: function (timeSheetDayDetailID) { return __awaiter(void 0, void 0, void 0, function () {
        var response;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, deleteTimesheetDayDetailVZ(timeSheetDayDetailID)];
                case 1:
                    response = _a.sent();
                    return [2 /*return*/, response];
            }
        });
    }); },
    deleteAllTimeSheetDayDetailsVZ: function (timeSheetVZDayID) { return __awaiter(void 0, void 0, void 0, function () {
        var response;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, deleteAllTimeSheetDayDetailsVZ(timeSheetVZDayID)];
                case 1:
                    response = _a.sent();
                    return [2 /*return*/, response];
            }
        });
    }); },
    getVZDay: function (timeSheetVZDayID) { return __awaiter(void 0, void 0, void 0, function () {
        var response;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, getVZDay(timeSheetVZDayID)];
                case 1:
                    response = _a.sent();
                    return [2 /*return*/, response];
            }
        });
    }); },
    resetTimeSheetData: function () {
        set(function (state) { return (__assign(__assign({}, state), { timeSheetDataEdit: {} })); });
    },
    fetchtimesheetsforapproval: function (querystring) { return __awaiter(void 0, void 0, void 0, function () {
        var response;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, gettimesheetsforapproval(querystring)];
                case 1:
                    response = _a.sent();
                    set(function (state) { return (__assign(__assign({}, state), { timesheetApprovalList: response.data })); });
                    return [2 /*return*/, response];
            }
        });
    }); },
    approveTimesheets: function (postData) { return __awaiter(void 0, void 0, void 0, function () {
        var response;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, approveTimesheets(postData)];
                case 1:
                    response = _a.sent();
                    return [2 /*return*/, response];
            }
        });
    }); },
    approveTimesheet: function (postData) { return __awaiter(void 0, void 0, void 0, function () {
        var response;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, approveTimesheet(postData)];
                case 1:
                    response = _a.sent();
                    return [2 /*return*/, response];
            }
        });
    }); },
    returnTimesheetToEmployee: function (queryString) { return __awaiter(void 0, void 0, void 0, function () {
        var response;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, returnTimesheetToEmployee(queryString)];
                case 1:
                    response = _a.sent();
                    return [2 /*return*/, response];
            }
        });
    }); },
    timeSheetSaveAccount: function (postData) { return __awaiter(void 0, void 0, void 0, function () {
        var response;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, timeSheetSaveAccount(postData)];
                case 1:
                    response = _a.sent();
                    return [2 /*return*/, response];
            }
        });
    }); },
    FetchTimeSheetEmpCmnt: function (id) { return __awaiter(void 0, void 0, void 0, function () {
        var response;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, getTimeSheetEmpcmnt(id)];
                case 1:
                    response = _a.sent();
                    set(function (state) { return (__assign(__assign({}, state), { TimeSheetEmpComntList: response.data })); });
                    return [2 /*return*/, response];
            }
        });
    }); },
    getAccounts: function (queryString) { return __awaiter(void 0, void 0, void 0, function () {
        var response;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, getAccountsList(queryString)];
                case 1:
                    response = _a.sent();
                    set(function (state) { return (__assign(__assign({}, state), { timesheetAccountsList: response.data })); });
                    return [2 /*return*/];
            }
        });
    }); },
    fetchWeekEndingDateTimeSheet: function (id) { return __awaiter(void 0, void 0, void 0, function () {
        var response;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, getWeekEndingDateTimeSheet(id)];
                case 1:
                    response = _a.sent();
                    set(function (state) { return (__assign(__assign({}, state), { weekEndingDateTimeSheetList: response.data })); });
                    return [2 /*return*/, response];
            }
        });
    }); },
    fetchTimeSheetDayDetailsAccounts: function (queryStr) { return __awaiter(void 0, void 0, void 0, function () {
        var response;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, getTimeSheetDayDetailsAccounts(queryStr)];
                case 1:
                    response = _a.sent();
                    set(function (state) { return (__assign(__assign({}, state), { timeSheetDayDetailsAccountsList: response.data })); });
                    return [2 /*return*/, response];
            }
        });
    }); },
    fetchUnsignedTimeSheetforEmployee: function (queryStr) { return __awaiter(void 0, void 0, void 0, function () {
        var response;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, getUnsignedTimeSheetforEmployee(queryStr)];
                case 1:
                    response = _a.sent();
                    set(function (state) { return (__assign(__assign({}, state), { manageUnsignedTimeSheetforEmployeeList: response.data })); });
                    return [2 /*return*/, response];
            }
        });
    }); },
    updateNoticeReadDate: function (employeeId, type) { return __awaiter(void 0, void 0, void 0, function () {
        var response;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, updateNoticeReadDate(employeeId, type)];
                case 1:
                    response = _a.sent();
                    return [2 /*return*/, response];
            }
        });
    }); },
    updateEmployeeRepeatI9: function (dataObj) { return __awaiter(void 0, void 0, void 0, function () {
        var response;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, updateEmployeeRepeatI9(dataObj)];
                case 1:
                    response = _a.sent();
                    return [2 /*return*/, response];
            }
        });
    }); },
    redirectEmployeeRepeatI9: function (dataObj) { return __awaiter(void 0, void 0, void 0, function () {
        var response;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, redirectEmployeeRepeatI9(dataObj)];
                case 1:
                    response = _a.sent();
                    return [2 /*return*/, response];
            }
        });
    }); },
    fetchAgreementDateRange: function (employeeId) { return __awaiter(void 0, void 0, void 0, function () {
        var response;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, getAgreementDateRange(employeeId)];
                case 1:
                    response = _a.sent();
                    set(function (state) { return (__assign(__assign({}, state), { agreementDateRange: response.data })); });
                    return [2 /*return*/, response];
            }
        });
    }); },
    getWorkLocationDataByID: function (workLocationID) { return __awaiter(void 0, void 0, void 0, function () {
        var response;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, getWorkLocationByID(workLocationID)];
                case 1:
                    response = _a.sent();
                    set(function (state) { return (__assign(__assign({}, state), { workLocationByIdList: response.data })); });
                    return [2 /*return*/, response];
            }
        });
    }); },
    redirectSignAgreement: function (dataObj) { return __awaiter(void 0, void 0, void 0, function () {
        var response;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, getSignAgreementUrl(dataObj)];
                case 1:
                    response = _a.sent();
                    return [2 /*return*/, response];
            }
        });
    }); },
    getProjectsForWorkDescriptions: function (timeSheetId) { return __awaiter(void 0, void 0, void 0, function () {
        var response;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, getProjectsForWorkDescriptions(timeSheetId)];
                case 1:
                    response = _a.sent();
                    set(function (state) { return (__assign(__assign({}, state), { workDescriptionProjectList: response.data })); });
                    return [2 /*return*/];
            }
        });
    }); },
    saveWorkDescription: function (dataObject) { return __awaiter(void 0, void 0, void 0, function () {
        var response;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, saveWorkDescription(dataObject)];
                case 1:
                    response = _a.sent();
                    return [2 /*return*/, response];
            }
        });
    }); },
    timeSheetPreSignAndSubmitValidation: function (timeSheetID) { return __awaiter(void 0, void 0, void 0, function () {
        var response;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, timeSheetPreSignAndSubmitValidation(timeSheetID)];
                case 1:
                    response = _a.sent();
                    return [2 /*return*/, response];
            }
        });
    }); },
    getWaivableMealPenaltiesDt: function (timeSheetID) { return __awaiter(void 0, void 0, void 0, function () {
        var response;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, getWaivableMealPenaltiesDt(timeSheetID)];
                case 1:
                    response = _a.sent();
                    set(function (state) { return (__assign(__assign({}, state), { waivableMealPenaltiesList: response.data })); });
                    return [2 /*return*/];
            }
        });
    }); },
    waiveMealPenalty: function (dataObject) { return __awaiter(void 0, void 0, void 0, function () {
        var response;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, waiveMealPenalty(dataObject)];
                case 1:
                    response = _a.sent();
                    return [2 /*return*/, response];
            }
        });
    }); },
    possibleOnsiteMealBreaksExist: function (timeSheetID) { return __awaiter(void 0, void 0, void 0, function () {
        var response;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, possibleOnsiteMealBreaksExist(timeSheetID)];
                case 1:
                    response = _a.sent();
                    set(function (state) { return (__assign(__assign({}, state), { onSiteMealBreakExistList: response.data })); });
                    return [2 /*return*/];
            }
        });
    }); },
    submitMealBreakOnsiteAsync: function (dataObject) { return __awaiter(void 0, void 0, void 0, function () {
        var response;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, submitMealBreakOnsiteAsync(dataObject)];
                case 1:
                    response = _a.sent();
                    return [2 /*return*/, response];
            }
        });
    }); },
    saveReCalculateTimeSheet: function (groupData) { return __awaiter(void 0, void 0, void 0, function () { return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, saveReCalculateTimeSheet(groupData)];
            case 1: return [2 /*return*/, _a.sent()];
        }
    }); }); },
    validateVZTimeSheet: function (timeSheetID) { return __awaiter(void 0, void 0, void 0, function () { return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, validateVZTimeSheet(timeSheetID)];
            case 1: return [2 /*return*/, _a.sent()];
        }
    }); }); },
    postTimecardBackup: function (groupData) { return __awaiter(void 0, void 0, void 0, function () {
        var response;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, postTimecardBackup(groupData)];
                case 1:
                    response = _a.sent();
                    return [2 /*return*/, response];
            }
        });
    }); },
    mealPenaltiesWaivableExist: function (timesheetId) { return __awaiter(void 0, void 0, void 0, function () {
        var response;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, mealPenaltiesWaivableExist(timesheetId)];
                case 1:
                    response = _a.sent();
                    return [2 /*return*/, response];
            }
        });
    }); },
    resetCitiesFromStates: function () { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            set(function (state) { return (__assign(__assign({}, state), { citiesFromStates: [], citiesFromStatesObj: [] })); });
            return [2 /*return*/];
        });
    }); },
    resetZipcodeFromCity: function () { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            set(function (state) { return (__assign(__assign({}, state), { zipcodeFromCity: [] })); });
            return [2 /*return*/];
        });
    }); },
    resetAccountSubAccounts: function () { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            set(function (state) { return (__assign(__assign({}, state), { timesheetAccountsList: [], timesheetSubAccountsList: [] })); });
            return [2 /*return*/];
        });
    }); },
}); });
