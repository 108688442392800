var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
var NoAccessPage = function () {
    return (_jsxs(_Fragment, { children: [_jsxs("section", __assign({ className: "e404-container" }, { children: [_jsx("span", { children: "4" }), _jsx("span", { children: _jsx("span", __assign({ className: "screen-reader-text" }, { children: "0" })) }), _jsx("span", { children: "3" })] })), _jsxs("div", __assign({ className: "e404-info" }, { children: [_jsx("h2", { children: "We can't find that page" }), _jsx("p", { children: "We're fairly sure that page used to be here, but seems to have gone missing or you are not authorized to view that page. We do apologise on it's behalf." })] }))] }));
};
export default NoAccessPage;
