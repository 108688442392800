var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import { useCallback, useEffect, useState } from "react";
import { Grid, ActionCell, createEncodedURL } from "components/shared";
import { getSelectedState, } from "@progress/kendo-react-grid";
import { getter } from "@progress/kendo-data-query";
import { useBatchesImportContext } from "store/batchimport/batchimport";
import { useNavigate, useParams } from "react-router-dom";
var DATA_ITEM_KEY = "manualPaymentID";
var SELECTED_FIELD = "selected";
var idGetter = getter(DATA_ITEM_KEY);
export default function BatchManualPaymentsGrid(_a) {
    var _b = _a.colums, colums = _b === void 0 ? [] : _b, _c = _a.gridData, gridData = _c === void 0 ? [] : _c, _d = _a.multiSelect, multiSelect = _d === void 0 ? false : _d, handleRefresh = _a.handleRefresh, backRoute = _a.backRoute, _e = _a.tabCode, tabCode = _e === void 0 ? "" : _e;
    var params = useParams();
    var exportsId = parseInt(params.exportsId);
    var _f = useBatchesImportContext(), selectedExportsData = _f.selectedExportsData, setSelectedExportsData = _f.setSelectedExportsData;
    var _g = useState(gridData || []), GridData = _g[0], setGridData = _g[1];
    var navigate = useNavigate();
    var _h = useState(selectedExportsData["".concat(tabCode)] || {}), selectedRecords = _h[0], setSelectedRecords = _h[1];
    var updateSelectedRows = function (values) {
        var selectedRows = __assign({}, selectedExportsData);
        selectedRows["".concat(tabCode)] = values;
        setSelectedExportsData(selectedRows);
    };
    var getColumns = function () {
        return multiSelect
            ? [
                {
                    title: "",
                    field: SELECTED_FIELD,
                    width: "40px",
                    filterable: false,
                    headerSelectionValue: gridData.findIndex(function (item) { return !selectedRecords[idGetter(item)]; }) === -1,
                },
            ]
            : [];
    };
    var onViewBtnClick = function (cell) {
        var dataItem = cell.dataItem;
        var url = createEncodedURL("/viewmanualpayments", {
            page: "view",
            manualPaymentID: dataItem.manualPaymentID,
            redirectFrom: "batches",
            redirectFromUrl: {
                id: "batchesManualPayments",
                text: "Batch Records Manual Payments",
                route: backRoute || "/batchrecords/".concat(exportsId, "?activetab=4"),
            },
            isReturnToClientVisible: true,
        });
        navigate(url);
    };
    var Columns = __spreadArray(__spreadArray(__spreadArray(__spreadArray([], getColumns(), true), [
        {
            cell: function (cell) { return (_jsx(ActionCell, { cellprops: cell, title: "View", onClick: function () { return onViewBtnClick(cell); } })); },
            field: "",
            title: "View",
            width: "80px",
            filterable: false,
        }
    ], false), colums, true), [
        { field: "manualPaymentType", title: "Manual Payment Type", width: "150px" },
        { field: "lastName", title: "Last Name", width: "150px" },
        { field: "firstName", title: "First Name", width: "150px" },
        { field: "grossPay", title: "Gross Pay", width: "150px", format: "{0:c2}" },
        { field: "invoicedAmount", title: "Invoiced Amount", width: "250px", format: "{0:c2}" },
    ], false);
    var onSelectionChange = useCallback(function (event) {
        var newSelectedState = getSelectedState({
            event: event,
            selectedState: selectedRecords,
            dataItemKey: DATA_ITEM_KEY,
        });
        setSelectedRecords(newSelectedState);
        updateSelectedRows(newSelectedState);
    }, [selectedRecords]);
    var onHeaderSelectionChange = useCallback(function (event) {
        var checkboxElement = event.syntheticEvent.target;
        var checked = checkboxElement.checked;
        var newSelectedState = {};
        event.dataItems.forEach(function (item) {
            newSelectedState[idGetter(item)] = checked;
        });
        setSelectedRecords(newSelectedState);
        updateSelectedRows(newSelectedState);
    }, []);
    useEffect(function () {
        if (gridData)
            setGridData(gridData);
    }, [gridData]);
    return (_jsx(_Fragment, { children: multiSelect ? (_jsx(Grid, { columns: Columns, gridData: GridData.map(function (item) {
                var _a;
                return (__assign(__assign({}, item), (_a = {}, _a[SELECTED_FIELD] = selectedRecords[idGetter(item)], _a)));
            }), dataItemKey: DATA_ITEM_KEY, selectedField: SELECTED_FIELD, selectable: {
                enabled: true,
                drag: false,
                cell: false,
                mode: "multiple",
            }, onSelectionChange: onSelectionChange, onHeaderSelectionChange: onHeaderSelectionChange, handleRefresh: handleRefresh })) : (_jsx(Grid, { columns: Columns, gridData: gridData, handleRefresh: handleRefresh })) }));
}
