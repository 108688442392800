var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React, { useEffect } from "react";
import { Form, FormElement } from "@progress/kendo-react-form";
import { Checkbox } from "@progress/kendo-react-inputs";
import { Transform, TranslationConstant, FormButtons, AppConstant, useDashboardContext, } from "./index";
export var DownloadDocuments = function (props) {
    var _a = useDashboardContext(), completedNewHireDocuments = _a.completedNewHireDocuments, fetchCompletedNewHireDocuments = _a.fetchCompletedNewHireDocuments, updateCompletedNewHireDocument = _a.updateCompletedNewHireDocument;
    var hideModal = props.hideModal, newHireJobID = props.newHireJobID, applicationId = props.applicationId, employeeProfile = props.employeeProfile;
    var _b = React.useState([]), selectedDocs = _b[0], setSelectedDocs = _b[1];
    var _c = React.useState(false), isCheckAll = _c[0], setIsCheckAll = _c[1];
    var _d = React.useState([]), isCheck = _d[0], setIsCheck = _d[1];
    var _e = React.useState([]), list = _e[0], setList = _e[1];
    useEffect(function () {
        fetchCompletedDocuments();
    }, []);
    useEffect(function () {
        if (completedNewHireDocuments.length > 0) {
            var cart_1 = [];
            completedNewHireDocuments.forEach(function (item, index) {
                var element = {};
                element.id = item.name.split(" ").join("") + index.toString();
                element.priority = item.priority;
                element.fileUrl = item.fileUrl;
                element.newHireDocumentID = item.newHireDocumentID;
                element.name = item.name;
                element.checked = false;
                cart_1.push(element);
            });
            setList(cart_1);
        }
    }, [completedNewHireDocuments]);
    var fetchCompletedDocuments = function () {
        fetchCompletedNewHireDocuments(newHireJobID);
    };
    var handleSelectAll = function (e) {
        setIsCheckAll(!isCheckAll);
        setIsCheck(list.map(function (li) { return li.id; }));
        if (!isCheckAll) {
            var updatedList_1 = [];
            setSelectedDocs([]);
            completedNewHireDocuments.forEach(function (item) {
                updatedList_1.push(item.fileUrl);
            });
            setSelectedDocs(updatedList_1);
        }
        else {
            setIsCheck([]);
            setSelectedDocs([]);
        }
        list.forEach(function (item) {
            item.checked = e.target.element.checked;
        });
        setList(list);
    };
    var handleCheck = function (event) {
        var _a = event.target.element, id = _a.id, checked = _a.checked;
        setIsCheck(__spreadArray(__spreadArray([], isCheck, true), [id], false));
        if (!checked) {
            setIsCheck(isCheck.filter(function (item) { return item !== id; }));
        }
        var selectedUrl = __spreadArray([], selectedDocs, true);
        if (event.target.element.checked) {
            selectedUrl = __spreadArray(__spreadArray([], selectedDocs, true), [event.target.element.value], false);
        }
        else {
            setIsCheckAll(false);
            selectedUrl.splice(selectedDocs.indexOf(event.target.element.value), 1);
        }
        list.forEach(function (item) {
            if (event.target.element.id === item.id) {
                item.checked = event.target.element.checked;
            }
        });
        setList(list);
        setSelectedDocs(selectedUrl);
        var allChecked = true;
        list.forEach(function (item) {
            if (item.checked === false) {
                allChecked = item.checked;
                return;
            }
        });
        setIsCheckAll(allChecked);
    };
    var handleSubmit = function () {
        if (selectedDocs.length === 0) {
            return;
        }
        var applicationID = applicationId;
        var dataObj = {
            newHireJobID: newHireJobID,
            fileUrls: selectedDocs,
        };
        updateCompletedDocument(applicationID, dataObj);
    };
    var updateCompletedDocument = function (applicationID, dataObj) { return __awaiter(void 0, void 0, void 0, function () {
        var response, e_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 2, , 3]);
                    return [4 /*yield*/, updateCompletedNewHireDocument(applicationID, dataObj)];
                case 1:
                    response = _a.sent();
                    if (AppConstant.HTTP_SUCCESS_CODES.includes(response.status)) {
                        downloadReport(response);
                    }
                    return [3 /*break*/, 3];
                case 2:
                    e_1 = _a.sent();
                    console.log("Exception in Onboarding Reports::", e_1);
                    return [3 /*break*/, 3];
                case 3: return [2 /*return*/];
            }
        });
    }); };
    var downloadReport = function (response) {
        var url = window.URL.createObjectURL(new Blob([response.data], { type: "application/pdf" }));
        var link = document.createElement("a");
        link.href = url;
        var fileName = "".concat(employeeProfile.firstName, " ").concat(employeeProfile.lastName, "_CompletedDocuments");
        link.setAttribute("download", "".concat(fileName, ".pdf"));
        document.body.appendChild(link);
        link.click();
    };
    var docList = list.map(function (item, index) {
        return (_jsx(_Fragment, { children: _jsxs("div", __assign({ className: "row" }, { children: [_jsx("div", __assign({ className: "col-xs-1 col-md-1 col-1 mb-3 ms-2" }, { children: _jsx(Checkbox, { name: item.name, checked: isCheck.includes(item.id), onChange: function (event) { return handleCheck(event); }, id: item.id, value: item.fileUrl, className: "float-right" }) }), index), _jsx("div", __assign({ className: "col-xs-10 col-md-10 col-10 mb-3" }, { children: item.name }))] })) }));
    });
    var closePopup = function () {
        hideModal();
    };
    return (_jsx(_Fragment, { children: _jsx(Form, { onSubmitClick: handleSubmit, render: function () { return (_jsxs(FormElement, __assign({ className: "general-form inside-popup-form" }, { children: [_jsx("fieldset", __assign({ className: "k-form-fieldset" }, { children: list.length > 0 ? (_jsxs(_Fragment, { children: [_jsxs("div", __assign({ className: "row" }, { children: [_jsx("div", __assign({ className: "col-xs-1 col-md-1 col-1 mb-3 ms-2" }, { children: _jsx(Checkbox, { type: "checkbox", className: "float-right", name: "selectAll", id: "selectAll", onChange: function (e) { return handleSelectAll(e); }, checked: isCheckAll }) })), _jsx("div", __assign({ className: "col-xs-10 col-md-10 col-10 mb-3" }, { children: _jsx("strong", { children: _jsx(Transform, { mkey: TranslationConstant.SELECT_ALL_DOCUMENTS }) }) }))] })), _jsx("div", __assign({ className: "document-list vertical-sleekbar" }, { children: docList }))] })) : (_jsx("div", __assign({ className: "row" }, { children: _jsx("div", __assign({ className: "col-xs-11 col-md-11 col-11 mb-3 ms-2" }, { children: _jsx(Transform, { mkey: TranslationConstant.DOCUMENTS_NOT_AVAILABLE }) })) }))) })), _jsx(FormButtons, { hideSubmit: list.length > 0, disabled: isCheck.length === 0, customSubmitText: TranslationConstant.DOWNLOAD, showCancelButton: true, closeText: _jsx(Transform, { mkey: TranslationConstant.CLOSE_BUTTON }), hideResetButton: true, cancelHandler: closePopup })] }))); } }) }));
};
