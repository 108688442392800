var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import axios from "axios";
import { useCurrentViewContext } from "store/common/currentViewContext";
import { AppConstant, ServiceConstant } from "common/constant/index";
import { renewSilentSession, signinSilentSessionCallback } from "services/auth/auth";
import { startTimeInterval } from "components/shared/utils";
var httpRequests = [];
var httpPromise = axios.create({
    baseURL: process.env.WEB_API_BASE_PATH,
});
startTimeInterval();
httpPromise.defaults.timeout = AppConstant.AXIOS_API_TIMEOUT;
httpPromise.interceptors.request.use(function (config) {
    if (!useCurrentViewContext.getState().resetShowProgressLoader) {
        useCurrentViewContext.getState().setShowProgress(true);
        httpRequests.push(Math.random());
    }
    // add headers here
    config.headers[ServiceConstant.AUTHORIZATION] = localStorage.getItem("oidc.user:".concat(process.env.AUTHORITY_URL, ":").concat(process.env.CLIENT_ID))
        ? "bearer ".concat(JSON.parse(localStorage.getItem("oidc.user:".concat(process.env.AUTHORITY_URL, ":").concat(process.env.CLIENT_ID))).access_token)
        : null;
    var isCorp = location.pathname.indexOf(AppConstant.CORP_BASE_PATHNAME) === 0 ? true : false;
    var userProfileKey = "".concat(AppConstant.LOCAL_STORAGE_USER_PROFILE, ":").concat(process.env.AUTHORITY_URL, ":").concat(process.env.CLIENT_ID);
    if (isCorp) {
        userProfileKey += ":".concat(AppConstant.CORP_TYPE_APPLICATION);
    }
    config.headers[ServiceConstant.USER_DETAILS] =
        localStorage.getItem(userProfileKey) || -1;
    config.headers[ServiceConstant.ACCEPT_LANGUAGE] = useCurrentViewContext.getState().locale;
    return config;
}, function (error) {
    useCurrentViewContext.getState().setApiFailure(true);
    useCurrentViewContext.getState().resetShowProgress(false);
    useCurrentViewContext.getState().setJobLoadingProgress(false);
    popHttpRequest();
    return Promise.reject(error);
});
httpPromise.interceptors.response.use(function (response) {
    useCurrentViewContext.getState().resetTimeToLogOut(true);
    useCurrentViewContext.getState().resetShowProgress(false);
    popHttpRequest();
    if (useCurrentViewContext.getState().isRenewRequired)
        renewSession();
    return Promise.resolve(response);
}, function (error) {
    var _a, _b, _c;
    if (((_a = error === null || error === void 0 ? void 0 : error.response) === null || _a === void 0 ? void 0 : _a.status) !== 400 &&
        ((_b = error === null || error === void 0 ? void 0 : error.response) === null || _b === void 0 ? void 0 : _b.status) !== 401 &&
        ((_c = error === null || error === void 0 ? void 0 : error.response) === null || _c === void 0 ? void 0 : _c.status) !== undefined) {
        useCurrentViewContext.getState().setApiFailure(true);
    }
    useCurrentViewContext.getState().setJobLoadingProgress(false);
    popHttpRequest();
    return Promise.reject(error);
});
var popHttpRequest = function () {
    httpRequests.pop();
    if (httpRequests.length <= 0) {
        useCurrentViewContext.getState().setShowProgress(false);
    }
};
export var renewSession = function () { return __awaiter(void 0, void 0, void 0, function () {
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, renewSilentSession()];
            case 1:
                _a.sent();
                signinSilentSessionCallback();
                return [2 /*return*/];
        }
    });
}); };
export { httpPromise };
