var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx } from "react/jsx-runtime";
import { useEffect } from "react";
import { useCurrentViewContext } from "store/common/currentViewContext";
import Spinner from "./Spinner";
import { AppConstant } from "common/constant";
import { getCookie } from "./utils";
var AdminAsEmployeeLogin = function () {
    var queryParameters = new URLSearchParams(window.location.search);
    var _a = useCurrentViewContext(), fetchLoggedInUserDetails = _a.fetchLoggedInUserDetails, fetchNewHireJobIDByEmpId = _a.fetchNewHireJobIDByEmpId;
    var employeeUsername, clientId;
    var refreshUserDetails = function () { return __awaiter(void 0, void 0, void 0, function () {
        var queryParams, isAdmin, response, oidcUserProfile, err_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (queryParameters.get("isRevampRedirection") &&
                        JSON.parse(queryParameters.get("isRevampRedirection"))) {
                        employeeUsername = queryParameters.get("username");
                        localStorage.setItem("isAdminLoggedInAsEmployee", "true");
                    }
                    else {
                        queryParams = getCookie(AppConstant.ADMIN_AS_EMPLOYEE_COOKIE_NAME);
                        employeeUsername = queryParams.split("&isAdmin")[0].replace("username=", "");
                        isAdmin = queryParams.split("&isAdmin")[1].replace("=", "");
                        clientId = Number(queryParams.split("&clientId=")[1]);
                        if (isAdmin) {
                            localStorage.setItem("isAdminLoggedInAsEmployee", "true");
                            localStorage.setItem("employeeUsername", employeeUsername);
                        }
                    }
                    if (!employeeUsername) return [3 /*break*/, 7];
                    _a.label = 1;
                case 1:
                    _a.trys.push([1, 5, , 6]);
                    return [4 /*yield*/, fetchLoggedInUserDetails(employeeUsername, 2)];
                case 2:
                    response = _a.sent();
                    return [4 /*yield*/, fetchLoggedInUserDetails(employeeUsername, 1, clientId)];
                case 3:
                    _a.sent();
                    return [4 /*yield*/, fetchNewHireJobIDByEmpId(response.data.IndexNumber)];
                case 4:
                    _a.sent();
                    oidcUserProfile = JSON.parse(localStorage.getItem("oidc.user:".concat(process.env.AUTHORITY_URL, ":").concat(process.env.CLIENT_ID)));
                    oidcUserProfile.profile.preferred_username = employeeUsername;
                    localStorage.setItem("employeeUsername", employeeUsername);
                    localStorage.setItem("oidc.user:".concat(process.env.AUTHORITY_URL, ":").concat(process.env.CLIENT_ID), JSON.stringify(oidcUserProfile));
                    window.location.href = AppConstant.CORP_BASE_PATHNAME;
                    return [3 /*break*/, 6];
                case 5:
                    err_1 = _a.sent();
                    console.log(err_1);
                    window.location.href = "/";
                    return [3 /*break*/, 6];
                case 6: return [3 /*break*/, 8];
                case 7:
                    window.location.href = "/";
                    _a.label = 8;
                case 8: return [2 /*return*/];
            }
        });
    }); };
    useEffect(function () {
        refreshUserDetails();
    }, []);
    return _jsx(Spinner, {});
};
export default AdminAsEmployeeLogin;
