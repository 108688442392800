var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { create } from "zustand";
import { getICWorkerStatusList, getSearchNewHireJobList, addICUserAsOBJob, getNewHireDocumentList, getApprovedJobsList, getManageNewHireJobDocumentList, addDocumentToJob, cancelNewHireDocument, getNewHireDocsForJobManagement, uploadManuallyDocument, getApproveNewHireJob, getResendEmailVerifier, getICWorkerFlag, getScreeningInfo, cancelNewHireJob, addNewJob, getPayReelCordinatorList, getEmployeeJobForEdit, editSaveJob, getResendEmailUserDetails, postResendEmailTemplate, getI9AgentDetail, updateEmpAuthI9AgentMapping, getSupportingDocuments, } from "services/jobs";
import { useCurrentViewContext } from "store/common/currentViewContext";
import { ComponentConstant } from "common/constant";
import { sendEmailTemplate } from "services/common";
import { getSupportingDocumentCount, markScreeningComplete, getworkersupervisordetails, BulkJobsImport, } from "services/jobs/jobs";
export var useJobsViewContext = create(function (set, get) { return ({
    ICWorkerStatusResponse: [],
    ICWorkerUserAdd: false,
    searchNewHireJobList: [],
    searchNewHireJobsResponse: [],
    newHireDocumentListResponse: [],
    approvedJobsList: [],
    manageNewHireJobDocumentList: [],
    newHireDocsForJobManagementList: [],
    resendEmailVerifier: [],
    ICWorkerFlag: [],
    screeningInfo: [],
    payReelCoordinatorList: [],
    employeeDetailsForEdit: {},
    resendEmailUserDetails: [],
    I9AgentDetails: [],
    supportingDocument: "",
    showSupportingDocument: false,
    fetchICWorkerStatusList: function () { return __awaiter(void 0, void 0, void 0, function () {
        var response;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, getICWorkerStatusList()];
                case 1:
                    response = _a.sent();
                    set(function (state) { return (__assign(__assign({}, state), { ICWorkerStatusResponse: response.data })); });
                    return [2 /*return*/];
            }
        });
    }); },
    addICUserAsOBJob: function (jobEvaluationID) { return __awaiter(void 0, void 0, void 0, function () {
        var response;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, addICUserAsOBJob(jobEvaluationID)];
                case 1:
                    response = _a.sent();
                    return [2 /*return*/, response];
            }
        });
    }); },
    fetchSearchNewHireJobList: function (params) { return __awaiter(void 0, void 0, void 0, function () {
        var response, searchNewHireJobList;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, getSearchNewHireJobList(params)];
                case 1:
                    response = _a.sent();
                    searchNewHireJobList = response.data.items.map(function (item) { return (__assign(__assign({}, item), { showEditLinks: item.newHireJobStatusID === 50 ||
                            item.newHireJobStatusID >= 500 ||
                            item.newHireJobStatusID === 0
                            ? false
                            : true })); });
                    set(function (state) { return (__assign(__assign({}, state), { searchNewHireJobsResponse: searchNewHireJobList })); });
                    return [2 /*return*/, __assign(__assign({}, response), { data: __assign(__assign({}, response.data), { items: searchNewHireJobList }) })];
            }
        });
    }); },
    resetsearchNewHireJobsList: function () {
        set(function (state) { return (__assign(__assign({}, state), { searchNewHireJobsResponse: [] })); });
    },
    fetchApprovedJobsList: function () { return __awaiter(void 0, void 0, void 0, function () {
        var response;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, getApprovedJobsList()];
                case 1:
                    response = _a.sent();
                    set(function (state) { return (__assign(__assign({}, state), { approvedJobsList: response.data })); });
                    return [2 /*return*/];
            }
        });
    }); },
    fetchManageNewHireJobDocumentList: function (newHireJobID) { return __awaiter(void 0, void 0, void 0, function () {
        var response;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, getManageNewHireJobDocumentList(newHireJobID)];
                case 1:
                    response = _a.sent();
                    set(function (state) { return (__assign(__assign({}, state), { manageNewHireJobDocumentList: response.data })); });
                    return [2 /*return*/];
            }
        });
    }); },
    resetDropdownsValue: function () {
        set(function (state) { return (__assign(__assign({}, state), { accountDropDownList: [], supervisorDropDownList: [] })); });
    },
    fetchNewHireDocumentList: function (newHireJobID) { return __awaiter(void 0, void 0, void 0, function () {
        var response, modifiedResponse;
        var _a;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0: return [4 /*yield*/, getNewHireDocumentList(newHireJobID)];
                case 1:
                    response = _b.sent();
                    modifiedResponse = (_a = response.data) === null || _a === void 0 ? void 0 : _a.map(function (item) {
                        if (item.fileName !== null && item.fileName !== "") {
                            item["allowEdit"] = false;
                            item["viewEditText"] = ComponentConstant.VIEW_THIS_FORM;
                        }
                        else {
                            item["allowEdit"] = false;
                            item["viewEditText"] = "";
                        }
                        if (item.newHireDocumentStatusID ===
                            ComponentConstant.NEWHIRE_DOCUMENT_STATUS_CANCELLED_ID ||
                            item.newHireDocumentStatusID ===
                                ComponentConstant.NEWHIRE_DOCUMENT_STATUS_DECLINEDBY_EMPLOYEE_ID) {
                            item["allowCancel"] = false;
                        }
                        else {
                            item["allowCancel"] = true;
                        }
                        return item;
                    });
                    set(function (state) { return (__assign(__assign({}, state), { newHireDocumentListResponse: modifiedResponse })); });
                    return [2 /*return*/];
            }
        });
    }); },
    fetchNewHireDocsForJobManagement: function (newHireJobID) { return __awaiter(void 0, void 0, void 0, function () {
        var response;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, getNewHireDocsForJobManagement(newHireJobID)];
                case 1:
                    response = _a.sent();
                    set(function (state) { return (__assign(__assign({}, state), { newHireDocsForJobManagementList: response.data })); });
                    return [2 /*return*/];
            }
        });
    }); },
    addDocumentManuallyToJob: function (docTypeList) { return __awaiter(void 0, void 0, void 0, function () {
        var response;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, addDocumentToJob(docTypeList)];
                case 1:
                    response = _a.sent();
                    return [2 /*return*/, response];
            }
        });
    }); },
    uploadManuallyDocument: function (formData) { return __awaiter(void 0, void 0, void 0, function () {
        var response;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, uploadManuallyDocument(formData)];
                case 1:
                    response = _a.sent();
                    return [2 /*return*/, response];
            }
        });
    }); },
    cancelNewHireDocument: function (newHireDocumentID) { return __awaiter(void 0, void 0, void 0, function () {
        var response;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, cancelNewHireDocument(newHireDocumentID)];
                case 1:
                    response = _a.sent();
                    return [2 /*return*/, response];
            }
        });
    }); },
    cancelSearchNewHireJob: function (newHireJobID, isICAdvisor) { return __awaiter(void 0, void 0, void 0, function () {
        var response;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, cancelNewHireJob(newHireJobID, isICAdvisor)];
                case 1:
                    response = _a.sent();
                    return [2 /*return*/, response];
            }
        });
    }); },
    approveNewHireJob: function (newHireJobID) { return __awaiter(void 0, void 0, void 0, function () {
        var response;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, getApproveNewHireJob(newHireJobID)];
                case 1:
                    response = _a.sent();
                    return [2 /*return*/, response];
            }
        });
    }); },
    addNewJob: function (formData, emailTemplateData, supervisiorEmailTemplate, supervisor2EmailTemplate, isSupervisorEmailRequired) { return __awaiter(void 0, void 0, void 0, function () {
        var response;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, addNewJob(formData)];
                case 1:
                    response = _a.sent();
                    useCurrentViewContext.getState().resetShowProgress(true);
                    emailTemplateData["Parameters"]["CreateWebUserToken"] = response.data.createWebUserToken;
                    if (isSupervisorEmailRequired) {
                        sendEmailTemplate(supervisiorEmailTemplate);
                        if (supervisor2EmailTemplate !== null)
                            sendEmailTemplate(supervisor2EmailTemplate);
                    }
                    sendEmailTemplate(emailTemplateData);
                    return [2 /*return*/, response];
            }
        });
    }); },
    fetchPayreelCordinatorList: function () { return __awaiter(void 0, void 0, void 0, function () {
        var response, coordinatorList;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, getPayReelCordinatorList()];
                case 1:
                    response = _a.sent();
                    coordinatorList = response.data.map(function (item) { return (__assign(__assign({}, item), { name: "".concat(item.firstName, " ").concat(item.lastName) })); });
                    set(function (state) { return (__assign(__assign({}, state), { payReelCoordinatorList: coordinatorList })); });
                    return [2 /*return*/];
            }
        });
    }); },
    fetchEmployeeDetailForEdit: function (newHireJobID) { return __awaiter(void 0, void 0, void 0, function () {
        var response;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, getEmployeeJobForEdit(newHireJobID)];
                case 1:
                    response = _a.sent();
                    if (response.data.startDate)
                        response.data.startDate = new Date(response.data.startDate);
                    if (response.data.endDate)
                        response.data.endDate = new Date(response.data.endDate);
                    set(function (state) { return (__assign(__assign({}, state), { employeeDetailsForEdit: response.data })); });
                    return [2 /*return*/];
            }
        });
    }); },
    patchSaveJob: function (formData) { return __awaiter(void 0, void 0, void 0, function () {
        var response;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, editSaveJob(formData)];
                case 1:
                    response = _a.sent();
                    return [2 /*return*/, response];
            }
        });
    }); },
    fetchResendEmailVerifier: function (newHireJobID) { return __awaiter(void 0, void 0, void 0, function () {
        var response;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, getResendEmailVerifier(newHireJobID)];
                case 1:
                    response = _a.sent();
                    set(function (state) { return (__assign(__assign({}, state), { resendEmailVerifier: response.data })); });
                    return [2 /*return*/];
            }
        });
    }); },
    fetchICWorkerFlag: function (newHireJobID) { return __awaiter(void 0, void 0, void 0, function () {
        var response;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, getICWorkerFlag(newHireJobID)];
                case 1:
                    response = _a.sent();
                    set(function (state) { return (__assign(__assign({}, state), { ICWorkerFlag: response.data })); });
                    return [2 /*return*/, response];
            }
        });
    }); },
    fetchScreeningInfo: function (newHireJobID) { return __awaiter(void 0, void 0, void 0, function () {
        var response;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, getScreeningInfo(newHireJobID)];
                case 1:
                    response = _a.sent();
                    set(function (state) { return (__assign(__assign({}, state), { screeningInfo: response.data })); });
                    return [2 /*return*/, response];
            }
        });
    }); },
    fetchResendEmailUserDetails: function (newHireJobID) { return __awaiter(void 0, void 0, void 0, function () {
        var response;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, getResendEmailUserDetails(newHireJobID)];
                case 1:
                    response = _a.sent();
                    set(function (state) { return (__assign(__assign({}, state), { resendEmailUserDetails: response.data })); });
                    return [2 /*return*/];
            }
        });
    }); },
    postResendEmailTemplate: function (CreateUserObj) { return __awaiter(void 0, void 0, void 0, function () {
        var response;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, postResendEmailTemplate(CreateUserObj)];
                case 1:
                    response = _a.sent();
                    return [2 /*return*/, response];
            }
        });
    }); },
    fetchI9AgentDetails: function (newHireJobID) { return __awaiter(void 0, void 0, void 0, function () {
        var response;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, getI9AgentDetail(newHireJobID)];
                case 1:
                    response = _a.sent();
                    set(function (state) { return (__assign(__assign({}, state), { I9AgentDetails: response.data })); });
                    return [2 /*return*/, response];
            }
        });
    }); },
    updateI9Agent: function (UpdateI9AgentObj) { return __awaiter(void 0, void 0, void 0, function () {
        var response;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, updateEmpAuthI9AgentMapping(UpdateI9AgentObj)];
                case 1:
                    response = _a.sent();
                    return [2 /*return*/, response];
            }
        });
    }); },
    fetchSupportingDocument: function (newHireDocumentID) { return __awaiter(void 0, void 0, void 0, function () {
        var response, e_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 2, , 3]);
                    return [4 /*yield*/, getSupportingDocuments(newHireDocumentID)];
                case 1:
                    response = _a.sent();
                    set(function (state) { return (__assign(__assign({}, state), { supportingDocument: response.data.pdf.fileContents })); });
                    return [2 /*return*/, response];
                case 2:
                    e_1 = _a.sent();
                    console.log(e_1);
                    return [2 /*return*/, ""];
                case 3: return [2 /*return*/];
            }
        });
    }); },
    fetchSupportingDocCount: function (newHireDocumentID) { return __awaiter(void 0, void 0, void 0, function () {
        var response, showDocument;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, getSupportingDocumentCount(newHireDocumentID)];
                case 1:
                    response = _a.sent();
                    showDocument = response.data > 0 ? true : false;
                    set(function (state) { return (__assign(__assign({}, state), { showSupportingDocument: showDocument })); });
                    return [2 /*return*/];
            }
        });
    }); },
    fetchMarkScreeningStatus: function (newHireJobID) { return __awaiter(void 0, void 0, void 0, function () {
        var response;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, markScreeningComplete(newHireJobID)];
                case 1:
                    response = _a.sent();
                    return [2 /*return*/, response];
            }
        });
    }); },
    fetchworkersupervisordetails: function (newHireWorkerID) { return __awaiter(void 0, void 0, void 0, function () {
        var response;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, getworkersupervisordetails(newHireWorkerID)];
                case 1:
                    response = _a.sent();
                    return [2 /*return*/, response];
            }
        });
    }); },
    getBulkImportJobs: function (formData) { return __awaiter(void 0, void 0, void 0, function () {
        var response;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, BulkJobsImport(formData)];
                case 1:
                    response = _a.sent();
                    return [2 /*return*/, response];
            }
        });
    }); },
}); });
