var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useEffect, useState, memo } from "react";
import { Form, Field, FormElement } from "@progress/kendo-react-form";
import { Transform, TranslationConstant, FormButtons, ComponentConstant, FormInput, FormHeading, FormDatePicker, getUserDetailsFromLocalStorage, } from "./index";
import { formatDate } from "@progress/kendo-intl";
var EmployeesSearchForm = function (props) {
    var searchObject = props.searchObject;
    var _a = useState({}), employeeData = _a[0], updateEmployeeData = _a[1];
    var formHeading = {
        title: _jsx(Transform, { mkey: TranslationConstant.SEARCH_NEWHIRE_DOCUMENT_TITLE }),
    };
    var oidcUserProfile = getUserDetailsFromLocalStorage("pro");
    useEffect(function () {
        if (!(employeeData === null || employeeData === void 0 ? void 0 : employeeData.FirstName) || !(employeeData === null || employeeData === void 0 ? void 0 : employeeData.LastName) || !(employeeData === null || employeeData === void 0 ? void 0 : employeeData.Date)) {
            var formatedDate = formatDate(new Date(), "MM/dd/yyyy");
            searchObject({ Date: formatedDate });
            handleSearch({ Date: formatedDate });
        }
    }, []);
    var handleSubmit = function (dataItem) {
        var _a, _b;
        var dataObj = {
            FirstName: ((_a = dataItem.values.FirstName) === null || _a === void 0 ? void 0 : _a.trim()) || null,
            LastName: ((_b = dataItem.values.LastName) === null || _b === void 0 ? void 0 : _b.trim()) || null,
            Date: dataItem.values.Date ? formatDate(new Date(dataItem.values.Date), "MM/dd/yyyy") : null
        };
        handleSearch(dataObj);
    };
    var handleSearch = function (searchData) {
        var ClientId = (oidcUserProfile === null || oidcUserProfile === void 0 ? void 0 : oidcUserProfile.ClientId) || null;
        searchObject(__assign(__assign({}, searchData), { oninitApiCall: true, ClientId: ClientId }));
        updateEmployeeData(__assign(__assign({}, searchData), { Date: searchData.Date ? new Date(searchData.Date) : null }));
    };
    return (_jsxs(_Fragment, { children: [_jsx(FormHeading, { data: formHeading }), _jsx(Form, { initialValues: employeeData, onSubmitClick: handleSubmit, render: function (formRenderProps) { return (_jsx(FormElement, __assign({ className: "general-form" }, { children: _jsxs("fieldset", __assign({ className: "k-form-fieldset row" }, { children: [_jsx("div", __assign({ className: "col-xs-12 col-md-3 mb-2" }, { children: _jsx(Field, { id: "first_name", name: "FirstName", component: FormInput, maxLength: 50, label: _jsx(Transform, { mkey: TranslationConstant.LABEL_FIRST_NAME }), placeholder: ComponentConstant.PLACEHOLDER_LABEL_FIRST_NAME }) })), _jsx("div", __assign({ className: "col-xs-12 col-md-3 mb-2" }, { children: _jsx(Field, { id: "last_name", name: "LastName", maxLength: 50, label: _jsx(Transform, { mkey: TranslationConstant.LABEL_LAST_NAME }), component: FormInput, placeholder: ComponentConstant.PLACEHOLDER_LABEL_LAST_NAME }) })), _jsx("div", __assign({ className: "col-xs-12 col-md-3 mb-2" }, { children: _jsx(Field, { id: "Date", name: "Date", component: FormDatePicker, maxLength: 50, label: _jsx(Transform, { mkey: TranslationConstant.DATE }) }) })), _jsx("div", __assign({ className: "col-xs-12 col-md-3 mb-2", style: { marginTop: '25px', paddingRight: '60px' } }, { children: _jsx(FormButtons, { searchType: true, resetHandler: function () {
                                        formRenderProps.onFormReset();
                                        updateEmployeeData(null);
                                    } }) }))] })) }))); } }, JSON.stringify(employeeData))] }));
};
export default memo(EmployeesSearchForm);
