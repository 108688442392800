var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useCurrentViewContext } from "store/common/currentViewContext";
import { Transform } from "helper/Transform";
import { AppConstant, TranslationConstant } from "common/constant";
var FormButtons = function (props) {
    var closeText = props.closeText, isButtonSubmitFocus = props.isButtonSubmitFocus;
    var cancelText = _jsx(Transform, { mkey: TranslationConstant.LABEL_CANCEL });
    var userAccessType = useCurrentViewContext().userAccessType;
    return (_jsxs("div", __assign({ className: "k-form-buttons ".concat(props.wrapperClass) }, { children: [props.showbackButton && (_jsx("button", __assign({ type: "button", id: props.backbtnid, onClick: props.formBackButtonHandler, className: "k-button k-button-md k-rounded-md k-button-solid k-button-solid-base" }, { children: _jsx(Transform, { mkey: TranslationConstant.BACK_BUTTON }) }))), !props.hideSubmitAction && (_jsx("button", __assign({ type: "submit", id: props.submitid, onClick: props.submitHandler, autoFocus: isButtonSubmitFocus || false, disabled: props.disabled ||
                    (userAccessType === AppConstant.READ_ONLY_USER_ACCESS_VALUE &&
                        !props.searchType &&
                        !props.customSubmitText), className: "k-button k-button-md k-rounded-md k-button-solid k-button-solid-base ".concat(userAccessType === AppConstant.READ_ONLY_USER_ACCESS_VALUE &&
                    !props.searchType &&
                    !props.customSubmitText
                    ? "access-disabled"
                    : "", " ").concat(props.hideSubmit !== undefined && !props.hideSubmit ? "d-none" : "") }, { children: props.customSubmitText ? (_jsx(Transform, { mkey: props.customSubmitText })) : props.searchType ? (_jsx(Transform, { mkey: TranslationConstant.LABEL_SEARCH })) : (_jsx(Transform, { mkey: TranslationConstant.LABEL_SUBMIT })) }))), props.showAddNewButton && (_jsx("button", __assign({ type: "button", id: props.addnewid, onClick: props.addNewHandler, className: "k-button k-button-md k-rounded-md k-button-solid \n                    ".concat(userAccessType === AppConstant.READ_ONLY_USER_ACCESS_VALUE
                    ? "access-disabled"
                    : "", "\n                    ").concat(props.applyCustomButtonDefaultColor !== undefined &&
                    props.applyCustomButtonDefaultColor === true
                    ? ""
                    : "k-button-cancel-white", "\n                    "), disabled: props.customButtonDisabled }, { children: props.customButtonTitle ? (props.customButtonTitle) : (_jsx(Transform, { mkey: TranslationConstant.ADD_NEW })) }))), !props.hideResetButton && (_jsx("button", __assign({ type: "button", id: props.resetid, onClick: props.resetHandler, className: "k-button k-button-md k-rounded-md k-button-solid k-button-cancel-white" }, { children: _jsx(Transform, { mkey: TranslationConstant.LABEL_RESET }) }))), props.showCancelButton && (_jsx("button", __assign({ type: "button", id: props.cancelid, onClick: props.cancelHandler, className: "k-button k-button-md k-rounded-md k-button-solid k-button-cancel-white" }, { children: closeText ? closeText : cancelText })))] })));
};
export default FormButtons;
