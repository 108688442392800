var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { create } from "zustand";
import { getAdminDashboardWidget } from "services/dashboard";
import { checkPaymentTypeDropdown, declineNewHireDoc, getEmployeeDetails, getEmployeeTaskGrid, getEmployerPosterDocument, getPaymentType, updateEmployeeProfileData, getCompletedNewHireDocuments, updateCompletedDocument, UpdatePaymentTypeForDocuments, getEmployeeIncompletePaperwork, getIncompleteEmployeeAgreement, newHireDocForExternalAgent, getClientFormToComplete, saveEmployeeInfoTemporary, generateEmployeePdf, getEmployeeInformation, uploadVisaDoc, signAndSubmitEmployeeInfo, ShowEmployeeCompleteDocument, getEnablePaymentDropDown, GetEmployeeDocPdf, } from "services/dashboard/common";
import { checkAndChange } from "components/shared/utils";
export var useDashboardContext = create(function (set, get) { return ({
    adminDashboardWidgetResponse: [],
    employeeTaskGridResponse: [],
    paymentTypeList: [],
    employeeDetailsResponse: [],
    updateEmployeeProfileData: [],
    employeePosterData: [],
    completedNewHireDocuments: [],
    employeeIncompletePaperwork: [],
    incompleteEmployeeAgreement: [],
    externalAgentsDocList: [],
    clientFormsToComplete: [],
    employeeInformation: {},
    employeeDocumentsCompleted: false,
    enablePaymentDropdown: false,
    employeeDocPdf: "",
    fetchAdminDashboardWidget: function (webUserRole, indexNumber) { return __awaiter(void 0, void 0, void 0, function () {
        var response;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, getAdminDashboardWidget(webUserRole, indexNumber)];
                case 1:
                    response = _a.sent();
                    set(function (state) { return (__assign(__assign({}, state), { adminDashboardWidgetResponse: response.data })); });
                    return [2 /*return*/];
            }
        });
    }); },
    fetchEmployeeTasks: function (newHireJobID) { return __awaiter(void 0, void 0, void 0, function () {
        var response;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, getEmployeeTaskGrid(newHireJobID)];
                case 1:
                    response = _a.sent();
                    set(function (state) { return (__assign(__assign({}, state), { employeeTaskGridResponse: response.data })); });
                    return [2 /*return*/];
            }
        });
    }); },
    fetchEmployeeDetails: function (webUserId) { return __awaiter(void 0, void 0, void 0, function () {
        var response;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, getEmployeeDetails(webUserId)];
                case 1:
                    response = _a.sent();
                    set(function (state) { return (__assign(__assign({}, state), { employeeDetailsResponse: response.data })); });
                    return [2 /*return*/];
            }
        });
    }); },
    updateEmployeeProfile: function (webUserId, groupdata) { return __awaiter(void 0, void 0, void 0, function () {
        var response, e_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 2, , 3]);
                    return [4 /*yield*/, updateEmployeeProfileData(webUserId, groupdata)];
                case 1:
                    response = _a.sent();
                    return [2 /*return*/, response];
                case 2:
                    e_1 = _a.sent();
                    return [2 /*return*/, e_1];
                case 3: return [2 /*return*/];
            }
        });
    }); },
    declineNewHireDocument: function (newHireDocumentID) { return __awaiter(void 0, void 0, void 0, function () {
        var response;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, declineNewHireDoc(newHireDocumentID)];
                case 1:
                    response = _a.sent();
                    return [2 /*return*/, response];
            }
        });
    }); },
    fetchPaymentTypeByJobId: function (jobId) { return __awaiter(void 0, void 0, void 0, function () {
        var response;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, getPaymentType(jobId)];
                case 1:
                    response = _a.sent();
                    set(function (state) { return (__assign(__assign({}, state), { paymentTypeList: response.data })); });
                    return [2 /*return*/];
            }
        });
    }); },
    fetchEmployeePosterDocumentData: function (stateId) { return __awaiter(void 0, void 0, void 0, function () {
        var response, updatedResponse;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, getEmployerPosterDocument(stateId)];
                case 1:
                    response = _a.sent();
                    updatedResponse = checkAndChange(response.data);
                    set(function (state) { return (__assign(__assign({}, state), { employeePosterData: updatedResponse })); });
                    return [2 /*return*/];
            }
        });
    }); },
    updatePaymentTypeForDocuments: function (newHireJobID, paymentTypeValue) { return __awaiter(void 0, void 0, void 0, function () {
        var response;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, UpdatePaymentTypeForDocuments(newHireJobID, paymentTypeValue)];
                case 1:
                    response = _a.sent();
                    return [2 /*return*/, response];
            }
        });
    }); },
    checkPaymentTypeForDocuments: function (newHireJobID) { return __awaiter(void 0, void 0, void 0, function () {
        var response;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, checkPaymentTypeDropdown(newHireJobID)];
                case 1:
                    response = _a.sent();
                    return [2 /*return*/, response];
            }
        });
    }); },
    fetchCompletedNewHireDocuments: function (newHireJobID) { return __awaiter(void 0, void 0, void 0, function () {
        var response;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, getCompletedNewHireDocuments(newHireJobID)];
                case 1:
                    response = _a.sent();
                    set(function (state) { return (__assign(__assign({}, state), { completedNewHireDocuments: response.data })); });
                    return [2 /*return*/];
            }
        });
    }); },
    updateCompletedNewHireDocument: function (applicationID, docDataObj) { return __awaiter(void 0, void 0, void 0, function () {
        var response, e_2;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 2, , 3]);
                    return [4 /*yield*/, updateCompletedDocument(applicationID, docDataObj)];
                case 1:
                    response = _a.sent();
                    return [2 /*return*/, response];
                case 2:
                    e_2 = _a.sent();
                    return [2 /*return*/, e_2];
                case 3: return [2 /*return*/];
            }
        });
    }); },
    fetchClientFormsToComplete: function () { return __awaiter(void 0, void 0, void 0, function () {
        var response;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, getClientFormToComplete()];
                case 1:
                    response = _a.sent();
                    set(function (state) { return (__assign(__assign({}, state), { clientFormsToComplete: response.data })); });
                    return [2 /*return*/];
            }
        });
    }); },
    fetchEmployeeIncompletePaperwork: function (clientID, indexNumber) { return __awaiter(void 0, void 0, void 0, function () {
        var response;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, getEmployeeIncompletePaperwork(clientID, indexNumber)];
                case 1:
                    response = _a.sent();
                    set(function (state) { return (__assign(__assign({}, state), { employeeIncompletePaperwork: response.data })); });
                    return [2 /*return*/];
            }
        });
    }); },
    fetchIncompleteEmployeeAgreement: function (clientUserID) { return __awaiter(void 0, void 0, void 0, function () {
        var response;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, getIncompleteEmployeeAgreement(clientUserID)];
                case 1:
                    response = _a.sent();
                    set(function (state) { return (__assign(__assign({}, state), { incompleteEmployeeAgreement: response.data })); });
                    return [2 /*return*/];
            }
        });
    }); },
    fetchNewHireDocForExternalAgents: function () { return __awaiter(void 0, void 0, void 0, function () {
        var response;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, newHireDocForExternalAgent()];
                case 1:
                    response = _a.sent();
                    set(function (state) { return (__assign(__assign({}, state), { externalAgentsDocList: response.data })); });
                    return [2 /*return*/];
            }
        });
    }); },
    fetchEmployeeInformation: function (encryptedDocID) { return __awaiter(void 0, void 0, void 0, function () {
        var response;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, getEmployeeInformation(encryptedDocID)];
                case 1:
                    response = _a.sent();
                    set(function (state) { return (__assign(__assign({}, state), { employeeInformation: response.data })); });
                    return [2 /*return*/];
            }
        });
    }); },
    saveEmployeeInfoTemporary: function (encryptedDocID, employeeData) { return __awaiter(void 0, void 0, void 0, function () {
        var response;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, saveEmployeeInfoTemporary(encryptedDocID, employeeData)];
                case 1:
                    response = _a.sent();
                    return [2 /*return*/, response];
            }
        });
    }); },
    generateEmployeePdf: function (encryptedDocID) { return __awaiter(void 0, void 0, void 0, function () {
        var response;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, generateEmployeePdf(encryptedDocID)];
                case 1:
                    response = _a.sent();
                    return [2 /*return*/, response];
            }
        });
    }); },
    onVisaDocumentUpload: function (formData) { return __awaiter(void 0, void 0, void 0, function () {
        var response;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, uploadVisaDoc(formData)];
                case 1:
                    response = _a.sent();
                    return [2 /*return*/, response];
            }
        });
    }); },
    onSignSubmitEmployeeInfo: function (encryptedDocID, fileUploadResponse) { return __awaiter(void 0, void 0, void 0, function () {
        var response;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, signAndSubmitEmployeeInfo(encryptedDocID, fileUploadResponse)];
                case 1:
                    response = _a.sent();
                    return [2 /*return*/, response];
            }
        });
    }); },
    getShowEmployeeCompleteDocument: function (newHireJobID) { return __awaiter(void 0, void 0, void 0, function () {
        var response;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, ShowEmployeeCompleteDocument(newHireJobID)];
                case 1:
                    response = _a.sent();
                    set(function (state) { return (__assign(__assign({}, state), { employeeDocumentsCompleted: response.data })); });
                    return [2 /*return*/];
            }
        });
    }); },
    getPaymentDropDownStatus: function (newHireJobID) { return __awaiter(void 0, void 0, void 0, function () {
        var response;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, getEnablePaymentDropDown(newHireJobID)];
                case 1:
                    response = _a.sent();
                    set(function (state) { return (__assign(__assign({}, state), { enablePaymentDropdown: response.data })); });
                    return [2 /*return*/];
            }
        });
    }); },
    getEmployeeDocPDF: function (newHireJobID) { return __awaiter(void 0, void 0, void 0, function () {
        var response;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, GetEmployeeDocPdf(newHireJobID)];
                case 1:
                    response = _a.sent();
                    set(function (state) {
                        var _a, _b;
                        return (__assign(__assign({}, state), { employeeDocPdf: ((_b = (_a = response.data) === null || _a === void 0 ? void 0 : _a.pdf) === null || _b === void 0 ? void 0 : _b.fileContents) || "" }));
                    });
                    return [2 /*return*/, response];
            }
        });
    }); },
}); });
