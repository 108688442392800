var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import { getter } from "@progress/kendo-data-query";
import { getSelectedState, } from "@progress/kendo-react-grid";
import { Grid, ActionCell, createEncodedURL, getUserDetailsFromLocalStorage, timesheetGridFilterMapper, } from "components/shared";
import { FooterCellWithCurrency, FooterCellTotalCount, DateFilterCell, } from "components/shared/GridCommonCells";
import { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useBatchesImportContext } from "store/batchimport/batchimport";
var DATA_ITEM_KEY = "timeSheetId";
var SELECTED_FIELD = "selected";
var idGetter = getter(DATA_ITEM_KEY);
export default function PayrollTimesheetsGrid(_a) {
    var _b = _a.colums, colums = _b === void 0 ? [] : _b, _c = _a.gridData, gridData = _c === void 0 ? [] : _c, _d = _a.multiSelect, multiSelect = _d === void 0 ? false : _d, handleRefresh = _a.handleRefresh, backRoute = _a.backRoute, title = _a.title, _e = _a.tabCode, tabCode = _e === void 0 ? "" : _e;
    var navigate = useNavigate();
    var _f = useBatchesImportContext(), selectedExportsData = _f.selectedExportsData, setSelectedExportsData = _f.setSelectedExportsData;
    var vzSetup = JSON.parse(localStorage.getItem("selectedClient")).clientDto.vzSetup;
    var oidcUserProfile = getUserDetailsFromLocalStorage("pro");
    var _g = useState(selectedExportsData["".concat(tabCode)] || {}), selectedRecords = _g[0], setSelectedRecords = _g[1];
    var _h = useState([]), localGridData = _h[0], setGridData = _h[1];
    var updateSelectedRows = function (values) {
        var selectedRows = __assign({}, selectedExportsData);
        selectedRows["".concat(tabCode)] = values;
        setSelectedExportsData(selectedRows);
    };
    useEffect(function () {
        if (gridData && gridData.length >= 0) {
            var formattedList = gridData.map(function (list) {
                return __assign(__assign({}, list), { weekEndingDate: (list === null || list === void 0 ? void 0 : list.weekEndingDate) ? new Date(list === null || list === void 0 ? void 0 : list.weekEndingDate) : null });
            });
            setGridData(formattedList);
        }
    }, [gridData]);
    var handleViewClick = function (cell) {
        var dataItem = cell.dataItem;
        var url = createEncodedURL("/viewtimesheet", {
            page: "view",
            FirstName: dataItem.firstName,
            LastName: dataItem.lastName,
            TimeSheetId: dataItem.timeSheetId,
            UserRoleId: oidcUserProfile === null || oidcUserProfile === void 0 ? void 0 : oidcUserProfile.WebUserRoleId,
            clientId: oidcUserProfile === null || oidcUserProfile === void 0 ? void 0 : oidcUserProfile.ClientId,
            weekEndingDate: dataItem.weekEndingDate,
            redirectFrom: "batches",
            redirectFromUrl: {
                id: "payrollExports",
                text: title,
                route: backRoute,
            },
            isVZClient: vzSetup,
        });
        navigate(url);
    };
    var getColumns = function () {
        return multiSelect
            ? [
                {
                    title: "",
                    field: SELECTED_FIELD,
                    width: "50px",
                    filterable: false,
                    headerSelectionValue: localGridData.findIndex(function (item) { return !selectedRecords[idGetter(item)]; }) ===
                        -1,
                },
            ]
            : [];
    };
    var Columns = __spreadArray(__spreadArray(__spreadArray(__spreadArray([], getColumns(), true), [
        {
            cell: function (cell) { return (_jsx(ActionCell, { cellprops: cell, title: "View", onClick: function () { return handleViewClick(cell); } })); },
            field: "",
            title: "View",
            width: "80px",
            filterable: false,
        }
    ], false), colums, true), [
        {
            field: "batchName",
            title: "Batch",
            width: "120px",
        },
        {
            field: "weekEndingDate",
            title: "Week Ending",
            width: "120px",
            filter: "date",
            format: "{0:MM/dd/yy}",
            filterCell: DateFilterCell,
        },
        {
            field: "lastName",
            title: "Last Name",
            width: "120px",
        },
        {
            field: "firstName",
            title: "First Name",
            width: "120px",
        },
        {
            field: "shortName",
            title: "State",
            width: "100px",
        },
        {
            field: "daysWorked",
            title: "Days Wrkd",
            width: "100px",
        },
        multiSelect
            ? {
                field: "paidHours",
                title: "Paid Hrs",
                width: "120px",
                format: "{0:n2}",
            }
            : {
                field: "paidHours",
                title: "Paid Hrs",
                width: "120px",
                format: "{0:n2}",
                footerCell: function (cell) { return _jsx(FooterCellTotalCount, __assign({}, cell, { data: localGridData })); },
            },
        multiSelect
            ? {
                field: "grossPay",
                title: "Gross Pay",
                width: "120px",
                format: "{0:c2}",
            }
            : {
                field: "grossPay",
                title: "Gross Pay",
                width: "120px",
                format: "{0:c2}",
                footerCell: function (cell) { return _jsx(FooterCellWithCurrency, __assign({}, cell, { data: localGridData })); },
            },
        {
            field: "invoicedAmount",
            title: "Inv Amount",
            width: "120px",
            format: "{0:c2}",
        },
    ], false);
    var onSelectionChange = useCallback(function (event) {
        var newSelectedState = getSelectedState({
            event: event,
            selectedState: selectedRecords,
            dataItemKey: DATA_ITEM_KEY,
        });
        setSelectedRecords(newSelectedState);
        updateSelectedRows(newSelectedState);
    }, [selectedRecords]);
    var onHeaderSelectionChange = useCallback(function (event) {
        var checkboxElement = event.syntheticEvent.target;
        var checked = checkboxElement.checked;
        var newSelectedState = {};
        event.dataItems.forEach(function (item) {
            newSelectedState[idGetter(item)] = checked;
        });
        setSelectedRecords(newSelectedState);
        updateSelectedRows(newSelectedState);
    }, []);
    return (_jsx(_Fragment, { children: multiSelect ? (_jsx(Grid, { columns: Columns, gridData: localGridData.map(function (item) {
                var _a;
                return (__assign(__assign({}, item), (_a = {}, _a[SELECTED_FIELD] = selectedRecords[idGetter(item)], _a)));
            }), dataItemKey: DATA_ITEM_KEY, selectedField: SELECTED_FIELD, selectable: {
                enabled: true,
                drag: false,
                cell: false,
                mode: "multiple",
            }, onSelectionChange: onSelectionChange, onHeaderSelectionChange: onHeaderSelectionChange, handleRefresh: handleRefresh, customFilterTitles: __assign(__assign({}, timesheetGridFilterMapper), { invoicedAmount: "Inv Amount" }) })) : (_jsx(Grid, { columns: Columns, gridData: localGridData, handleRefresh: handleRefresh, customFilterTitles: __assign(__assign({}, timesheetGridFilterMapper), { invoicedAmount: "Inv Amount" }) })) }));
}
