import { jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ActionCell, Grid, createEncodedURL, createQueryString, decodedURL, removeFalsyValueFromObj, useSearchEmpViewContext, } from "../index";
var WorkLocationGrid = function (props) {
    var workLocationSearchData = props.workLocationSearchData;
    var formRedirectData = decodedURL().formRedirectData;
    var navigate = useNavigate();
    var _a = useState(formRedirectData && formRedirectData !== "undefined" && formRedirectData !== "null"
        ? formRedirectData
        : null), searchData = _a[0], setSearchData = _a[1];
    var _b = useSearchEmpViewContext(), clientWorkLocations = _b.clientWorkLocations, fetchWorkLocationList = _b.fetchWorkLocationList, resetWorkLocationList = _b.resetWorkLocationList;
    useEffect(function () {
        if (workLocationSearchData) {
            initApiCall(workLocationSearchData);
        }
        else if (searchData) {
            initApiCall(searchData);
        }
        else {
            resetWorkLocationList();
        }
    }, [workLocationSearchData, searchData]);
    var initApiCall = function (data) {
        if (data) {
            fetchWorkLocationList(createQueryString(removeFalsyValueFromObj(data)));
        }
    };
    var refreshGridDataHandler = function () {
        workLocationSearchData ? initApiCall(workLocationSearchData) : initApiCall(searchData);
    };
    var columns = [
        {
            field: "",
            title: "Edit Work Location",
            filterable: false,
            width: "140px",
            cell: function (cell) { return (_jsx(ActionCell, { cellprops: cell, title: "Edit Work Location", onClick: function () {
                    return navigate(createEncodedURL("/addworklocation", {
                        workLocationID: cell.dataItem.workLocationID,
                        formRedirectData: workLocationSearchData
                            ? workLocationSearchData
                            : searchData,
                    }));
                } })); },
        },
        {
            field: "workLocation",
            title: "Work Location",
            width: "140px",
        },
        {
            field: "city",
            title: "City",
            width: "140px",
        },
        {
            field: "state",
            title: "State",
            width: "140px",
        },
        {
            field: "status",
            title: "Status",
            width: "110px",
        },
    ];
    return (_jsx(_Fragment, { children: _jsx(Grid, { columns: columns, gridData: clientWorkLocations, handleRefresh: refreshGridDataHandler }) }));
};
export default WorkLocationGrid;
