var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useState } from "react";
import { hot } from "react-hot-loader";
import { loadMessages, LocalizationProvider } from "@progress/kendo-react-intl";
import { useCurrentViewContext } from "store/common/currentViewContext";
import { AppConstant, LOCALE, LocalizedMessages } from "common/constant/index";
import { Home } from "components/onboarding/Home";
import { CorpHome } from "components/corp/CorpHome";
import ErrorOnApiFailure from "components/shared/ApiErrorDialog";
import { CreateUserRoutes } from "components/shared/createuserroutes";
import { getUserDetailsFromLocalStorage, redirectToAdmin } from "components/shared/utils";
import { InvalidUserRoutes } from "components/shared/InvalidUserRoutes";
import Spinner from "components/shared/Spinner";
import "assets/css/kendo-theme-default/all.css";
import "assets/css/main.scss";
import "assets/css/index.css";
import "assets/css/common.scss";
import "assets/css/form.scss";
import "assets/css/responsive.scss";
import FlashMessagePopup from "components/shared/FlashMessagePopup";
import { ROUTE_ADMIN_EMPLOYEE_SEARCH } from "common/constant/CustomRoutes";
loadMessages(LocalizedMessages[LOCALE.ENGLISH_LANGUAGE], LOCALE.ENGLISH_LANGUAGE);
var App = function () {
    var _a, _b;
    var _c = useCurrentViewContext(), locale = _c.locale, applicationName = _c.applicationName, setApplicationName = _c.setApplicationName, isApiFailure = _c.isApiFailure;
    var isCorpAplication = window.location.href.toLowerCase().indexOf(AppConstant.CORP_BASE_PATHNAME) > -1;
    var isInvalidUser = window.location.href.toLowerCase().indexOf(AppConstant.INVALID_USER_ROUTE) > -1;
    var oidcUserProfile = getUserDetailsFromLocalStorage();
    var isCreateWebUser = window.location.href.toLowerCase().indexOf("createwebuser") > -1 ||
        window.location.href.toLowerCase().indexOf("viewhrdocument") > -1;
    var isAdminLoginAsEmployee = window.location.href.toLowerCase().indexOf("loginasemployee") > -1;
    var _d = useState(false), isApiResponseFailed = _d[0], updateApiResponse = _d[1];
    var userDetailsFromCorp = getUserDetailsFromLocalStorage("pro");
    useEffect(function () {
        if (isCorpAplication) {
            setApplicationName(AppConstant.CORP_TYPE_APPLICATION);
        }
        updateApiResponse(isApiFailure);
    }, [applicationName, isApiFailure]);
    useEffect(function () {
        var handleBackButtonClick = function () {
            var matchPath = "".concat(AppConstant.CORP_BASE_PATHNAME).concat(ROUTE_ADMIN_EMPLOYEE_SEARCH.EMPLOYEE_SEARCH);
            var _a = window.location, pathname = _a.pathname, search = _a.search;
            var isAdminClientLoggedIn = localStorage.getItem("adminClientLoggedIn");
            if (pathname.includes(matchPath) && isAdminClientLoggedIn) {
                redirectToAdmin();
                window.location.pathname = search ? encodeURI(pathname) : matchPath;
            }
        };
        window.addEventListener("popstate", handleBackButtonClick);
        return function () {
            window.removeEventListener("popstate", handleBackButtonClick);
        };
    }, []);
    return (_jsxs(_Fragment, { children: [isCreateWebUser ? (_jsx(CreateUserRoutes, {})) : isInvalidUser || isAdminLoginAsEmployee ? (_jsx(InvalidUserRoutes, {})) : (_jsxs(LocalizationProvider, __assign({ language: locale }, { children: [isCorpAplication && ((_a = oidcUserProfile === null || oidcUserProfile === void 0 ? void 0 : oidcUserProfile.Parameters) === null || _a === void 0 ? void 0 : _a.ShowCorpLink) === "true" ? (_jsxs(_Fragment, { children: [((_b = userDetailsFromCorp === null || userDetailsFromCorp === void 0 ? void 0 : userDetailsFromCorp.FlashMessageIDList) === null || _b === void 0 ? void 0 : _b.length) > 0 && (_jsx(FlashMessagePopup, { flashMessageIds: userDetailsFromCorp.FlashMessageIDList })), _jsx(CorpHome, {})] })) : (_jsx(Home, {})), _jsx(ErrorOnApiFailure, { isApiFailure: isApiResponseFailed })] }))), _jsx(ErrorOnApiFailure, { isApiFailure: isApiResponseFailed }), _jsx("div", __assign({ className: "main-loader application-loader" }, { children: _jsx(Spinner, {}) }))] }));
};
export default hot(module)(App);
