import { httpPromise } from "services/common/httpPromise";
import { ServiceConstant } from "common/constant";
export var getI9ReverificationNewClients = function () {
    return httpPromise.get(ServiceConstant.I9_REVERIFICATION_NEW_CLIENTS);
};
export var getI9ReverificationExistingClients = function () {
    return httpPromise.get(ServiceConstant.I9_REVERIFICATION_EXISTING_CLIENTS);
};
export var I9ReverificationDeleteClients = function (employeeRepeateI9ID) {
    return httpPromise.delete("".concat(ServiceConstant.I9_REVERIFICATION_DELETE_CLIENT, "/").concat(employeeRepeateI9ID));
};
export var GetClientsByMasterWebUserId = function (masterWebUserId) {
    return httpPromise.get("".concat(ServiceConstant.I9_REVERIFICATION_CLIENT_LIST, "/").concat(masterWebUserId));
};
export var UpdateStartDateExistingClient = function (employeeRepeateI9ID, date) {
    return httpPromise.put("".concat(ServiceConstant.I9_REVERIFICATION_UPDATE_START_DATE_API, "?employeeRepeateI9ID=").concat(employeeRepeateI9ID, "&date=").concat(date));
};
export var UpdateExistingClient = function (dataObject) {
    return httpPromise.put("".concat(ServiceConstant.I9_REVERIFICATION_UPDATE_EXISTING_CLIENT), dataObject);
};
export var i9ReverificationGetApprovals = function (listType) {
    return httpPromise.get("".concat(ServiceConstant.I9_REVERIFICATION_APPROED_LIST, "?listType=").concat(listType));
};
export var I9ReverificationApproveBtn = function (employeeRepeatI9ID) {
    return httpPromise.put("".concat(ServiceConstant.I9_REVERIFICATION_APPROVE_BTN_API, "/").concat(employeeRepeatI9ID));
};
export var ChangeI9VerifierDetails = function (paramsObj) {
    return httpPromise.put("".concat(ServiceConstant.CHANGE_I9_VERIFIER_DETAILS_API, "?newHireJobID=").concat(paramsObj.newHireJobID, "&webUserID=").concat(paramsObj.webUserID), paramsObj);
};
export var FedralI9SignAndSave = function (FedralI9DataObj) {
    return httpPromise.put("".concat(ServiceConstant.FEDRAL_I9_SIGN_AND_SAVE), FedralI9DataObj);
};
export var FedralI9ListData = function () {
    return httpPromise.get("".concat(ServiceConstant.FEDRAL_I9_LIST_DATA));
};
export var SaveI9ProfilePic = function (formData) {
    return httpPromise.post("".concat(ServiceConstant.SAVE_I9_FEDRAL_PROFILE_PIC), formData);
};
export var deleteSupportingDocument = function (newHireSupportingDocumentID) {
    return httpPromise.delete("".concat(ServiceConstant.DELETE_SUPPORTING_DOCUMENTS).concat(newHireSupportingDocumentID));
};
export var getClientData = function (clientId) {
    return httpPromise.get("".concat(ServiceConstant.CLIENT_DROPDOWN_LIST_PATH, "/").concat(clientId));
};
export var FedralI9AgentSignAndSave = function (FedralI9DataObj) {
    return httpPromise.post("".concat(ServiceConstant.FEDRAL_I9_ADMIN_AGENT_SAVE), FedralI9DataObj);
};
export var getClientUserData = function (clientId) {
    return httpPromise.get("".concat(ServiceConstant.GET_CLIENT_USER_DETAILS_PATH, "/").concat(clientId));
};
export var getAllClientsByMasterWebUserId = function (masterWebUserId) {
    return httpPromise.get("".concat(ServiceConstant.CLIENT_DROPDOWN_LIST_PATH, "/GetAllClientsByMasterWebUserId/").concat(masterWebUserId));
};
