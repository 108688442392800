export var DATATYPE;
(function (DATATYPE) {
    DATATYPE["Date"] = "AAAA";
    DATATYPE["Text"] = "AAAB";
    DATATYPE["Numeric"] = "AAAC";
    DATATYPE["Option"] = "AAAD";
    DATATYPE["CheckBox"] = "AAAE";
    DATATYPE["Signature"] = "AAAF";
    DATATYPE["State"] = "AAAG";
    DATATYPE["SSN"] = "AAAH";
    DATATYPE["Account"] = "AAAI";
    DATATYPE["RADIOBUTTON"] = "AAAJ";
})(DATATYPE || (DATATYPE = {}));
