import { httpPromise } from "services/common/httpPromise";
import { AppConstant, ServiceConstant } from "common/constant";
export var getICWorkerStatusList = function () {
    return httpPromise.get(ServiceConstant.IC_WORKER_STATUS_LIST_API_PATH);
};
export var getSearchNewHireJobList = function (queryString) {
    return httpPromise.get("".concat(ServiceConstant.SEARCH_NEW_HIRE_JOB_API_PATH, "?").concat(queryString));
};
export var getNewHireDocumentList = function (newHireJobId) {
    return httpPromise.get("".concat(ServiceConstant.NEW_HIRE_DOCUMENT_API_PATH, "/").concat(newHireJobId, "/Payreel"));
};
export var addICUserAsOBJob = function (jobEvaluationID) {
    return httpPromise.post(ServiceConstant.IC_WORKER_STATUS_LIST_API_PATH, {
        jobEvalID: jobEvaluationID,
    });
};
export var getApprovedJobsList = function () {
    return httpPromise.get(ServiceConstant.APPROVED_JOBS_API_PATH);
};
export var getManageNewHireJobDocumentList = function (newHireJobID) {
    return httpPromise.get("".concat(ServiceConstant.MANAGE_NEW_HIRE_DOCUMENT_API, "/").concat(newHireJobID));
};
export var getNewHireDocsForJobManagement = function (newHireJobID) {
    return httpPromise.get("".concat(ServiceConstant.NEW_HIRE_DOC_JOB_MANAGEMENT_API_PATH, "?newHireJobID=").concat(newHireJobID));
};
export var addDocumentToJob = function (docTypeListObject) {
    return httpPromise.post(ServiceConstant.ADD_DOCUMENT_TO_JOB_API_PATH, docTypeListObject);
};
export var uploadManuallyDocument = function (formData) {
    return httpPromise.post(ServiceConstant.MANUALLY_UPLOAD_DOCUMENT_API_PATH, formData);
};
export var cancelNewHireDocument = function (newHireDocumentID) {
    return httpPromise.delete("".concat(ServiceConstant.CANCEL_NEW_HIRE_DOCUMENT_API_PATH, "?newHireDocumentID=").concat(newHireDocumentID));
};
export var cancelNewHireJob = function (newHireJobID, isICAdvisor) {
    return httpPromise.delete("".concat(ServiceConstant.CANCEL_NEW_HIRE_JOB_API_PATH, "?newHireJobID=").concat(newHireJobID, "&isIcAdviser=").concat(isICAdvisor));
};
export var getApproveNewHireJob = function (newHireJobID) {
    return httpPromise.patch("".concat(ServiceConstant.APPROVE_NEWHIRE_JOBS_API_PATH, "?newHireJobID=").concat(newHireJobID));
};
export var getPayReelCordinatorList = function () {
    return httpPromise.get(ServiceConstant.PAYREEL_COORDINATOR_API_LIST);
};
export var addNewJob = function (formData) {
    return httpPromise.post(ServiceConstant.ADD_NEW_JOB_API_PATH, formData);
};
export var getResendEmailVerifier = function (newHireJobID) {
    return httpPromise.get("".concat(ServiceConstant.RESEND_EMAIL_VERIFIER_API_PATH, "?newHireJobID=").concat(newHireJobID));
};
export var getICWorkerFlag = function (newHireJobID) {
    return httpPromise.get("".concat(ServiceConstant.IC_WORKER_FLAG_API_PATH, "?newHireJobID=").concat(newHireJobID));
};
export var getScreeningInfo = function (newHireJobID) {
    return httpPromise.get("".concat(ServiceConstant.SCREENING_INFO_API_PATH, "?newHireJobID=").concat(newHireJobID));
};
export var editSaveJob = function (formData) {
    return httpPromise.put(ServiceConstant.ADD_NEW_JOB_API_PATH, formData);
};
export var getEmployeeJobForEdit = function (newHireJobID) {
    return httpPromise.get("".concat(ServiceConstant.ADD_NEW_JOB_API_PATH, "/").concat(newHireJobID));
};
export var getResendEmailUserDetails = function (newHireJobID) {
    return httpPromise.get("".concat(ServiceConstant.RESEND_USER_EMAIL_DETAILS_API_PATH, "?newHireJobID=").concat(newHireJobID));
};
export var postResendEmailTemplate = function (CreateUserObj) {
    return httpPromise.post(ServiceConstant.SEND_EMAIL_TEMPLATE_API_PATH, CreateUserObj);
};
export var getI9AgentDetail = function (newHireJobID) {
    return httpPromise.get("".concat(ServiceConstant.I9_AGENT_MAIL_DETAILS, "?newHireJobID=").concat(newHireJobID));
};
export var updateEmpAuthI9AgentMapping = function (UpdateI9AgentID) {
    return httpPromise.put("".concat(ServiceConstant.UPDATE_I9_AGENT_API_PATH, "?empAuthI9AgentMappingID=").concat(UpdateI9AgentID));
};
export var getSupportingDocuments = function (newHireDocumentID) {
    return httpPromise.get("".concat(ServiceConstant.VIEW_SUPPORTING_DOCUMENT_API_PATH, "?newHireDocumentID=").concat(newHireDocumentID));
};
export var getSupportingDocumentCount = function (newHireDocumentID) {
    return httpPromise.get("".concat(ServiceConstant.SUPPORTING_HIRE_DOCUMENT_COUNT, "?newHireDocumentID=").concat(newHireDocumentID));
};
export var markScreeningComplete = function (newHireJobID) {
    return httpPromise.post("".concat(ServiceConstant.MARK_SCREENING_COMPLETE_API, "?newHireJobID=").concat(newHireJobID));
};
export var getworkersupervisordetails = function (newHireWorkerID) {
    return httpPromise.get("".concat(ServiceConstant.GET_WORKER_SUPERVISOR_DETAIL_API, "?newHireWorkerID=").concat(newHireWorkerID));
};
export var BulkJobsImport = function (formData) {
    return httpPromise.post("".concat(ServiceConstant.BULK_IMPORT_JOBS_API), formData, {
        timeout: AppConstant.BULK_IMPORT_API_TIMEOUT,
    });
};
