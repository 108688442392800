var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useEffect, useState } from "react";
import { Form, Field, FormElement } from "@progress/kendo-react-form";
import { Transform, TranslationConstant, FormButtons, ComponentConstant, FormHeading, FormInput, FormRadioGroup, removeFalsyValueFromObj, decodedURL, createEncodedURL, WEBUSERROLEID, } from "../index";
import { STATUS_TYPE_DATA_LIST } from "../StaticData";
import { useNavigate } from "react-router-dom";
export var ClientSearchEmployeeForm = function (_a) {
    var SessionData = _a.SessionData, SearchEmpDatafun = _a.SearchEmpDatafun, SelectedClientData = _a.SelectedClientData, IsAdminAccess = _a.IsAdminAccess;
    var urlData = decodedURL();
    var _b = useState({
        title: _jsx(Transform, { mkey: TranslationConstant.SEARCH_NEWHIRE_DOCUMENT_TITLE }),
    }), formHeading = _b[0], setFormHeading = _b[1];
    var _c = useState(null), addEmpRedirectdata = _c[0], setAddEmpRedirectdata = _c[1];
    var _d = useState(urlData), initialUrlData = _d[0], updateInitialUrlData = _d[1];
    useEffect(function () {
        updateInitialUrlData(urlData);
    }, []);
    var _e = useState(Object.keys(urlData).length
        ? urlData.EmployeeStatusId
            ? Number(urlData.EmployeeStatusId)
            : null
        : STATUS_TYPE_DATA_LIST[1].value), checkedStatusType = _e[0], setStatusType = _e[1];
    var navigate = useNavigate();
    useEffect(function () {
        if (IsAdminAccess) {
            setFormHeading({
                title: _jsx(Transform, { mkey: TranslationConstant.SEARCH_NEWHIRE_DOCUMENT_TITLE }),
                tooltip: _jsx(Transform, { mkey: TranslationConstant.LABEL_ADD_EMPLOYEE }),
            });
        }
    }, [IsAdminAccess]);
    var _f = useState({}), initialValues = _f[0], updateInitalValues = _f[1];
    useEffect(function () {
        if (Object.keys(initialUrlData).length) {
            var urlDataObj = {
                firstName: (urlData === null || urlData === void 0 ? void 0 : urlData.FirstName) || null,
                lastName: (urlData === null || urlData === void 0 ? void 0 : urlData.LastName) || null,
                ssnumber: (urlData === null || urlData === void 0 ? void 0 : urlData.SSN) || null,
                EmpIdNum: (urlData === null || urlData === void 0 ? void 0 : urlData.EmployeeIdNumber) || null,
                emailAddress: (urlData === null || urlData === void 0 ? void 0 : urlData.EmailAddress) || null,
                isActive: checkedStatusType || null,
            };
            updateInitalValues(urlDataObj);
            searchEmpData(urlDataObj);
        }
    }, [initialUrlData]);
    var handleSubmit = function (dataItem) { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            searchEmpData(dataItem.values);
            return [2 /*return*/];
        });
    }); };
    var searchEmpData = function (dataObj) {
        var dataobj = {
            ClientId: (SessionData === null || SessionData === void 0 ? void 0 : SessionData.ClientId) || null,
            UserRole: (SessionData === null || SessionData === void 0 ? void 0 : SessionData.WebUserRoleId) || null,
            MasterClientId: (SelectedClientData === null || SelectedClientData === void 0 ? void 0 : SelectedClientData.masterClientID) || null,
            EmployeeStatusId: checkedStatusType || null,
            FirstName: dataObj.firstName || null,
            LastName: dataObj.lastName || null,
            EmailAddress: dataObj.emailAddress || null,
            SSN: dataObj.ssnumber || null,
            EmployeeIdNumber: dataObj.EmpIdNum || null,
        };
        if ((SessionData === null || SessionData === void 0 ? void 0 : SessionData.WebUserRoleId) === WEBUSERROLEID.MASTER_CLIENT_READ_ONLY) {
            dataobj = __assign(__assign({}, dataobj), { MasterClientId: (SessionData === null || SessionData === void 0 ? void 0 : SessionData.ClientId) || null });
        }
        SearchEmpDatafun(removeFalsyValueFromObj(dataobj));
        setAddEmpRedirectdata(dataobj);
    };
    var handleresetform = function () {
        setStatusType(STATUS_TYPE_DATA_LIST[1].value);
    };
    var addEmployee = function () {
        navigate(createEncodedURL("/adminaddemployee", __assign({}, addEmpRedirectdata)));
    };
    return (_jsxs(_Fragment, { children: [IsAdminAccess ? (_jsx(FormHeading, { data: formHeading, openModal: addEmployee, IconClass: "fa fa-plus" })) : (_jsx(FormHeading, { data: formHeading })), _jsx(Form, { initialValues: initialValues, onSubmitClick: handleSubmit, render: function (formRenderProps) { return (_jsxs(FormElement, __assign({ className: "general-form" }, { children: [_jsxs("fieldset", __assign({ className: "k-form-fieldset row" }, { children: [_jsx("div", __assign({ className: "col-xs-12 col-md-4 mb-2" }, { children: _jsx(Field, { id: "ssnumber", name: "ssnumber", component: FormInput, isFocusableElement: true, maxLength: 9, label: _jsx(Transform, { mkey: TranslationConstant.LABEL_SHORT_SSN }), placeholder: ComponentConstant.PLACEHOLDER_SSN }) })), _jsx("div", __assign({ className: "col-xs-12 col-md-4 mb-2" }, { children: _jsx(Field, { id: "last_name", name: "lastName", maxLength: 50, label: _jsx(Transform, { mkey: TranslationConstant.LABEL_LAST_NAME }), component: FormInput, placeholder: ComponentConstant.PLACEHOLDER_LABEL_LAST_NAME }) })), _jsx("div", __assign({ className: "col-xs-12 col-md-4 mb-2" }, { children: _jsx(Field, { id: "first_name", name: "firstName", component: FormInput, maxLength: 50, label: _jsx(Transform, { mkey: TranslationConstant.LABEL_FIRST_NAME }), placeholder: ComponentConstant.PLACEHOLDER_LABEL_FIRST_NAME }) })), SessionData.WebUserRoleId !==
                                    WEBUSERROLEID.MASTER_CLIENT_READ_ONLY && (_jsx("div", __assign({ className: "col-xs-12 col-md-4 mb-2" }, { children: _jsx(Field, { id: "EmpIdNum", name: "EmpIdNum", component: FormInput, maxLength: 100, label: _jsx(Transform, { mkey: TranslationConstant.LABEL_EIN }), placeholder: ComponentConstant.PLACEHOLDER_LABEL_EIN }) }))), _jsx("div", __assign({ className: "col-xs-12 col-md-4 mb-2" }, { children: _jsx(Field, { id: "email_id", name: "emailAddress", maxLength: 75, component: FormInput, label: _jsx(Transform, { mkey: TranslationConstant.LABEL_EMAIL_ADDRESS }), placeholder: ComponentConstant.PLACEHOLDER_EMAIL_ADDRESS }) })), _jsx("div", __assign({ className: "col-xs-12 col-md-4 mb-2" }, { children: _jsx(Field, { id: "isActive", name: "isActive", label: _jsx(Transform, { mkey: TranslationConstant.LABEL_STATUS }), layout: "horizontal", data: STATUS_TYPE_DATA_LIST, defaultValue: checkedStatusType, onChange: function (e) { return setStatusType(e.value); }, component: FormRadioGroup }) }))] })), _jsx(FormButtons, { searchType: true, resetHandler: function () {
                                formRenderProps.onFormReset();
                                handleresetform();
                            } })] }))); } }, JSON.stringify(initialValues))] }));
};
