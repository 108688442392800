import { httpPromise } from "services/common/httpPromise";
import { ServiceConstant } from "common/constant";
export var getAllPayrollExports = function (ClientID) {
    return httpPromise.get("".concat(ServiceConstant.CORP_API_BASE_PATH, "/PayrollExport/GetPayrollExports?clientid=").concat(ClientID));
};
export var postAddPayrollExports = function (ClientID, postData) {
    return httpPromise.post("".concat(ServiceConstant.CORP_API_BASE_PATH, "/PayrollExport?clientid=").concat(ClientID), postData);
};
export var getAllARExports = function (ClientID) {
    return httpPromise.get("".concat(ServiceConstant.CORP_API_BASE_PATH, "/MasArExport/MASArExports?clientID=").concat(ClientID));
};
export var putCloseExports = function (exportId, exportType) {
    var apiUrl = "".concat(ServiceConstant.CORP_API_BASE_PATH);
    switch (exportType) {
        case "payrollExport": {
            apiUrl += "/PayrollExport/ClosePayrollExport?masPayrollExportId=".concat(exportId);
            break;
        }
        case "apExport": {
            apiUrl += "/MasAPExport/CloseAPExport?masAPExportId=".concat(exportId);
            break;
        }
        case "arExport": {
            apiUrl += "/ARExportDetail/CloseArExport?masArExportId=".concat(exportId);
            break;
        }
        default:
            break;
    }
    return httpPromise.put(apiUrl);
};
export var putReOpenExports = function (exportId, exportType) {
    var apiUrl = "".concat(ServiceConstant.CORP_API_BASE_PATH);
    switch (exportType) {
        case "payrollExport": {
            apiUrl += "/PayrollExport/ReOpenPayrollExport?masPayrollExportId=".concat(exportId);
            break;
        }
        case "apExport": {
            apiUrl += "/MasAPExport/ReOpenAPExport?masAPExportId=".concat(exportId);
            break;
        }
        case "arExport": {
            apiUrl += "/ARExportDetail/ReOpenArExport?masArExportId=".concat(exportId);
            break;
        }
        default:
            break;
    }
    return httpPromise.put(apiUrl);
};
export var getARExportDetails = function (exportId, exportType) {
    var apiUrl = "".concat(ServiceConstant.CORP_API_BASE_PATH, "/ARExportDetail/").concat(exportType, "?masARExportId=").concat(exportId);
    return httpPromise.get(apiUrl);
};
export var getPayrollExportDetails = function (exportId, exportType) {
    var apiUrl = "".concat(ServiceConstant.CORP_API_BASE_PATH, "/PayrollExportDetail/").concat(exportType, "?masPayrollExportId=").concat(exportId);
    return httpPromise.get(apiUrl);
};
export var removePayrollExportDetails = function (id, exportType) {
    var apiUrl = "".concat(ServiceConstant.CORP_API_BASE_PATH, "/PayrollExport/");
    switch (exportType) {
        case "timesheet":
            apiUrl += "RemoveTimeSheetFromExport?timesheetId=".concat(id);
            break;
        case "manualPayment":
            apiUrl += "RemoveManualPaymentFromExport?manualPaymentId= ".concat(id);
            break;
        default:
            break;
    }
    return httpPromise.put(apiUrl);
};
export var getPayrollExportTabCount = function (masPayrollExportId, activeTab) {
    return httpPromise.get("".concat(ServiceConstant.CORP_API_BASE_PATH, "/PayrollExportDetail/GetPayrollExportDetailsCounts?masPayrollExportId=").concat(masPayrollExportId, "&activeTabCode=").concat(activeTab));
};
export var downloadPayrollThinkware = function (masPayrollExportId) {
    return httpPromise.get("".concat(ServiceConstant.CORP_API_BASE_PATH, "/PayrollExportDetail/DownloadTWPayrollExport?masPayrollExportId=").concat(masPayrollExportId));
};
export var removeARExportDetails = function (id, exportType) {
    console.log({ exportType: exportType });
    var apiUrl = "".concat(ServiceConstant.CORP_API_BASE_PATH, "/ARExportDetail/");
    switch (exportType) {
        case "nonAllocatedTSDetails":
            apiUrl += "RemoveTimeSheetDayDetail?timeSheetDayDetailId=".concat(id);
            break;
        case "allocatedTimesheetDetailsOracle":
            apiUrl += "RemoveAllocatedTimeSheetDayDetail?timeSheetDayDetailDollarsAllocationId=".concat(id);
            break;
        case "allocatedtimesheetdetailsvz":
            apiUrl += "RemoveAllocatedTimeSheetDayDetail?timeSheetDayDetailDollarsAllocationId=".concat(id);
            break;
        case "nonAllocatedERDetails":
            apiUrl += "RemoveExpenseReportDetail?expenseReportDetailId=".concat(id);
            break;
        case "allocatedERDetails":
            apiUrl += "RemoveAllocatedExpenseReportDetail?expenseReportDetailDollarsAllocationId=".concat(id);
            break;
        case "nonAllocatedVPDetails":
            apiUrl += "RemoveVendorPaymentDetail?vendorPaymentDetailId=".concat(id);
            break;
        case "allocatedVPDetails":
            apiUrl += "RemoveAllocatedVendorPaymentDetail?vendorPaymentDetailDollarsAllocationId=".concat(id);
            break;
        case "clientTimesheets":
            apiUrl += "RemoveClientTimeSheetDayDetail?clientTimeSheetDayDetailId=".concat(id);
            break;
        case "manualPayments":
            apiUrl += "RemoveManualPayment?manualPaymentId=".concat(id);
            break;
        case "creditCardDetails":
            apiUrl += "RemoveCreditCard?creditCardItemId=".concat(id);
            break;
        default:
            break;
    }
    return httpPromise.put(apiUrl);
};
export var getARExportTabCount = function (masPayrollExportId, activeTab) {
    return httpPromise.get("".concat(ServiceConstant.CORP_API_BASE_PATH, "/ARExportDetail/GetARExportDetailsCounts?masARExportId=").concat(masPayrollExportId, "&activeTabCode=").concat(activeTab));
};
export var getAllAPExports = function (ClientID) {
    return httpPromise.get("".concat(ServiceConstant.CORP_API_BASE_PATH, "/MasAPExport/GetAllAPExports?clientId=").concat(ClientID));
};
export var postAddAPExports = function (_a) {
    var clientId = _a.clientId, exportName = _a.exportName;
    return httpPromise.post("".concat(ServiceConstant.CORP_API_BASE_PATH, "/MasAPExport?clientId=").concat(clientId, "&exportName=").concat(exportName));
};
export var getAPExportDetails = function (exportId, exportType) {
    var apiUrl = "".concat(ServiceConstant.CORP_API_BASE_PATH, "/APExportDetail/").concat(exportType, "?masAPExportId=").concat(exportId);
    return httpPromise.get(apiUrl);
};
export var removeAPExportDetails = function (id, exportType) {
    var apiUrl = "".concat(ServiceConstant.CORP_API_BASE_PATH, "/APExportDetail/");
    switch (exportType) {
        case "manualPayments":
            apiUrl += "RemoveManualPayment?manualPaymentId=".concat(id);
            break;
        case "vendorPayments":
            apiUrl += "RemoveVendorPayment?vendorPaymentId=".concat(id);
            break;
        case "expenseReports":
            apiUrl += "RemoveExpenseReport?expenseReportId=".concat(id);
            break;
        default:
            break;
    }
    return httpPromise.put(apiUrl);
};
export var postAddARExports = function (data) {
    return httpPromise.post("".concat(ServiceConstant.CORP_API_BASE_PATH, "/MasArExport/CreateArExport"), data);
};
export var downloadExport = function (exportId, type) {
    var apiUrl = "".concat(ServiceConstant.CORP_API_BASE_PATH, "/");
    switch (type) {
        case "arExport":
            apiUrl += "ARExportDetail/DownloadARExportDetail?arExportId=".concat(exportId);
            break;
        case "EXPENSE_REIMBURSMENT_THINKWARE":
            apiUrl += "APExportDetail/DownloadExpenseReimbursmentThinkware?masAPExportId=".concat(exportId);
            break;
        case "AP_Report_For_Accounting":
            apiUrl += "APExportDetail/DownloadAPAccountingReport?masAPExportId=".concat(exportId);
            break;
        case "VENDOR_COST_THINKWARE":
            apiUrl += "APExportDetail/DownloadVendorPaymentsAndEquipmentcost?masAPExportId=".concat(exportId);
            break;
        case "AP_EXPORT_DARWIN":
            apiUrl += "APExportDetail/DownloadAPExport?masAPExportId=".concat(exportId);
            break;
        case "NEPAPVoucher":
            apiUrl += "ARExportDetail/DownloadNEPVoucher?arExportId=".concat(exportId);
            break;
        default:
            break;
    }
    return httpPromise.get(apiUrl);
};
export var getAPExportTabCount = function (masAPExportId, activeTab) {
    return httpPromise.get("".concat(ServiceConstant.CORP_API_BASE_PATH, "/APExportDetail/GetARExportDetailsCounts?masAPExportId=").concat(masAPExportId, "&activeTabCode=").concat(activeTab));
};
export var getExportExpandDetails = function (exportId, type) {
    var apiUrl = "".concat(ServiceConstant.CORP_API_BASE_PATH, "/");
    switch (type) {
        case "apVendorPayment":
            apiUrl += "APExportDetail/VendorPaymentDetails?vendorPaymentId=".concat(exportId);
            break;
        case "apExpenseReport":
            apiUrl += "APExportDetail/ExpenseReportDetails?expenseReportId=".concat(exportId);
            break;
        case "batchRecordsTimesheets":
            apiUrl += "BatchDetailTimeSheet/timeSheetDetails?timeSheetId=".concat(exportId);
            break;
        case "batchRecordsClientTimesheets":
            apiUrl += "batchrecord/clienttimesheetsdetails?clientTimesheetId=".concat(exportId);
            break;
        default:
            break;
    }
    return httpPromise.get(apiUrl);
};
export var getManageExports = function (clientId, exportType) {
    var apiUrl = "".concat(ServiceConstant.CORP_API_BASE_PATH, "/");
    switch (exportType) {
        case "mgPayrollExport":
            apiUrl += "ManagePayrollExport/openpayrollexport?clientId=".concat(clientId);
            break;
        case "mgAPExport":
            apiUrl += "ManageAPExport/openapexport?clientId=".concat(clientId);
            break;
        case "mgARExport":
            apiUrl += "ManageARExport/openarexport?clientId=".concat(clientId);
            break;
        case "batchRecords":
            apiUrl += "BatchRecord/availablebatches?clientId=".concat(clientId);
            break;
        default:
            break;
    }
    return httpPromise.get(apiUrl);
};
export var getManageExportsTabCount = function (clientId, activeTabCode, exportType) {
    var apiUrl = "".concat(ServiceConstant.CORP_API_BASE_PATH, "/");
    switch (exportType) {
        case "mgPayrollExport":
            apiUrl += "ManagePayrollExport/GetManagePayrollExportDetailsCounts?clientId=".concat(clientId, "&activeTabCode=").concat(activeTabCode);
            break;
        case "mgAPExport":
            apiUrl += "ManageAPExport/GetManageAPExportCounts?clientId=".concat(clientId, "&activeTabCode=").concat(activeTabCode);
            break;
        case "mgARExport":
            apiUrl += "ManageARExport/ManageARExportTabsCount?clientId=".concat(clientId, "&activeTabCode=").concat(activeTabCode);
            break;
        case "batchRecords":
            apiUrl += "BatchRecord/GetBatchRecordsCountsAsync?clientId=".concat(clientId, "&activeTabCode=").concat(activeTabCode);
            break;
        default:
            break;
    }
    return httpPromise.get(apiUrl);
};
export var getManageExportsGridData = function (clientId, type, exportType) {
    var apiUrl = "".concat(ServiceConstant.CORP_API_BASE_PATH, "/");
    switch (exportType) {
        case "mgPayrollExport":
            apiUrl += "ManagePayrollExport/".concat(type, "?clientId=").concat(clientId);
            break;
        case "mgAPExport":
            apiUrl += "ManageAPExport/".concat(type, "?clientId=").concat(clientId);
            break;
        case "mgARExport":
            apiUrl += "ManageARExport/".concat(type, "?clientId=").concat(clientId);
            break;
        case "batchRecords":
            apiUrl += "BatchRecord/".concat(type, "?clientId=").concat(clientId);
            break;
        default:
            break;
    }
    return httpPromise.get(apiUrl);
};
export var postSelectedRecords = function (clientId, exportType, postData) {
    var apiUrl = "".concat(ServiceConstant.CORP_API_BASE_PATH, "/");
    switch (exportType) {
        case "mgPayrollExport":
            apiUrl += "ManagePayrollExport";
            break;
        case "mgAPExport":
            apiUrl += "ManageAPExport";
            break;
        case "mgARExport":
            apiUrl += "ManageARExport";
            break;
        case "batchRecords":
            apiUrl += "BatchRecord";
            break;
        default:
            break;
    }
    return httpPromise.post(apiUrl, postData);
};
