import { httpPromise } from "services/common/httpPromise";
import { ServiceConstant } from "common/constant";
export var getMasterClients = function (queryString) {
    return httpPromise.get("".concat(ServiceConstant.MASTER_CLIENT_API_BASE_PATH, "/SearchMasterClients?").concat(queryString));
};
export var addMasterClients = function (queryString) {
    return httpPromise.post("".concat(ServiceConstant.MASTER_CLIENT_API_BASE_PATH, "/AddMasterClient?").concat(queryString));
};
export var editMasterClients = function (queryString) {
    return httpPromise.put("".concat(ServiceConstant.MASTER_CLIENT_API_BASE_PATH, "/EditMasterClient?").concat(queryString));
};
export var getMasterClientsUsers = function (queryString) {
    return httpPromise.get("".concat(ServiceConstant.MASTER_CLIENT_USER_API_BASE_PATH, "?").concat(queryString));
};
export var getMasterClientsUserInfoByID = function (clientID) {
    return httpPromise.get("".concat(ServiceConstant.MASTER_CLIENT_USER_API_BASE_PATH, "/GetMasterClientUser?masterClientUserId=").concat(clientID));
};
export var getMasterClientsUserWebSettingsByID = function (clientID) {
    return httpPromise.get("".concat(ServiceConstant.MASTER_CLIENT_USER_API_BASE_PATH, "/GetMasterClientUserWebSettings?masterClientUserId=").concat(clientID));
};
export var saveMasterClientsUserInfoData = function (groupData) {
    return httpPromise.post("".concat(ServiceConstant.MASTER_CLIENT_USER_API_BASE_PATH, "/PostClientUserInfoAsync"), groupData);
};
export var saveMasterClientsUserWebSettingsData = function (groupData) {
    return httpPromise.post("".concat(ServiceConstant.MASTER_CLIENT_USER_API_BASE_PATH, "/PostMasterClientUserWebSettingsAsync"), groupData);
};
export var getPayreelUserDataById = function (payreelUserId) {
    return httpPromise.get("".concat(ServiceConstant.CORP_PAYREEL_USERS_BASE_API_PATH, "/GetPayReelUsersByID?payReelUserId=").concat(payreelUserId));
};
export var savePayreelUserInfoData = function (groupData) {
    return httpPromise.post("".concat(ServiceConstant.CORP_PAYREEL_USERS_BASE_API_PATH, "/SavePayReelUser"), groupData);
};
export var getPayreelUserWebsettingsData = function (payreelUserId) {
    return httpPromise.get("".concat(ServiceConstant.CORP_PAYREEL_USERS_BASE_API_PATH, "/GetPayReelUserWebSettings?payReelUserID=").concat(payreelUserId));
};
export var savePayreelUserWebSettingsData = function (groupData) {
    return httpPromise.post("".concat(ServiceConstant.CORP_PAYREEL_USERS_BASE_API_PATH, "/PostPayreelUserWebSettings"), groupData);
};
export var getAllMasterClients = function () {
    return httpPromise.get("".concat(ServiceConstant.MASTER_CLIENT_API_BASE_PATH, "/GetMasterClients"));
};
export var getPayReelCoordinators = function () {
    return httpPromise.get("".concat(ServiceConstant.CORP_PAYREEL_USERS_BASE_API_PATH, "/GetPayReelCoordinators"));
};
