var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import { useEffect, useState } from "react";
import { Transform } from "helper/Transform";
import { TranslationConstant } from "common/constant";
import { DialogBox } from "./DialogBox";
import { cleanLocalUserStorage } from "./utils";
import { signoutRedirect } from "services/auth/auth";
var InvalidUserPage = function () {
    var _a = useState(true), modalState = _a[0], setModalState = _a[1];
    var _b = useState({
        headingText: "",
        bodyText: "",
        isErrorDialog: "",
    }), modalContent = _b[0], updatemodalContent = _b[1];
    useEffect(function () {
        updatemodalContent({
            headingText: (_jsx(_Fragment, { children: _jsx(Transform, { mkey: TranslationConstant.INVALID_LOGIN_HEADING }) })),
            bodyText: (_jsx("div", __assign({ className: "block subheading" }, { children: "The user has been marked inactive. If this was a mistake send us an email at info@payreel.com or call 303-526-4900" }))),
            acceptText: _jsx(Transform, { mkey: TranslationConstant.RETURN_TO_LOGIN_PAGE }),
            acceptHandler: hideModal,
            fixedWidth: true,
        });
        setModalState(true);
        cleanLocalUserStorage();
    }, []);
    var hideModal = function () {
        setModalState(false);
        signoutRedirect();
    };
    return (_jsx(_Fragment, { children: modalState && (_jsx(DialogBox, __assign({}, modalContent, { isDialogOpen: modalState, onModalChangeState: hideModal }))) }));
};
export default InvalidUserPage;
