var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useEffect } from "react";
import { Link } from "react-router-dom";
import { GridColumn, KendoGrid, Transform, TranslationConstant, useSearchEmpViewContext, } from "../index";
export var NonWorkedHoursCodesGrid = function (_a) {
    var SessionData = _a.SessionData, UpdateEditFormData = _a.UpdateEditFormData, CallGridRefresher = _a.CallGridRefresher, UpdateGridrefreshercall = _a.UpdateGridrefreshercall;
    var _b = useSearchEmpViewContext(), nonWorkedHoursCodeGridDataList = _b.nonWorkedHoursCodeGridDataList, fetchNonWorkedHoursCodeGridData = _b.fetchNonWorkedHoursCodeGridData;
    var EditForm = function (data) {
        UpdateEditFormData(data);
        window.scrollTo({
            top: 0,
            behavior: "smooth",
        });
    };
    var initAPICall = function () {
        fetchNonWorkedHoursCodeGridData(SessionData.ClientId);
    };
    useEffect(function () {
        if (SessionData.ClientId && !CallGridRefresher) {
            initAPICall();
        }
    }, [SessionData.ClientId]);
    useEffect(function () {
        if (CallGridRefresher) {
            refreshGridDataHandler();
            UpdateGridrefreshercall(false);
        }
    }, [CallGridRefresher]);
    var EditClientCell = function (props) {
        return (_jsx("td", __assign({ colSpan: props.colSpan, role: "gridcell", "aria-colindex": props.ariaColumnIndex, "aria-selected": props.isSelected, onClick: function () { return EditForm(props.dataItem); } }, { children: _jsxs(Link, __assign({ to: "#" }, { children: [_jsx("i", { className: "fa fa-edit fa-lg m-0" }), _jsx(Transform, { mkey: TranslationConstant.EDIT })] })) })));
    };
    var refreshGridDataHandler = function () {
        initAPICall();
    };
    return (_jsx(_Fragment, { children: _jsxs(KendoGrid, __assign({ data: nonWorkedHoursCodeGridDataList, refreshMethod: refreshGridDataHandler }, { children: [_jsx(GridColumn, { field: "", title: "Edit", width: "100px", cell: EditClientCell, filterable: false }), _jsx(GridColumn, { field: "code", title: "Code", width: "200px" }), _jsx(GridColumn, { field: "description", title: "Description", width: "250px" }), _jsx(GridColumn, { field: "earningsCode", title: "Earnings Code", width: "200px" })] })) }));
};
