var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
// KendoGrid.tsx
import { useState, useEffect } from "react";
import { Grid } from "@progress/kendo-react-grid";
import { AppConstant } from "common/constant";
import { process } from "@progress/kendo-data-query";
import GridRefresher from "components/shared/GridRefersher";
import { GridFilters } from "components/shared/GridFilters";
import { getGridItemCount, setGridItemCount } from "components/shared/utils";
import { Tooltip } from "@progress/kendo-react-tooltip";
var initDataState = { skip: 0, take: AppConstant.GRID_ITEM_TAKE_SIZE };
var KendoGrid = function (_a) {
    var data = _a.data, children = _a.children, refreshMethod = _a.refreshMethod, pageable = _a.pageable, removeProcessData = _a.removeProcessData, containerStyle = _a.containerStyle, hideGridFilter = _a.hideGridFilter, handleFilterDataCallBack = _a.handleFilterDataCallBack, showFilterStatus = _a.showFilterStatus, hideRefresher = _a.hideRefresher, dataStateHandle = _a.dataStateHandle, dataItemKey = _a.dataItemKey, selectedField = _a.selectedField, enabled = _a.enabled, drag = _a.drag, cell = _a.cell, mode = _a.mode, onSelectionChange = _a.onSelectionChange, onHeaderSelectionChange = _a.onHeaderSelectionChange, customFilterTitles = _a.customFilterTitles, gridIndex = _a.gridIndex, childGrid = _a.childGrid, expandField = _a.expandField, _b = _a.showPageable, showPageable = _b === void 0 ? true : _b, editField = _a.editField, sort = _a.sort, onSortChange = _a.onSortChange, onItemChange = _a.onItemChange, rowRender = _a.rowRender, showGridFilters = _a.showGridFilters;
    var _c = useState(initDataState), dataState = _c[0], setDataState = _c[1];
    var _d = useState(showGridFilters), showFilter = _d[0], setShowHideFilter = _d[1];
    var _e = useState([]), filter = _e[0], setFilter = _e[1];
    useEffect(function () {
        var Grid_Item_Count = getGridItemCount();
        setDataState(__assign(__assign({}, dataState), { take: Grid_Item_Count }));
    }, []);
    return (_jsxs(_Fragment, { children: [!hideGridFilter && (_jsx(GridFilters, { filter: filter, showFilter: showFilter, setShowHideFilter: setShowHideFilter, customFilterTitles: customFilterTitles })), _jsxs("div", __assign({ className: "general-grid ".concat(containerStyle) }, { children: [_jsx(Tooltip, __assign({ openDelay: 100 }, { children: _jsx(Grid, __assign({ sortable: true, resizable: true, sort: sort, onSortChange: onSortChange, pageable: showPageable
                                ? __assign({ buttonCount: AppConstant.BUTTON_COUNT_GRID, info: true, type: "numeric", pageSizes: AppConstant.PAGE_SIZE, previousNext: true }, pageable) : false, filterable: hideGridFilter ? showFilterStatus : showFilter, data: removeProcessData ? data : process(data, dataState) }, dataState, { onDataStateChange: function (e) {
                                var _a, _b;
                                setDataState(e.dataState);
                                if (dataStateHandle)
                                    dataStateHandle(e.dataState);
                                if (((_a = e.dataState) === null || _a === void 0 ? void 0 : _a.take) !== dataState.take) {
                                    setGridItemCount((_b = e.dataState) === null || _b === void 0 ? void 0 : _b.take);
                                }
                                if (hideGridFilter) {
                                    handleFilterDataCallBack(e.dataState.filter && e.dataState.filter.filters);
                                }
                                else {
                                    setFilter(e.dataState.filter && e.dataState.filter.filters);
                                }
                            }, dataItemKey: dataItemKey, selectedField: selectedField, selectable: { enabled: enabled, drag: drag, cell: cell, mode: mode }, onSelectionChange: onSelectionChange, onHeaderSelectionChange: onHeaderSelectionChange, detail: childGrid, expandField: expandField, editField: editField ? editField : "", onItemChange: onItemChange, rowRender: rowRender }, { children: children })) })), !hideRefresher && (_jsx(GridRefresher, { gridIndex: gridIndex || 0, refreshGridDataHandler: refreshMethod }))] }))] }));
};
export default KendoGrid;
