import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { ClientDashBoard } from "../ClientDashboard/ClientDashboard";
import { Transform, TranslationConstant, BreadcrumbComponent, PageTitle, AdminBoard, ComponentConstant, AppConstant, EmployeeAuthorisedDashBoard, EmployeeDashBoard, WEBUSERROLEID, } from "./index";
export var Dashboard = function () {
    var items = [
        {
            id: "dashboard",
            text: "Dashboard",
            iconClass: "k-i-home",
            route: "/",
        },
    ];
    var createDashboard = function () {
        var localStorageKey = "".concat(AppConstant.LOCAL_STORAGE_USER_PROFILE, ":").concat(process.env.AUTHORITY_URL, ":").concat(process.env.CLIENT_ID);
        var userDetails = (localStorage.getItem(localStorageKey) &&
            JSON.parse(atob(localStorage.getItem(localStorageKey)))) ||
            null;
        var WebUserRoleId = (userDetails || {}).WebUserRoleId;
        if (WebUserRoleId === WEBUSERROLEID.PAYREEL_ADMIN) {
            return _jsx(AdminBoard, {});
        }
        else if (WebUserRoleId === WEBUSERROLEID.NEW_HIRE_EMPLOYEE ||
            WebUserRoleId === WEBUSERROLEID.Employee) {
            return _jsx(EmployeeDashBoard, {});
        }
        else if (WebUserRoleId === WEBUSERROLEID.CLIENT) {
            return _jsx(ClientDashBoard, {});
        }
        else if (WebUserRoleId === WEBUSERROLEID.EMPLOYEE_AUTHORISED_I9_VERIFIER) {
            return _jsx(EmployeeAuthorisedDashBoard, {});
        }
    };
    var localStorageKey = "".concat(AppConstant.LOCAL_STORAGE_USER_PROFILE, ":").concat(process.env.AUTHORITY_URL, ":").concat(process.env.CLIENT_ID);
    var userDetails = (localStorage.getItem(localStorageKey) &&
        JSON.parse(atob(localStorage.getItem(localStorageKey)))) ||
        null;
    var data = {
        title: _jsx(Transform, { mkey: TranslationConstant.DASHBOARD }),
        subtitle: "".concat(ComponentConstant.WELCOME_BACK_TEXT, " ").concat(userDetails === null || userDetails === void 0 ? void 0 : userDetails.FirstName, " ").concat(userDetails === null || userDetails === void 0 ? void 0 : userDetails.LastName),
    };
    return (_jsxs(_Fragment, { children: [_jsx(BreadcrumbComponent, { items: items }), _jsx(PageTitle, { data: data }), createDashboard()] }));
};
