var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { create } from "zustand";
import { postManageAttributeGroupList, getFormGroupAttributeById, deleteFormGroupAttributeById, editFormGroupAttributeById, checkForUniqueAttrGroup, getManageAttributeGroupList, getAcroFieldMappingDataList, getDefaultSystemFieldAttribute, getGroupDropDownList, getAttributesDropDownList, getDataTypeList, getAttributeTypeList, getFormAttributeById, deleteFormAttributeById, editFormAttributeById, checkForUniqueAttribute, checkForValidFormat, getManageAttributeList, postManageAttributes, postAddNewMapping, getManageAttributeMappingOnLoadData, getDocumentCategoryList, getDocumentWorkflowList, getDocumentTypeList, getStateLists, getMangeUploadClientLists, publishCustomForm, postMangeUploadClientLists, getCustomFormWithIDData, patchCustomFormData, deleteManageAttributeMapping, patchManageAttrData, postSystemFieldMappingData, getCustomFormSearch, uploadNewFormDocument, getCustomFormAttributeValidation, postAttributeValidationResult, fetchSectionDropDownList, postManageSectionList, getSectionList, getSectionTypeList, deleteSection, editSectionList, getAttrActions, getAttrEvents, getAttrOperator, getAttrManipulationById, deleteAttrManipulationById, getAttrForEditbyId, saveAttrManipulation, updateAttrManipulation, postCustomDynamicFormData, fetchDynamicFormRenderData, getCustomFormNewHireDocumentType, getAttrActionValues, } from "services/customforms";
import { ComponentConstant } from "common/constant";
import { getWorkVisaDocumentfileinfo, postEmployeeWorkvisaApprove, postEmployeeWorkvisaNotApprove, } from "services/customforms/customforms";
export var useCustomFormContext = create(function (set, get) { return ({
    attributeGroupList: [],
    attributeGroupById: {},
    acroFieldMappingList: [],
    dropdownSystemField: [],
    attributeById: {},
    manageAttributesList: [],
    attributeGroupDropDownList: [],
    attributesDropDownList: [],
    dataTypeDropDownList: [],
    attributeTypeDropDownList: [],
    attributeGroupDropDownListWithSelectAll: [],
    dataTypeDropDownListWithSelectAll: [],
    attributeTypeDropDownListWithSelectAll: [],
    manageAttributeMappingOnLoadData: [],
    documentCateoryList: [],
    clientList: [],
    documentWorkflowList: [],
    documentTypeList: [],
    stateList: [],
    manageUploadClientsList: [],
    customformSingleDataList: [],
    attributesDownListWithSelectAll: [],
    customFormSearchResult: [],
    manageSectionOnLoadData: [],
    sectionList: [],
    sectionTypeList: [],
    operatorTypeList: [],
    eventTypeList: [],
    actionTypeList: [],
    manipulationList: [],
    singleAttrManiData: {},
    dynamicFormRenderData: {},
    customFormNewHireDocumentTypeList: [],
    actionValueList: [],
    WorkVisaDocumentfileinfo: [],
    fetchCustomFormNewHireDocumentTypeList: function () { return __awaiter(void 0, void 0, void 0, function () {
        var response;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, getCustomFormNewHireDocumentType()];
                case 1:
                    response = _a.sent();
                    set(function (state) { return (__assign(__assign({}, state), { customFormNewHireDocumentTypeList: response.data })); });
                    return [2 /*return*/];
            }
        });
    }); },
    saveManageSectionList: function (manageSection) { return __awaiter(void 0, void 0, void 0, function () {
        var response;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, postManageSectionList(manageSection)];
                case 1:
                    response = _a.sent();
                    return [2 /*return*/, response];
            }
        });
    }); },
    deleteManageSection: function (sectionId) { return __awaiter(void 0, void 0, void 0, function () {
        var response;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, deleteSection(sectionId)];
                case 1:
                    response = _a.sent();
                    return [2 /*return*/, response];
            }
        });
    }); },
    editManageSectionList: function (groupData) { return __awaiter(void 0, void 0, void 0, function () {
        var response;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, editSectionList(groupData)];
                case 1:
                    response = _a.sent();
                    return [2 /*return*/, response];
            }
        });
    }); },
    fetchSectionList: function () { return __awaiter(void 0, void 0, void 0, function () {
        var response;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, getSectionList()];
                case 1:
                    response = _a.sent();
                    set(function (state) { return (__assign(__assign({}, state), { sectionList: response.data })); });
                    return [2 /*return*/];
            }
        });
    }); },
    fetchSectionTypeList: function () { return __awaiter(void 0, void 0, void 0, function () {
        var response;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, getSectionTypeList()];
                case 1:
                    response = _a.sent();
                    set(function (state) { return (__assign(__assign({}, state), { sectionTypeList: response.data })); });
                    return [2 /*return*/];
            }
        });
    }); },
    saveManageAttributeGroupList: function (groupData) { return __awaiter(void 0, void 0, void 0, function () {
        var response;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, postManageAttributeGroupList(groupData)];
                case 1:
                    response = _a.sent();
                    return [2 /*return*/, response];
            }
        });
    }); },
    deleteManageAttributeGroup: function (id) { return __awaiter(void 0, void 0, void 0, function () {
        var response;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, deleteFormGroupAttributeById(id)];
                case 1:
                    response = _a.sent();
                    return [2 /*return*/, response];
            }
        });
    }); },
    editManageAttributeGroupList: function (groupData) { return __awaiter(void 0, void 0, void 0, function () {
        var response;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, editFormGroupAttributeById(groupData)];
                case 1:
                    response = _a.sent();
                    return [2 /*return*/, response];
            }
        });
    }); },
    fetchFormAttributeGroupById: function (formAttributeId) { return __awaiter(void 0, void 0, void 0, function () {
        var response;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, getFormGroupAttributeById(formAttributeId)];
                case 1:
                    response = _a.sent();
                    set(function (state) { return (__assign(__assign({}, state), { attributeGroupById: response.data })); });
                    return [2 /*return*/];
            }
        });
    }); },
    checkUniqueFormAttributeGroup: function (groupData) { return __awaiter(void 0, void 0, void 0, function () {
        var response;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, checkForUniqueAttrGroup(groupData)];
                case 1:
                    response = _a.sent();
                    return [2 /*return*/, response];
            }
        });
    }); },
    fetchManageAttributeGroupList: function () { return __awaiter(void 0, void 0, void 0, function () {
        var response;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, getManageAttributeGroupList()];
                case 1:
                    response = _a.sent();
                    set(function (state) { return (__assign(__assign({}, state), { attributeGroupList: response.data })); });
                    return [2 /*return*/];
            }
        });
    }); },
    fetchAcroFieldMappingList: function (id) { return __awaiter(void 0, void 0, void 0, function () {
        var response;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, getAcroFieldMappingDataList(id)];
                case 1:
                    response = _a.sent();
                    set(function (state) { return (__assign(__assign({}, state), { acroFieldMappingList: response.data })); });
                    return [2 /*return*/];
            }
        });
    }); },
    fetchAttributeDropdownValue: function (id) { return __awaiter(void 0, void 0, void 0, function () {
        var response;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, getDefaultSystemFieldAttribute(id)];
                case 1:
                    response = _a.sent();
                    set(function (state) { return (__assign(__assign({}, state), { dropdownSystemField: response.data })); });
                    return [2 /*return*/];
            }
        });
    }); },
    deleteManageAttribute: function (id) { return __awaiter(void 0, void 0, void 0, function () {
        var response;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, deleteFormAttributeById(id)];
                case 1:
                    response = _a.sent();
                    return [2 /*return*/, response];
            }
        });
    }); },
    deleteManageAttributeMapping: function (data) { return __awaiter(void 0, void 0, void 0, function () {
        var response;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, deleteManageAttributeMapping(data)];
                case 1:
                    response = _a.sent();
                    return [2 /*return*/, response];
            }
        });
    }); },
    editManageAttributeList: function (groupData) { return __awaiter(void 0, void 0, void 0, function () {
        var response;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, editFormAttributeById(groupData)];
                case 1:
                    response = _a.sent();
                    return [2 /*return*/, response];
            }
        });
    }); },
    fetchFormAttributeById: function (formAttributeId) { return __awaiter(void 0, void 0, void 0, function () {
        var response;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, getFormAttributeById(formAttributeId)];
                case 1:
                    response = _a.sent();
                    set(function (state) { return (__assign(__assign({}, state), { attributeById: response.data })); });
                    return [2 /*return*/];
            }
        });
    }); },
    checkUniqueFormAttribute: function (groupData) { return __awaiter(void 0, void 0, void 0, function () {
        var response;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, checkForUniqueAttribute(groupData)];
                case 1:
                    response = _a.sent();
                    return [2 /*return*/, response];
            }
        });
    }); },
    checkIsValidFormat: function (groupData) { return __awaiter(void 0, void 0, void 0, function () {
        var response;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, checkForValidFormat(groupData)];
                case 1:
                    response = _a.sent();
                    return [2 /*return*/, response];
            }
        });
    }); },
    fetchattributeGroupDropDownList: function () { return __awaiter(void 0, void 0, void 0, function () {
        var response;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, getGroupDropDownList()];
                case 1:
                    response = _a.sent();
                    set(function (state) { return (__assign(__assign({}, state), { attributeGroupDropDownList: response.data })); });
                    return [2 /*return*/];
            }
        });
    }); },
    fetchAttributesList: function (groupData) { return __awaiter(void 0, void 0, void 0, function () {
        var response;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, getAttributesDropDownList(groupData)];
                case 1:
                    response = _a.sent();
                    set(function (state) { return (__assign(__assign({}, state), { attributesDropDownList: response.data, attributesDownListWithSelectAll: __spreadArray([
                            {
                                attributeName: ComponentConstant.CHECK_ALL_DROPDOWN_VALUE,
                                attributeGroupMappingID: null,
                            }
                        ], response.data, true) })); });
                    return [2 /*return*/];
            }
        });
    }); },
    fetchDataTypeDropDownList: function () { return __awaiter(void 0, void 0, void 0, function () {
        var response;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, getDataTypeList()];
                case 1:
                    response = _a.sent();
                    set(function (state) { return (__assign(__assign({}, state), { dataTypeDropDownList: response.data })); });
                    return [2 /*return*/];
            }
        });
    }); },
    fetchAttributeTypeDropDownList: function () { return __awaiter(void 0, void 0, void 0, function () {
        var response;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, getAttributeTypeList()];
                case 1:
                    response = _a.sent();
                    set(function (state) { return (__assign(__assign({}, state), { attributeTypeDropDownList: response.data })); });
                    return [2 /*return*/];
            }
        });
    }); },
    saveManageAttributes: function (groupData) { return __awaiter(void 0, void 0, void 0, function () {
        var response;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, postManageAttributes(groupData)];
                case 1:
                    response = _a.sent();
                    return [2 /*return*/, response];
            }
        });
    }); },
    saveAddNewMapping: function (data) { return __awaiter(void 0, void 0, void 0, function () {
        var response;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, postAddNewMapping(data)];
                case 1:
                    response = _a.sent();
                    return [2 /*return*/, response];
            }
        });
    }); },
    fetchManageAttributesList: function () { return __awaiter(void 0, void 0, void 0, function () {
        var response;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, getManageAttributeList()];
                case 1:
                    response = _a.sent();
                    set(function (state) { return (__assign(__assign({}, state), { manageAttributesList: response.data })); });
                    return [2 /*return*/];
            }
        });
    }); },
    resetFormsAttributeList: function () {
        set(function (state) { return (__assign(__assign({}, state), { attributeById: {}, customformSingleDataList: [] })); });
    },
    // manage attribute mapping calls
    fetchManageAttributeMappingOnLoadData: function (customFormID) { return __awaiter(void 0, void 0, void 0, function () {
        var response;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, getManageAttributeMappingOnLoadData(customFormID)];
                case 1:
                    response = _a.sent();
                    set(function (state) { return (__assign(__assign({}, state), { manageAttributeMappingOnLoadData: response.data })); });
                    return [2 /*return*/];
            }
        });
    }); },
    fetchDocumentCategoryList: function () { return __awaiter(void 0, void 0, void 0, function () {
        var response;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, getDocumentCategoryList()];
                case 1:
                    response = _a.sent();
                    set(function (state) { return (__assign(__assign({}, state), { documentCateoryList: response.data })); });
                    return [2 /*return*/];
            }
        });
    }); },
    fetchdocumentWorkflowListList: function () { return __awaiter(void 0, void 0, void 0, function () {
        var response;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, getDocumentWorkflowList()];
                case 1:
                    response = _a.sent();
                    set(function (state) { return (__assign(__assign({}, state), { documentWorkflowList: response.data })); });
                    return [2 /*return*/];
            }
        });
    }); },
    fetchdocumentTypeList: function () { return __awaiter(void 0, void 0, void 0, function () {
        var response;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, getDocumentTypeList()];
                case 1:
                    response = _a.sent();
                    set(function (state) { return (__assign(__assign({}, state), { documentTypeList: response.data })); });
                    return [2 /*return*/];
            }
        });
    }); },
    fetchstateList: function () { return __awaiter(void 0, void 0, void 0, function () {
        var response;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, getStateLists()];
                case 1:
                    response = _a.sent();
                    set(function (state) { return (__assign(__assign({}, state), { stateList: response.data })); });
                    return [2 /*return*/];
            }
        });
    }); },
    fetchManageUploadClientsList: function () { return __awaiter(void 0, void 0, void 0, function () {
        var response;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, getMangeUploadClientLists()];
                case 1:
                    response = _a.sent();
                    set(function (state) { return (__assign(__assign({}, state), { manageUploadClientsList: response.data })); });
                    return [2 /*return*/];
            }
        });
    }); },
    publishCustomForm: function (groupData) { return __awaiter(void 0, void 0, void 0, function () {
        var response;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, publishCustomForm(groupData)];
                case 1:
                    response = _a.sent();
                    return [2 /*return*/, response];
            }
        });
    }); },
    postUploadData: function (dataObject) { return __awaiter(void 0, void 0, void 0, function () {
        var response;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, postMangeUploadClientLists(dataObject)];
                case 1:
                    response = _a.sent();
                    return [2 /*return*/, response];
            }
        });
    }); },
    fetchCustomFormDataWithID: function (customFormID) { return __awaiter(void 0, void 0, void 0, function () {
        var response;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, getCustomFormWithIDData(customFormID)];
                case 1:
                    response = _a.sent();
                    set(function (state) { return (__assign(__assign({}, state), { customformSingleDataList: response.data })); });
                    return [2 /*return*/];
            }
        });
    }); },
    saveSystemFieldMappingData: function (groupData) { return __awaiter(void 0, void 0, void 0, function () {
        var response;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, postSystemFieldMappingData(groupData)];
                case 1:
                    response = _a.sent();
                    return [2 /*return*/, response];
            }
        });
    }); },
    patchCustomFormDataWithID: function (patchToUpdate) { return __awaiter(void 0, void 0, void 0, function () {
        var response, e_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 2, , 3]);
                    return [4 /*yield*/, patchCustomFormData(patchToUpdate)];
                case 1:
                    response = _a.sent();
                    return [2 /*return*/, response];
                case 2:
                    e_1 = _a.sent();
                    return [2 /*return*/, e_1];
                case 3: return [2 /*return*/];
            }
        });
    }); },
    fetchCustomFormSearch: function (objData) { return __awaiter(void 0, void 0, void 0, function () {
        var response;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, getCustomFormSearch(objData)];
                case 1:
                    response = _a.sent();
                    set(function (state) { return (__assign(__assign({}, state), { customFormSearchResult: response.data })); });
                    return [2 /*return*/];
            }
        });
    }); },
    updateManageAttributeMapping: function (dataObject) { return __awaiter(void 0, void 0, void 0, function () {
        var response;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, patchManageAttrData(dataObject)];
                case 1:
                    response = _a.sent();
                    return [2 /*return*/, response];
            }
        });
    }); },
    uploadNewFormDocument: function (formData) { return __awaiter(void 0, void 0, void 0, function () {
        var response;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, uploadNewFormDocument(formData)];
                case 1:
                    response = _a.sent();
                    return [2 /*return*/, response];
            }
        });
    }); },
    getCustomFormAttributeValidation: function (customFormAttrMappingID) { return __awaiter(void 0, void 0, void 0, function () {
        var response;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, getCustomFormAttributeValidation(customFormAttrMappingID)];
                case 1:
                    response = _a.sent();
                    return [2 /*return*/, response];
            }
        });
    }); },
    postAttributeValidationResult: function (attributeData) { return __awaiter(void 0, void 0, void 0, function () {
        var response;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, postAttributeValidationResult(attributeData)];
                case 1:
                    response = _a.sent();
                    return [2 /*return*/, response];
            }
        });
    }); },
    fetchSectionDropDownList: function () { return __awaiter(void 0, void 0, void 0, function () {
        var response;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, fetchSectionDropDownList()];
                case 1:
                    response = _a.sent();
                    set(function (state) { return (__assign(__assign({}, state), { manageSectionOnLoadData: response.data })); });
                    return [2 /*return*/];
            }
        });
    }); },
    getAttrEvents: function () { return __awaiter(void 0, void 0, void 0, function () {
        var response;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, getAttrEvents()];
                case 1:
                    response = _a.sent();
                    set(function (state) { return (__assign(__assign({}, state), { eventTypeList: response.data })); });
                    return [2 /*return*/];
            }
        });
    }); },
    getAttrOperator: function () { return __awaiter(void 0, void 0, void 0, function () {
        var response;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, getAttrOperator()];
                case 1:
                    response = _a.sent();
                    set(function (state) { return (__assign(__assign({}, state), { operatorTypeList: response.data })); });
                    return [2 /*return*/];
            }
        });
    }); },
    getAttrActions: function () { return __awaiter(void 0, void 0, void 0, function () {
        var response;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, getAttrActions()];
                case 1:
                    response = _a.sent();
                    set(function (state) { return (__assign(__assign({}, state), { actionTypeList: response.data })); });
                    return [2 /*return*/];
            }
        });
    }); },
    postAttributeManipulationData: function (attributeData) { return __awaiter(void 0, void 0, void 0, function () {
        var response;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, saveAttrManipulation(attributeData)];
                case 1:
                    response = _a.sent();
                    return [2 /*return*/, response];
            }
        });
    }); },
    getAttrEditById: function (attributeManipulationId, manipulationResultId) { return __awaiter(void 0, void 0, void 0, function () {
        var response;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, getAttrForEditbyId(attributeManipulationId, manipulationResultId)];
                case 1:
                    response = _a.sent();
                    return [2 /*return*/, response];
            }
        });
    }); },
    deleteAttrManipulation: function (manipulationId, manipulationResultId) { return __awaiter(void 0, void 0, void 0, function () {
        var response;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, deleteAttrManipulationById(manipulationId, manipulationResultId)];
                case 1:
                    response = _a.sent();
                    return [2 /*return*/, response];
            }
        });
    }); },
    loadAttrManipulationList: function (id, isCalculation) { return __awaiter(void 0, void 0, void 0, function () {
        var response;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, getAttrManipulationById(id, isCalculation)];
                case 1:
                    response = _a.sent();
                    return [2 /*return*/, response];
            }
        });
    }); },
    updateAttributeManipulationData: function (attributeData) { return __awaiter(void 0, void 0, void 0, function () {
        var response;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, updateAttrManipulation(attributeData)];
                case 1:
                    response = _a.sent();
                    return [2 /*return*/, response];
            }
        });
    }); },
    saveCustomDynamicFormData: function (data) { return __awaiter(void 0, void 0, void 0, function () {
        var response;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, postCustomDynamicFormData(data)];
                case 1:
                    response = _a.sent();
                    return [2 /*return*/, response];
            }
        });
    }); },
    fetchDynamicFormRenderData: function (newHireDocumentID, getFromNewHireDocument) { return __awaiter(void 0, void 0, void 0, function () {
        var response;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, fetchDynamicFormRenderData(newHireDocumentID, getFromNewHireDocument)];
                case 1:
                    response = _a.sent();
                    set(function (state) { return (__assign(__assign({}, state), { dynamicFormRenderData: response.data })); });
                    return [2 /*return*/];
            }
        });
    }); },
    getAttrActionValues: function () { return __awaiter(void 0, void 0, void 0, function () {
        var response;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, getAttrActionValues()];
                case 1:
                    response = _a.sent();
                    set(function (state) { return (__assign(__assign({}, state), { actionValueList: response.data })); });
                    return [2 /*return*/];
            }
        });
    }); },
    saveEmployeeWorkvisaApprove: function (data) { return __awaiter(void 0, void 0, void 0, function () {
        var response;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, postEmployeeWorkvisaApprove(data)];
                case 1:
                    response = _a.sent();
                    return [2 /*return*/, response];
            }
        });
    }); },
    saveEmployeeWorkvisanotApprove: function (data) { return __awaiter(void 0, void 0, void 0, function () {
        var response;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, postEmployeeWorkvisaNotApprove(data)];
                case 1:
                    response = _a.sent();
                    return [2 /*return*/, response];
            }
        });
    }); },
    getWorkVisaDocumentfileinfo: function (newHireDocumentID) { return __awaiter(void 0, void 0, void 0, function () {
        var response;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, getWorkVisaDocumentfileinfo(newHireDocumentID)];
                case 1:
                    response = _a.sent();
                    set(function (state) { return (__assign(__assign({}, state), { WorkVisaDocumentfileinfo: response.data })); });
                    return [2 /*return*/, response];
            }
        });
    }); },
    resetDynamicFormRenderData: function () { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            set(function (state) { return (__assign(__assign({}, state), { dynamicFormRenderData: {} })); });
            return [2 /*return*/];
        });
    }); },
}); });
