var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useEffect } from "react";
import { GridColumn as Column } from "@progress/kendo-react-grid";
import { useSearchEmpViewContext, KendoGrid, Transform, TranslationConstant, ActionCell, EllipsisGridCell, createEncodedURL, redirectAdminUser, useReleasePayrollContextView, getUserDetailsFromLocalStorage, } from "../index";
import { useNavigate } from "react-router";
export var AdminAddEmployeeGrid = function (_a) {
    var ClientEmpSearchData = _a.ClientEmpSearchData, UpdateIsSearchPerformed = _a.UpdateIsSearchPerformed, IsSearchPerformed = _a.IsSearchPerformed;
    var navigate = useNavigate();
    var _b = useSearchEmpViewContext(), fetchProOnboardingEmployees = _b.fetchProOnboardingEmployees, proOnboardingEmployeesList = _b.proOnboardingEmployeesList, resetProOnboardingEmployees = _b.resetProOnboardingEmployees;
    var fetchClientGlobalDetails = useReleasePayrollContextView().fetchClientGlobalDetails;
    var initAPICall = function () {
        fetchProOnboardingEmployees(ClientEmpSearchData);
    };
    var sessionData = getUserDetailsFromLocalStorage("pro");
    useEffect(function () {
        if (ClientEmpSearchData || IsSearchPerformed) {
            initAPICall();
            UpdateIsSearchPerformed(false);
        }
        else {
            resetProOnboardingEmployees();
        }
    }, [ClientEmpSearchData]);
    var accessAdminClient = function (clientID, dataItem) { return __awaiter(void 0, void 0, void 0, function () {
        var response;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, fetchClientGlobalDetails(clientID)];
                case 1:
                    response = _a.sent();
                    localStorage.setItem("selectedClient", JSON.stringify(response === null || response === void 0 ? void 0 : response.data));
                    redirectAdminUser(clientID);
                    navigate(createEncodedURL("/admineditemployee", {
                        recordTypeId: dataItem === null || dataItem === void 0 ? void 0 : dataItem.recordTypeId,
                        ClientName: dataItem === null || dataItem === void 0 ? void 0 : dataItem.clientName,
                        FirstName: dataItem === null || dataItem === void 0 ? void 0 : dataItem.firstName,
                        LastName: dataItem === null || dataItem === void 0 ? void 0 : dataItem.lastName,
                        recordType: dataItem.recordType,
                        ClientID: clientID,
                        addType: true,
                    }));
                    navigate(0);
                    return [2 /*return*/];
            }
        });
    }); };
    var refreshGridHandler = function () {
        initAPICall();
    };
    return (_jsx(_Fragment, { children: _jsxs(KendoGrid, __assign({ data: proOnboardingEmployeesList, refreshMethod: refreshGridHandler }, { children: [_jsx(Column, { field: "", title: "Select", width: "80px", filterable: false, cell: function (cell) { return (_jsx(ActionCell, { cellprops: cell, icon: "", title: _jsx(Transform, { mkey: TranslationConstant.LABEL_SELECT }), onClick: function () { return accessAdminClient(sessionData === null || sessionData === void 0 ? void 0 : sessionData.ClientId, cell.dataItem); } })); } }), _jsx(Column, { field: "recordType", title: "Record Type", width: "140px" }), _jsx(Column, { field: "clientName", title: "Client", width: "160px", cell: function (cell) { return (_jsx(EllipsisGridCell, { dataItem: cell === null || cell === void 0 ? void 0 : cell.dataItem, cellField: "clientName" })); } }), _jsx(Column, { field: "lastName", title: "Last Name", width: "160px" }), _jsx(Column, { field: "firstName", title: "First Name", width: "160px" }), _jsx(Column, { field: "phoneNumber", title: "Phone Number", width: "150px" }), _jsx(Column, { field: "emailAddress", title: "Email Address", width: "220px" }), _jsx(Column, { field: "masid", title: "MAS ID", width: "120px" }), _jsx(Column, { field: "employeeIdentificationNumber", title: "Employee ID #", width: "140px" }), _jsx(Column, { field: "ssn", title: "SSN", width: "120px" }), _jsx(Column, { field: "status", title: "Status", width: "160px" })] })) }));
};
