import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { BreadcrumbComponent, Transform, TranslationConstant, PageTitle, NewYorkMinorWorkPermitForm } from './index';
import { useSearchParams } from 'react-router-dom';
var NewYorkMinorWorkPermit = function (props) {
    var searchParams = useSearchParams()[0];
    var newHireDocumentID = searchParams.get('newHireDocumentID');
    var items = [
        {
            id: "dashboard",
            text: "Dashboard",
            iconClass: "k-i-home",
            route: '/employee'
        },
        {
            id: "newyorkminorworkpermit",
            text: "New York Minor Work Permit",
        },
    ];
    var data = {
        title: _jsx(Transform, { mkey: TranslationConstant.NEW_YORK_MINOR_WORK_PERMIT })
    };
    return (_jsxs(_Fragment, { children: [_jsx(BreadcrumbComponent, { items: items }), _jsx(PageTitle, { data: data }), _jsx(NewYorkMinorWorkPermitForm, { newHireDocumentID: newHireDocumentID })] }));
};
export default NewYorkMinorWorkPermit;
