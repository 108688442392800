import { jsx as _jsx } from "react/jsx-runtime";
import { Transform, TranslationConstant } from "./index";
export var PAGE_TITLE_EMPLOYEE = {
    title: _jsx(Transform, { mkey: TranslationConstant.LABEL_EMPLOYEE }),
};
export var CLIENT_SEARCH_EMP_BREADCRUMB = [
    {
        id: "home",
        text: "Home",
        iconClass: "k-i-home",
        route: "/",
    },
    {
        id: "employee",
        text: "Employees",
    },
];
export var PAGE_TITLE_ADD_EMPLOYEE = {
    title: _jsx(Transform, { mkey: TranslationConstant.LABEL_ADD_EMPLOYEE }),
};
export var STATUS_TYPE_DATA_LIST = [
    {
        label: _jsx(Transform, { mkey: TranslationConstant.LABEL_BOTH }),
        value: null,
    },
    {
        label: _jsx(Transform, { mkey: TranslationConstant.RADIO_ACTIVE_STATUS }),
        value: 1,
    },
    {
        label: _jsx(Transform, { mkey: TranslationConstant.RADIO_INACTIVE_STATUS }),
        value: 2,
    },
];
