var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx } from "react/jsx-runtime";
import * as React from "react";
import { toODataString } from "@progress/kendo-data-query";
import { useCurrentViewContext } from "store/common/currentViewContext";
import { AppConstant } from "common/constant";
import { createQueryString, removeFalsyValueFromObj } from "./utils";
import Spinner from "./Spinner";
export var GridOData = function (props) {
    var lastSuccess = React.useRef("");
    var pending = React.useRef("");
    var resetShowProgress = useCurrentViewContext().resetShowProgress;
    var requestDataIfNeeded = function () { return __awaiter(void 0, void 0, void 0, function () {
        var queryParam, res;
        var _a, _b, _c;
        return __generator(this, function (_d) {
            switch (_d.label) {
                case 0:
                    if (pending.current || toODataString(props.dataState) === lastSuccess.current) {
                        return [2 /*return*/];
                    }
                    resetShowProgress(true);
                    pending.current = toODataString(props.dataState);
                    queryParam = "$count=true&".concat(pending.current, "&").concat(createQueryString(removeFalsyValueFromObj(props.paramObj)));
                    return [4 /*yield*/, props.contextMethod(queryParam)];
                case 1:
                    res = _d.sent();
                    lastSuccess.current = pending.current;
                    pending.current = "";
                    if (toODataString(props.dataState) === lastSuccess.current) {
                        props.onDataReceived.call(undefined, {
                            data: ((_a = res === null || res === void 0 ? void 0 : res.data) === null || _a === void 0 ? void 0 : _a.items) || (res === null || res === void 0 ? void 0 : res.data) || [],
                            total: ((_b = res === null || res === void 0 ? void 0 : res.data) === null || _b === void 0 ? void 0 : _b.count) || ((_c = res === null || res === void 0 ? void 0 : res.data[0]) === null || _c === void 0 ? void 0 : _c.totalCount) || AppConstant.GRID_ITEM_TAKE_SIZE,
                        });
                    }
                    else {
                        requestDataIfNeeded();
                    }
                    resetShowProgress(false);
                    return [2 /*return*/];
            }
        });
    }); };
    requestDataIfNeeded();
    return pending.current ? _jsx(Spinner, {}) : null;
};
