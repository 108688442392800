import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { PageTitle, ManageRolesGrid, TranslationConstant, Transform, BreadcrumbComponent, } from "./index";
export var ManageRoles = function () {
    var breadcrumbTtems = [
        {
            id: "home",
            text: "Home",
            iconClass: "k-i-home",
            route: '/'
        },
        {
            id: "manage-roles",
            text: "Manage Roles",
        }
    ];
    var data = {
        title: _jsx(Transform, { mkey: TranslationConstant.MANAGE_ROLES })
    };
    return (_jsxs(_Fragment, { children: [_jsx(BreadcrumbComponent, { items: breadcrumbTtems }), _jsx(PageTitle, { data: data }), _jsx(ManageRolesGrid, {})] }));
};
