var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useEffect, useRef, useState } from "react";
import { PageTitle, Button, Transform, BreadcrumbComponent, TranslationConstant, EmployeesSearchForm, TimeClockGrid, decodedURL, DialogBox, getUserDetailsFromLocalStorage, useCurrentViewContext, } from "./index";
import AddTimeForm from "./AddTimeClockTimesheet";
import { useReleasePayrollContextView } from "../../ClientSearchEmployees";
var items = [
    {
        id: "home",
        text: "Home",
        iconClass: "k-i-home",
        route: "/",
    },
    {
        id: "searchEmployeesTimeClock",
        text: "Time Clock Management",
    },
];
var initialDayDetails = {
    dateWorked: "",
    timeSheetDayDetailID: null,
};
export default function EmployeesSearchTimeClock(_a) {
    var _this = this;
    var employeeIDForEdit = _a.employeeIDForEdit, employeeInformation = _a.employeeInformation;
    var queryStringObject = decodedURL();
    var formRedirectData = queryStringObject.formRedirectData, TimeSheetId = queryStringObject.TimeSheetId, weekEndingDate = queryStringObject.weekEndingDate;
    var _b = useState((formRedirectData && formRedirectData) || null), searchData = _b[0], setSearchData = _b[1];
    var timeClockGridRef = useRef(null);
    var _c = useState(null), selectedClient = _c[0], setSelectedClient = _c[1];
    var _d = useState(false), modalState = _d[0], setModalState = _d[1];
    var searchObject = function (data) {
        setSearchData(data);
    };
    var getEmployeesList = useCurrentViewContext().getEmployeesList;
    var oidcUserProfile = getUserDetailsFromLocalStorage("pro");
    var _e = useState(initialDayDetails), timeSheetDayDetails = _e[0], updatetimeSheetDayDetails = _e[1];
    var data = {
        title: _jsx(Transform, { mkey: TranslationConstant.SEARCH_EMPLOYEE_TIMECLOCK }),
    };
    var fetchClientGlobalDetails = useReleasePayrollContextView().fetchClientGlobalDetails;
    var addNewTimesheet = function () {
        setModalState(true);
    };
    useEffect(function () {
        if (oidcUserProfile === null || oidcUserProfile === void 0 ? void 0 : oidcUserProfile.ClientId) {
            initApiCall();
        }
    }, [oidcUserProfile === null || oidcUserProfile === void 0 ? void 0 : oidcUserProfile.ClientId]);
    var initApiCall = function () { return __awaiter(_this, void 0, void 0, function () {
        var response;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, fetchClientGlobalDetails(oidcUserProfile === null || oidcUserProfile === void 0 ? void 0 : oidcUserProfile.ClientId)];
                case 1:
                    response = _a.sent();
                    return [4 /*yield*/, getEmployeesList(oidcUserProfile === null || oidcUserProfile === void 0 ? void 0 : oidcUserProfile.ClientId)];
                case 2:
                    _a.sent();
                    localStorage.setItem("selectedClient", JSON.stringify(response === null || response === void 0 ? void 0 : response.data));
                    setSelectedClient(response === null || response === void 0 ? void 0 : response.data);
                    return [2 /*return*/];
            }
        });
    }); };
    var handleDayTimeDetails = function (dayDetails) {
        updatetimeSheetDayDetails(dayDetails);
        setModalState(true);
    };
    var handleForceGridRefresher = function () { var _a; return (_a = timeClockGridRef === null || timeClockGridRef === void 0 ? void 0 : timeClockGridRef.current) === null || _a === void 0 ? void 0 : _a.refreshGridDataHandler(); };
    return (_jsxs(_Fragment, { children: [_jsx(BreadcrumbComponent, { items: items }), _jsx(PageTitle, { data: data }), _jsxs("div", __assign({ className: "ps-4" }, { children: [_jsx(Button, { buttonText: _jsx(Transform, { mkey: TranslationConstant.ADD_MISSING_CLOCK_IN_OUT }), buttonClass: "btn-success", buttonClickHandler: addNewTimesheet }), _jsx("br", {}), _jsx("br", {})] })), _jsx(EmployeesSearchForm, { searchObject: searchObject }), _jsx("div", { children: _jsx(TimeClockGrid, { searchData: searchData, ref: timeClockGridRef, updateDayTimeDetails: handleDayTimeDetails }) }, JSON.stringify(searchData)), _jsx(DialogBox, { headingText: "Add new Timesheet", bodyText: _jsx(AddTimeForm, { handleClose: function () {
                        setModalState(false);
                        updatetimeSheetDayDetails(initialDayDetails);
                    }, weekEndingDate: (timeSheetDayDetails === null || timeSheetDayDetails === void 0 ? void 0 : timeSheetDayDetails.weekEndingDate) || weekEndingDate, EmployeeId: (timeSheetDayDetails === null || timeSheetDayDetails === void 0 ? void 0 : timeSheetDayDetails.employeeID) || (oidcUserProfile === null || oidcUserProfile === void 0 ? void 0 : oidcUserProfile.IndexNumber) || employeeIDForEdit, userProfile: oidcUserProfile, TimeSheetId: TimeSheetId, refreshGridDataHandler: handleForceGridRefresher, selectedClient: selectedClient === null || selectedClient === void 0 ? void 0 : selectedClient.clientDto, timeSheetDayDetails: timeSheetDayDetails, employeeInformation: employeeInformation }), hideDialogBtns: true, isDialogOpen: modalState })] }));
}
