var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import React from "react";
import ReactDOM from "react-dom/client";
import { ErrorBoundary } from "react-error-boundary";
import { AppConstant } from "common/constant/index";
import ErrorFallback from "components/shared/ErrorFallback";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import "assets/css/index.css";
import "bootstrap/dist/css/bootstrap.css";
var strictMode = process.env.NODE_ENV === AppConstant.PRODUCTION;
var root = ReactDOM.createRoot(document.getElementById(AppConstant.ROOT));
root.render((strictMode && (_jsx(React.StrictMode, { children: _jsx(ErrorBoundary, __assign({ FallbackComponent: ErrorFallback, onError: function (error, errorInfo) {
            //do nothing
        }, onReset: function () {
            // reset the state of your app so the error doesn't happen again
        } }, { children: _jsx(App, {}) })) }))) || (_jsx(ErrorBoundary, __assign({ FallbackComponent: ErrorFallback, onError: function (error, errorInfo) {
        //do nothing
    }, onReset: function () {
        // reset the state of your app so the error doesn't happen again
    } }, { children: _jsx(App, {}) }))));
// ReactDOM.render(<Home />, document.getElementById("root"));
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
if (module[AppConstant.HOT] && process.env.NODE_ENV === AppConstant.DEVELOPMENT) {
    module[AppConstant.HOT].accept(AppConstant.APP_PATH, function () {
        root.render((strictMode && (_jsx(React.StrictMode, { children: _jsx(ErrorBoundary, __assign({ FallbackComponent: ErrorFallback, onError: function (error, errorInfo) {
                    //do nothing
                }, onReset: function () {
                    // reset the state of your app so the error doesn't happen again
                } }, { children: _jsx(App, {}) })) }))) || (_jsx(ErrorBoundary, __assign({ FallbackComponent: ErrorFallback, onError: function (error, errorInfo) {
                //do nothings
            }, onReset: function () {
                // reset the state of your app so the error doesn't happen again
            } }, { children: _jsx(App, {}) }))));
    });
}
