var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { create } from "zustand";
import { getWorker1099Details, updateWorker1099Details, postWorker1099Documents, getWorker1099DocumentsType, getWorker1099PendingDocuments, downloadWorker1099Document, getStatesFor1099Worker, getCitiesFromState, getZIPcodeFromCity, signAndSaveForW9Form, getSavedModifiedW9FormDetail, getW9FormRevalidationDocuments, uploadWorkerSupportiveDocument, signAndSaveDDAForm, getRequiredNotes, getRequiredDocuments, getActiveStates, getAllActiveStates, } from "services/workers1099";
import { AppConstant } from "common/constant";
export var useWorker1099 = create(function (set, get) { return ({
    workerDetails: {},
    documentList: [],
    documentsType: [],
    pendingDocuments: [],
    cities: [],
    states: [],
    zipCodes: [],
    w9FormValidation: {},
    requiredNotes: [],
    requiredDocuments: [],
    isW9Filled: true,
    activeStates: [],
    allActiveStates: [],
    fetchWorkerDetails: function (workerId) { return __awaiter(void 0, void 0, void 0, function () {
        var res;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, getWorker1099Details(workerId)];
                case 1:
                    res = _a.sent();
                    set(function (state) { return (__assign(__assign({}, state), { workerDetails: res.data.workerDetails, documentList: res.data.documentList })); });
                    return [2 /*return*/];
            }
        });
    }); },
    updateWorkerDetails: function (postDetails) { return __awaiter(void 0, void 0, void 0, function () {
        var res;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, updateWorker1099Details(postDetails)];
                case 1:
                    res = _a.sent();
                    return [2 /*return*/, res];
            }
        });
    }); },
    postWorkerDocument: function (formData) { return __awaiter(void 0, void 0, void 0, function () {
        var res;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, postWorker1099Documents(formData)];
                case 1:
                    res = _a.sent();
                    return [2 /*return*/, res];
            }
        });
    }); },
    getPendingDocuments: function (workerId) { return __awaiter(void 0, void 0, void 0, function () {
        var res;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, getWorker1099PendingDocuments(workerId)];
                case 1:
                    res = _a.sent();
                    set(function (state) { return (__assign(__assign({}, state), { pendingDocuments: res.data })); });
                    return [2 /*return*/];
            }
        });
    }); },
    getDocumentType: function () { return __awaiter(void 0, void 0, void 0, function () {
        var res;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, getWorker1099DocumentsType()];
                case 1:
                    res = _a.sent();
                    set(function (state) { return (__assign(__assign({}, state), { documentsType: res.data })); });
                    return [2 /*return*/];
            }
        });
    }); },
    downloadUploadedDocument: function (fileUrl, fileName) { return __awaiter(void 0, void 0, void 0, function () {
        var res;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, downloadWorker1099Document(fileUrl, fileName)];
                case 1:
                    res = _a.sent();
                    return [2 /*return*/, res];
            }
        });
    }); },
    getStates: function () { return __awaiter(void 0, void 0, void 0, function () {
        var res;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, getStatesFor1099Worker()];
                case 1:
                    res = _a.sent();
                    set(function (state) { return (__assign(__assign({}, state), { states: res.data })); });
                    return [2 /*return*/];
            }
        });
    }); },
    getCitiesFromState: function (stateID) { return __awaiter(void 0, void 0, void 0, function () {
        var res;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, getCitiesFromState(stateID)];
                case 1:
                    res = _a.sent();
                    set(function (state) { return (__assign(__assign({}, state), { cities: res.data, zipCodes: [] })); });
                    return [2 /*return*/];
            }
        });
    }); },
    getZIPFromCity: function (cityID) { return __awaiter(void 0, void 0, void 0, function () {
        var res;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, getZIPcodeFromCity(cityID)];
                case 1:
                    res = _a.sent();
                    set(function (state) { return (__assign(__assign({}, state), { zipCodes: res.data })); });
                    return [2 /*return*/, res];
            }
        });
    }); },
    signAndSaveW9Form: function (formDetails) { return __awaiter(void 0, void 0, void 0, function () {
        var res;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, signAndSaveForW9Form(formDetails)];
                case 1:
                    res = _a.sent();
                    return [2 /*return*/, res];
            }
        });
    }); },
    updateWorkerDetailsStatic: function (workerDetails) {
        set(function (state) { return (__assign(__assign({}, state), { workerDetails: workerDetails })); });
    },
    getW9FormValidation: function (workerId) { return __awaiter(void 0, void 0, void 0, function () {
        var res;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, getW9FormRevalidationDocuments(workerId)];
                case 1:
                    res = _a.sent();
                    set(function (state) { var _a; return (__assign(__assign({}, state), { w9FormValidation: ((_a = res.data) === null || _a === void 0 ? void 0 : _a[0]) || {} })); });
                    return [2 /*return*/];
            }
        });
    }); },
    resetW9FormRevalidation: function () { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            set(function (state) { return (__assign(__assign({}, state), { w9FormValidation: {} })); });
            return [2 /*return*/];
        });
    }); },
    saveValidations: function (dataObj) { return __awaiter(void 0, void 0, void 0, function () {
        var res;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, getSavedModifiedW9FormDetail(dataObj)];
                case 1:
                    res = _a.sent();
                    return [2 /*return*/, res];
            }
        });
    }); },
    uploadWorkerSupportiveDocuments: function (dataObj) { return __awaiter(void 0, void 0, void 0, function () {
        var res;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, uploadWorkerSupportiveDocument(dataObj)];
                case 1:
                    res = _a.sent();
                    return [2 /*return*/, res];
            }
        });
    }); },
    saveAndSignDDAForm: function (dataObj) { return __awaiter(void 0, void 0, void 0, function () {
        var res;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, signAndSaveDDAForm(dataObj)];
                case 1:
                    res = _a.sent();
                    return [2 /*return*/, res];
            }
        });
    }); },
    setIsW9Filled: function (value) {
        set(function (state) { return (__assign(__assign({}, state), { isW9Filled: value })); });
    },
    setRequiredNotes: function (workerId) { return __awaiter(void 0, void 0, void 0, function () {
        var res;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, getRequiredNotes(workerId)];
                case 1:
                    res = _a.sent();
                    set(function (state) { return (__assign(__assign({}, state), { requiredNotes: res === null || res === void 0 ? void 0 : res.data })); });
                    return [2 /*return*/];
            }
        });
    }); },
    getRequiredDocuments: function (workerId) { return __awaiter(void 0, void 0, void 0, function () {
        var res;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, getRequiredDocuments(workerId)];
                case 1:
                    res = _a.sent();
                    try {
                        if (AppConstant.HTTP_SUCCESS_CODES.includes(res.status) && res.data) {
                            set(function (state) { return (__assign(__assign({}, state), { requiredDocuments: res.data })); });
                        }
                    }
                    catch (e) {
                        console.log(e);
                    }
                    return [2 /*return*/];
            }
        });
    }); },
    getActiveStates: function () { return __awaiter(void 0, void 0, void 0, function () {
        var res;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, getActiveStates()];
                case 1:
                    res = _a.sent();
                    set(function (state) { return (__assign(__assign({}, state), { activeStates: res.data, zipCodes: [], cities: [] })); });
                    return [2 /*return*/];
            }
        });
    }); },
    getAllActiveStates: function () { return __awaiter(void 0, void 0, void 0, function () {
        var res;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, getAllActiveStates()];
                case 1:
                    res = _a.sent();
                    set(function (state) { return (__assign(__assign({}, state), { allActiveStates: res.data })); });
                    return [2 /*return*/];
            }
        });
    }); },
    resetCitiesAndZip: function () { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            set(function (state) { return (__assign(__assign({}, state), { zipCodes: [], cities: [] })); });
            return [2 /*return*/];
        });
    }); },
    resetZipCodes: function () { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            set(function (state) { return (__assign(__assign({}, state), { zipCodes: [] })); });
            return [2 /*return*/];
        });
    }); },
}); });
