var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx } from "react/jsx-runtime";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useCurrentViewContext } from "store/common/currentViewContext";
import { signinRedirectCallback, signoutRedirect, signoutRedirectCallback, } from "services/auth/auth";
import { AppConstant } from "common/constant";
import { WEBUSERROLEID } from "common/constant/Enums";
import Spinner from "components/shared/Spinner";
import { getCookie } from "./utils";
export var SigninOidc = function () {
    var navigate = useNavigate();
    var _a = useCurrentViewContext(), setApplicationName = _a.setApplicationName, fetchLoggedInUserDetails = _a.fetchLoggedInUserDetails, setShowProgress = _a.setShowProgress, fetchNewHireJobIDByEmpId = _a.fetchNewHireJobIDByEmpId, postSendEmailTemplate = _a.postSendEmailTemplate, resetShowProgress = _a.resetShowProgress;
    var createRedirectionURL = function (response) {
        var webUserRoleId = response.data.WebUserRoleId;
        var isAdminLoginAsEmployee = getCookie(AppConstant.ADMIN_AS_EMPLOYEE_COOKIE_NAME);
        if (webUserRoleId === WEBUSERROLEID.NEW_HIRE_EMPLOYEE) {
            return AppConstant.ONBOARDING_APPLICATION_EMPLOYEE_PATH;
        }
        else if (webUserRoleId === WEBUSERROLEID.EMPLOYEE_AUTHORISED_I9_VERIFIER) {
            return "/";
        }
        else if (webUserRoleId === WEBUSERROLEID.NEW_HIRE_WORKER) {
            return "/ICAdvisor";
        }
        else if (isAdminLoginAsEmployee && webUserRoleId === WEBUSERROLEID.PAYREEL_ADMIN) {
            return "/loginasemployee";
        }
        else {
            return AppConstant.CORP_BASE_PATHNAME;
        }
    };
    var signinRedirectionCallback = function () { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            signinRedirectCallback()
                .then(function () { return __awaiter(void 0, void 0, void 0, function () {
                var oidcStorage, selectedClient, preferredUserName, response, element, corpresponse, emailObject, element, isLegacyRedirect, RedirectionURLCookie, redirectionUrl, applicationName, e_1;
                var _a, _b, _c, _d;
                return __generator(this, function (_e) {
                    switch (_e.label) {
                        case 0:
                            setShowProgress(true);
                            oidcStorage = JSON.parse(localStorage.getItem("oidc.user:".concat(process.env.AUTHORITY_URL, ":").concat(process.env.CLIENT_ID)));
                            selectedClient = JSON.parse(localStorage.getItem("selectedClient"));
                            preferredUserName = localStorage.getItem("employeeUsername")
                                ? localStorage.getItem("employeeUsername")
                                : oidcStorage.profile.preferred_username;
                            _e.label = 1;
                        case 1:
                            _e.trys.push([1, 8, 9, 10]);
                            return [4 /*yield*/, fetchLoggedInUserDetails(preferredUserName, oidcStorage.profile.ApplicationId)];
                        case 2:
                            response = _e.sent();
                            if (((_a = response === null || response === void 0 ? void 0 : response.data) === null || _a === void 0 ? void 0 : _a.WebUserRoleId) === WEBUSERROLEID.NEW_HIRE_WORKER) {
                                element = document.querySelector(".application-loader");
                                if (element)
                                    element.style.display = "block";
                                window.location.href = "/ICAdvisor";
                                return [2 /*return*/, false];
                            }
                            if (!(((_b = response === null || response === void 0 ? void 0 : response.data) === null || _b === void 0 ? void 0 : _b.UserStatusId) === 1 ||
                                ((_c = response === null || response === void 0 ? void 0 : response.data) === null || _c === void 0 ? void 0 : _c.WebUserRoleId) === WEBUSERROLEID.Employee)) return [3 /*break*/, 6];
                            return [4 /*yield*/, fetchLoggedInUserDetails(preferredUserName, 1, (selectedClient === null || selectedClient === void 0 ? void 0 : selectedClient.clientID) || 0)];
                        case 3:
                            corpresponse = _e.sent();
                            if (!(response.data.WebUserRoleId === WEBUSERROLEID.NEW_HIRE_EMPLOYEE ||
                                response.data.WebUserRoleId === WEBUSERROLEID.Employee)) return [3 /*break*/, 5];
                            return [4 /*yield*/, fetchNewHireJobIDByEmpId(response.data.IndexNumber)];
                        case 4:
                            _e.sent();
                            _e.label = 5;
                        case 5:
                            if (((_d = response.data.Parameters) === null || _d === void 0 ? void 0 : _d.IsUserFirstLogin) === "True") {
                                emailObject = {
                                    UserName: response.data.UserName,
                                    FirstName: response.data.FirstName,
                                    LastName: response.data.LastName,
                                    EmailAddress: response.data.EmailAddress,
                                    ClientName: "",
                                    PrimaryWorkStateId: 0,
                                    NotificationCode: "UserAccountCreated",
                                    RedirectURI: "",
                                    Parameters: {
                                        WebUserRoleID: response.data.WebUserRoleId.toString(),
                                        SourceApplication: "Onboarding",
                                    },
                                };
                                resetShowProgress(true);
                                postSendEmailTemplate(emailObject);
                            }
                            element = document.querySelector(".application-loader");
                            if (element)
                                element.style.display = "none";
                            isLegacyRedirect = getCookie("LegacyRedirect");
                            RedirectionURLCookie = getCookie("RedirectURL");
                            if (isLegacyRedirect) {
                                if (RedirectionURLCookie.indexOf(AppConstant.CORP_BASE_PATHNAME) > -1) {
                                    setApplicationName("pro");
                                    navigate(AppConstant.CORP_BASE_PATHNAME);
                                }
                                else {
                                    setApplicationName("onboarding");
                                    navigate("/");
                                }
                            }
                            else {
                                redirectionUrl = sessionStorage.getItem("applicationType") ===
                                    AppConstant.CORP_TYPE_APPLICATION
                                    ? AppConstant.CORP_BASE_PATHNAME
                                    : createRedirectionURL(corpresponse);
                                applicationName = redirectionUrl === AppConstant.CORP_BASE_PATHNAME
                                    ? AppConstant.CORP_TYPE_APPLICATION
                                    : response.data.WebUserRoleId === WEBUSERROLEID.VENDOR
                                        ? AppConstant.CORP_TYPE_APPLICATION
                                        : "onboarding";
                                setApplicationName(applicationName);
                                navigate(redirectionUrl);
                            }
                            return [3 /*break*/, 7];
                        case 6:
                            navigate(AppConstant.INVALID_USER_ROUTE);
                            _e.label = 7;
                        case 7: return [3 /*break*/, 10];
                        case 8:
                            e_1 = _e.sent();
                            signoutRedirect();
                            signoutRedirectCallback();
                            return [3 /*break*/, 10];
                        case 9:
                            setShowProgress(false);
                            return [7 /*endfinally*/];
                        case 10: return [2 /*return*/];
                    }
                });
            }); })
                .catch(function (error) {
                var oidcStorage = JSON.parse(localStorage.getItem("oidc.user:".concat(process.env.AUTHORITY_URL, ":").concat(process.env.CLIENT_ID)));
                if (oidcStorage === null) {
                    navigate("/signout-oidc");
                }
            });
            return [2 /*return*/];
        });
    }); };
    useEffect(function () {
        signinRedirectionCallback();
    }, [navigate]);
    return _jsx(Spinner, {});
};
