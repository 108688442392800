var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useState } from "react";
import { Field, Form, FormElement } from "@progress/kendo-react-form";
import { COMMON_RADIO_TYPE_OPTIONS, COMMON_STATUS_TYPE_RADIO, FORM_HEADING, MISC_ITEM_CODE_TYPE_RADIO, } from "../StaticData";
import { AppConstant, ComponentConstant, DialogBox, FormButtons, FormCheckbox, FormHeading, FormInput, FormRadioGroup, Transform, TranslationConstant, requiredValidator, useSearchEmpViewContext, } from "../index";
export var ExpenseCodesForm = function (_a) {
    var SessionData = _a.SessionData, IntialData = _a.IntialData, CancelEditFunction = _a.CancelEditFunction, UpdateGridRefresherCall = _a.UpdateGridRefresherCall, formRefresherValue = _a.formRefresherValue, updateFormRefresher = _a.updateFormRefresher, selectedClientData = _a.selectedClientData;
    var saveAddorEditExpenseCodeData = useSearchEmpViewContext().saveAddorEditExpenseCodeData;
    var _b = useState(false), modalState = _b[0], changeModalState = _b[1];
    var _c = useState({
        headingText: "",
        bodyText: "",
        isErrorDialog: false,
    }), modalContent = _c[0], updateModalContent = _c[1];
    var handleSubmit = function (dataItem) { return __awaiter(void 0, void 0, void 0, function () {
        var dataObj, response, err_1;
        var _a;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    if (!!dataItem.isValid) return [3 /*break*/, 1];
                    return [2 /*return*/];
                case 1:
                    dataObj = __assign(__assign({ expenseCodeID: null }, dataItem.values), { clientID: SessionData.ClientId, isActive: ((_a = dataItem.values) === null || _a === void 0 ? void 0 : _a.isActive) === "Active" ? true : false });
                    _b.label = 2;
                case 2:
                    _b.trys.push([2, 4, , 5]);
                    return [4 /*yield*/, saveAddorEditExpenseCodeData(dataObj)];
                case 3:
                    response = _b.sent();
                    if (AppConstant.HTTP_SUCCESS_CODES.includes(response.status)) {
                        if (response.data.status) {
                            onSaveSuccess();
                        }
                        else {
                            onSaveFailure(response.data.message);
                        }
                    }
                    else {
                        onSaveFailure(_jsx(Transform, { mkey: TranslationConstant.SAVE_FAILED }));
                    }
                    return [3 /*break*/, 5];
                case 4:
                    err_1 = _b.sent();
                    console.log(err_1);
                    return [3 /*break*/, 5];
                case 5: return [2 /*return*/];
            }
        });
    }); };
    var onSaveSuccess = function () {
        updateModalContent({
            headingText: _jsx(Transform, { mkey: TranslationConstant.SUCCESS }),
            bodyText: (_jsxs("div", __assign({ className: "block subheading" }, { children: [_jsx("i", { className: "fa fa-check fa-lg" }), _jsx("span", { children: _jsx(Transform, { mkey: TranslationConstant.FEATURE_MAPPING_SUCCESS_HEADING }) })] }))),
            cancelText: _jsx(Transform, { mkey: TranslationConstant.CLOSE_BUTTON }),
            isErrorDialog: false,
            onModalChangeState: SuccessHideModal,
        });
        changeModalState(true);
    };
    var SuccessHideModal = function () {
        changeModalState(false);
        CancelEditFunction();
        updateFormRefresher(formRefresherValue + 1);
        UpdateGridRefresherCall(true);
    };
    var onSaveFailure = function (errmsg) {
        updateModalContent({
            headingText: _jsx(Transform, { mkey: TranslationConstant.FAILED }),
            bodyText: (_jsxs("div", __assign({ className: "block subheading" }, { children: [_jsx("i", { className: "fa fa-times-circle fa-lg" }), _jsx("span", { children: errmsg })] }))),
            cancelText: _jsx(Transform, { mkey: TranslationConstant.CLOSE_BUTTON }),
            isErrorDialog: true,
            onModalChangeState: hideModal,
        });
        changeModalState(true);
    };
    var hideModal = function () {
        changeModalState(false);
    };
    return (_jsxs(_Fragment, { children: [_jsx(FormHeading, { data: FORM_HEADING }), _jsx(Form, { onSubmitClick: handleSubmit, initialValues: IntialData, render: function (formRenderProps) { return (_jsx(FormElement, __assign({ className: "general-form add-new-job-form" }, { children: _jsxs("fieldset", __assign({ className: "k-form-fieldset row" }, { children: [_jsx("div", __assign({ className: "col-xs-12 col-md-4 mb-3" }, { children: _jsx(Field, { id: "code", name: "code", component: FormInput, isRequired: true, maxLength: 20, validator: requiredValidator, label: _jsx(Transform, { mkey: TranslationConstant.LABEL_CODE }), placeholder: ComponentConstant.PLACEHOLDER_CODE, disabled: IntialData ? true : false, isFocusableElement: true }) })), _jsx("div", __assign({ className: "col-xs-12 col-md-4 mb-3" }, { children: _jsx(Field, { id: "description", name: "description", component: FormInput, isRequired: true, maxLength: 50, validator: requiredValidator, label: _jsx(Transform, { mkey: TranslationConstant.DESCRIPTION }), placeholder: ComponentConstant.PLACEHOLDER_DESCRIPTION, isFocusableElement: IntialData ? true : false }) })), _jsx("div", __assign({ className: "col-xs-12 col-md-4 mb-3" }, { children: _jsx(Field, { id: "glAccountNumber", name: "glAccountNumber", component: FormInput, isRequired: (selectedClientData === null || selectedClientData === void 0 ? void 0 : selectedClientData.glCodeForPositionAllowed) === true
                                        ? true
                                        : false, maxLength: 50, validator: (selectedClientData === null || selectedClientData === void 0 ? void 0 : selectedClientData.glCodeForPositionAllowed) === true &&
                                        requiredValidator, label: _jsx(Transform, { mkey: TranslationConstant.LABEL_GL_ACCOUNT_NUMBER }), placeholder: ComponentConstant.LABEL_GL_ACCOUNT_NUMBER }) })), _jsx("div", __assign({ className: "col-xs-12 col-md-4 mb-3" }, { children: _jsx(Field, { id: "isActive", name: "isActive", component: FormRadioGroup, isRequired: true, validator: requiredValidator, label: _jsx(Transform, { mkey: TranslationConstant.LABEL_STATUS }), data: COMMON_STATUS_TYPE_RADIO, layout: "horizontal" }) })), _jsx("div", __assign({ className: "col-xs-12 col-md-4 mb-3" }, { children: _jsx(Field, { id: "miscItemCode", name: "miscItemCode", component: FormRadioGroup, isRequired: true, validator: requiredValidator, label: _jsx(Transform, { mkey: TranslationConstant.LABEL_MISC_ITEM_CODE }), data: MISC_ITEM_CODE_TYPE_RADIO, layout: "horizontal" }) })), _jsx("div", __assign({ className: "col-xs-12 col-md-4 mb-3" }, { children: _jsx(Field, { id: "receiptsNeeded", name: "receiptsNeeded", component: FormRadioGroup, isRequired: true, validator: requiredValidator, label: _jsx(Transform, { mkey: TranslationConstant.LABEL_RECEIPTS_NEEDED }), data: COMMON_RADIO_TYPE_OPTIONS, layout: "horizontal" }) })), _jsx("div", __assign({ className: "col-xs-12 col-md-6 mb-3" }, { children: _jsx(Field, { id: "taxable", name: "taxable", label: _jsx(Transform, { mkey: TranslationConstant.LABEL_TAXABLE }), component: FormCheckbox }) })), _jsx("div", __assign({ className: "col-xs-12 col-md-6 mb-3" }, { children: _jsx(FormButtons, { searchType: false, resetHandler: function () {
                                        formRenderProps.onFormReset();
                                    }, showCancelButton: IntialData ? true : false, cancelHandler: function () {
                                        formRenderProps.onFormReset();
                                        CancelEditFunction();
                                    } }) }))] })) }))); } }, IntialData), _jsx(DialogBox, __assign({ isDialogOpen: modalState }, modalContent))] }));
};
