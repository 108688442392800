var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Button } from "@progress/kendo-react-buttons";
import { Transform } from "helper/Transform";
import { TranslationConstant } from "common/constant/index";
var ErrorFallback = function (props) {
    return (_jsxs("div", __assign({ className: "d-flex flex-column w-100 h-screen mt-5 justify-content-middle align-items-center text-center", role: "alert" }, { children: [_jsx("h5", __assign({ className: "text-burgendy fw-bold" }, { children: _jsx(Transform, { mkey: TranslationConstant.SOMETHING_WENT_WRONG }) })), _jsx("div", __assign({ className: "text-danger lead" }, { children: _jsx("pre", { children: props.error.message }) })), _jsx(Button, __assign({ className: "button bg-success text-white w-auto", onClick: props.resetErrorBoundary }, { children: _jsx(Transform, { mkey: TranslationConstant.TRY_AGAIN }) }))] })));
};
export default ErrorFallback;
