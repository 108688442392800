var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx } from "react/jsx-runtime";
import { TabsComponent, getQueryParams } from "components/shared";
import ARTSDetailsGrid from "../../Common/ARTSDetailsGrid";
import ARVPDetailsGrid from "../../Common/ARVPDetailsGrid";
import PayrollManualPaymentsGrid from "../../Common/PayrollManualPaymentsGrid";
import CreditCardsGrid from "../../Common/CreditCardsGrid";
import { useBatchesImportContext } from "store/batchimport/batchimport";
import { useEffect, useState } from "react";
import { EntityTypesEnum, VIEW_AR_RECORD_TABS } from "../../data";
var exportType = [
    "nonallocatedtimesheetdetails",
    "allocatedtimesheetdetails",
    "nonallocatederdetails",
    "allocatederdetails",
    "nonallocatedvpdetails",
    "allocatedvpdetails",
    "clienttimesheetdetails",
    "manualpayments",
    "CreditCardDetails",
];
export default function ARRecordsTabView() {
    var _this = this;
    var queryParams = getQueryParams(window.location.search);
    var activeTab = queryParams.get("activetab")
        ? parseInt(queryParams.get("activetab"))
        : 0;
    var _a = JSON.parse(localStorage.getItem("selectedClient")).clientDto, clientID = _a.clientID, isCreditCardEnabled = _a.isCreditCardEnabled, vzSetup = _a.vzSetup;
    var _b = useState(activeTab), activeTabValue = _b[0], setActiveTabValue = _b[1];
    var _c = useBatchesImportContext(), manageExportsTabsCount = _c.manageExportsTabsCount, fetchManageExportsGridData = _c.fetchManageExportsGridData, nonallocatedtimesheetdetails = _c.nonallocatedtimesheetdetails, allocatedtimesheetdetails = _c.allocatedtimesheetdetails, nonallocatederdetails = _c.nonallocatederdetails, allocatederdetails = _c.allocatederdetails, nonallocatedvpdetails = _c.nonallocatedvpdetails, allocatedvpdetails = _c.allocatedvpdetails, clienttimesheetdetails = _c.clienttimesheetdetails, manualpayments = _c.manualpayments, CreditCardDetails = _c.CreditCardDetails;
    useEffect(function () {
        initData();
    }, [activeTabValue]);
    var initData = function () { return __awaiter(_this, void 0, void 0, function () {
        var queryParams, activeTab;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    queryParams = getQueryParams(window.location.search);
                    activeTab = queryParams.get("activetab") || 0;
                    return [4 /*yield*/, fetchManageExportsGridData(clientID, exportType[activeTab], "mgARExport")];
                case 1:
                    _a.sent();
                    return [2 /*return*/];
            }
        });
    }); };
    var tabMenu = [
        {
            title: "".concat(VIEW_AR_RECORD_TABS[0].title, " - ").concat((nonallocatedtimesheetdetails === null || nonallocatedtimesheetdetails === void 0 ? void 0 : nonallocatedtimesheetdetails.length) ||
                manageExportsTabsCount.totalTimesheetDetails ||
                0),
            children: (_jsx(ARTSDetailsGrid, { hideTimeCardBackup: vzSetup, backRoute: "/managearrecords?activetab=0", handleRefresh: initData, dataItemKey: VIEW_AR_RECORD_TABS[0].actionId, tabCode: EntityTypesEnum.TimeSheet, multiSelect: true, gridData: nonallocatedtimesheetdetails, colums: [], handleBudgetException: true })),
        },
        {
            title: "".concat(VIEW_AR_RECORD_TABS[1].title, "  - ").concat((allocatedtimesheetdetails === null || allocatedtimesheetdetails === void 0 ? void 0 : allocatedtimesheetdetails.length) ||
                manageExportsTabsCount.totalTimesheetDetailsAllocated ||
                0),
            children: (_jsx(ARTSDetailsGrid, { hideTimeCardBackup: vzSetup, backRoute: "/managearrecords?activetab=1", handleRefresh: initData, dataItemKey: "rowAllocationID", tabCode: EntityTypesEnum.TimeSheetAllocated, multiSelect: true, gridData: allocatedtimesheetdetails, colums: [], handleBudgetException: true })),
        },
        {
            title: "".concat(VIEW_AR_RECORD_TABS[2].title, "  - ").concat((nonallocatederdetails === null || nonallocatederdetails === void 0 ? void 0 : nonallocatederdetails.length) ||
                manageExportsTabsCount.totalExpenseReportDetails ||
                0),
            children: (_jsx(ARTSDetailsGrid, { backRoute: "/managearrecords?activetab=2", handleRefresh: initData, dataItemKey: VIEW_AR_RECORD_TABS[2].actionId, tabCode: EntityTypesEnum.ExpenseReport, multiSelect: true, colums: [], gridData: nonallocatederdetails, handleBudgetException: true })),
        },
        {
            title: "".concat(VIEW_AR_RECORD_TABS[3].title, "  - ").concat((allocatederdetails === null || allocatederdetails === void 0 ? void 0 : allocatederdetails.length) ||
                manageExportsTabsCount.totalExpenseReportDetailsAllocated ||
                0),
            children: (_jsx(ARTSDetailsGrid, { backRoute: "/managearrecords?activetab=3", handleRefresh: initData, dataItemKey: VIEW_AR_RECORD_TABS[3].actionId, tabCode: EntityTypesEnum.ExpenseReportAllocated, multiSelect: true, colums: [], gridData: allocatederdetails, handleBudgetException: true })),
        },
        {
            title: "".concat(VIEW_AR_RECORD_TABS[4].title, "  - ").concat((nonallocatedvpdetails === null || nonallocatedvpdetails === void 0 ? void 0 : nonallocatedvpdetails.length) ||
                manageExportsTabsCount.totalVendorPaymentDetails ||
                0),
            children: (_jsx(ARVPDetailsGrid, { backRoute: "/managearrecords?activetab=4", handleRefresh: initData, dataItemKey: VIEW_AR_RECORD_TABS[4].actionId, tabCode: EntityTypesEnum.VendorPayment, multiSelect: true, colums: [], gridData: nonallocatedvpdetails, handleBudgetException: true })),
        },
        {
            title: "".concat(VIEW_AR_RECORD_TABS[5].title, "  - ").concat((allocatedvpdetails === null || allocatedvpdetails === void 0 ? void 0 : allocatedvpdetails.length) ||
                manageExportsTabsCount.totalVendorPaymentDetailsAllocated ||
                0),
            children: (_jsx(ARVPDetailsGrid, { backRoute: "/managearrecords?activetab=5", handleRefresh: initData, dataItemKey: VIEW_AR_RECORD_TABS[5].actionId, tabCode: EntityTypesEnum.VendorPaymentAllocated, multiSelect: true, colums: [], gridData: allocatedvpdetails, handleBudgetException: true })),
        },
        {
            title: "".concat(VIEW_AR_RECORD_TABS[6].title, "  - ").concat((clienttimesheetdetails === null || clienttimesheetdetails === void 0 ? void 0 : clienttimesheetdetails.length) ||
                manageExportsTabsCount.totalClientTimesheetDetails ||
                0),
            children: (_jsx(ARTSDetailsGrid, { backRoute: "/managearrecords?activetab=6", handleRefresh: initData, dataItemKey: VIEW_AR_RECORD_TABS[6].actionId, tabCode: EntityTypesEnum.ClientTimeSheet, multiSelect: true, colums: [], gridData: clienttimesheetdetails })),
        },
        {
            title: "".concat(VIEW_AR_RECORD_TABS[7].title, "  - ").concat((manualpayments === null || manualpayments === void 0 ? void 0 : manualpayments.length) || manageExportsTabsCount.totalManualPayments || 0),
            children: (_jsx(PayrollManualPaymentsGrid, { handleRefresh: initData, colums: [], gridData: manualpayments, multiSelect: true, tabCode: EntityTypesEnum.ManualPayment, backRoute: "/managearrecords?activetab=7" })),
        },
    ];
    if (isCreditCardEnabled) {
        tabMenu[8] = {
            title: "".concat(VIEW_AR_RECORD_TABS[8].title, "  - ").concat(CreditCardDetails.length || manageExportsTabsCount.totalCreditCards || 0),
            children: (_jsx(CreditCardsGrid, { handleRefresh: initData, gridData: CreditCardDetails, colums: [], tabCode: EntityTypesEnum.CreditCard, multiSelect: true })),
        };
    }
    var onTabChangeHandler = function (tab) {
        setActiveTabValue(tab);
        window.history.replaceState("", "", "".concat(window.location.pathname, "?activetab=").concat(tab));
    };
    return (_jsx("div", __assign({ className: "admin-panel-wrapper admin-agent-task employee-task-grid mt-1" }, { children: _jsx(TabsComponent, { tabsMenu: tabMenu, selectedIndex: activeTabValue, onSelectHandler: onTabChangeHandler }) })));
}
