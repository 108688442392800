var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { PageTitle, Transform, BreadcrumbComponent, TranslationConstant, HOME_BREADCRUMB, TopButtons, getQueryParams, DialogBox, AppConstant, } from "components/shared";
import APRecordsTabView from "./APRecordsTabView";
import { AP_EXPORTS_MANAGE_BREADCRUMB, RETURN_TO_EXPORTS_BUTTON, EXPORTS_BREADCRUMB, prepareSelctedRows, BATCH_VIEW_TABS_CODES, } from "../../data";
import { useNavigate } from "react-router-dom";
import ManageExportsForm from "../../Common/ManageExportsForm";
import { useBatchesImportContext } from "store/batchimport/batchimport";
import { useEffect, useState } from "react";
import useUpdateDialogContent from "hooks/useUpdateDialogContent";
import { useReleasePayrollContextView } from "store/releasePayroll/releasePayroll";
var items = [HOME_BREADCRUMB, EXPORTS_BREADCRUMB, AP_EXPORTS_MANAGE_BREADCRUMB];
var data = {
    title: _jsx(Transform, { mkey: TranslationConstant.MANAGE_AP_RECORDS_TITLE }),
};
var APRecords = function () {
    var navigate = useNavigate();
    var _a = useUpdateDialogContent(), showDialog = _a.showDialog, setShowDialog = _a.setShowDialog, dialogContent = _a.dialogContent, setDialogContent = _a.setDialogContent;
    var _b = useBatchesImportContext(), fetchManageExports = _b.fetchManageExports, fetchManageExportsTabsCount = _b.fetchManageExportsTabsCount, selectedExportsData = _b.selectedExportsData, saveSelectedRecords = _b.saveSelectedRecords, resetSelectedExportsData = _b.resetSelectedExportsData, resetBatchStore = _b.resetBatchStore, manageexpensereports = _b.manageexpensereports, managemanualpayments = _b.managemanualpayments, managevendorpayments = _b.managevendorpayments, manageExportsTabsCount = _b.manageExportsTabsCount;
    var ClientId = JSON.parse(localStorage.getItem("selectedClient")).clientDto.clientID;
    var fetchClientGlobalDetails = useReleasePayrollContextView().fetchClientGlobalDetails;
    var queryParams = getQueryParams(window.location.search);
    var activeTab = queryParams.get("activetab") || 0;
    var _c = useState(false), forceReload = _c[0], setForceReload = _c[1];
    var _d = useState(0), recordsCount = _d[0], setRecordsCount = _d[1];
    var _e = useState(false), globalDataCall = _e[0], setGlobalDataCall = _e[1];
    useEffect(function () {
        (function () { return __awaiter(void 0, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, fetchClientGlobalDetails(ClientId)];
                    case 1:
                        _a.sent();
                        return [4 /*yield*/, fetchTabCount()];
                    case 2:
                        _a.sent();
                        return [4 /*yield*/, fetchManageExports(ClientId, "mgAPExport")];
                    case 3:
                        _a.sent();
                        setGlobalDataCall(true);
                        return [2 /*return*/];
                }
            });
        }); })();
        return function () {
            resetBatchStore();
        };
    }, []);
    var fetchTabCount = function () { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, fetchManageExportsTabsCount(ClientId, BATCH_VIEW_TABS_CODES[activeTab], "mgAPExport")];
                case 1:
                    _a.sent();
                    return [2 /*return*/];
            }
        });
    }); };
    useEffect(function () {
        var total = manageExportsTabsCount.totalExpenseReports +
            manageExportsTabsCount.totalManualPayments +
            manageExportsTabsCount.totalVendorPayments +
            __spreadArray(__spreadArray(__spreadArray([], manageexpensereports, true), managemanualpayments, true), managevendorpayments, true).length;
        setRecordsCount(parseInt(total));
    }, [manageExportsTabsCount, manageexpensereports, managemanualpayments, managevendorpayments]);
    var handleButtonClick = function (type) {
        switch (type.id) {
            case "returnToExports":
                navigate("/batchexports");
                break;
            default:
                break;
        }
    };
    var handleAddSelectedExport = function (e) { return __awaiter(void 0, void 0, void 0, function () {
        var SelectedRows, dataObj, response, resetFields;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, prepareSelctedRows(selectedExportsData)];
                case 1:
                    SelectedRows = _a.sent();
                    dataObj = {
                        exportId: e.ExportId,
                        selectedRows: SelectedRows,
                    };
                    if (!(SelectedRows.length === 0)) return [3 /*break*/, 2];
                    setDialogContent({
                        headingText: TranslationConstant.FAILED,
                        staticMsg: "Select atleast one record!",
                        isErrorDialog: true,
                        fixedWidth: true,
                    });
                    setShowDialog(true);
                    return [3 /*break*/, 4];
                case 2: return [4 /*yield*/, saveSelectedRecords(ClientId, "mgAPExport", dataObj)];
                case 3:
                    response = _a.sent();
                    if (AppConstant.HTTP_SUCCESS_CODES.includes(response.status) && response.data) {
                        resetFields = {
                            managemanualpayments: [],
                            managevendorpayments: [],
                            manageexpensereports: [],
                        };
                        resetSelectedExportsData(resetFields);
                        fetchTabCount();
                        setDialogContent({
                            headingText: TranslationConstant.SUCCESS,
                            displayMsg: TranslationConstant.SELECTED_RECORD_ADDED_SUCCESSFULLY,
                        });
                        setShowDialog(true);
                    }
                    else {
                        setDialogContent({
                            headingText: TranslationConstant.FAILED,
                            displayMsg: TranslationConstant.SOMETHING_WENT_WRONG,
                            isErrorDialog: true,
                        });
                        setShowDialog(true);
                    }
                    _a.label = 4;
                case 4: return [2 /*return*/];
            }
        });
    }); };
    return (_jsx("div", { children: _jsxs(_Fragment, { children: [_jsx(BreadcrumbComponent, { items: items }), _jsx(PageTitle, { data: data }), _jsx(TopButtons, { styleClass: "mb-2", buttonData: [__assign(__assign({}, RETURN_TO_EXPORTS_BUTTON), { autoFocus: true })], onClick: handleButtonClick }), globalDataCall && (_jsxs("div", { children: [_jsx(ManageExportsForm, { handleAddSelectedExport: handleAddSelectedExport, recordsCount: recordsCount }), _jsx(APRecordsTabView, {})] }, JSON.stringify(forceReload))), showDialog && (_jsx(DialogBox, __assign({ isDialogOpen: showDialog, onModalChangeState: function () {
                        setForceReload(!forceReload);
                        setShowDialog(false);
                    }, cancelText: _jsx(Transform, { mkey: TranslationConstant.CLOSE_BUTTON }) }, dialogContent)))] }) }));
};
export default APRecords;
