var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import { useEffect } from "react";
import { DocumentViewer } from "./DocumentViewer";
import { decodedURL } from "./utils";
var DocumentViewerWithoutInfo = function (props) {
    var queryStringObject = decodedURL();
    var _a = queryStringObject || props || {}, fileUrl = _a.fileUrl, isAddOnboardingFilePrefix = _a.isAddOnboardingFilePrefix, isAddAWSDirPathInBucket = _a.isAddAWSDirPathInBucket, isLoggedOff = _a.isLoggedOff;
    useEffect(function () {
        var element = document.querySelector(".application-loader");
        if (element)
            element.style.display = "none";
    }, []);
    return (_jsx("div", __assign({ className: "view-without-info ".concat(isLoggedOff ? "not-logged-in" : "") }, { children: _jsx(DocumentViewer, { fileUrl: fileUrl, isAddOnboardingFilePrefix: isAddOnboardingFilePrefix, isAddAWSDirPathInBucket: isAddAWSDirPathInBucket }) })));
};
export default DocumentViewerWithoutInfo;
