var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsxs as _jsxs, Fragment as _Fragment, jsx as _jsx } from "react/jsx-runtime";
import { useEffect, useState } from "react";
import { useI9VerificationViewContext } from "store/i9reverification/i9reverification";
import { getUserDetailsFromLocalStorage } from "components/shared/utils";
import { WEBUSERROLEID } from "common/constant/Enums";
var PageTitle = function (props) {
    var _a, _b, _c, _d, _e, _f, _g;
    var _h = props.containerStyle, containerStyle = _h === void 0 ? null : _h;
    var _j = useI9VerificationViewContext(), i9clientDropDownList = _j.i9clientDropDownList, getClientData = _j.getClientData, clientData = _j.clientData;
    var _k = useState(null), selectedClient = _k[0], setSelectedClient = _k[1];
    var userProfilePro = getUserDetailsFromLocalStorage("pro");
    var isCorpEmployee = (userProfilePro === null || userProfilePro === void 0 ? void 0 : userProfilePro.WebUserRoleId) === WEBUSERROLEID.Employee &&
        window.location.href.toLowerCase().includes("pro");
    var is1099Worker = (userProfilePro === null || userProfilePro === void 0 ? void 0 : userProfilePro.WebUserRoleId) === WEBUSERROLEID.WORKER_1099 &&
        window.location.href.toLowerCase().includes("pro");
    var isAdmin = (userProfilePro === null || userProfilePro === void 0 ? void 0 : userProfilePro.WebUserRoleId) === WEBUSERROLEID.PAYREEL_ADMIN &&
        window.location.href.toLowerCase().includes("pro");
    var isMasterClientLoggedIn = localStorage.getItem("masterClientLoggedIn") || null;
    var renderClientName = function () {
        return selectedClient ? (_jsx(_Fragment, { children: _jsxs("div", __assign({ className: "client-name-title d-block" }, { children: ["\u00A0 - ", selectedClient.clientName] })) })) : props.showSubTitleBySide ? (_jsxs(_Fragment, { children: [_jsxs("div", __assign({ className: "client-name-title d-none d-md-block" }, { children: ["\u00A0 - ", props.showSubTitleBySide] })), _jsxs("div", __assign({ className: "client-name-title d-block d-md-none" }, { children: [" ", props.showSubTitleBySide] }))] })) : ("");
    };
    useEffect(function () {
        var _a;
        if (isCorpEmployee && !excludeRoutes()) {
            var userProfile_1 = getUserDetailsFromLocalStorage("pro");
            if (i9clientDropDownList && i9clientDropDownList.length >= 0) {
                var selectedClientData = i9clientDropDownList.find(function (item) { return item.clientID === userProfile_1.ClientId; });
                setSelectedClient(selectedClientData);
            }
        }
        else if ((is1099Worker || isMasterClientLoggedIn) && !excludeRoutes()) {
            if (Object.keys(clientData).length === 0) {
                getClientData(userProfilePro === null || userProfilePro === void 0 ? void 0 : userProfilePro.ClientId);
            }
            setSelectedClient(clientData);
        }
        else if (isAdmin && !excludeRoutes()) {
            setSelectedClient((_a = JSON.parse(localStorage.getItem("selectedClient"))) === null || _a === void 0 ? void 0 : _a.clientDto);
        }
        else {
            setSelectedClient(null);
        }
    }, [i9clientDropDownList, clientData]);
    var excludeRoutes = function () {
        return ["support", "paidsickleave", "personalinformation", "addresschange", "documentview"].some(function (element) { return window.location.href.toLowerCase().includes(element); });
    };
    return (_jsx("div", __assign({ className: "main-header ".concat(containerStyle) }, { children: _jsxs("div", __assign({ className: "page-title" }, { children: [_jsxs("div", __assign({ className: "d-flex align-items-center" }, { children: [((_a = props === null || props === void 0 ? void 0 : props.data) === null || _a === void 0 ? void 0 : _a.title) ? (props.useSmallTitle ? (_jsx("h5", __assign({ className: "" }, { children: (_b = props === null || props === void 0 ? void 0 : props.data) === null || _b === void 0 ? void 0 : _b.title }))) : (_jsx("h3", __assign({ className: "no-margin" }, { children: (_c = props === null || props === void 0 ? void 0 : props.data) === null || _c === void 0 ? void 0 : _c.title })))) : null, renderClientName()] })), _jsxs("div", __assign({ className: "d-md-flex" }, { children: [((_d = props === null || props === void 0 ? void 0 : props.data) === null || _d === void 0 ? void 0 : _d.subtitlelabel) && (_jsxs("h6", __assign({ className: "no-margin me-1" }, { children: [(_e = props === null || props === void 0 ? void 0 : props.data) === null || _e === void 0 ? void 0 : _e.subtitlelabel, " - "] }))), ((_f = props === null || props === void 0 ? void 0 : props.data) === null || _f === void 0 ? void 0 : _f.subtitle) && _jsx("span", { children: (_g = props === null || props === void 0 ? void 0 : props.data) === null || _g === void 0 ? void 0 : _g.subtitle })] }))] })) })));
};
export default PageTitle;
