var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import Button from "components/shared/Button";
import { Transform } from "helper/Transform";
import { TranslationConstant } from "common/constant";
var EmployeeInfoDetails = function (props) {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k;
    return (_jsxs(_Fragment, { children: [!props.hideSpacing && _jsx("div", { className: "spacing-15" }), _jsxs("div", __assign({ className: "emp-client-info ".concat(props.hideSpacing ? "margin-0" : "") }, { children: [_jsxs("ul", __assign({ className: "approve-job-bttns" }, { children: [_jsx("li", { children: _jsx(Button, { buttonText: _jsx(Transform, { mkey: TranslationConstant.BACK_BUTTON }), buttonIconClass: "fa-arrow-left", buttonClass: "btn-success", buttonClickHandler: function () { return props.backButtonHandler(); }, autoFocus: true }) }), ((_a = props.additionalButtons) === null || _a === void 0 ? void 0 : _a.approveJob) && (_jsx("li", { children: _jsx(Button, { buttonText: _jsx(Transform, { mkey: TranslationConstant.APPROVE_JOB_BUTTON }), buttonIconClass: "fa-envelope", buttonClass: "btn-success", buttonClickHandler: function () {
                                        return props.additionalButtons.approveJobHandler();
                                    } }) })), ((_b = props.additionalButtons) === null || _b === void 0 ? void 0 : _b.resendCreateUserlink) && (_jsx("li", { children: _jsx(Button, { buttonText: _jsx(Transform, { mkey: TranslationConstant.RESEND_CREATE_USER_LINK }), buttonIconClass: "fa-envelope", buttonClass: "btn-success", buttonClickHandler: function () {
                                        return props.additionalButtons.resendCreateUserHandler();
                                    } }) })), ((_c = props.additionalButtons) === null || _c === void 0 ? void 0 : _c.resendEmailI9Verifier) && (_jsx("li", { children: _jsx(Button, { buttonText: _jsx(Transform, { mkey: TranslationConstant.RESEND_EMAIL_I9_VERIFIER }), buttonIconClass: "fa-envelope", buttonClass: "btn-success", buttonClickHandler: function () {
                                        return props.additionalButtons.resendEmailI9VerifierHanler();
                                    } }) })), ((_d = props.additionalButtons) === null || _d === void 0 ? void 0 : _d.supportingDocuments) && (_jsx("li", { children: _jsx(Button, { buttonText: _jsx(Transform, { mkey: TranslationConstant.SUPPORTING_DOCUMENT_VIEWER }), buttonIconClass: "fa-envelope", buttonClass: "btn-success", buttonClickHandler: function () {
                                        return props.additionalButtons.supportingDocumentHandler();
                                    } }) })), ((_e = props.additionalButtons) === null || _e === void 0 ? void 0 : _e.viewDocuments) && (_jsx("li", { children: _jsx(Button, { buttonText: _jsx(Transform, { mkey: TranslationConstant.VIEW_DOCUMENTS }), buttonIconClass: "fa-envelope", buttonClass: "btn-success", buttonClickHandler: function () {
                                        return props.additionalButtons.viewDocumentHandler();
                                    } }) })), ((_f = props.additionalButtons) === null || _f === void 0 ? void 0 : _f.notApprove) && (_jsx("li", { children: _jsx(Button, { buttonText: _jsx(Transform, { mkey: TranslationConstant.LABEL_NOT_APPROVE_BTN }), buttonClass: "btn-success", buttonClickHandler: function () {
                                        return props.additionalButtons.NotApproveHandler();
                                    } }) })), ((_g = props.additionalButtons) === null || _g === void 0 ? void 0 : _g.Approve) && (_jsx("li", { children: _jsx(Button, { buttonText: _jsx(Transform, { mkey: TranslationConstant.LABEL_APPROVE_BTN }), buttonClass: "btn-success", buttonClickHandler: function () { return props.additionalButtons.ApproveHandler(); } }) }))] })), _jsx("ul", __assign({ className: "emp-info" }, { children: _jsxs("div", { children: [((_h = props.employeeDetails) === null || _h === void 0 ? void 0 : _h.employeeName) && (_jsxs("li", { children: [_jsx(Transform, { mkey: TranslationConstant.EMPLOYEE_TITLE }), ":", " ", _jsx("span", { children: props.employeeDetails.employeeName })] })), ((_j = props.employeeDetails) === null || _j === void 0 ? void 0 : _j.clientName) && (_jsxs("li", { children: [_jsx(Transform, { mkey: TranslationConstant.CLIENT_TITLE }), ":", " ", _jsx("span", { children: props.employeeDetails.clientName })] })), ((_k = props.employeeDetails) === null || _k === void 0 ? void 0 : _k.masid) && (_jsxs("li", { children: [_jsx(Transform, { mkey: TranslationConstant.EMPLOYEE_IDENTIFICATION_TITLE }), ": ", _jsx("span", { children: props.employeeDetails.masid })] }))] }) }))] }))] }));
};
export default EmployeeInfoDetails;
