var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import 'assets/css/login.scss';
export var EmployeeDashboardDialogBody = function () {
    var emailLink = "HR@payreel.com";
    var subject = "";
    var body = "";
    var PayreelHR = "mailto:".concat(emailLink, "?subject=").concat(encodeURIComponent(subject), "&body=").concat(encodeURIComponent(body));
    return (_jsx("div", __assign({ className: "employeeDashboardDialog" }, { children: _jsxs("div", __assign({ className: "d-flex" }, { children: [_jsx("i", { className: "fa fa-exclamation-circle fa-3x" }), _jsxs("div", { children: [_jsx("p", { children: "Please take a moment to review Federal and State Workplace Labor and Employment Law Posters for your specific work location. These postings are available in PayReel Online under the HR Documents section." }), _jsxs("div", { children: [_jsxs("p", { children: [" Please ", _jsx("a", __assign({ href: "/hrdocuments" }, { children: "Click here" })), " to access posters."] }), _jsxs("p", { children: ["Email ", _jsx("a", __assign({ href: PayreelHR }, { children: "HR@payreel.com" })), " if you have any questions on their contents."] })] })] })] })) })));
};
