var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import { addZeroes } from "components/shared/utils";
var DecimalGridCell = function (_a) {
    var cellData = _a.cellData, fieldName = _a.fieldName;
    return (_jsx("td", __assign({ colSpan: cellData.colSpan, role: "gridcell", "aria-colindex": cellData.ariaColumnIndex, "aria-selected": cellData.isSelected }, { children: addZeroes(cellData === null || cellData === void 0 ? void 0 : cellData.dataItem[fieldName]) })));
};
export default DecimalGridCell;
