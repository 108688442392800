var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import ChildGrid from "components/shared/ChildGrid";
import { getter } from "@progress/kendo-data-query";
import { getSelectedState, } from "@progress/kendo-react-grid";
import { DateFilterCell, ExpandCell, FooterCellWithCustomValue, } from "components/shared/GridCommonCells";
import { useState, useCallback, useEffect, cloneElement } from "react";
import { Grid, ActionCell, getUserDetailsFromLocalStorage, createEncodedURL, } from "components/shared";
import { useNavigate, useParams } from "react-router-dom";
import { useBatchesImportContext } from "store/batchimport/batchimport";
var DATA_ITEM_KEY = "expenseReportID";
var SELECTED_FIELD = "selected";
var idGetter = getter(DATA_ITEM_KEY);
export default function ExpenseReportsGrid(_a) {
    var _this = this;
    var _b = _a.colums, colums = _b === void 0 ? [] : _b, _c = _a.gridData, gridData = _c === void 0 ? [] : _c, handleRefresh = _a.handleRefresh, _d = _a.equipTotal, equipTotal = _d === void 0 ? 0 : _d, _e = _a.expenseTotal, expenseTotal = _e === void 0 ? 0 : _e, _f = _a.multiSelect, multiSelect = _f === void 0 ? false : _f, backRoute = _a.backRoute, title = _a.title, _g = _a.tabCode, tabCode = _g === void 0 ? "" : _g;
    var _h = useBatchesImportContext(), fetchExportExpandDetails = _h.fetchExportExpandDetails, selectedExportsData = _h.selectedExportsData, setSelectedExportsData = _h.setSelectedExportsData;
    var oidcUserProfile = getUserDetailsFromLocalStorage("pro");
    var params = useParams();
    var exportsId = parseInt(params.exportsId);
    var ClientDetails = JSON.parse(localStorage.getItem("selectedClient")).clientDto;
    var navigate = useNavigate();
    var _j = useState(gridData), GridData = _j[0], setGridData = _j[1];
    var _k = useState([]), childGridData = _k[0], setChildGridData = _k[1];
    var _l = useState(selectedExportsData["".concat(tabCode)] || {}), selectedRecords = _l[0], setSelectedRecords = _l[1];
    useEffect(function () {
        setGridData(gridData);
    }, [gridData]);
    useEffect(function () {
        if (selectedExportsData && selectedExportsData.length >= 0) {
            var formattedList = selectedExportsData.map(function (list) {
                return __assign(__assign({}, list), { weekEndingDate: (list === null || list === void 0 ? void 0 : list.weekEndingDate) ? new Date(list === null || list === void 0 ? void 0 : list.weekEndingDate) : "", expenseDate: (list === null || list === void 0 ? void 0 : list.expenseDate) ? new Date(list === null || list === void 0 ? void 0 : list.expenseDate) : "" });
            });
            setGridData(formattedList);
        }
    }, [selectedExportsData]);
    var updateSelectedRows = function (values) {
        var selectedRows = __assign({}, selectedExportsData);
        selectedRows["".concat(tabCode)] = values;
        setSelectedExportsData(selectedRows);
    };
    var onViewBtnClick = function (cell) {
        var dataItem = cell.dataItem;
        var vzSetup = ClientDetails === null || ClientDetails === void 0 ? void 0 : ClientDetails.vzSetup;
        var url = createEncodedURL("/viewexpensereport", {
            page: "view",
            FirstName: dataItem.firstName,
            LastName: dataItem.lastName,
            expenseReportID: dataItem.expenseReportID,
            UserRoleId: oidcUserProfile === null || oidcUserProfile === void 0 ? void 0 : oidcUserProfile.WebUserRoleId,
            clientId: oidcUserProfile === null || oidcUserProfile === void 0 ? void 0 : oidcUserProfile.ClientId,
            weekEndingDate: dataItem.weekEndingDate,
            redirectFrom: "batches",
            redirectFromUrl: {
                id: "exportAPDetails",
                text: title || "AP Export Expense Report",
                route: backRoute || "/batchapexports/".concat(exportsId, "?activetab=0"),
            },
            isVZClient: vzSetup,
            hideReturnToEmployee: true,
        });
        navigate(url);
    };
    var getColumns = function () {
        return multiSelect
            ? [
                {
                    title: "",
                    field: SELECTED_FIELD,
                    width: "40px",
                    filterable: false,
                    headerSelectionValue: GridData.findIndex(function (item) { return !selectedRecords[idGetter(item)]; }) === -1,
                },
            ]
            : [];
    };
    var Columns = __spreadArray(__spreadArray(__spreadArray(__spreadArray([
        {
            field: "",
            cell: function (cell) { return _jsx(ExpandCell, { dataItem: cell.dataItem, expandChange: handleExpand }); },
            width: "25px",
            title: "",
            filterable: false,
        }
    ], getColumns(), true), [
        {
            cell: function (cell) { return (_jsx(ActionCell, { cellprops: cell, title: "View", onClick: function () { return onViewBtnClick(cell); } })); },
            field: "",
            title: "View",
            width: "80px",
            filterable: false,
        }
    ], false), colums, true), [
        {
            field: "batchName",
            title: "Batch Name",
            width: "120px",
        },
        {
            field: "weekEndingDate",
            title: "Week Ending Date",
            width: "120px",
            filter: "date",
            format: "{0:MM/dd/yy}",
            filterCell: DateFilterCell,
        },
        {
            field: "lastName",
            title: "Last Name",
            width: "120px",
        },
        multiSelect
            ? {
                field: "firstName",
                title: "First Name",
                width: "120px",
            }
            : {
                field: "firstName",
                title: "First Name",
                width: "120px",
                footerCell: function (cell) { return (_jsx(FooterCellWithCustomValue, __assign({}, cell, { cellTitle: "Expense Total", colSpan: 2, cellTotal: "".concat(expenseTotal) }))); },
            },
        multiSelect
            ? {
                field: "amount",
                title: "Amount",
                width: "120px",
                format: "{0:c2}",
            }
            : {
                field: "amount",
                title: "Amount",
                width: "120px",
                format: "{0:c2}",
                footerCell: function (cell) { return (_jsx(FooterCellWithCustomValue, __assign({}, cell, { cellTitle: "Equipment Total", colSpan: 2, cellTotal: "".concat(equipTotal) }))); },
            },
    ], false);
    var ChildGridColumns = [
        {
            field: "expenseDate",
            title: "Expense Date",
            width: "120px",
            filter: "date",
            format: "{0:MM/dd/yy}",
            filterCell: DateFilterCell,
        },
        {
            field: "accountName",
            title: "Account",
            width: "120px",
        },
        {
            field: "subAccountName",
            title: "Sub Account",
            width: "120px",
        },
        {
            field: "amount",
            title: "Amount",
            width: "100px",
            format: "{0:c2}",
        },
        {
            field: "expenseCode",
            title: "Expense Code",
            width: "120px",
        },
        {
            field: "description",
            title: "Description",
            width: "120px",
        },
    ];
    var handleExpand = function (dataItem) { return __awaiter(_this, void 0, void 0, function () {
        var gridDataObj, res;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    gridDataObj = GridData.map(function (item) {
                        if (item.expenseReportID === dataItem.expenseReportID) {
                            if (item === null || item === void 0 ? void 0 : item.expanded) {
                                item = __assign(__assign({}, item), { expanded: false });
                                dataItem = __assign(__assign({}, dataItem), { expanded: false });
                            }
                            else {
                                item = __assign(__assign({}, item), { expanded: true });
                                dataItem = __assign(__assign({}, dataItem), { expanded: true });
                            }
                        }
                        else {
                            item.expanded = false;
                        }
                        return item;
                    });
                    if (!(dataItem === null || dataItem === void 0 ? void 0 : dataItem.expanded)) return [3 /*break*/, 2];
                    return [4 /*yield*/, fetchExportExpandDetails(dataItem.expenseReportID, "apExpenseReport")];
                case 1:
                    res = _a.sent();
                    setChildGridData(__spreadArray([], res.data, true));
                    _a.label = 2;
                case 2:
                    setGridData(gridDataObj);
                    return [2 /*return*/];
            }
        });
    }); };
    var onSelectionChange = useCallback(function (event) {
        var _a;
        var newSelectedState = ((_a = event.dataItems["".concat(event.endRowIndex)]) === null || _a === void 0 ? void 0 : _a.budgetException)
            ? selectedRecords
            : getSelectedState({
                event: event,
                selectedState: selectedRecords,
                dataItemKey: DATA_ITEM_KEY,
            });
        setSelectedRecords(newSelectedState);
        updateSelectedRows(newSelectedState);
    }, [selectedRecords]);
    var onHeaderSelectionChange = useCallback(function (event) {
        var checkboxElement = event.syntheticEvent.target;
        var checked = checkboxElement.checked;
        var newSelectedState = {};
        event.dataItems.forEach(function (item) {
            newSelectedState[idGetter(item)] = (item === null || item === void 0 ? void 0 : item.budgetException) ? false : checked;
        });
        setSelectedRecords(newSelectedState);
        updateSelectedRows(newSelectedState);
    }, []);
    var rowRender = function (trElement, props) {
        var _a;
        var budgetException = (_a = props === null || props === void 0 ? void 0 : props.dataItem) === null || _a === void 0 ? void 0 : _a.budgetException;
        var red = {
            backgroundColor: "rgb(243, 23, 0, 0.32)",
        };
        var trProps = {
            style: budgetException ? red : {},
        };
        return cloneElement(trElement, __assign({}, trProps), trElement.props.children);
    };
    return (_jsx(_Fragment, { children: multiSelect ? (_jsx(Grid, { columns: Columns, customFilterTitles: { subAccountName: "Sub Account" }, gridData: GridData.map(function (item) {
                var _a;
                return (__assign(__assign({}, item), (_a = {}, _a[SELECTED_FIELD] = selectedRecords[idGetter(item)], _a)));
            }), dataItemKey: DATA_ITEM_KEY, selectedField: SELECTED_FIELD, selectable: {
                enabled: true,
                drag: false,
                cell: false,
                mode: "multiple",
            }, onSelectionChange: onSelectionChange, onHeaderSelectionChange: onHeaderSelectionChange, handleRefresh: handleRefresh, childGrid: function (cell) { return (_jsx(ChildGrid, { columns: ChildGridColumns, gridData: childGridData, isRowRender: true })); }, expandField: "expanded", containerStyle: "expand-grid", rowRender: rowRender })) : (_jsx("div", __assign({ className: "hide-grid-footer-border" }, { children: _jsx(Grid, { handleRefresh: handleRefresh, childGrid: function (cell) { return (_jsx(ChildGrid, { columns: ChildGridColumns, gridData: childGridData })); }, columns: Columns, customFilterTitles: { subAccountName: "Sub Account" }, gridData: GridData, expandField: "expanded", containerStyle: "expand-grid" }) }))) }));
}
