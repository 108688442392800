var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import * as React from 'react';
import { Link } from 'react-router-dom';
import { Grid, GridColumn as Column, } from "@progress/kendo-react-grid";
import { useWebUsersViewContext, ComponentConstant, AppConstant, GridRefresher, GridFilters, getGridItemCount, setGridItemCount, EllipsisGridCell } from './index';
import { createEncodedURL } from 'components/shared/utils';
import { GridOData } from 'components/shared/GridOData';
import { Tooltip } from '@progress/kendo-react-tooltip';
var initialDataState = { skip: 0, take: AppConstant.GRID_ITEM_TAKE_SIZE };
export var SearchWebUserGrid = function (props) {
    var _a = useWebUsersViewContext(), resetWebUserList = _a.resetWebUserList, fetchWebUsersList = _a.fetchWebUsersList;
    var userData = props.userData;
    var _b = React.useState(initialDataState), dataState = _b[0], setDataState = _b[1];
    var _c = React.useState({
        data: [],
        total: 0,
    }), gridData = _c[0], setGridData = _c[1];
    var _d = React.useState(), showFilter = _d[0], setShowHideFilter = _d[1];
    var _e = React.useState([]), filter = _e[0], setFilter = _e[1];
    var _f = React.useState(false), forceReload = _f[0], setForceReload = _f[1];
    React.useEffect(function () {
        setInitialStates();
        return (resetWebUserList());
    }, []);
    React.useEffect(function () {
        setInitialStates();
    }, [userData]);
    var setInitialStates = function () {
        var Grid_Item_Count = getGridItemCount();
        setDataState(__assign(__assign({}, dataState), { take: Grid_Item_Count, skip: 0 }));
    };
    var dataReceived = function (results) {
        setGridData(results);
    };
    function Datetimeformat(value) {
        var LoginSuccess = "";
        if (value) {
            var date1 = value.split("T")[0];
            if (date1.includes('1900')) {
                return '';
            }
            var time1 = value.split("T")[1];
            var Stime = time1.split(".")[0];
            var Sdiff = Number(Stime.split(":")[0]);
            var Stimeset = '';
            if (Sdiff > 11) {
                Stimeset = "PM";
            }
            else {
                Stimeset = "AM";
            }
            LoginSuccess = date1 + " " + Stime + " " + Stimeset;
        }
        ;
        return LoginSuccess;
    }
    ;
    var LastLoginSuccess = function (props) {
        return (_jsx("td", __assign({ colSpan: props.colSpan, role: "gridcell", "aria-colindex": props.ariaColumnIndex, "aria-selected": props.isSelected, title: Datetimeformat(props.dataItem['lastLoginSuccess']), className: "ellipsis-text" }, { children: Datetimeformat(props.dataItem['lastLoginSuccess']) })));
    };
    var LastLoginFailure = function (props) {
        return (_jsx("td", __assign({ colSpan: props.colSpan, role: "gridcell", "aria-colindex": props.ariaColumnIndex, "aria-selected": props.isSelected, title: Datetimeformat(props.dataItem['lastLoginFailure']), className: "ellipsis-text" }, { children: Datetimeformat(props.dataItem['lastLoginFailure']) })));
    };
    var EditDocumentsCell = function (props) {
        return (_jsx("td", __assign({ colSpan: props.colSpan, role: "gridcell", "aria-colindex": props.ariaColumnIndex, "aria-selected": props.isSelected }, { children: _jsxs(Link, __assign({ to: createEncodedURL("/editwebuser", {
                    WebUserID: props.dataItem['webUserID'],
                    firstName: props.dataItem['firstName'],
                    lastName: props.dataItem['lastName'],
                    LastLoginSuccess: props.dataItem['lastLoginSuccess'],
                    LastLoginFailure: props.dataItem['lastLoginFailure'],
                    WebUserRole: props.dataItem['role'],
                    WebUserStatus: props.dataItem['WebUserStatus'],
                    userData: userData
                }) }, { children: [_jsx("i", { className: "fa fa-edit fa-lg" }), ComponentConstant.EDIT] })) })));
    };
    var EditDocumentsCustomCell = function (props) {
        var _a;
        if (((_a = props === null || props === void 0 ? void 0 : props.dataItem) === null || _a === void 0 ? void 0 : _a.webUserStatus) === '') {
            return _jsx("td", {});
        }
        return _jsx(EditDocumentsCell, __assign({}, props));
    };
    return (_jsxs(_Fragment, { children: [_jsx(GridFilters, { filter: filter, showFilter: showFilter, setShowHideFilter: setShowHideFilter }), _jsxs("div", __assign({ className: "general-grid" }, { children: [_jsx(Tooltip, __assign({ openDelay: 100 }, { children: _jsxs(Grid, __assign({ sortable: true, pageable: {
                                buttonCount: AppConstant.BUTTON_COUNT_GRID,
                                info: true,
                                type: 'numeric',
                                pageSizes: AppConstant.PAGE_SIZE,
                                previousNext: true
                            }, filterable: showFilter }, dataState, { data: gridData, onDataStateChange: function (e) {
                                var _a, _b;
                                if (((_a = e.dataState) === null || _a === void 0 ? void 0 : _a.take) !== dataState.take) {
                                    setGridItemCount((_b = e.dataState) === null || _b === void 0 ? void 0 : _b.take);
                                }
                                setDataState(e.dataState);
                                setFilter(e.dataState.filter && e.dataState.filter.filters);
                            } }, { children: [_jsx(Column, { field: "firstName", title: "First Name", width: "100px" }), _jsx(Column, { field: "lastName", title: "Last Name", width: "100px" }), _jsx(Column, { field: "webUserStatus", title: "User Status", width: "90px", filterable: false, sortable: false }), _jsx(Column, { field: "", title: "Last Login Success", width: "110px", filterable: false, sortable: false, cell: LastLoginSuccess }), _jsx(Column, { field: "", title: "Last Login Failure", width: "110px", filterable: false, sortable: false, cell: LastLoginFailure }), _jsx(Column, { field: "role", title: "Role", width: "100px", cell: function (cell) { return _jsx(EllipsisGridCell, { dataItem: cell === null || cell === void 0 ? void 0 : cell.dataItem, cellField: 'role' }); } }), _jsx(Column, { field: "client", title: "Client", width: "100px", cell: function (cell) { return _jsx(EllipsisGridCell, { dataItem: cell === null || cell === void 0 ? void 0 : cell.dataItem, cellField: 'client' }); } }), _jsx(Column, { field: "", title: "Edit", width: "100px", filterable: false, cell: EditDocumentsCustomCell })] })) })), _jsx(GridRefresher, { refreshGridDataHandler: function () { return setForceReload(!forceReload); } }), userData && _jsxs("div", { children: [" ", _jsx(GridOData, { dataState: dataState, onDataReceived: dataReceived, contextMethod: function (queryParam) { return fetchWebUsersList(queryParam); }, paramObj: userData })] }, "".concat(forceReload))] }))] }));
};
