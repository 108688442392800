import { useDashboardContext } from "store/dashboard/dashboard";
import { useCurrentViewContext } from "store/common/currentViewContext";
import { Transform } from "helper/Transform";
import { AppConstant, ComponentConstant, TranslationConstant } from "common/constant";
import { BreadcrumbComponent } from "components/shared/BreadCrumb";
import { CardsComponent } from "components/shared/CardsComponent";
import PageTitle from "components/shared/PageTitle";
import GridRefresher from "components/shared/GridRefersher";
import { GridFilters } from "components/shared/GridFilters";
import { DialogBox } from "components/shared/DialogBox";
import { createEncodedURL, decodedURL } from "components/shared/utils";
import { TabsComponent } from "components/shared/TabsComponent";
import FormHeading from "components/shared/FormHeading";
import { EmployeeAuthorisedTaskGrid } from "./EmployeeAgentTaskGrid";
import { EmployeeAuthorisedInformation } from "./EmployeeAgentInfo";
import { SignaturePanel } from "components/shared/SignaturePanel";
import Button from "components/shared/Button";
import { useI9VerificationViewContext } from "store/i9reverification/i9reverification";
import { FormDatePicker, FormInput, FormRadioGroup, FormDropDownList, FormDropDownListWithValue, } from "components/shared/FormComponents";
import { requiredValidator } from "components/shared/FormValidators";
import { DocumentViewer } from "components/shared/DocumentViewer";
var documentListTypeOptions = [
    {
        label: "List A (Identity and Employment Authorization)",
        value: 1,
    },
    {
        label: "List B (Identity) and C (Employment Authorization)",
        value: 2,
    },
];
import { getGridItemCount, setGridItemCount } from "components/shared/utils";
export { AppConstant, ComponentConstant, useCurrentViewContext, CardsComponent, EmployeeAuthorisedTaskGrid, useDashboardContext, Transform, TranslationConstant, BreadcrumbComponent, PageTitle, GridRefresher, GridFilters, TabsComponent, DialogBox, createEncodedURL, FormHeading, EmployeeAuthorisedInformation, FormDatePicker, FormDropDownList, FormInput, FormRadioGroup, useI9VerificationViewContext, documentListTypeOptions, requiredValidator, SignaturePanel, Button, getGridItemCount, setGridItemCount, FormDropDownListWithValue, DocumentViewer, decodedURL, };
