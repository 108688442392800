export var ExportsButtonLists = [
    {
        id: "payrollExports",
        title: "Payroll Exports",
        routes: "/batchpayrollexports",
    },
    {
        id: "apExports",
        title: "AP Exports",
        routes: "/batchapexports",
    },
    {
        id: "arExports",
        title: "AR Exports",
        routes: "/batcharexports",
    },
    {
        id: "managePayrollRecords",
        title: "Manage Payroll Records",
        routes: "/managepayrollrecords",
    },
    {
        id: "manageapExports",
        title: "Manage AP Records",
        routes: "/manageaprecords",
    },
    {
        id: "arManageExports",
        title: "Manage AR Records",
        routes: "/managearrecords",
    },
];
export var EXPORTS_BREADCRUMB = {
    id: "exports",
    text: "Exports",
    route: "/batchexports",
};
export var PAYROLL_EXPORTS_BREADCRUMB = {
    id: "payrollExports",
    text: "Payroll Exports",
    route: "/batchpayrollexports",
};
export var AP_EXPORTS_BREADCRUMB = {
    id: "apExports",
    text: "AP Exports",
    route: "/batchapexports",
};
export var AR_EXPORTS_BREADCRUMB = {
    id: "arExports",
    text: "AR Exports",
    route: "/batcharexports",
};
export var AP_EXPORTS_MANAGE_BREADCRUMB = {
    id: "manageapExports",
    text: "Manage AP Records",
    route: "/manageaprecords",
};
export var AR_EXPORTS_MANAGE_BREADCRUMB = {
    id: "managearExports",
    text: "Manage AR Records",
    route: "/managearrecords",
};
export var PAYROLL_EXPORTS_MANAGE_BREADCRUMB = {
    id: "managepayrollExports",
    text: "Manage Payroll Records",
    route: "/managepayrollrecords",
};
export var BATCH_RECORDS_BREADCRUMB = {
    id: "batchRecords",
    text: "Batch Records",
    route: "",
};
export var BACK_BUTTON_DATA = {
    id: "back",
    title: "Back",
    active: true,
    icon: "fa-arrow-left pe-1",
    noTransformText: true,
};
export var ADD_EXPORTS_BUTTON_DATA = {
    id: "addExports",
    title: "Add Export",
    active: true,
    noTransformText: true,
};
export var RETURN_TO_EXPORTS_BUTTON = {
    id: "returnToExports",
    title: "Return To Exports",
    active: true,
    noTransformText: true,
};
export var RETURN_TO_PAYROLL_EXPORTS_BUTTON = {
    id: "returnToPayrollExports",
    title: "Return To Payroll Exports",
    active: true,
    noTransformText: true,
    autoFocus: true,
};
export var RETURN_TO_AR_EXPORTS_BUTTON = {
    id: "returnToARExports",
    title: "Return To AR Exports",
    active: true,
    noTransformText: true,
    autoFocus: true,
};
export var AR_EXPORTS_BUTTON = {
    id: "ARExports",
    title: "A/R Exports",
    active: true,
    noTransformText: true,
    autoFocus: true,
};
export var CLOSE_EXPORT_BUTTON = {
    id: "closeExport",
    title: "Close Export",
    active: true,
    noTransformText: true,
    disabled: false,
};
export var REOPEN_EXPORT_BUTTON = {
    id: "reopenExport",
    title: "ReOpen Export",
    active: true,
    noTransformText: true,
    disabled: false,
};
export var PAYROLL_EXPORT_SAGE_BUTTON = {
    id: "payrollExportSage",
    title: "Payroll Export - SAGE",
    active: true,
    noTransformText: true,
};
export var PAYROLL_EXPORT_THINKWARE_BUTTON = {
    id: "payrollExportThinkware",
    title: "Payroll Export - Thinkware",
    active: true,
    noTransformText: true,
};
export var EXPENSE_REIMBURSMENT_THINKWARE = {
    id: "EXPENSE_REIMBURSMENT_THINKWARE",
    title: "Expense Reimbursment-Thinkware",
    active: true,
    noTransformText: true,
};
export var AP_REPORT_FOR_ACCOUNTING = {
    id: "AP_Report_For_Accounting",
    title: "AP report for accounting",
    active: true,
    noTransformText: true,
};
export var VENDOR_COST_THINKWARE = {
    id: "VENDOR_COST_THINKWARE",
    title: "Vendor Payments & Equipment cost-Thinkware",
    active: true,
    noTransformText: true,
};
export var AP_EXPORT_DARWIN = {
    id: "AP_EXPORT_DARWIN",
    title: "A/P Export To Darwin",
    active: true,
    noTransformText: true,
};
export var RETURN_TO_AP_EXPORTS_BUTTON = {
    id: "returnToAPExports",
    title: "Return To AP Exports",
    active: true,
    noTransformText: true,
    autoFocus: true,
};
export var CONFIRM_TO_REMOVE_RECORD = "Are you sure you would like to remove this record?";
export var VIEW_BATCH_TABS = {
    CLIENT_TIMESHEETS: {
        id: 0,
        type: "batchDetailClientTimesheet",
    },
    CREDIT_CARD: {
        id: 1,
        type: "batchDetailCreditCard",
    },
    EXPENSE_REPORT: {
        id: 2,
        type: "batchDetailExpenseReport",
    },
    MANUAL_PAYMENT: {
        id: 3,
        type: "batchDetailManualPayment",
    },
    TIMESHEET: {
        id: 4,
        type: "batchDetailTimeSheet",
    },
    VENDOR_PAYMENT: {
        id: 5,
        type: "batchDetailVendorPayment",
    },
};
export var VIEW_AR_RECORD_TABS = [
    {
        id: 0,
        type: "nonAllocatedTSDetails",
        title: "TS Details",
        code: "AAAA",
        actionId: "timeSheetDayDetailID",
    },
    {
        id: 1,
        type: "allocatedTimesheetDetailsOracle",
        title: "TS Details - Allocated",
        code: "AAAB",
        actionId: "timeSheetDayDetailDollarsAllocationID",
    },
    {
        id: 2,
        type: "nonAllocatedERDetails",
        title: "ER Details",
        code: "AAAC",
        actionId: "expenseReportDetailID",
    },
    {
        id: 3,
        type: "allocatedERDetails",
        title: "ER Details - Allocated",
        code: "AAAD",
        actionId: "expenseReportDetailDollarsAllocationID",
    },
    {
        id: 4,
        type: "nonAllocatedVPDetails",
        title: "VP Details",
        code: "AAAE",
        actionId: "vendorPaymentDetailID",
    },
    {
        id: 5,
        type: "allocatedVPDetails",
        title: "VP Details - Allocated",
        code: "AAAF",
        actionId: "vendorPaymentDetailDollarsAllocationID",
    },
    {
        id: 6,
        type: "clientTimesheets",
        title: "Client Timesheet Details",
        code: "AAAG",
        actionId: "clientTimeSheetDayDetailId",
    },
    {
        id: 7,
        type: "manualPayments",
        title: "Manual Payments",
        code: "AAAH",
        actionId: "manualPaymentID",
    },
    {
        id: 8,
        type: "creditCardDetails",
        title: "Credit Cards",
        code: "AAAI",
        actionId: "creditCardItemID",
    },
];
export var VIEW_PAYROLL_TABS = {
    TIMESHEETS: {
        id: 0,
        type: "timesheet",
        title: "Timesheets",
        code: "AAAA",
    },
    MANUAL_PAYMENTS: {
        id: 1,
        type: "manualPayment",
        title: "Manual Payments",
        code: "AAAB",
    },
};
export var BATCH_VIEW_TABS_CODES = ["AAAA", "AAAB", "AAAC", "AAAD", "AAAE", "AAAF"];
export var VIEW_BATCHES_BREADCRUMB = {
    id: "viewBatches",
    text: "Batches",
    route: "/viewbatches",
};
export var BATCH_DETAIL_BREADCRUMB = {
    id: "batchDetail",
    text: "Batch Detail",
    route: "",
};
export var VIEW_AP_RECORD_TABS = [
    {
        id: 0,
        type: "expenseReports",
        title: "Expense Reports",
        code: "AAAA",
        actionId: "expenseReportID",
    },
    {
        id: 1,
        type: "vendorPayments",
        title: "Vendor Payments",
        code: "AAAB",
        actionId: "vendorPaymentID",
    },
    {
        id: 2,
        type: "manualPayments",
        title: "Manual Payments",
        code: "AAAC",
        actionId: "manualPaymentID",
    },
];
export var prepareSelctedRows = function (selectedItemObj) {
    var selectedRow = [];
    for (var entityType in selectedItemObj) {
        for (var entityId in selectedItemObj[entityType]) {
            if (selectedItemObj[entityType][entityId]) {
                selectedRow.push({ entityId: parseInt(entityId), entityType: entityType });
            }
        }
    }
    return selectedRow;
};
export var EntityTypesEnum;
(function (EntityTypesEnum) {
    EntityTypesEnum["TimeSheet"] = "AAAA";
    EntityTypesEnum["TimeSheetAllocated"] = "AAAB";
    EntityTypesEnum["ExpenseReport"] = "AAAC";
    EntityTypesEnum["ExpenseReportAllocated"] = "AAAD";
    EntityTypesEnum["VendorPayment"] = "AAAE";
    EntityTypesEnum["VendorPaymentAllocated"] = "AAAF";
    EntityTypesEnum["ClientTimeSheet"] = "AAAG";
    EntityTypesEnum["ManualPayment"] = "AAAH";
    EntityTypesEnum["CreditCard"] = "AAAI";
})(EntityTypesEnum || (EntityTypesEnum = {}));
export var NEP_AP_BUTTON = {
    id: "NEPAPVoucher",
    title: "NEP AP Voucher",
    active: true,
    noTransformText: true,
};
