import { httpPromise } from "services/common/httpPromise";
import { ServiceConstant } from "common/constant";
export var getAllBatches = function (ClientID) {
    return httpPromise.get("".concat(ServiceConstant.GET_BATCH_API_PATH, "/GetBatchesAsync?clientid=").concat(ClientID));
};
export var getBatchViewDetails = function (batchId, type) {
    var endpoint = "".concat(ServiceConstant.CORP_API_BASE_PATH, "/").concat(type, "/");
    switch (type) {
        case "batchDetailExpenseReport":
            endpoint += "GetBatchedExpenseReports";
            break;
        case "batchDetailCreditCard":
            endpoint += "";
            break;
        case "batchDetailVendorPayment":
            endpoint += "GetBatchedVendorPayments";
            break;
        case "batchDetailManualPayment":
            endpoint += "GetBatchedManualPayments";
            break;
        case "batchDetailTimeSheet":
            endpoint += "GetBatchedTimeSheet";
            break;
        case "batchDetailClientTimesheet":
            endpoint += "GetBatchedClientTimeSheets";
            break;
        default:
            break;
    }
    return httpPromise.get("".concat(endpoint, "?batchid=").concat(batchId));
};
export var removeBatchRecord = function (recordId, type) {
    var endpoint = ServiceConstant.CORP_API_BASE_PATH;
    switch (type) {
        case "batchDetailExpenseReport":
            endpoint += "/BatchDetailExpenseReport?expenseReportId=".concat(recordId);
            break;
        case "batchDetailCreditCard":
            endpoint += "/BatchDetailCreditCard?creditCardItemId=".concat(recordId);
            break;
        case "batchDetailVendorPayment":
            endpoint += "/BatchDetailVendorPayment?vendorPaymentId=".concat(recordId);
            break;
        case "batchDetailManualPayment":
            endpoint += "/BatchDetailManualPayment?manualPaymentId=".concat(recordId);
            break;
        case "batchDetailTimeSheet":
            endpoint += "/BatchDetailTimesheet?timeSheetId=".concat(recordId);
            break;
        case "batchDetailClientTimeSheet":
            endpoint += "/BatchDetailClientTimeSheet?clientTimesheetId=".concat(recordId);
            break;
        default:
            break;
    }
    return httpPromise.put(endpoint);
};
export var getBatchViewDetailsTabCount = function (batchId, activeTab) {
    return httpPromise.get("".concat(ServiceConstant.CORP_API_BASE_PATH, "/BatchDetails/GetBatchEntityCounts?batchid=").concat(batchId, "&activeTabCode=").concat(activeTab));
};
export var createBatch = function (clientId) {
    return httpPromise.post("".concat(ServiceConstant.CORP_API_BASE_PATH, "/Batch?clientId=").concat(clientId));
};
