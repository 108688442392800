import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { Transform, TranslationConstant, BreadcrumbComponent, PageTitle, AdminTaskGrid, ComponentConstant, AppConstant, } from "./index";
export var AdminAgentTasks = function () {
    var userProfile = localStorage.getItem("oidc.user:".concat(process.env.AUTHORITY_URL, ":").concat(process.env.CLIENT_ID));
    var items = [
        {
            id: "home",
            text: "Home",
            iconClass: "k-i-home",
            route: AppConstant.ONBOARDING_APPLICATION_ADMIN_PATH,
        },
        {
            id: "Dashboard",
            text: "Dashboard",
        },
    ];
    var adminAgentTask = [
        {
            firstName: "Aaron",
            lastName: "Gelperin",
            document: "Document Name",
            docId: "12314",
        },
        {
            firstName: "Daniel",
            lastName: "Wilder",
            document: "Document Name",
            docId: "12314",
        },
        {
            firstName: "Liam",
            lastName: "Stevens",
            document: "Document Name",
            docId: "12314",
        },
        {
            firstName: "Darren",
            lastName: "Fleming",
            document: "Document Name",
            docId: "12314",
        },
        {
            firstName: "testI9",
            lastName: "testI",
            document: "Document Name",
            docId: "12314",
        },
    ];
    var localStorageKey = "".concat(AppConstant.LOCAL_STORAGE_USER_PROFILE, ":").concat(process.env.AUTHORITY_URL, ":").concat(process.env.CLIENT_ID);
    var userDetails = (localStorage.getItem(localStorageKey) &&
        JSON.parse(atob(localStorage.getItem(localStorageKey)))) ||
        null;
    var data = {
        title: _jsx(Transform, { mkey: TranslationConstant.DASHBOARD }),
        subtitle: "".concat(ComponentConstant.WELCOME_BACK_TEXT, " ").concat(userDetails === null || userDetails === void 0 ? void 0 : userDetails.FirstName, " ").concat(userDetails === null || userDetails === void 0 ? void 0 : userDetails.LastName),
    };
    return (_jsxs(_Fragment, { children: [_jsx(BreadcrumbComponent, { items: items }), _jsx(PageTitle, { data: data }), _jsx(AdminTaskGrid, { adminHeading: _jsx(Transform, { mkey: TranslationConstant.AGENTS_TASKS_HEADING }), adminTasks: adminAgentTask })] }));
};
